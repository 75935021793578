// export default {
//   /**
//    * Returns if an user is an admin
//    *
//    * @param { array } roles
//    * @returns
//    */
//   isAdmin( roles ) {
//     return roles.some( ( element ) => element.name === 'Administrator' );
//   },
// };

/* Find pool */
const SAVE_EDIT_DETAILS = "Inqwiry::save_edit_details";
const USER_NAME = "Inqwiry::user_name";
export const ACCESS_TOKEN = "Inqwiry::access_token";

const Utils = {
  /* Autherization */
  setSavedEditDetails(save_edit_details) {
    localStorage.setItem(SAVE_EDIT_DETAILS, save_edit_details);
  },
  getSavedEditDetails() {
    return localStorage.getItem(SAVE_EDIT_DETAILS);
  },
  removeSavedEditDetails() {
    localStorage.removeItem(SAVE_EDIT_DETAILS);
  },
  setUserName(user_name) {
    localStorage.setItem(USER_NAME, user_name);
  },
  getUserName() {
    return localStorage.getItem(USER_NAME);
  },
  removeUserName() {
    localStorage.removeItem(USER_NAME);
  },
  setAccessToken(access_token) {
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(access_token));
  },
  getAccessToken() {
    return JSON.parse(localStorage.getItem(ACCESS_TOKEN))?.access_token;
  },
  getAdminType() {
    return JSON.parse(localStorage.getItem(ACCESS_TOKEN))?.admin_type;
  },
  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN);
  },
  /* Logout */
  logout() {},
};
export default Utils;
