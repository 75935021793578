import * as commonFunctions from "../../../utilities/commonFunctions";

import {
  Box,
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Dialog, DialogTitle, Grid } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Pageloader from "../pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTable from "../Table/ReactTable";
import Typography from "@material-ui/core/Typography";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import { size } from "lodash";
import styles from "./styles";
import useDebounce from "../../../hooks/useDebounce";
import { useUserDetailsContext } from "../../../context/userDetails";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const ALLADMINS = loader("../../../graphql/schema/ui/GetAdmins.graphql");
const DELETEADMIN = loader("../../../graphql/schema/ui/deleteAdmin.graphql");
const EXPORT_INKWIRY_ADMIN = loader(
  "../../../graphql/schema/ui/exportInkwiryAdmins.graphql"
);

const InkwiryAdmins = (props) => {
  const ref = React.useRef(null);
  const {
    state: { search = "" },
    clearSearch,
  } = useUserDetailsContext();
  const debouncedSearch = useDebounce(search, 1000);
  const { classes, history } = props;
  const [selectedType, setSelectedType] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [allAdminsData, setAllAdminsData] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] =
    React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [openSuccesPopup, setOpenSuccesPopup] = React.useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [openFullNameFilters, setOpenFullNameFilters] = React.useState(false);
  const [level, setLevel] = useState({ key: "name", val: null });
  const [resetFilter, setResetFilter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const ref = React.useRef(null);

  // const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //         setOpenFirstNameFilters(false);
  //     }
  // };
  // React.useEffect(() => {
  //     document.addEventListener('click', handleClickOutside, true);
  //     return () => {
  //         document.removeEventListener('click', handleClickOutside, true);
  //     };
  // });

  const [getAllAdmin, { fetchMore }] = useLazyQuery(ALLADMINS, {
    variables: {
      data: {
        search_query: "",
        page: 1,
        perPage: 20,
        orderBy: "",
        sortBy: "",
      },
    },
    fetchPolicy: "network-only",
    onCompleted({ GetAdmins }) {
      if (GetAdmins) {
        for (let index = 0; index < GetAdmins?.admin.length; index++) {
          const element = GetAdmins["admin"][index];
          element.admin_id = index + 1;
        }
        setAllAdminsData(GetAdmins?.admin);
        setActivePage(GetAdmins?.page);
        setPageFineshedCount(GetAdmins.totalCount);
        setLoading(false);
        setResetFilter(false);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  useEffect(
    () => {
      setLoading(true);
      setActivePage(1);
      setLevel({ key: "name", val: null });
      getAllAdmin({
        variables: {
          data: {
            page: 1,
            perPage: 20,
            search_query: debouncedSearch,
            orderBy: "",
            sortBy: "",
          },
        },
      });
    },
    [debouncedSearch] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    getAllAdmin();
  }, []);

  const [deleteAdminData, { data: deleteResult }] = useMutation(DELETEADMIN, {
    onCompleted() {
      setOpenConfirmDeletePopup(false);
      setDeleteId("");
      setLoading(false);
      getAllAdmin();
      // setOpenSuccesPopup(true);
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const [exportInkwiryAdminData, { data: exportData }] = useLazyQuery(
    EXPORT_INKWIRY_ADMIN,
    {
      fetchPolicy: "network-only",
      onCompleted() {
        const data = exportData.exportInkwiryAdmins;
        if (data) {
          var link = document.createElement("a");
          link.download = "support";
          link.href = data.url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
        }
      },
      onError(error) {
        let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
        setErrorMessage(errorMsg);
        setLoading(false);
      },
    }
  );
  const exportInkwiryAdminTableData = () => {
    setLoading(true);
    exportInkwiryAdminData({
      variables: {
        data: {
          search_query: debouncedSearch,
          sortBy: level?.key,
          orderBy: level?.val,
        },
      },
    });
  };

  React.useEffect(() => {
    if (deleteResult) {
      setLoading(false);
    }
  }, [deleteResult]);
  const handleOpenConfirmDeletePopup = (id) => {
    setDeleteId(id);
    setOpenConfirmDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenConfirmDeletePopup(false);
    setDeleteId("");
  };

  const handleDeleteAdmin = () => {
    setLoading(true);
    deleteAdminData({
      variables: {
        id: deleteId,
      },
    });
  };

  const handleUserEdit = (id) => {
    history.push("/edit-admin?admin_id=" + id);
  };
  // const handleClose = () => {
  //   setOpenSuccesPopup(false);
  //   window.location.reload(true);
  // };

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    fetchMore({
      variables: {
        data: {
          page: pageNumber,
          perPage: 20,
          search_query: debouncedSearch,
          sortBy: level?.key,
          orderBy: level?.val,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setAllAdminsData(fetchMoreResult?.GetAdmins?.admin);
      },
    });
    setActivePage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const convertPassword = (password) => {
    // let passwordStr = password.toString();
    let encryPass = "*";
    for (let i = 0; i < 10; i++) {
      encryPass = encryPass + "*";
    }
    return encryPass;
  };

  const handleSorting = (row, val) => {
    setLoading(true);
    setLevel({ key: row, val });
    getAllAdmin({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          search_query: debouncedSearch,
          sortBy: row,
          orderBy: val,
        },
      },
    });
  };

  const handleClearAllFilters = () => {
    setLoading(true);
    setResetFilter(true);
    setLevel({ key: "name", val: null });
    setActivePage(1);
    clearSearch();
    getAllAdmin({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          search_query: "",
          sortBy: "",
          orderBy: "",
        },
      },
    });
  };

  return (
    <Typography className="main-bannerRight">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <Grid container className={classes.searchRow} spacing={2}>
        <Grid item md={6} sm={6} xs={12}></Grid>
        <Grid item md={6} sm={6} xs={12}>
          <div>
            {pageFineshedCount > 0 && (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={20}
                totalItemsCount={pageFineshedCount}
                pageRangeDisplayed={5}
                onChange={handleGetFineshedMatchesPageClick}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <ReactTable
        activePage={activePage}
        count={pageFineshedCount}
        filterFunction={(row, value) => {
          handleSorting(row, value);
        }}
        resetFilter={resetFilter}
        columns={[
          {
            name: "First Name",
            row: "name",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "Last Name",
            row: "user_lname",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "Email",
            row: "email",
          },
          {
            name: "Username",
            row: "user_name",
          },
          {
            name: "Password",
            row: "password",
            customRowComponent: (value) => convertPassword(value),
          },
          {
            name: "Schools",
            row: "inkwiry_school",
            customRowComponent: (value) => {
              return (
                <Box display={"flex"} flexWrap="wrap" width={"20rem"}>
                  {value.map((l, i) => {
                    return (
                      <Box paddingRight={1}>{`${l.institution_name}${
                        i !== value.length - 1 ? "," : ""
                      }`}</Box>
                    );
                  })}
                </Box>
              );
            },

            // <span
            //   dangerouslySetInnerHTML={{
            //     __html: value,
            //   }}
            // ></span>
          },
          {
            name: "Businesses",
            row: "inkwiry_business",
            customRowComponent: (value) => {
              return (
                <Box display={"flex"} flexWrap="wrap" width={"20rem"}>
                  {value.map((l, i) => {
                    return (
                      <Box paddingRight={1}>{`${l.institution_name}${
                        i !== value.length - 1 ? "," : ""
                      }`}</Box>
                    );
                  })}
                </Box>
              );
            },
          },
          {
            name: "Options",
            row: "id",
            customRowComponent: (value) => (
              <span className={classes.moreInfo}>
                <ul>
                  <li
                    className={classes.editAdmin}
                    onClick={() => handleUserEdit(value)}
                  >
                    <i className="fa fa-pencil" title="Edit"></i>
                  </li>
                  <li
                    className={classes.deleteAdmin}
                    onClick={() => handleOpenConfirmDeletePopup(value)}
                  >
                    <i className="fa fa-trash" title="Delete"></i>
                  </li>
                </ul>
              </span>
            ),
          },
        ]}
        rows={allAdminsData}
      />
      <div className={classes.userTableSec}>
        <div className={classes.schoolsBtn}>
          {/* <div className={classes.msgBtn}>
                            <a onClick={handleOpenMessagePopup}>Message</a>
                        </div> */}
          <div className={classes.addAdminBtn}>
            <Link to="/admin-creation">ADD NEW ADMIN</Link>
          </div>
          <div className={classes.clearFilterBtn}>
            <button onClick={handleClearAllFilters}>CLEAR ALL FILTERS</button>
          </div>
          <Tooltip
            title=" This will export only the data that is currently shown above to
                excel. If you want to export all the data in this tab, make sure
                you clear all filters before using the export button."
          >
            <div className={classes.exportBtn}>
              <button
                data-tip
                data-for="main"
                onClick={exportInkwiryAdminTableData}
              >
                EXPORT
              </button>
            </div>
          </Tooltip>
        </div>
      </div>
      {/* <Dialog
                className="popupDiv popupDiv-Loan"
                open={openConfirmDeletePopup}
                onClose={handleCloseDeletePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.colseButton}><button onClick={handleCloseDeletePopup} ><i className="fa fa-times"></i></button></div>

                <DialogTitle className="modal-header" id="customized-dialog-title" onClose={handleCloseDeletePopup}>
                    Are you sure you want to delete this admin?
						</DialogTitle>
                <div className={classes.openApply}>
                    <DialogContent className={classes.confirmDeleteBtn}>
                        <Button onClick={() => handleDeleteAdmin()}>Yes</Button>
                    </DialogContent>
                    <DialogContent className={classes.closeBtn}>
                        <Button onClick={handleCloseDeletePopup}>No</Button>
                    </DialogContent>
                </div>
            </Dialog> */}
      <Dialog
        className="popup-model"
        open={openConfirmDeletePopup}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="model-content">
          <div className="modal-header">
            <button onClick={handleCloseDeletePopup}>
              <i className="fa fa-times"></i>
            </button>
            <h3>Delete Admin</h3>
          </div>

          <div
            className="model-body"
            id="customized-dialog-title"
            onClose={handleCloseDeletePopup}
          >
            Are you sure you want to delete this admin?
          </div>
          <div className="model-footer">
            <button
              onClick={() => handleDeleteAdmin()}
              className="close-btn delete-btn"
            >
              Ok
            </button>
            <button onClick={handleCloseDeletePopup} className="close-btn">
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        className="popup-model"
        open={openSuccesPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.colseButton}>
          <button onClick={handleClose}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className={classes.sucessPopup}>
          <p>Successfully Deleted</p>
          <figure>
            <img src="./img/success-img.png" alt="" />
          </figure>
        </div>
      </Dialog>
    </Typography>
  );
};
InkwiryAdmins.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);
export default enhance(InkwiryAdmins);
