// import * as UserUtils from '../../utilities/userUtils';
import * as commonFunctions from "../../../utilities/commonFunctions";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
// import { TextField } from 'final-form-material-ui';
import { Field, Form } from "react-final-form";
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import Fail from "../../../assets/img/passwordCheck/fail.png";
import IconButton from "@mui/material/IconButton";
import IsLoadingHOC from "../../../Hoc/IsLoadingHOC";
import Pageloader from "../../../components/ui/pageloader";
import Pass from "../../../assets/img/passwordCheck/pass.png";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Utilities from "../../../utilities/userUtils";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "../styles.css";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const RESET_PASSWORD = loader(
  "../../../graphql/schema/auth/reset-password.graphql"
);
const CHECK_VAILD_TOKEN = loader(
  "../../../graphql/schema/auth/checkToken.graphql"
);

const ResetPassword = (props) => {
  const { history, classes, location, setLoading } = props;
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = React.useState({});
  const [passwordLength, setPasswordLength] = React.useState(false);
  const [alphabetsExist, setAlphabetsExist] = React.useState(false);
  const [numericExist, setNumericExist] = React.useState(false);
  const [passwordMatch, setPasswordMatch] = React.useState(false);
  const [buttonDisable, setButtonDisable] = React.useState(false);
  const [openMessagePopup, setOpenMessagePopup] = React.useState(false);
  const [tokenId, setTokenId] = useState("");
  useEffect(() => {
    const url = new URLSearchParams(props.location.search);
    if (!url.get("reset_id")) {
      history.push("/");
    } else {
      setTokenId(url.get("reset_id"));
      checkVaildToken({
        variables: {
          token: url.get("reset_id"),
        },
      });
    }
  }, []);

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted({ updateAdminForgottenPassword }) {
      setOpenMessagePopup(true);
      setErrorMessage(updateAdminForgottenPassword);
      setLoading(false);
    },
    onError(errors) {
      setLoading(false);
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
    },
  });
  const [checkVaildToken] = useMutation(CHECK_VAILD_TOKEN, {
    onCompleted({ checkToken }) {
      setLoading(false);
      if (!checkToken?.status) {
        setErrorMessage(checkToken);
        setOpenMessagePopup(true);
      } else {
        setEmail(checkToken?.message);
      }
    },
    onError(errors) {
      setLoading(false);
      setOpenMessagePopup(true);
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
    },
  });

  const onSubmit = (data, formData) => {
    setLoading(true);
    resetPassword({
      variables: {
        data: {
          email,
          password: data?.new_password,
          token: tokenId,
        },
      },
    });
  };

  const handleClose = () => {
    history.push("/");
    setOpenMessagePopup(false);
  };

  const validate = (values) => {
    const errors = {};

    if (values.new_password) {
      if (values.new_password.length >= 8) {
        setPasswordLength(true);
      } else {
        setPasswordLength(false);
      }
      if (
        !commonFunctions.validateUpperAlpha(values.new_password) &&
        !commonFunctions.validateLowerAlpha(values.new_password)
      ) {
        setAlphabetsExist(true);
      } else {
        setAlphabetsExist(false);
      }
      if (!commonFunctions.validateNumeric(values.new_password)) {
        setNumericExist(true);
      } else {
        setNumericExist(false);
      }
    } else {
      setPasswordLength(false);
      setAlphabetsExist(false);
      setNumericExist(false);
    }

    if (values.confirm_new_password) {
      if (values.new_password === values.confirm_new_password) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }

    if (
      passwordLength &&
      alphabetsExist &&
      numericExist &&
      passwordMatch &&
      values.confirm_new_password &&
      values.new_password === values.confirm_new_password
    ) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
    return errors;
  };

  return (
    <Typography className="main-banner-login">
      <Grid container className="container">
        <Grid item xl={7} lg={5} md={5} sm={12} xs={12} className="col-login">
          <div className="login">
            <figure>
              <img src="./img/logo2.png" alt="inkwiryLogo" />
            </figure>
            <Grid container>
              <Grid
                item
                className="login-box forgot-password reset-password"
                lg={6}
                md={6}
                sm={6}
                xs={6}
              >
                <h3>Reset Password</h3>
                <Form
                  onSubmit={onSubmit}
                  validate={validate}
                  render={({ handleSubmit, submitting }) => (
                    <form className="form" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <Field
                          labelText="New Password"
                          id="new-password"
                          name="new_password"
                          type="password"
                          component="input"
                          fullWidth
                          variant="outlined"
                          placeholder="New Password"
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          labelText="confirm New Password"
                          id="confirm-new-password"
                          name="confirm_new_password"
                          component="input"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          type="password"
                          placeholder="confirm New Password"
                        />
                      </div>

                      <div>
                        <div className="text-center">
                          <button
                            className={
                              buttonDisable ? "otp-button" : "button-disable"
                            }
                            name="data"
                            type="submit"
                            disabled={!buttonDisable}
                          >
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                />
              </Grid>
              <Grid className="password-check" item lg={6} md={6} sm={6} xs={6}>
                <ul className="password-validation">
                  <li>
                    <img src={passwordLength ? Pass : Fail} alt="" />
                    <p>Include at least 8 characters</p>
                  </li>
                  <li>
                    <img src={alphabetsExist ? Pass : Fail} alt="" />
                    <p>Include upper and lower case characters</p>
                  </li>
                  <li>
                    <img src={numericExist ? Pass : Fail} alt="" />
                    <p>Include at least 1 number</p>
                  </li>
                  <li>
                    <img src={passwordMatch ? Pass : Fail} alt="" />
                    <p>Passwords match</p>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <footer className="desktop_footer">
        <div className="container_footer">
          <Grid container>
            {/* <Grid item xl lg={1} md={1} sm={1} xs={1} /> */}
            <Grid item lg={6} md={6} sm={6} className="copyright copyright_txt">
              <p>Copyright © 2022 // All rights reserved</p>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="copyright powered-text">
              <p className="logoDesc">
                Informed and Powered by{" "}
                <Link to="/" className="logoLink">
                  <img src="./img/logo2.png" alt="" />
                </Link>
              </p>
            </Grid>
          </Grid>
        </div>
        <div className="container-fluid new_gray_footer">
          <p className="terms_links">
            <Link
              to={{ pathname: "https://inkwiry.com/termsofservice" }}
              target="_blank"
            >
              Terms of Service
            </Link>
            &nbsp;//&nbsp;
            <Link
              to={{ pathname: "https://inkwiry.com/privacy" }}
              target="_blank"
            >
              Privacy Policy
            </Link>
            &nbsp;//&nbsp;
            <Link
              to={{ pathname: "https://inkwiry.com/security" }}
              target="_blank"
            >
              Security
            </Link>
          </p>
        </div>
      </footer>

      <Dialog
        open={openMessagePopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="school-popup"
      >
        <Grid container="true" display="flex" justifyContent="space-between">
          <DialogTitle id="alert-dialog-title"> Reset Password</DialogTitle>
          <IconButton aria-label="clear" onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </Grid>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <label>
              {!errorMessage.status
                ? "This page is outdated and cannot be used anymore. Please click on the link in the most recent email from Inkwiry Support to change your password."
                : errorMessage?.message}
            </label>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="model-footer">
          <Button onClick={handleClose} color="primary" autoFocus>
            Go
          </Button>
        </DialogActions>
      </Dialog>
    </Typography>
  );
};
// export default withStyles(styles)(Home);
ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);

export default enhance(ResetPassword);
