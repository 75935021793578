import { NavLink, useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import PropTypes from "prop-types";
import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  logo: {
    width: 200,
    height: 50,
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    width: 600,
    textAlign: "center",
  },
  notice: {
    color: "red",
    margin: "auto",
  },
};

const Unauthorized = (props) => {
  const history = useHistory();
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <h2 className={classes.notice}>
            The section you are trying to access does not exists or is
            unauthorized.
          </h2>
        </CardContent>
        <CardActions>
          <Button color="primary" size="small" onClick={() => history.goBack()}>
            Back Home
            {/* <NavLink to="/">Back home</NavLink> */}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

Unauthorized.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Unauthorized);
