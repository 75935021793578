const styles = () => ({
  userTableSec: {
    marginBottom: "70px",
  },
  questionExpalin: {
    fontSize: "14px",
    paddingBottom: "5px",
    "& span": {
      display: "table-cell",
      minWidth: "88px",
      fontFamily: "museosans-700",
      fontWeight: "normal",
    },
  },
  userTableDiv: {
    width: "calc(100% - 4px)",
    background: "#fff",
    boxShadow: "0 0px 2px rgb(25 25 25 / 20%)",
    transition: "all .3s ease-in-out",
    marginLeft: "2px",
    marginBottom: "2px",
    overflow: "auto",
    borderRadius: "15px 11px 0px 0px",
    minHeight: "500px",
    "& table": {
      width: "100%",
      background: "#036",
      tableLayout: "fixed",
      border: "1px solid #ccc",
      borderSpacing: "0",
      borderCollapse: "collapse",
      "& thead": {
        width: "100%",
        borderRadius: "15px 11px 0 0",
        color: "#fff",
        "& th": {
          padding: "25px 10px 10px",
          fontSize: "14px",
          textAlign: "left",
          width: "calc(125px - 20px)",
          background: " #036",
          color: "white",
          "&:nth-child(1)": {
            width: "calc(42px - 20px)",
            textAlign: "center",
          },
          "&:nth-child(2)": {
            width: "calc(155px - 20px)",
          },
          "&:nth-child(10)": {
            width: "calc(165px - 20px)",
          },
          "& i": {
            marginLeft: "5px",
            cursor: "pointer",
          },
        },
      },
      "& tbody": {
        background: "#fff",
        "& tr": {
          background: "#fff",
          "&:nth-child(even)": {
            background: "#f2f2f2",
          },
          "& td": {
            padding: "8px 10px",
            fontSize: "14px",
            // borderBottom: '1px solid #ededed',
            verticalAlign: "middle",
            color: "#333",
            wordBreak: "break-all",
            width: "calc(125px - 20px)",
            "&:nth-child(1)": {
              width: "calc(42px - 20px)",
              textAlign: "center",
            },
            "&:nth-child(2)": {
              width: "calc(155px - 20px)",
            },
            "&:nth-child(10)": {
              width: "calc(165px - 20px)",
            },
          },
        },
      },
    },
  },
  filterDrop: {
    display: "block",
    position: "absolute",
    background: "#e9e6e6",
    color: "#000",
    padding: 0,
    left: "50%",
    transform: "translatex(-50%)",
    minWidth: "220px",
    fontFamily: "museosans-100",
    listStyle: "none",
    zIndex: 1,
    top: "29px",
    margin: "0px 0px 10px",
    "&::before": {
      borderLeft: "9px solid transparent",
      borderRight: "9px solid transparent",
      content: '""',
      position: "absolute",
      width: 0,
      background: "transparent none repeat scroll 0 0",
      borderBottom: "9px solid #e9e6e6",
      borderTop: "10px solid transparent",
      height: "auto",
      left: "50%",
      top: "-19px",
      transform: "translateX(-50%)",
    },
    "& li": {
      padding: "4px 20px",
      fontSize: "14px",
      lineHeight: "1.4",
      cursor: "pointer",
      color: "#333",
      "&:hover": {
        color: "#0069aa",
      },
    },
  },
  pl43: {
    "& li": {
      padding: "4px 20px 4px 43px",
    },
  },
  filterIcon: {
    position: "relative",
    "& img": {
      maxWidth: "15px",
      marginLeft: "5px",
      cursor: "pointer",
      position: "relative",
      top: "2px",
    },
  },
  colLeft: {
    "& ul": {
      float: "none",
    },
  },
  colRight: {
    textAlign: "right",
  },
  tdColorNot: {
    color: "#bf0000 !important",
    fontSize: "13.5px !important",
    cursor: "pointer",
  },
  tdColorCom: {
    color: "green !important",
    fontSize: "13.5px !important",
    cursor: "pointer",
  },
  tdColorMid: {
    color: "orange !important",
    fontSize: "13.5px !important",
    cursor: "pointer",
  },
  paginationBottom: {
    display: "flex",
    alignItems: "center",
  },
  paginationLeft: {
    width: "70%",
    "& ul": {
      float: "none",
    },
  },
  schoolsBtn: {
    width: "30%",
    display: "flex",
    float: "right",
    margin: "10px 0px",
    justifyContent: "end",
    "& > div": {
      border: "1px solid transparent",
    },
    "& a": {
      textAlign: "center",
      display: "inline-block",
      fontWeight: "400",
      padding: "5px 12px",
      marginBottom: "0",
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      WebkitUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textDecoration: "none",
      WebkitTransition: "all .5s ease 0s",
      MozTransition: "all .5s ease 0s",
      color: "#fff",
      cursor: "pointer",
    },
    "& button": {
      textAlign: "center",
      display: "inline-block",
      fontWeight: "400",
      padding: "5px 12px",
      marginBottom: "0",
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      WebkitUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textDecoration: "none",
      WebkitTransition: "all .5s ease 0s",
      MozTransition: "all .5s ease 0s",
      color: "#fff",
      cursor: "pointer",
    },
  },
  clearFilterBtn: {
    "& a": {
      backgroundColor: "#caa336",
    },
    "& button": {
      backgroundColor: "#caa336",
    },
  },
  exportBtn: {
    "& a": {
      backgroundColor: "#006AAA",
    },
    "& button": {
      backgroundColor: "#006AAA",
    },
  },
  searchRow: {
    marginBottom: "15px",
  },
  searchBar: {
    position: "relative",
    "& input": {
      borderRadius: "15px",
      border: "1px solid #ccc",
      padding: "7px 15px",
      outline: "0",
      width: "calc(100% - 30px)",
      color: "#555",
      font: "inherit",
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      fontSize: "14px !important",
      fontWeight: "100 !important",
      maxWidth: "380px",
      "&::placeholder": {
        color: "#7c7c7c",
      },
    },
  },
  searchRow: {
    marginBottom: "15px",
  },
});
export default styles;
