import { createContext, useCallback, useContext, useReducer } from "react";

import { ACCESS_TOKEN } from "../utilities/userUtils";

export const UserDetailsContext = createContext();

export const useUserDetailsContext = () => useContext(UserDetailsContext);

function reducer(state, action) {
  switch (action.type) {
    case "SELECT_INSTITUTION":
      return {
        ...state,
        institution: action.payload,
        admin_type: action?.payload?.school_type,
      };
    case "SEARCH_BY_NAME":
      return { ...state, search: action.payload };
    default:
      throw new Error();
  }
}

export const UserDetailsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    admin_type:
      JSON.parse(localStorage.getItem("institution"))?.school_type || "school",
    institution: JSON.parse(localStorage.getItem("institution")),
    type: JSON.parse(localStorage.getItem(ACCESS_TOKEN))?.admin_type,
    search: "",
  });

  const selectedInstitutionHandler = (value) => {
    dispatch({ type: "SELECT_INSTITUTION", payload: value });
  };
  const searchByNameHandler = useCallback((e) => {
    dispatch({ type: "SEARCH_BY_NAME", payload: e.target.value });
  }, []);

  const clearSearch = useCallback((e) => {
    dispatch({ type: "SEARCH_BY_NAME", payload: "" });
  }, []);

  return (
    <UserDetailsContext.Provider
      value={{
        state,
        selectedInstitutionHandler,
        searchByNameHandler,
        clearSearch,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};
