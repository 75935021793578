import { Box, Grid, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "react-apollo";

import IsLoadingHOC from "../../Hoc/IsLoadingHOC";
import ReactTable from "../../components/ui/Table/ReactTable";
import { color } from "highcharts";
import { loader } from "graphql.macro";
import moment from "moment";
import { useUserDetailsContext } from "../../context/userDetails";

const CustomBox = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  background: "black",
  color: "white",
  fontSize: "50px",
  fontWeight: "bold",
  width: "fit-content",
  borderRadius: theme.shape.borderRadius,
  lineHeight: "normal",
  marginRight: theme.spacing(1),
}));

const GET_BUSINESS_DASHBOARD = loader(
  "../../graphql/schema/dashboard/business-dashboard.graphql"
);

const BusinessDashboard = (props) => {
  const [instutionId, setInstutionId] = useState();
  const {
    state: { institution },
  } = useUserDetailsContext();
  const [dashboardData, setDashboardData] = useState({});
  const { setLoading } = props;
  const [businessDashboard] = useLazyQuery(GET_BUSINESS_DASHBOARD, {
    fetchPolicy: "network-only",
    onCompleted({ GetClientDashboard: data = {} }) {
      setLoading(false);
      setDashboardData(data?.data);
    },
  });

  useEffect(() => {
    if (institution?.id !== instutionId) {
      businessDashboard();
      setInstutionId(institution?.id);
    }
  }, [institution?.id]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Box textAlign={"center"}>
          <Typography variant="subtitle2" color="primary" fontWeight={700}>
            Member Count
          </Typography>

          <Box width={"100%"} display={"flex"} justifyContent="center">
            {(dashboardData?.total_users || [])
              .toString()
              .padStart(6, "0")
              .split("")
              .map((l) => (
                <CustomBox>{l}</CustomBox>
              ))}
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography variant="subtitle2" color="primary" mb={2} fontWeight={700}>
          Top 10 Pages (Time Spent)
        </Typography>
        <ReactTable
          showNo={false}
          activePage={1}
          count={10}
          columns={[
            {
              name: "Page",
              row: "page",
              customRowComponent: (value, key) =>
                key?.page.includes("Total Time Spent") ||
                key?.page.includes("Average Time per Member") ? (
                  <Box sx={{ fontWeight: 700 }}>{value}</Box>
                ) : (
                  value
                ),
            },
            {
              name: "Time Spent",
              row: "time",
              customRowComponent: (value, key) =>
                key?.page.includes("Total Time Spent") ||
                key?.page.includes("Average Time per Member") ? (
                  <Box sx={{ fontWeight: 700 }}>{value} mins</Box>
                ) : (
                  `${value} mins`
                ),
            },
          ]}
          rows={[
            ...(dashboardData?.most_visited_pages || []),
            {
              page: "Total Time Spent",
              time: dashboardData?.total_time,
            },
            {
              page: "Average Time per Member",
              time: dashboardData?.avg_time,
            },
          ]}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography variant="subtitle2" color="primary" mb={2} fontWeight={700}>
          Scenarios Saved by Life Event
        </Typography>
        <ReactTable
          showNo={false}
          activePage={1}
          count={10}
          columns={[
            {
              name: "Module",
              row: "name",
              customRowComponent: (value, key) =>
                key?.name.includes("Total Scenarios") ? (
                  <Box sx={{ fontWeight: 700 }}>{value}</Box>
                ) : (
                  value
                ),
            },
            {
              name: "Scenarios",
              row: "count",
              customRowComponent: (value, key) =>
                key?.name.includes("Total Scenarios") ? (
                  <Box sx={{ fontWeight: 700 }}>{value}</Box>
                ) : (
                  value
                ),
            },
          ]}
          rows={[
            ...(dashboardData?.modules || []),
            {
              name: "Total Scenarios",
              count: dashboardData?.total_scenarios,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default IsLoadingHOC(BusinessDashboard);
