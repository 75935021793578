import * as commonFunctions from "../../../utilities/commonFunctions";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, Form, useField } from "react-final-form";
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import { Close } from "@mui/icons-material";
import { Grid } from "@material-ui/core";
import Pageloader from "../pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTable from "../Table/ReactTable";
import ReactTooltip from "react-tooltip";
import compose from "recompose/compose";
import isEmpty from "lodash/isEmpty";
import { loader } from "graphql.macro";
import styles from "./styles";
import useDebounce from "../../../hooks/useDebounce";
import { useUserDetailsContext } from "../../../context/userDetails";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const ALLSCHOOLS = loader("../../../graphql/schema/ui/Get-school.graphql");
const DELETESCHOOL = loader("../../../graphql/schema/ui/deleteSchool.graphql");
const ADDSCHOOLS = loader("../../../graphql/schema/ui/addSchool.graphql");
const GETSCHOOL_ID = loader("../../../graphql/schema/ui/GetSchoolById.graphql");
const UPDATESCHOOLS = loader("../../../graphql/schema/ui/saveSchool.graphql");
const UPDATESTATUS = loader(
  "../../../graphql/schema/ui/updateSchoolStatus.graphql"
);
const EXPORT_BUSINESS_ADMIN = loader(
  "../../../graphql/schema/ui/export-school.graphql"
);

const initialValues = {
  institution_name: null,
  school_type: "school",
  school_type2: null,
  school_link: null,
  street_address: null,
  zip_code: null,
  relationship_start_date: null,
  logo: null,
  sponsored_by_logo: null,
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Schools = (props) => {
  const { classes } = props;
  const {
    state: { search = "" },
    clearSearch,
  } = useUserDetailsContext();
  const debouncedSearch = useDebounce(search, 1000);

  const [schoolData, setSchoolData] = useState(initialValues);
  const [logo, setLogo] = useState();
  const [sponsoredLogo, setSponsoredLogo] = useState();
  const [loading, setLoading] = React.useState(true);
  const [allSchoolsData, setAllSchoolsData] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] =
    React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [openSuccesPopup, setOpenSuccesPopup] = React.useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [openSchoolPopup, setOpenSchoolPopup] = React.useState(false);
  const [schoolErrorMessage, setSchoolErrorMessage] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [openActiveSchoolPopup, setOpenActiveSchoolPopup] =
    React.useState(false);
  const [errorSchoolLogo, setErrorSchoolLogo] = useState("");
  const [errorSponsoredSchoolLogo, setErrorSponsoredSchoolLogo] = useState("");

  const [openStatusFilters, setOpenStatusFilters] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [activateId, setActivateId] = React.useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const ref = React.useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenStatusFilters(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  const [schools, { fetchMore }] = useLazyQuery(ALLSCHOOLS, {
    variables: {
      data: {
        page: 1,
        perPage: 20,
        // status: status,
        type: "school",
        name: "",
      },
    },
    fetchPolicy: "network-only",
    onCompleted(response) {
      if (response.GetSchools) {
        setAllSchoolsData(response.GetSchools.school);
        setActivePage(response?.GetSchools?.page);
        setPageFineshedCount(response.GetSchools.totalCount);
        setLoading(false);
        setResetFilter(false);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  useEffect(
    () => {
      setLoading(true);
      setActivePage(1);
      schools({
        variables: {
          data: {
            page: 1,
            perPage: 20,
            type: "school",
            name: debouncedSearch,
          },
        },
      });
    },
    [debouncedSearch] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    schools();
  }, []);

  const [exportschools] = useLazyQuery(EXPORT_BUSINESS_ADMIN, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = response.exportSchools.url;
      hiddenElement.download = "school.csv";
      hiddenElement.click();
      setLoading(false);
    },
  });

  const verfiyFileSize = (selectedFile) => {
    if (selectedFile && selectedFile.size > 1 * 1024 * 1024) {
      return true;
    } else {
      return false;
    }
  };

  const [GetSchoolById] = useLazyQuery(GETSCHOOL_ID, {
    variables: {
      id: userId,
    },
    fetchPolicy: "network-only",
    onCompleted({ GetSchoolById }) {
      const {
        id,
        institution_name,
        school_link,
        school_type,
        school_type2,
        zip_code,
        street_address,
        relationship_start_date,
      } = GetSchoolById;
      setSchoolData({
        id,
        institution_name,
        school_link,
        school_type,
        school_type2,
        zip_code,
        street_address,
        relationship_start_date,
      });
      setLoading(false);
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setSchoolErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const [deleteAdminData] = useMutation(DELETESCHOOL, {
    onCompleted() {
      setOpenConfirmDeletePopup(false);
      setDeleteId("");
      setLoading(false);
      setOpenSuccesPopup(true);
      schools();
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const [activateAdminData] = useMutation(UPDATESTATUS, {
    onCompleted() {
      setOpenActiveSchoolPopup(false);
      setActivateId("");
      setLoading(false);
      schools();
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const [addSchoolsData] = useMutation(ADDSCHOOLS, {
    onCompleted() {
      schools();
      setLoading(false);
      setOpenSchoolPopup(false);
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setSchoolErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const [updateSchoolsData] = useMutation(UPDATESCHOOLS, {
    onCompleted() {
      schools();
      setUserId("");
      setOpenSchoolPopup(false);
      schoolData(initialValues);
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setSchoolErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const handleOpenConfirmDeletePopup = (id) => {
    setDeleteId(id);
    setOpenConfirmDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenConfirmDeletePopup(false);
    setDeleteId("");
  };

  const handleAddSchool = () => {
    const {
      institution_name = null,
      school_link = null,
      school_type2 = null,
      zip_code = null,
      street_address = null,
      relationship_start_date = null,
    } = schoolData;
    if (isEmpty(institution_name)) {
      setSchoolErrorMessage("Please provide school name");
    } else if (isEmpty(school_link)) {
      setSchoolErrorMessage("Please provide school link");
    } else if (isEmpty(school_type2)) {
      setSchoolErrorMessage("Please provide school type");
    } else if (
      isEmpty(zip_code) ||
      !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip_code)
    ) {
      setSchoolErrorMessage("Please provide valid zip code");
    } else if (isEmpty(street_address)) {
      setSchoolErrorMessage("Please provide street address");
    } else if (isEmpty(relationship_start_date)) {
      setSchoolErrorMessage("Please provide relationship start date");
    } else {
      setLoading(true);
      if (userId) {
        if (logo !== null || sponsoredLogo !== null) {
          updateSchoolsData({
            variables: {
              data: {
                id: userId,
                ...schoolData,
                logo: logo,
                sponsored_by_logo: sponsoredLogo,
              },
            },
          });
        } else {
          updateSchoolsData({
            variables: {
              data: {
                id: userId,
                ...schoolData,

                logo: logo,
                sponsored_by_logo: sponsoredLogo,
              },
            },
          });
        }
      } else {
        addSchoolsData({
          variables: {
            data: {
              ...schoolData,
              logo: logo,
              sponsored_by_logo: sponsoredLogo,
            },
          },
        });
      }
    }
  };
  const handleDeleteAdmin = () => {
    setLoading(true);
    deleteAdminData({
      variables: {
        id: deleteId,
      },
    });
  };
  const handleActivateAdmin = () => {
    setLoading(true);
    activateAdminData({
      variables: {
        data: {
          id: activateId,
          status: "A",
        },
      },
    });
  };
  const handleUserEdit = (id) => {
    setUserId(id);
    setOpenSchoolPopup(true);
    GetSchoolById();
    setSchoolErrorMessage("");
    setLoading(true);
  };

  const handleClose = () => {
    setOpenSuccesPopup(false);
  };

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    fetchMore({
      variables: {
        data: {
          page: pageNumber,
          perPage: 20,
          type: "school",
          name: debouncedSearch,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setAllSchoolsData(fetchMoreResult?.GetSchools?.school);
        // return Object.assign({}, prev, {
        //   feed: [...prev.feed, ...fetchMoreResult.feed]
        // });
      },
    });
    setActivePage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleOpenSchoolPopup = () => {
    setOpenSchoolPopup(true);
    setSchoolData(initialValues);
    setSchoolErrorMessage("");
  };
  const handleCloseSchoolPopup = () => {
    setOpenSchoolPopup(false);
    setSchoolData(initialValues);
    setUserId("");
    setSchoolErrorMessage("");
  };
  const handleChange = (e) => {
    setSchoolErrorMessage("");
    const { name, value, files = [] } = e.target;

    if (name === "logo") {
      setLogo(files[0]);
    } else if (name === "sponsored_by_logo") {
      setSponsoredLogo(files[0]);
    } else {
      setSchoolData({
        ...schoolData,
        [name]: value,
      });
    }
  };

  const handleOpenActive = (id) => {
    setActivateId(id);
    setOpenActiveSchoolPopup(true);
  };
  const handleCloseActivePopup = () => {
    setUserId("");
    setOpenActiveSchoolPopup(false);
  };
  const handleFilters = () => {
    setOpenStatusFilters(!openStatusFilters);
  };
  const handleSorting = (val) => {
    setStatus(val);
    setLoading(true);
  };
  const handleClearAllFilters = () => {
    setLoading(true);
    setResetFilter(true);
    setActivePage(1);
    clearSearch();
    schools({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          type: "school",
          name: "",
        },
      },
    });
  };
  const validate = (values) => {
    const errors = {};
    if (!values.institution_name?.trim()) {
      errors.institution_name = "Please provide school name";
    }
    if (!values.school_type2) {
      errors.school_type2 = "Please provide school type";
    }
    if (
      !values.school_link ||
      !commonFunctions.isUrlValid(values.school_link)
    ) {
      errors.school_link = "Please provide valid school link";
    }
    if (!values.street_address?.trim()) {
      errors.street_address = "Please provide street address";
    }
    if (
      !values.zip_code?.trim() ||
      !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.zip_code)
    ) {
      errors.zip_code = "Please provide valid zip code";
    }
    if (!values.relationship_start_date?.trim()) {
      errors.relationship_start_date = "Please provide relationship start date";
    }

    return errors;
  };
  const onSubmit = (values) => {
    setLoading(true);
    if (userId) {
      updateSchoolsData({
        variables: {
          data: {
            id: userId,
            school_type: "school",
            ...values,
          },
        },
      });
    } else {
      addSchoolsData({
        variables: {
          data: {
            school_type: "school",
            ...values,
          },
        },
      });
    }
  };
  return (
    <div>
      <Typography className="main-bannerRight">
        {loading === true ? <Pageloader loading={loading} /> : ""}
        <Grid container className={classes.searchRow} spacing={2}>
          <Grid item md={6} sm={6} xs={12}></Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div>
              {pageFineshedCount > 0 && (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={20}
                  totalItemsCount={pageFineshedCount}
                  pageRangeDisplayed={5}
                  onChange={handleGetFineshedMatchesPageClick}
                />
              )}
            </div>
          </Grid>
        </Grid>
        <ReactTable
          resetFilter={resetFilter}
          activePage={activePage}
          count={pageFineshedCount}
          columns={[
            {
              name: "School Name",
              row: "institution_name",
            },
            {
              name: "School Type",
              row: "school_type2",
            },
            {
              name: "Street Address",
              row: "street_address",
            },
            {
              name: "ZIP Code",
              row: "zip_code",
            },
            {
              name: "URL",
              row: "school_link",
            },
            {
              name: "Relationship Start Date",
              row: "relationship_start_date",
            },
            {
              name: "School Logo",
              row: "logo_link",
              customRowComponent: (value) => (
                <>
                  {value ? (
                    <img src={value} width="50px" alt="logo_link" />
                  ) : (
                    "N/A"
                  )}
                </>
              ),
            },
            {
              name: "Sponsored by Logo",
              row: "sponsored_by_logo_link",
              customRowComponent: (value) => (
                <>
                  {value ? (
                    <img
                      src={value}
                      width="50px"
                      alt="sponsored_by_logo_link"
                    />
                  ) : (
                    "N/A"
                  )}
                </>
              ),
            },
            {
              name: "Options",
              row: "id",
              customRowComponent: (value) => (
                <span className={classes.moreInfo}>
                  <ul>
                    <li
                      className={classes.editAdmin}
                      onClick={() => handleUserEdit(value)}
                    >
                      <i className="fa fa-pencil" title="Edit"></i>
                    </li>
                    <li
                      className={classes.deleteAdmin}
                      onClick={() => handleOpenConfirmDeletePopup(value)}
                    >
                      <i className="fa fa-trash" title="Delete"></i>
                    </li>
                  </ul>
                </span>
              ),
            },
          ]}
          rows={allSchoolsData}
        />
        <div className={classes.userTableSec}>
          <div className={classes.schoolsBtn}>
            {/* <div className={classes.msgBtn}>
                            <a onClick={handleOpenMessagePopup}>Message</a>
                        </div> */}
            <div className={classes.addAdminBtn}>
              <button onClick={handleOpenSchoolPopup}>ADD NEW</button>
            </div>
            <div className={classes.clearFilterBtn}>
              <button onClick={handleClearAllFilters}>CLEAR ALL FILTERS</button>
            </div>
            <Tooltip
              title=" This will export only the data that is currently shown above to
                excel. If you want to export all the data in this tab, make sure
                you clear all filters before using the export button."
            >
              <div className={classes.exportBtn}>
                <button
                  data-tip
                  data-for="main"
                  onClick={() => {
                    exportschools({
                      variables: {
                        data: {
                          // page: activePage,
                          // perPage: pageFineshedCount,
                          // status: status,
                          type: "school",
                          name: debouncedSearch,
                        },
                      },
                    });
                    setLoading(true);
                  }}
                >
                  EXPORT
                </button>
              </div>
            </Tooltip>
          </div>
        </div>
        <Dialog
          fullWidth={"lg"}
          scroll="paper"
          open={openSchoolPopup}
          onClose={handleCloseSchoolPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <BootstrapDialogTitle onClose={handleCloseSchoolPopup}>
            {userId ? "Edit School" : "Add School"}
          </BootstrapDialogTitle>
          <DialogContent dividers={"paper"}>
            <div className="model-body">
              <Form
                validate={validate}
                onSubmit={onSubmit}
                initialValues={{
                  institution_name: schoolData?.institution_name,
                  school_type2: schoolData?.school_type2,
                  school_link: schoolData?.school_link,
                  street_address: schoolData?.street_address,
                  zip_code: schoolData?.zip_code,
                  relationship_start_date: schoolData?.relationship_start_date,
                }}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.fields}>
                      <div className={classes.formGroup}>
                        <label>School Name</label>
                        <Field
                          className={classes.formField}
                          name="institution_name"
                        >
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                error={
                                  (meta.error || meta.submitError) &&
                                  meta.touched
                                }
                                sx={{ marginBottom: "20px" }}
                                name={input?.name}
                                // placeholder="ex. Mike"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                                helperText={
                                  (meta.error || meta.submitError) &&
                                  meta.touched &&
                                  (meta.error || meta.submitError)
                                }
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className={classes.formGroup}>
                        <label>School Type</label>
                        <Field
                          className={classes.formField}
                          name="school_type2"
                        >
                          {({ input, meta }) => (
                            <FormControl
                              error={
                                (meta.error || meta.submitError) && meta.touched
                              }
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                fontSize: "14px",
                                marginBottom: "20px",
                              }}
                            >
                              <Select
                                size="small"
                                name={input?.name}
                                // placeholder="ex. Mike"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                                helperText={
                                  (meta.error || meta.submitError) &&
                                  meta.touched &&
                                  (meta.error || meta.submitError)
                                }
                              >
                                <MenuItem sx={{ fontSize: "14px" }} value="">
                                  <em>Select School type</em>
                                </MenuItem>
                                <MenuItem
                                  sx={{ fontSize: "14px" }}
                                  value="secondary"
                                >
                                  Secondary
                                </MenuItem>
                                <MenuItem
                                  sx={{ fontSize: "14px" }}
                                  value="postsecondary"
                                >
                                  Post Secondary
                                </MenuItem>
                              </Select>
                              <FormHelperText sx={{ marginLeft: 0 }}>
                                {(meta.error || meta.submitError) &&
                                  meta.touched &&
                                  (meta.error || meta.submitError)}
                              </FormHelperText>
                            </FormControl>
                          )}
                        </Field>
                      </div>
                      <div className={classes.formGroup}>
                        <label>Street Address</label>
                        <Field
                          className={classes.formField}
                          name="street_address"
                        >
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                error={
                                  (meta.error || meta.submitError) &&
                                  meta.touched
                                }
                                sx={{ marginBottom: "20px" }}
                                name={input?.name}
                                // placeholder="ex. Mike"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                                helperText={
                                  (meta.error || meta.submitError) &&
                                  meta.touched &&
                                  (meta.error || meta.submitError)
                                }
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className={classes.formGroup}>
                        <label>Zip Code</label>
                        <Field className={classes.formField} name="zip_code">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                error={
                                  (meta.error || meta.submitError) &&
                                  meta.touched
                                }
                                sx={{ marginBottom: "20px" }}
                                name={input?.name}
                                placeholder="ex. 12345"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                                helperText={
                                  (meta.error || meta.submitError) &&
                                  meta.touched &&
                                  (meta.error || meta.submitError)
                                }
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className={classes.formGroup}>
                        <label>School Link</label>
                        <Field className={classes.formField} name="school_link">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                error={
                                  (meta.error || meta.submitError) &&
                                  meta.touched
                                }
                                sx={{ marginBottom: "20px" }}
                                name={input?.name}
                                // placeholder="ex. Mike"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                                helperText={
                                  (meta.error || meta.submitError) &&
                                  meta.touched &&
                                  (meta.error || meta.submitError)
                                }
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className={classes.formGroup}>
                        <label>Relationship Start Date</label>
                        <Field
                          className={classes.formField}
                          name="relationship_start_date"
                        >
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                error={
                                  (meta.error || meta.submitError) &&
                                  meta.touched
                                }
                                sx={{ marginBottom: "20px" }}
                                name={input?.name}
                                // placeholder="ex. Mike"
                                type="date"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                                helperText={
                                  (meta.error || meta.submitError) &&
                                  meta.touched &&
                                  (meta.error || meta.submitError)
                                }
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className={classes.formGroup}>
                        <label>School Logo</label>
                        <Field className={classes.formField} name="logo">
                          {({
                            input: { value, onChange, name, ...input },
                            meta,
                          }) => (
                            <div className="input-div">
                              <input
                                type="file"
                                name={name}
                                onChange={({ target }) => {
                                  if (verfiyFileSize(target.files?.[0])) {
                                    setErrorSchoolLogo(
                                      `File size is too large. Max file size is ${
                                        props.maxSize / 1024 / 1024
                                      } MB.`
                                    );
                                  } else {
                                    onChange(target.files?.[0]);
                                    setErrorSchoolLogo("");
                                  }
                                }}
                                {...input}
                                accept=".jpg,.gif,.png,.jpeg"
                              />
                              {errorSchoolLogo && (
                                <Typography
                                  variant="caption"
                                  className={classes.noteError}
                                >
                                  File size is too large. Max file size is 1MB.
                                </Typography>
                              )}
                              {!errorSchoolLogo && (
                                <span className={classes.imageDetails}>
                                  JPF, GIF or PNG. Max Size of 1 MB
                                </span>
                              )}
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className={classes.formGroup}>
                        <label>Sponsored by Logo</label>
                        <Field
                          className={classes.formField}
                          name="sponsored_by_logo"
                        >
                          {({
                            input: { value, onChange, name, ...input },
                            meta,
                          }) => (
                            <div className="input-div">
                              <input
                                type="file"
                                name={name}
                                onChange={({ target }) => {
                                  if (verfiyFileSize(target.files?.[0])) {
                                    setErrorSponsoredSchoolLogo(
                                      `File size is too large. Max file size is ${
                                        props.maxSize / 1024 / 1024
                                      } MB.`
                                    );
                                  } else {
                                    onChange(target.files?.[0]);
                                    setErrorSponsoredSchoolLogo("");
                                  }
                                }}
                                {...input}
                                accept=".jpg,.gif,.png,.jpeg"
                              />

                              {errorSponsoredSchoolLogo && (
                                <Typography
                                  variant="caption"
                                  className={classes.noteError}
                                >
                                  File size is too large. Max file size is 1MB.
                                </Typography>
                              )}
                              {!errorSponsoredSchoolLogo && (
                                <span className={classes.imageDetails}>
                                  JPF, GIF or PNG. Max Size of 1 MB
                                </span>
                              )}
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                      {schoolErrorMessage && (
                        <div className={classes.noteError}>
                          {schoolErrorMessage}
                        </div>
                      )}
                    </div>
                    <DialogActions>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={handleCloseSchoolPopup}
                        className="close-btn"
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        // onClick={onSubmit}
                        className="delete-btn"
                      >
                        {userId ? "UPDATE" : "Save"}
                      </Button>
                    </DialogActions>
                  </form>
                )}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          className="popup-model"
          open={openConfirmDeletePopup}
          onClose={handleCloseDeletePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="model-content">
            <div className="modal-header">
              <button onClick={handleCloseDeletePopup}>X</button>
              <h3>Delete School</h3>
            </div>

            <div
              className="model-body"
              id="customized-dialog-title"
              onClose={handleCloseDeletePopup}
            >
              <p>
                Are you sure you want to delete this school? By deleting this
                school, you will also make all of its users inactive.
              </p>
            </div>
            <div className="model-footer">
              <button
                onClick={() => handleDeleteAdmin()}
                className="close-btn delete-btn"
              >
                Delete
              </button>
              <button onClick={handleCloseDeletePopup} className="close-btn">
                No
              </button>
            </div>
          </div>
        </Dialog>
        {/* <Dialog
          className="popup-model"
          open={openActiveSchoolPopup}
          onClose={handleCloseActivePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="model-content">
            <div className="modal-header">
              <button onClick={handleCloseActivePopup}>X</button>
              <h3>Activate school</h3>
            </div>

            <div
              className="model-body"
              id="customized-dialog-title"
              onClose={handleCloseActivePopup}
            >
              <p>
                Are you sure you want to activate this school? By activating
                this school, you will also make all of its users active.
              </p>
            </div>
            <div className="model-footer">
              <button
                onClick={() => handleActivateAdmin()}
                className="close-btn delete-btn"
              >
                Activate
              </button>
              <button onClick={handleCloseActivePopup} className="close-btn">
                No
              </button>
            </div>
          </div>
        </Dialog> */}
        <Dialog
          className="popupBankDetails"
          open={openSuccesPopup}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.colseButton}>
            <button onClick={handleClose}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className={classes.sucessPopup}>
            <p>Successfully Deleted</p>
            <figure>
              <img src="./img/success-img.png" alt="" />
            </figure>
          </div>
        </Dialog>
      </Typography>
    </div>
  );
};
Schools.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);
export default enhance(Schools);
