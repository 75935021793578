import selectArrow from "../../../assets/img/select_ic.jpg";

const styles = () => ({
  userTableSec: {
    // display: 'flex',
    // overflow: 'hidden',
    // position: 'relative',
    // overflowY: 'auto',
  },
  headingDiv: {
    display: "flex",
    alignItems: "center",
    float: "right",
  },
  searchInput: {
    padding: "21px",
    "& input": {
      borderRadius: "50px",
      outline: 0,
      width: "289px",
      marginLeft: "20px",
      padding: "5px",
      textIndent: "10px",
    },
  },
  userTableDiv: {
    width: "calc(100% - 4px)",
    background: "#fff",
    boxShadow: "0 0px 2px rgb(25 25 25 / 20%)",
    transition: "all .3s ease-in-out",
    marginLeft: "2px",
    marginBottom: "2px",
    overflow: "auto",
    borderRadius: "10px",
    "& table": {
      width: "100%",
      overflow: "hidden",
      borderCollapse: "collapse",
      tableLayout: "fixed",
      border: "1px solid #ccc",
      "& thead": {
        "& th": {
          padding: "25px 10px 10px",
          fontSize: "13px",
          // background: '#f4f4f4',
          textAlign: "left",
          // lineHeight: '24px',
          // verticalAlign: 'top',
          width: "150px",
          background: " #036",
          color: "white",
          "& i": {
            marginLeft: "5px",
            cursor: "pointer",
          },
        },
      },
      "& tbody": {
        "& td": {
          padding: "8px 10px",
          fontSize: "13px",
          borderBottom: "1px solid #ededed",
          verticalAlign: "middle",
        },
      },
    },
  },
  emailText: {
    width: "200px",
  },
  noteError: {
    color: "#ff0606",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "1.66",
  },
  moreInfo: {
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        display: "inline-block",
        padding: "0px 5px",
        cursor: "pointer",
      },
    },
  },
  confirmDeleteBtn: {
    display: "inline-block",
    width: "auto",
    flex: "initial",
    padding: "0px !important",
    margin: "0px 10px",
    "& button": {
      background: "white",
    },
  },
  closeBtn: {
    padding: "0px",
    display: "inline-block",
    flex: "initial",
    margin: "0px 10px",
    "& button": {
      background: "white",
    },
  },
  openApply: {
    textAlign: "center",
    padding: "0px 0px 20px",
  },
  colseButton: {
    float: "right",
    marginLeft: "auto",
    margin: "10px",
    cursor: "pointer",
    fontSize: "large",
    "& button": {
      color: "#ddd",
      border: "0px",
      cursor: "pointer",
      fontSize: "18px",
      background: "transparent",
    },
  },
  uploadImageDiv: {
    maxWidth: "45%",
    borderLeft: "1px solid #ddd",
    padding: "0 30px",
  },
  srcImageDiv: {
    display: "inline-block",
    width: "100%",
    border: "1px solid #eaeaea",
    marginTop: "5px",
    borderRadius: "5px",
    position: "relative",
  },
  inputImageDiv: {
    position: "relative",
    textAlign: "center",
    height: "30px",
    lineHeight: "30px",
    background: "#f4f4f4",
    "& input": {
      position: "absolute",
      width: "100%",
      height: "30px",
      lineHeight: "30px",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
      zIndex: 9,
      opacity: 0,
    },
  },
  mainDiv: {
    padding: "20px",
    display: "inline-block",
    width: "100%",
  },
  inputDiv: {
    marginBottom: "15px",
    "& label": {
      width: "154px",
      display: "-webkit-inline-box",
    },
    "& input": {
      position: "relative",
      width: "57%",
      outline: 0,
      height: "17px",
      padding: "6px 12px",
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "4px",
      webkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
    },
  },
  stateInput: {
    marginBottom: "15px",
    "& label": {
      width: "154px",
      display: "-webkit-inline-box",
    },
    "& >div": {
      width: "60%",
      "& div > div": {
        position: "relative",
        outline: 0,
        height: "17px",
        padding: "6px 12px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: "4px",
        webkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
        boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      },
    },
    "& input": {
      opacity: "none",
      height: "25px",
      width: "99%",
    },
    "& select": {
      width: "100%",
      padding: "7px 15px",
      borderRadius: "7px",
      border: "1px solid #8a8a88",
      outline: "0",
      fontSize: "18px",
      transition: "box-shadow .3s ease-in-out",
      WebkitTransition: "box-shadow .3s ease-in-out",
      MozTransition: "box-shadow .3s ease-in-out",
      color: "#898a8a",
      position: "relative",
      boxSizing: "border-box",
      fontFamily: "MuseoSans-100",
      backgroundImage: `url(${selectArrow})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "98% center",
      backgroundSize: "20px",
      MsAppearance: "none",
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
    },
  },
  updateBtn: {
    marginLeft: "auto",
    // marginRight: 'auto',
    width: "60%",
    "& span": {
      color: "white",
      transform: "none",
    },
  },
  updateText: {
    marginBottom: "15px",
    color: "#3070bf",
  },
  sucessPopup: {
    textAlign: "center",
    padding: "50px 30px",
    "& p": {
      fontWeight: "500",
      fontSize: "14px",
    },
    "& img": {
      maxWidth: "150px",
    },
  },
  statusSuccess: {
    display: "inline",
    padding: ".2em .6em .3em",
    fontSize: "75%",
    color: "#fff",
    backgroundColor: "#5cb85c",
    borderRadius: ".25em",
  },
  statusInActive: {
    display: "inline",
    padding: ".2em .6em .3em",
    fontSize: "75%",
    color: "#fff",
    backgroundColor: "#e23d63",
    borderRadius: ".25em",
  },
  filterDrop: {
    display: "block",
    position: "absolute",
    background: "#e9e6e6",
    color: "#000",
    padding: 0,
    left: "19.4%",
    transform: "translatex(-50%)",
    minWidth: "200px",
    fontFamily: "museosans-100",
    listStyle: "none",
    zIndex: 1,
    "&::before": {
      borderLeft: "9px solid transparent",
      borderRight: "9px solid transparent",
      content: '""',
      position: "absolute",
      width: 0,
      background: "transparent none repeat scroll 0 0",
      borderBottom: "9px solid #e9e6e6",
      borderTop: "10px solid transparent",
      height: "auto",
      left: "50%",
      top: "-19px",
      transform: "translateX(-50%)",
    },
    "& li": {
      padding: "4px 20px",
      fontSize: "14px",
      lineHeight: "1.4",
      cursor: "pointer",
      color: "#333",
    },
  },
  filterTwoDrop: {
    left: "31.8%",
  },
  filterThreeDrop: {
    left: "42.4%",
  },
  filterFourDrop: {
    left: "57%",
  },
  filterFiveDrop: {
    left: "80.5%",
  },
  filterSixDrop: {
    left: "91%",
    height: "300px",
    overflow: "auto",
  },
  filterSchoolDrop: {
    height: "300px",
    overflow: "auto",
    left: "57.5%",
  },
  filterZipcodeDrop: {
    left: "57.7%",
  },
  passwordPopup: {
    display: "relative",
  },
  resetPass: {
    position: "absolute",
    width: "max-content",
    // height: "calc(155px - 16px)",
    padding: "8px 20px",
    top: "-55px",
    right: "-51%",
    border: "2px solid #0069aa",
    background: "#fff",
    zIndex: "99",
    borderRadius: "15px",
    opacity: "0",
    visibility: "hidden",
    WebkitTransition: "all .3s ease-in-out",
    MozTransition: "all .3s ease-in-out",
    MsTransition: "all .3s ease-in-out",
    OTransition: "all .3s ease-in-out",
    "&:before": {
      position: "absolute",
      top: "50px",
      left: "-22px",
      background: "#fff",
      width: "40px",
      height: "40px",
      border: "2px solid #0069aa",
      transform: "rotate(45deg) skew(-6deg,-6deg)",
      WebkitTransform: "rotate(45deg) skew(-6deg,-6deg)",
      MozTransform: "rotate(45deg) skew(-6deg,-6deg)",
      MsTransform: "rotate(45deg) skew(-6deg,-6deg)",
      OTransform: "rotate(45deg) skew(-6deg,-6deg)",
      borderRight: "none",
      borderTop: "none",
      zIndex: "-1",
      content: '""',
    },
  },
  conditions: {
    padding: "17px 0 0 0",
    color: "green",
    "& p": {
      lineHeight: "14px",
      fontSize: "14px",
      color: "#999",
      margin: "0 0 8px",
      display: "flex",
      alignItems: "center",
      "& span": {
        marginRight: "6px",
        "& img": {
          width: "12px",
          height: "14px",
        },
      },
      "& label": {
        display: "inline-block",
        verticalAlign: "middle",
        width: "80%",
        paddingTop: "0px",
        fontSize: "85%",
        color: "#999",
        lineHeight: "14px",
        fontWeight: "100",
      },
    },
  },
  resetPassCondition: {
    opacity: "1",
    visibility: "visible",
  },

  typeError: {
    border: "1px solid red !important",
    borderRadius: "7px",
  },
  backToAdmin: {
    "& a": {
      textDecoration: "underline",
      color: "#337ab7",
      "&:hover": {
        color: "#23527c",
      },
    },
  },
  formBlock: {
    "& h3": {
      fontSize: "16px",
      lineHeight: "23px",
      marginBottom: "20px",
      color: "#0069aa",
      fontWeight: "500",
      fontFamily: "MuseoSans-500",
      fontWeight: "normal",
    },
  },
  formGroup: {
    padding: "0",
    position: "relative",
    marginBottom: "15px",
    display: "flex",
    "& label": {
      width: "calc(210px - 25px)",
      paddingTop: "7px",
      display: "inline-block",
      float: "left",
      padding: "0 25px 0 0",
      position: "relative",
      color: "#333",
      fontFamily: "MuseoSans-700",
      fontWeight: "normal",
    },
  },
  centerBtn: {
    width: "67%",
  },
  iconError: {
    position: "relative",
  },
  iconWrong: {
    position: "absolute",
    right: "35px",
    top: "7px",
    "& img": {
      maxWidth: "15px",
    },
  },
  iconWright: {
    position: "absolute",
    right: "35px",
    top: "7px",
    "& img": {
      maxWidth: "15px",
    },
  },
  emailExist: {
    color: "#f44336",
    fontSize: "13.5px",
  },
  inputLeft: {
    display: "inline-block",
    float: "left",
    padding: "0 25px 0 0",
    position: "relative",
    width: "calc(57% - 25px)",
    "& input": {
      width: "calc(100% - 10px)",
      height: "calc(34px - 6px)",
      padding: "2px 5px",
      backgroundColor: "#fff",
      backgroundImage: "none",
      border: "1px solid #ccc",
      borderRadius: "4px",
      WebkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      WebkitTransition:
        "border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s",
      OTransition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
      transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
      fontFamily: "MuseoSans-300",
      fontWeight: "normal",
      color: "#555",
      fontSize: "14px",
      "&::placeholder": {
        color: "#625c5c",
      },
      "&:focus": {
        borderColor: "#66afe9",
        outline: "0",
        WebkitBoxShadow:
          "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
        boxShadow:
          "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
        borderWidth: "1px",
      },
    },
    "& fieldset": {
      display: "none",
    },
    "& p": {
      marginLeft: "0",
      marginRight: "0",
    },
  },
  inputLeftSelect: {
    display: "inline-block",
    float: "left",
    padding: "0 25px 0 0",
    position: "relative",
    width: "calc(57% - 25px)",
    "& select": {
      width: "calc(100% - 0px)",
      height: "calc(34px - 0px)",
      padding: "2px 5px",
      backgroundColor: "#fff",
      backgroundImage: "none",
      border: "1px solid #ccc",
      borderRadius: "4px",
      WebkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      WebkitTransition:
        "border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s",
      OTransition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
      transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
      color: "#555",
      "&:focus": {
        borderColor: "#66afe9",
        outline: "0",
        WebkitBoxShadow:
          "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
        boxShadow:
          "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
        borderWidth: "1px",
      },
    },
  },
  inputError: {
    "& input": {
      border: "1px solid red",
      boxShadow: "none",
    },
  },
  updateBtn: {
    marginBottom: "15px",
    textAlign: "center",
    "& button": {
      backgroundColor: "#036",
      borderColor: "#036",
      padding: "5px 15px",
      boxShadow: "none",
      fontSize: "14px",
      fontWeight: "400",
      cursor: "pointer",
      border: "1px solid transparent",
      lineHeight: "1.3333333",
      borderRadius: "6px",
      color: "#fff",
    },
  },
  sccessMsg: {
    paddingLeft: "155px",
    paddingTop: "15px",
    fontSize: "13.5px",
    marginBottom: "5px",
    marginTop: "-5px",
    color: "green",
  },
  sccessMsgDiv: {
    //  marginLeft: "180px",
    // width: "calc(57% - 25px)",
    // marginTop: "-20px",
    // marginBottom: "15px",
  },
  searchBar: {
    position: "relative",
    "& input": {
      borderRadius: "15px",
      border: "1px solid #ccc",
      padding: "7px 15px",
      outline: "0",
      width: "calc(100% - 30px)",
      color: "#555",
      font: "inherit",
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      fontSize: "14px !important",
      fontWeight: "100 !important",
      maxWidth: "380px",
      "&::placeholder": {
        color: "#7c7c7c",
      },
    },
  },
  searchRow: {
    marginBottom: "15px",
  },
  nameError: {
    color: "#bf0000",
    float: "left",
    fontSize: "13.5px",
    marginBottom: "10px",
    marginTop: "5px",
    textAlign: "left",
    width: "100%",
  },
});

export default styles;
