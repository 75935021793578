// import * as UserUtils from '../../utilities/userUtils';
import * as commonFunctions from "../../utilities/commonFunctions";

import { Link, withRouter } from "react-router-dom";
import React, { useLayoutEffect, useState } from "react";
import Utilities, { ACCESS_TOKEN } from "../../utilities/userUtils";

import { Grid } from "@material-ui/core";
import Pageloader from "../../components/ui/pageloader";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles.css";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const LOGIN = loader("../../graphql/schema/auth/login.graphql");

const Home = (props) => {
  const { history } = props;

  useLayoutEffect(() => {
    if (JSON.parse(localStorage.getItem(ACCESS_TOKEN))) {
      if (
        JSON.parse(localStorage.getItem(ACCESS_TOKEN))?.admin_type === "inkwiry"
      ) {
        history.push("/inkwiry-admins");
      } else {
        history.push("/client-dashboard");
      }
    }
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [emailError, setEmailError] = useState(false);
  const [pwdError, setPwdError] = useState(false);

  const [submitLoginData, { data: loginResult }] = useMutation(LOGIN, {
    onCompleted(response) {
      if (response.adminLogin.status === false) {
        setErrorMessage(response.adminLogin.message);
      } else {
        Utilities.setUserName(email);
        history.push("./verify-otp");
        setErrorMessage("");
      }
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const handleChange = (e) => {
    setErrorMessage("");
    if (e.currentTarget.id === "email") {
      setEmail(e.currentTarget.value);
      if (e.currentTarget.value === "") {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    } else {
      setPassword(e.currentTarget.value);
      if (e.currentTarget.value === "") {
        setPwdError(true);
      } else {
        setPwdError(false);
      }
    }
  };

  const handleSubmitLogin = () => {
    if (email === "") {
      setEmailError(true);
    }
    if (!password) {
      setPwdError(true);
    }
    if (email) {
      if (password) {
        setLoading(true);
        submitLoginData({
          variables: {
            data: {
              username: email,
              password: password,
            },
          },
        });
      }
    }
  };
  React.useEffect(() => {
    if (loginResult) {
      setLoading(false);
    }
  }, [loginResult]);

  return (
    <Typography className="main-banner-login">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <Grid container className="container">
        <Grid item xl={7} lg={5} md={5} sm={12} xs={12} className="col-login">
          <div className="login">
            <figure>
              <img src="./img/logo2.png" alt="inkwiryLogo" />
            </figure>
            <div className="login-box">
              <Typography variant="h3">Admin Tool</Typography>
              <form
                className="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmitLogin();
                }}
              >
                <div className="form-group">
                  <input
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    onChange={handleChange}
                    type="text"
                    placeholder="Username or Email Address"
                    className={`infoPlaceholder ${
                      emailError ? "has-error" : ""
                    }`}
                  />
                </div>
                <div className="form-group">
                  <input
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    onChange={handleChange}
                    type="password"
                    placeholder="Password"
                    className={`infoPlaceholder ${pwdError ? "has-error" : ""}`}
                  />
                </div>
                {errorMessage && (
                  <div className="noteError">
                    Please provide valid username and password
                  </div>
                )}
                <div>
                  <div className="text-center">
                    <button
                      className="otp-button"
                      name="data"
                      // onClick={() => handleSubmitLogin()}
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="text-center forgot">
                    <Link to="/forgotpassword">Forgot Password</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
      <footer className="desktop_footer">
        <div className="container_footer">
          <Grid container>
            {/* <Grid item xl lg={1} md={1} sm={1} xs={1} /> */}
            <Grid item lg={6} md={6} sm={6} className="copyright copyright_txt">
              <p>Copyright © 2022 // All rights reserved</p>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="copyright powered-text">
              <p className="logoDesc">
                Informed and Powered by{" "}
                <Link to="/" className="logoLink">
                  <img src="./img/logo2.png" alt="" />
                </Link>
              </p>
            </Grid>
          </Grid>
        </div>
        <div className="container-fluid new_gray_footer">
          <p className="terms_links">
            <Link
              to={{ pathname: "https://inkwiry.com/termsofservice" }}
              target="_blank"
            >
              Terms of Service
            </Link>
            &nbsp;//&nbsp;
            <Link
              to={{ pathname: "https://inkwiry.com/privacy" }}
              target="_blank"
            >
              Privacy Policy
            </Link>
            &nbsp;//&nbsp;
            <Link
              to={{ pathname: "https://inkwiry.com/security" }}
              target="_blank"
            >
              Security
            </Link>
          </p>
        </div>
      </footer>
    </Typography>
  );
};
// export default withStyles(styles)(Home);
Home.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(Home);
