import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';

import classNames from 'classnames';
import { withStyles, useTheme } from '@material-ui/core/styles';
import * as commonFunctions from '../../../utilities/commonFunctions';
import PropTypes from 'prop-types';
import { withApollo } from "react-apollo";
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import styles from './styles';
import { loader } from 'graphql.macro';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import Pageloader from '../pageloader';
import _ from 'lodash'

const ALL_REMINDERS = loader('../../../graphql/schema/ui/general-settings/getAllReminders.graphql');
const ALL_VIDEOS = loader('../../../graphql/schema/ui/general-settings/getAllVideos.graphql');

const UPDATE_CONNECT_VIDEOS = loader('../../../graphql/schema/ui/general-settings/updateConnectVideos.graphql');
const UPDATE_TUTORIAL_VIDEOS = loader('../../../graphql/schema/ui/general-settings/saveTutorialPageDetails.graphql');
const UPDATE_POWER_UP_FINANCIAL_VIDEOS = loader('../../../graphql/schema/ui/general-settings/updatePowerUpFinancialVideos.graphql')
const UPDATE_OTHER_VIDEOS = loader('../../../graphql/schema/ui/general-settings/updateOtherPageDetails.graphql')

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
    children: PropTypes.object.isRequired,
    index: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const GeneralSettings = (props) => {
    const { classes } = props;

    const [value, setValue] = useState(0);
    const [loading, setLoading] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState("");
    
    const [openConnectAction, setOpenConnectAction] = React.useState(false);
    const [actionConnectValues, setActionConnectValues] = React.useState({});
    
    const [openTutorialAction, setOpenTutorialAction] = React.useState(false);
    const [actionTutorialValues, setActionTutorialValues] = React.useState({});
    
    const [openPOFLPAction, setOpenPOFLPAction] = React.useState(false);
    const [actionPOFLPValues, setActionPOFLPValues] = React.useState({});

    const [openOtherAction, setOpenOtherAction] = React.useState(false);
    const [actionOtherValues, setActionOtherValues] = React.useState({});

    const [remindersPeriods, setReminders] = React.useState([]);
    const [saveTrailPeriod, setSaveTrailPeriod] = React.useState({});
    const [videos, setVideos] = React.useState({});

    const [reminderCheck, setReminderCheck] = React.useState([
        '1d',
        '2d',
        '3d',
        '4d',
        '5d',
        '6d',
        '7d',
        '14d',
        '21d',
        '1m',
    ]);

    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [getAllReminders, {data: res}] = useLazyQuery(ALL_REMINDERS, {
        fetchPolicy: 'network-only',
        onCompleted(){
            if(res.getAllReminders){
                setReminders(res.getAllReminders);
                const value = _.filter(res.getAllReminders, (r) => r.reminder_value === "saveTrailPeriod");
                const storePropeties = "reminder_value"
                res.getAllReminders.map((v) => {
                    Object.keys(v).reduce(function (obj, key) {
                    if(storePropeties.includes(key))
                        console.log(v[key])
                    }, {})    
                });
                setSaveTrailPeriod(value[0])
                setLoading(false);
            }
        },
        onError(error){
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
            setErrorMessage(errorMsg);
            setLoading(false);
        }
    });
    const [getVideos, {data: result}] = useLazyQuery(ALL_VIDEOS, {
        fetchPolicy: 'network-only',
        onCompleted(){
            if(result.getAllVideos){
                setVideos(result.getAllVideos);
                setLoading(false);
            }
        },
        onError(error){
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
            setErrorMessage(errorMsg);
            setLoading(false);
        }
    });

    useEffect(() => {
        setLoading(true);
        getAllReminders();
        getVideos();
    }, [])

    const [updateConnectVideos] = useMutation( UPDATE_CONNECT_VIDEOS, {
        onCompleted(){
            setLoading(false);
            setOpenConnectAction({status: false, id: 0}); 
            setActionConnectValues({});
            getVideos();
        },
        onError(error) {
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
            setErrorMessage(errorMsg);
            console.log(error);
            setLoading(false);
        },
    });
    const [updateTutorialsVideos] = useMutation( UPDATE_TUTORIAL_VIDEOS, {
        onCompleted(){
            setLoading(false);
            setOpenTutorialAction({status: false, id: 0}); 
            setActionTutorialValues({})
            getVideos();
        },
        onError(error) {
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
            setErrorMessage(errorMsg);
            console.log(error);
            setLoading(false);
        },
    });
    const [updatePowerUpFinancialVideos] = useMutation( UPDATE_POWER_UP_FINANCIAL_VIDEOS, {
        onCompleted(){
            setLoading(false);
            setOpenPOFLPAction({status: false, id: 0}); 
            setActionPOFLPValues({})
            getVideos();
        },
        onError(error) {
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
            setErrorMessage(errorMsg);
            console.log(error);
            setLoading(false);
        },
    });
    const [updateOtherVideos] = useMutation( UPDATE_OTHER_VIDEOS, {
        onCompleted(){
            setLoading(false);
            setOpenOtherAction({status: false, id: 0}); 
            setActionOtherValues({})
            getVideos();
        },
        onError(error) {
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
            setErrorMessage(errorMsg);
            console.log(error);
            setLoading(false);
        },
    });

    const handleConnectAction = (id)=>{
        let field  = _.filter(videos.contact_videos, video => video.id === id);
        field = field[0];
        setActionConnectValues({id: field.id, title: field.title, youtube_link: field.link, image: field.image, order: field.order})
        setOpenConnectAction({status: true, id})
    }
    const handleConnectActionChange = (e) => {
        setActionConnectValues({...actionConnectValues, id: openConnectAction.id,[e.target.name]: e.target.value})
    }

    const handleTutorialAction = (id)=>{
        let field  = _.filter(videos.tutorial_videos, video => video.id === id);
        field = field[0];
        setActionTutorialValues({
            created_date: field.created_date,
            description: field.description,
            id: field.id,
            id_class: field.id_class,
            image: field.image,
            youtube_link: field.link,
            order: field.order,
            status: field.status,
            title: field.title,
            filter:field.filter,
        })
        setOpenTutorialAction({status: true, id})
    }
    const handleTutorialActionChange = (e) => {
        setActionTutorialValues({...actionTutorialValues, id: openTutorialAction.id,[e.target.name]: e.target.value})
    }

    const handlePOFLPAction = (id)=>{
        let field  = _.filter(videos.power_up_tutorial, video => video.id === id);
        field = field[0];
        setActionPOFLPValues({
            id: field.id, 
            main_title: field.main_title, 
            title: field.title, 
            youtube_link: field.link,
            type: field.type,
            part: field.part,
            create_date: field.create_date,
        })
        setOpenPOFLPAction({status: true, id})
    }
    const handlePOFLPActionChange = (e) => {
        setActionPOFLPValues({...actionPOFLPValues, id: openPOFLPAction.id,[e.target.name]: e.target.value})
    }

    const handleOtherAction = (id)=>{
        let field  = _.filter(videos.other_videos, video => video.id === id);
        field = field[0];
        setActionOtherValues({
            id: field.id, 
            title: field.page_title, 
            youtube_link: field.link,
            created_date: field.created_date,
        })
        setOpenOtherAction({status: true, id})
    }
    const handleOtherActionChange = (e) => {
        setActionOtherValues({...actionOtherValues, id: openOtherAction.id,[e.target.name]: e.target.value})
    }

    return (
        <div>
            {loading === true ? <Pageloader loading={loading} /> : ""}
            <div className="dashboard-page">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.dashboardTabs}
                >
                    <Tab label="Billing Settings" {...a11yProps(0)} />
                    <Tab label="Videos Management" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <div className={classes.billingRow}>
                        <h3>Payment Reminders</h3>
                        <p> The below options can be toggled on and off. These options dictate what will show in the drop down for the user in the billing settings page. Payment Reminders send an auto generated action notification to the user based on this selection. </p>
                        <div className={classes.reminders_checkbox}>
                            {
                                remindersPeriods !== undefined &&
                                remindersPeriods.map((reminder => (
                                    reminder.reminder_value !== "saveTrailPeriod" &&
                                    <>
                                    <div>
                                        <input type="checkbox" name= {reminder.reminder_value} id={reminder.reminder_value} onClick={(e) => { 
                                            !reminderCheck.includes(reminder.reminder_value) ?
                                            setReminderCheck([...reminderCheck, reminder]) 
                                            : setReminderCheck(reminderCheck.filter(r => r !== reminder.reminder_value));
                                        }} checked = {reminderCheck.includes(reminder.reminder_value)}/>
                                        <label htmlFor={reminder.reminder_value}>{reminder.reminder_name}</label>
                                    </div>
                                    </>
                                )))
                            }
                            {
                                console.log(reminderCheck)

                            }
                        </div>
                        <h3>Waiting time to cancel the account 
                            <span className={classes.infoIcon} data-for="detailsTooltip" data-tip="When a user does not make a payment for whatever reason, we will notify them of the missed payment. Then, if no payment is made within the time selected below, we will cancel the account." title="When a user does not make a payment for whatever reason, we will notify them of the missed payment. Then, if no payment is made within the time selected below, we will cancel the account."><img src="./img/info-icon.svg" alt="" /></span>
                        </h3>
                        <div className={classes.inputGroup}>
                            <input className={classes.input} value={saveTrailPeriod !== undefined ? saveTrailPeriod.selected: ""} type="number" name={saveTrailPeriod !== undefined ? saveTrailPeriod.reminder_value: ""} ng-model="trailPeriod" min="0" max="30"/>
                            <label htmlFor={saveTrailPeriod !== undefined ? saveTrailPeriod.reminder_value: ""}> days </label>
                            <button className={classes.saveBtn}>SAVE</button>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <div>
                        {errorMessage && (<div className={classes.noteError}>{errorMessage}</div>)}
                    </div>

                    {/* Connect */}
                    <div className={classes.userTableDiv}>
                        <h3>Connect page videos</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Title</th>
                                    <th>Youtube Link</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    videos.contact_videos !== undefined && videos.contact_videos.map((video, index)=>(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{video.title}</td>
                                            <td>{video.link}</td>
                                            <td>
                                                <Link onClick={()=>handleConnectAction(video.id)}>
                                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <Dialog
                        className="popup-model popup-model-new"
                        open={openConnectAction.status}
                        onClose={()=>{setOpenConnectAction({status: false, id: 0}); setActionConnectValues({})}}
                        aria-labelledby="note-dialog-title"
                        aria-describedby="note-dialog-description"
                    >
                        <div className="model-content">
                            <div className="modal-header">
                                <button onClick={()=>{setOpenConnectAction({status: false, id: 0}); setActionConnectValues({})}} >X</button>
                                <h3 className="popup-title">Edit Video Details</h3>
                            </div>
                            <div className="model-body">
                            <div className="input-div">
                                <label htmlFor="title">Title <span className="tool-info" title="Test"><img src="./img/info-icon.svg" alt="" /></span></label>
                                <input onChange={(e)=>handleConnectActionChange(e)} value = {actionConnectValues.title} type="text" name="title" id="title"/>
                            </div>
                            <div className="input-div">
                                <label htmlFor="youtube_link">Youtube Link <span className="tool-info" title="Test"><img src="./img/info-icon.svg" alt="" /></span></label>
                                <input onChange={(e)=>handleConnectActionChange(e)} value = {actionConnectValues.youtube_link} type="text" name="youtube_link" id="youtube_link"/>
                            </div>
                            </div>
                            <div className="model-footer">
                            <button onClick = {() => {
                                setLoading(true);
                                updateConnectVideos({
                                    variables: {
                                        data : {
                                            id: actionConnectValues.id, 
                                            image: actionConnectValues.image, 
                                            link: actionConnectValues.youtube_link, 
                                            order: actionConnectValues.order, 
                                            title: actionConnectValues.title
                                        }
                                    }
                                })
                            }} className="delete-btn text-capitalize">Save</button>
                            <button onClick = {()=>{
                                setOpenConnectAction({status: false, id: 0})
                                setActionConnectValues({})
                            }} className="close-btn text-capitalize">Cancel</button>
                            </div>
                        </div>
                    </Dialog>

                    {/* Tutorial */}
                    <div className={classes.userTableDiv}>
                        <h3>Tutorial page videos</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Title</th>
                                    <th>Youtube Link</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    videos.tutorial_videos !== undefined && videos.tutorial_videos.map((video, index)=>(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{video.title}</td>
                                            <td>{video.link}</td>
                                            <td>
                                                <Link onClick={()=>handleTutorialAction(video.id)}>
                                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <Dialog
                        className="popup-model popup-model-new"
                        open={openTutorialAction.status}
                        onClose={()=>{setOpenTutorialAction({status: false, id: 0}); setActionTutorialValues({})}}
                        aria-labelledby="note-dialog-title"
                        aria-describedby="note-dialog-description"
                    >
                        <div className="model-content">
                            <div className="modal-header">
                                <button onClick={()=>{setOpenConnectAction({status: false, id: 0}); setActionConnectValues({})}} >X</button>
                                <h3 className="popup-title">Edit Video Details</h3>
                            </div>
                            <div className="model-body">
                                <div className="input-div">
                                    <label htmlFor="title">Title <span className="tool-info" title="Test"><img src="./img/info-icon.svg" alt="" /></span></label>
                                    <input onChange={(e)=>handleTutorialActionChange(e)} value = {actionTutorialValues.title} type="text" name="title" id="title"/>
                                </div>
                                <div className="input-div">
                                    <label htmlFor="youtube_link">Youtube Link <span className="tool-info" title="Test"><img src="./img/info-icon.svg" alt="" /></span></label>
                                    <input onChange={(e)=>handleTutorialActionChange(e)} value = {actionTutorialValues.youtube_link} type="text" name="youtube_link" id="youtube_link"/>
                                </div>
                                <div className="input-div">
                                    <label htmlFor="minutes_title">Minute and titles <span className="tool-info" title="Test"><img src="./img/info-icon.svg" alt="" /></span></label>
                                    <textarea onChange={(e)=>handleTutorialActionChange(e)} value={actionTutorialValues.description} rows="9" name="minutes_title" className={classNames(classes.input)}  id="" cols="60" rows="10"></textarea>
                                </div>
                            </div>
                            <div className="model-footer">
                            <button onClick = {() => {
                                setLoading(true);
                                updateTutorialsVideos({
                                    variables: {
                                        data: {
                                            created_date: actionTutorialValues.created_date,
                                            description: actionTutorialValues.description,
                                            id: actionTutorialValues.id,
                                            id_class: actionTutorialValues.id_class,
                                            image: actionTutorialValues.image,
                                            link: actionTutorialValues.youtube_link,
                                            order: actionTutorialValues.order,
                                            status: actionTutorialValues.status,
                                            title: actionTutorialValues.title,
                                            filter:actionTutorialValues.filter,
                                        }
                                    }
                                })
                            }} className="delete-btn text-capitalize">Save</button>
                            <button onClick = {()=>{
                                setOpenTutorialAction({status: false, id: 0})
                                setActionTutorialValues({})
                            }} className="close-btn text-capitalize">Cancel</button>
                            </div>
                        </div>
                    </Dialog>
                    {/* Tutorial End */}

                     {/* Power of Financial Life Planning Video */}
                    <div className={classes.userTableDiv}>
                        <h3>Power of Financial Life Planning</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Main Title</th>
                                    <th>Title</th>
                                    <th>Youtube Link</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    videos.power_up_tutorial !== undefined && videos.power_up_tutorial.map((video, index)=>(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{video.main_title}</td>
                                            <td>{video.title}</td>
                                            <td>{video.link}</td>
                                            <td>
                                                <Link onClick={()=>handlePOFLPAction(video.id)}>
                                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <Dialog
                        className="popup-model popup-model-new"
                        open={openPOFLPAction.status}
                        onClose={()=>{setOpenPOFLPAction({status: false, id: 0}); setActionPOFLPValues({})}}
                        aria-labelledby="note-dialog-title"
                        aria-describedby="note-dialog-description"
                    >
                        <div className="model-content">
                            <div className="modal-header">
                                <button onClick={()=>{setOpenConnectAction({status: false, id: 0}); setActionConnectValues({})}} >X</button>
                                <h3 className="text-light">Edit Videos Details</h3>
                            </div>
                            <div className="model-body">
                                <div className="input-div">
                                    <label htmlFor="title">Title <span className="tool-info" title="Test"><img src="./img/info-icon.svg" alt="" /></span></label>
                                    <input onChange={(e)=>handlePOFLPActionChange(e)} value = {actionPOFLPValues.title} type="text" name="title" id="title"/>
                                </div>
                                <div className="input-div">
                                    <label htmlFor="youtube_link">Youtube Link <span className="tool-info" title="Test"><img src="./img/info-icon.svg" alt="" /></span></label>
                                    <input onChange={(e)=>handlePOFLPActionChange(e)} value = {actionPOFLPValues.youtube_link} type="text" name="youtube_link" id="youtube_link"/>
                                </div>
                            </div>
                            <div className="model-footer">
                            <button onClick = {() => {
                                setLoading(true);
                                updatePowerUpFinancialVideos({
                                    variables: {
                                        data: {
                                            id: actionPOFLPValues.id, 
                                            main_title: actionPOFLPValues.main_title, 
                                            title: actionPOFLPValues.title, 
                                            link: actionPOFLPValues.youtube_link,
                                            type: actionPOFLPValues.type,
                                            part: actionPOFLPValues.part,
                                            create_date: actionPOFLPValues.create_date,
                                        }
                                    }
                                })
                            }} className="delete-btn text-capitalize">Save</button>
                            <button onClick = {()=>{
                                setOpenPOFLPAction({status: false, id: 0})
                                setActionPOFLPValues({})
                            }} className="close-btn text-capitalize">Cancel</button>
                            </div>
                        </div>
                    </Dialog>
                     {/* Power of Financial Life Planning Video End*/}

                    {/* Other */}
                    <div className={classes.userTableDiv}>
                        <h3>Other videos</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Title</th>
                                    <th>Youtube Link</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    videos.other_videos !== undefined && videos.other_videos.map((video, index)=>(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{video.title}</td>
                                            <td>{video.link}</td>
                                            <td>
                                                <Link onClick={()=>handleOtherAction(video.id)}>
                                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <Dialog
                        className="popup-model popup-model-new"
                        open={openOtherAction.status}
                        onClose={()=>{setOpenOtherAction({status: false, id: 0}); setActionOtherValues({})}}
                        aria-labelledby="note-dialog-title"
                        aria-describedby="note-dialog-description"
                    >
                        <div className="model-content">
                            <div className="modal-header">
                                <button onClick={()=>{setOpenConnectAction({status: false, id: 0}); setActionConnectValues({})}} >X</button>
                                <h3 className="popup-title">Edit Video Details</h3>
                            </div>
                            <div className="model-body">
                                <div className="input-div">
                                    <label htmlFor="title">Title <span className="tool-info" title="Test"><img src="./img/info-icon.svg" alt="" /></span></label>
                                    <input onChange={(e)=>handleOtherActionChange(e)} value = {actionOtherValues.title} type="text" name="title" id="title"/>
                                </div>
                                <div className="input-div">
                                    <label htmlFor="youtube_link">Youtube Link <span className="tool-info" title="Test"><img src="./img/info-icon.svg" alt="" /></span></label>
                                    <input onChange={(e)=>handleOtherActionChange(e)} value = {actionOtherValues.youtube_link} type="text" name="youtube_link" id="youtube_link"/>
                                </div>
                            </div>
                            <div className="model-footer">
                            <button onClick = {() => {
                                setLoading(true);
                                updateOtherVideos({
                                    variables: {
                                        data: {
                                            id: actionOtherValues.id, 
                                            page_title: actionOtherValues.title, 
                                            link: actionOtherValues.youtube_link,
                                            created_date: actionOtherValues.created_date,
                                        }
                                    }
                                })
                            }} className="delete-btn text-capitalize">Save</button>
                            <button onClick = {()=>{
                                setOpenOtherAction({status: false, id: 0})
                                setActionOtherValues({})
                            }} className="close-btn text-capitalize">Cancel</button>
                            </div>
                        </div>
                    </Dialog>
                    {/* Other End*/}
                </TabPanel>
                
            </div>
        </div>
    )
}

GeneralSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles),
    withRouter,
    withApollo
);

export default enhance(GeneralSettings)