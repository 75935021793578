import * as commonFunctions from "../../utilities/commonFunctions";

import { Button, Grid } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import { Link } from "react-router-dom";
import Pageloader from "../ui/pageloader";
import PropTypes from "prop-types";
import { TextField } from "final-form-material-ui";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const CHECK_USER_NAME = loader(
  "../../graphql/schema/schools/check-admin-username.graphql"
);
const CHECK_EMAIL = loader(
  "../../graphql/schema/schools/check-admin-email.graphql"
);
const INSTITUTIONS = loader(
  "../../graphql/schema/business/business-institutions.graphql"
);
const UPDATE_ADMIN = loader(
  "../../graphql/schema/schools/update-school-admin.graphql"
);
const UPDATE_ADMIN_PASSWORD = loader(
  "../../graphql/schema/schools/update-school-admin-password.graphql"
);
const GETADMINBY_ID = loader(
  "../../graphql/schema/schools/get-school-admin.graphql"
);

const EditBusinessAdmin = (props) => {
  const { classes, history } = props;

  const [loading, setLoading] = React.useState(true);
  const [defaultData, setdefaultData] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  // const [userName, setUserName] = React.useState("");
  // const [password, setPassword] = React.useState("");
  // const [confirmPassword, setConfirmPassword] = React.useState("");
  const [userId, setUserId] = React.useState("");
  // const [paswordValidation, setPaswordValidation] = React.useState(false);
  // const [alpFild, setAlpFild] = React.useState(false);
  // const [uplet, setUplet] = React.useState(false);
  // const [numFild, setNumFild] = React.useState(false);
  // const [passLen, setPassLen] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailValue, setEmailValue] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [passCheck, setPassCheck] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [alphabetsExist, setAlphabetsExist] = useState(false);
  const [numericExist, setNumericExist] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [userName, setUserName] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [institutionData, setInstitutionData] = useState([]);
  const [schoolError, setSchoolError] = useState(false);
  const [successmsg, setSuccessMsg] = useState("");
  const [userSuccessMsg, setUserSuccessMsg] = useState("");

  const [checkEmailExist] = useLazyQuery(CHECK_EMAIL, {
    variables: {
      email: emailValue,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckAdminEmail: { status } }) {
      if (status) {
        setEmailError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (emailValue !== "") {
        setEmailError(formatedErrors);
      }
    },
  });

  const [institutions] = useLazyQuery(INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(response.getBusinessInstitutions);
      setLoading(false);
    },
  });

  useEffect(() => {
    institutions();
  }, []);

  useEffect(() => {
    userData();
  }, [userId]);

  const [userData, { data: userResult }] = useLazyQuery(GETADMINBY_ID, {
    variables: {
      id: userId,
    },
    fetchPolicy: "network-only",
    onCompleted() {
      if (userResult.getSchoolAdminById) {
        setdefaultData(userResult.getSchoolAdminById);
        setFirstName(userResult.getSchoolAdminById.name);
        setLastName(userResult.getSchoolAdminById.user_lname);
        setEmailValue(userResult.getSchoolAdminById.email);
        setLoading(false);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [checkUserName] = useLazyQuery(CHECK_USER_NAME, {
    variables: {
      user_name: userName,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckAdminName: { status } }) {
      if (status) {
        setUsernameError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (userName !== "") {
        setUsernameError(formatedErrors);
      }
    },
  });

  const [updateSchoolAdminData] = useMutation(UPDATE_ADMIN, {
    onCompleted() {
      setLoading(false);
      setSuccessMsg("Account information has been updated successfully.");
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [updateSchoolAdminPassword] = useMutation(UPDATE_ADMIN_PASSWORD, {
    onCompleted() {
      setLoading(false);
      setUserSuccessMsg(
        "Username and password have been updated successfully."
      );
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  // const wrapperRef = React.useRef(null);

  // const handleClickOutside = (event) => {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //         setPaswordValidation(false);
  //     }
  // }
  // React.useEffect(() => {
  //     document.addEventListener("mousedown", handleClickOutside);

  //     return () => {
  //         document.removeEventListener("mousedown", handleClickOutside);
  //     };
  // });
  React.useEffect(() => {
    setUserId(window.location.search.split("=")[1]);
  }, []);
  // const handleChange = (e) => {
  //     setErrorMessage('');
  //     if (e.currentTarget.id === 'email') {
  //         setEmail(e.currentTarget.value);
  //     } else if (e.currentTarget.id === 'firstName') {
  //         setFirstName(e.currentTarget.value);
  //     } else if (e.currentTarget.id === 'lastName') {
  //         setLastName(e.currentTarget.value);
  //     } else if (e.currentTarget.id === "userName") {
  //         setUserName(e.currentTarget.value);
  //     } else if (e.currentTarget.id === "password") {
  //         let Lowlet = /[a-z]/;
  //         let Upperlet = /[A-Z]/;
  //         if (typeof e.target.value != 'undefined' && e.target.value.match(Lowlet)) {
  //             setAlpFild(true);
  //         } else {
  //             setAlpFild(false);
  //         }
  //         if (typeof e.target.value != 'undefined' && e.target.value.match(Upperlet)) {
  //             setUplet(true);
  //         } else {
  //             setUplet(false);
  //         }
  //         let number = /[0-9]/i;
  //         if (typeof e.target.value != 'undefined' && e.target.value.match(number)) {
  //             setNumFild(true);
  //         } else {
  //             setNumFild(false);
  //         }
  //         if (typeof e.target.value != 'undefined' && e.target.value.length === 8) {
  //             setPassLen(true);
  //         } else {
  //             setPassLen(e.target.value.length <= 8 ? false : true);
  //         }
  //         setPassword(e.currentTarget.value);
  //     } else if (e.currentTarget.id === "confirmpassword") {
  //         setConfirmPassword(e.currentTarget.value);
  //     }

  // };

  // const handleSaveUserData = () => {
  //     if (firstName === '') {
  //         setErrorMessage('Please provide your first name.');
  //     } else if (lastName === '') {
  //         setErrorMessage('Please provide your last name.');
  //     } else if (email === '') {
  //         setErrorMessage('Please enter your email.');
  //     } else if (userName === '') {
  //         setErrorMessage('Please provide your username.');
  //     } else if (password === '') {
  //         setErrorMessage('Please provide your password.');
  //     } else if (confirmPassword === '') {
  //         setErrorMessage('Please enter confirm password.');
  //     } else if (password !== confirmPassword) {
  //         setErrorMessage('Confirm password should match password.');
  //     } else {
  //         setLoading(true);
  //         saveUserData({
  //             variables: {
  //                 data: {
  //                     id: userId,
  //                     name: firstName,
  //                     user_lname: lastName,
  //                     email: email,
  //                     password: password,
  //                     user_name: userName,
  //                     confirm_password: confirmPassword
  //                 }
  //             },
  //         });
  //     }
  // }
  // const handlePasswordFocus = () => {
  //     setPaswordValidation(true);
  // }

  const handleSubmitClick = () => {
    setSubmitClicked(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name is required";
    } else {
      setFirstName(values.first_name);
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required.";
    } else {
      setLastName(values.last_name);
    }

    if (values.email) {
      if (!commonFunctions.validateEmail(values.email)) {
        errors.email = "Enter valid email.";
      }
      setEmailValue(values.email);
    }
    if (!values.email) {
      errors.email = "email is required.";
      setEmailValue("");
    }
    if (!values.School) {
      if (submitClicked) {
        setSchoolError(true);
      }
      errors.School = "please select School";
    } else {
      setSchoolError(false);
      setSchoolId(values.School);
    }
    return errors;
  };

  const validateOne = (values) => {
    const errors = {};

    if (!values.userName) {
      errors.userName = "username is required.";
    } else {
      setUserName(values.userName);
    }

    if (values.userName) {
      if (values.userName.length <= 3) {
        setUsernameError("User must be more that 4 charecters");
      } else {
        setUsernameError("");
      }
    }

    if (values.password) {
      if (values.password.length >= 8) {
        setPasswordLength(true);
      } else {
        errors.password = "";
        setPasswordLength(false);
      }
      if (!commonFunctions.validateAlpha(values.password)) {
        setAlphabetsExist(true);
      } else {
        errors.password = "";
        setAlphabetsExist(false);
      }
      if (!commonFunctions.validateNumeric(values.password)) {
        setNumericExist(true);
      } else {
        errors.password = "";
        setNumericExist(false);
      }
    } else {
      setPasswordLength(false);
      setAlphabetsExist(false);
      setNumericExist(false);
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = "confirm password is required.";
    } else {
      if (values.password_confirmation) {
        if (values.password === values.password_confirmation) {
          setPasswordMatch(true);
        } else {
          setPasswordMatch(false);
          errors.password_confirmation = "";
        }
      }
    }
    return errors;
  };

  const onSubmit = (values) => {
    setSubmitClicked(false);
    updateSchoolAdminData({
      variables: {
        data: {
          id: userId,
          name: firstName,
          user_lname: lastName,
          user_name: userName,
          email: emailValue,
          password: values.password,
          confirm_password: values.password_confirmation,
          institution_id: schoolId,
        },
      },
    });
  };

  const onSubmitOne = (values) => {
    updateSchoolAdminPassword({
      variables: {
        data: {
          id: userId,
          user_name: userName,
          password: values.password,
          confirm_password: values.password_confirmation,
        },
      },
    });
  };

  return (
    <Typography className="edit-page-new">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <div className={classes.backToAdmin}>
        <Link to="/businesses-admins">
          <i className="fa fa-long-arrow-left" aria-hidden="true"></i> Back to
          Business Admins
        </Link>
      </div>
      <div className={classes.formBlock}>
        <h3>Edit Business Admins</h3>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Form
              validate={validate}
              onSubmit={onSubmit}
              initialValues={{
                first_name: defaultData.name,
                last_name: defaultData.user_lname,
                email: defaultData.email,
                School: defaultData.institution_ids,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <div className={classes.formGroup}>
                      <label>First Name</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="first_name"
                        fullWidth
                        required
                        component={TextField}
                        defaultValue={defaultData.first_name}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Last Name</label>
                      <Field
                        placeholder="ex. Inkwiry"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="last_name"
                        fullWidth
                        required
                        component={TextField}
                        defaultValue={defaultData.last_name}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Email</label>
                      <Field
                        placeholder="ex. mike@inkwiry.com"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="email"
                        fullWidth
                        required
                        onBlur={(e) => {
                          checkEmailExist(e);
                        }}
                        component={TextField}
                        defaultValue={defaultData.email}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div>{emailError}</div>
                    <div className={classes.formGroup}>
                      <label>School</label>
                      <div
                        className={classNames(
                          classes.inputLeft,
                          classes.inputLeftSelect
                        )}
                      >
                        <Field
                          className={schoolError ? classes.typeError : ""}
                          name="School"
                          component="select"
                          variant="outlined"
                          multiple
                          defaultValue={defaultData.institution_ids}
                        >
                          {institutionData.length !== 0 &&
                            institutionData.map((l, index) => (
                              <option key={index} value={l.id}>
                                {l.institution_name}
                              </option>
                            ))}
                        </Field>
                        {successmsg && (
                          <div className={classes.sccessMsg}>
                            <span>{successmsg}</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={classes.updateBtn}>
                      <button type="submit" onClick={handleSubmitClick}>
                        UPDATE
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
            <Form
              validate={validateOne}
              onSubmit={onSubmitOne}
              initialValues={{
                userName: defaultData.user_name,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <h3>Username and Password</h3>
                    {/* <div className={classes.updateText}>
																					<span>Username and Password</span>
																			</div> */}
                    <div className={classes.formGroup}>
                      <label>Username</label>
                      <div className={classes.inputLeft}>
                        <Field
                          id="outlined-basic"
                          variant="outlined"
                          type="text"
                          autoComplete="current-password"
                          name="userName"
                          placeholder="Username"
                          fullWidth
                          required
                          onBlur={(e) => {
                            checkUserName(e);
                          }}
                          component={TextField}
                        />
                        {usernameError ? (
                          <div className={classes.nameError}>
                            {usernameError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className={classes.formGroup}>
                      <label>New Password</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="password"
                        autoComplete="current-password"
                        placeholder="Password"
                        name="password"
                        fullWidth
                        required
                        component={TextField}
                        onFocus={() => {
                          setPassCheck(true);
                        }}
                        onBlur={() => {
                          setPassCheck(false);
                        }}
                        className={classes.inputLeft}
                      />
                      <div
                        className={
                          passCheck
                            ? classes.resetPassCondition +
                              " " +
                              classes.resetPass
                            : classes.resetPass
                        }
                      >
                        <div className={classes.conditions}>
                          <p>
                            <span>
                              {passwordLength ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>Include at least 8 characters</label>
                          </p>
                          <p>
                            <span>
                              {alphabetsExist ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>
                              Include upper and lower case characters
                            </label>
                          </p>
                          <p>
                            <span>
                              {numericExist ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>Include at least 1 number</label>
                          </p>
                          <p>
                            <span>
                              {passwordMatch ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>Passwords match</label>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Confirm Password</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="password"
                        autoComplete="current-password"
                        name="password_confirmation"
                        placeholder="Confirm New Password"
                        fullWidth
                        required
                        component={TextField}
                        onFocus={() => {
                          setPassCheck(true);
                        }}
                        onBlur={() => {
                          setPassCheck(false);
                        }}
                        className={classes.inputLeft}
                      />
                    </div>
                    {userSuccessMsg && (
                      <div className={classes.sccessMsgDiv}>
                        <div className={classes.sccessMsg}>
                          <span>{userSuccessMsg}</span>
                        </div>
                      </div>
                    )}

                    <div className={classes.updateBtn}>
                      <button type="submit">UPDATE</button>
                    </div>
                  </div>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Typography>
  );
};
EditBusinessAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(EditBusinessAdmin);
