import React,{useState} from 'react';
import {  useApolloClient } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Grid } from "@material-ui/core";
import { Link, withRouter,useHistory } from 'react-router-dom';


const Footer = (props) => {
  const { classes } = props;
  const [logoutButton,setLogout] = React.useState(false);
   const history = useHistory();
   const apolloClient = useApolloClient();

  const handleLogout = () => {
    localStorage.removeItem('Inqwiry::access_token');
    localStorage.removeItem('Inqwiry::user_name');
    localStorage.removeItem('institution');
    apolloClient.resetStore();
    history.push('/');
  };

  return (
    <div className={classes.footerVillanova}>
      <div className={ `${classes.container} ${classes.containerAdmin}` } >
        <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <div className={classes.villanovaLeft}>
                <Link to="#" onClick={ ()=> setLogout(!logoutButton) } ><img src="./img/user.png" alt="" />admin
                  {logoutButton && 
                     <div className={ classes.logutText }onClick={handleLogout} > <a href="#"> logout </a> </div>
                  }
                </Link>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <div className={classes.inkwiryLogo}>
                <Link to="#">
                  <img src="/logo.png" alt="" />
                </Link>
              </div>
            </Grid>
          </Grid>
      </div>
    </div>
  )
}

export default withStyles(styles)(Footer);
