import * as commonFunctions from "../../utilities/commonFunctions";

import { Button, Grid } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import { Link } from "react-router-dom";
import Pageloader from "../../components/ui/pageloader";
import PropTypes from "prop-types";
import { TextField } from "final-form-material-ui";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const GETMEMBERBY_ID = loader("../../graphql/schema/ui/GetMemberById.graphql");
const UPDATEUSERANDPASSWORD = loader(
  "../../graphql/schema/schools/update-schoolUser-password.graphql"
);
const UPDATEUSER = loader(
  "../../graphql/schema/schools/update-schoolUser-userName.graphql"
);
const ADDMEMBER = loader(
  "../../graphql/schema/schools/add-school-user.graphql"
);
const STATES = loader("../../graphql/schema/ui/states.graphql");
const CHECK_USER_NAME = loader("../../graphql/schema/ui/CheckUserName.graphql");
const CHECK_EMAIL = loader("../../graphql/schema/ui/checkEmail.graphql");
const INSTITUTIONS = loader(
  "../../graphql/schema/business/business-institutions.graphql"
);

const EditBusinessUsers = (props) => {
  const { classes, history } = props;

  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [statesData, setStatesData] = React.useState([]);
  const [emailValue, setEmailValue] = useState("");
  const [passCheck, setPassCheck] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [alphabetsExist, setAlphabetsExist] = useState(false);
  const [numericExist, setNumericExist] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [AccountType, setAccountType] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [userName, setUserName] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [institutionData, setInstitutionData] = useState([]);
  const [school, setSchool] = useState(false);
  const [userId, setUserId] = React.useState("");
  const [displaydata, setDisplayData] = useState("");
  const [successmsg, setSuccessMsg] = useState("");
  const [userSuccessMsg, setUserSuccessMsg] = useState("");

  const [institutions] = useLazyQuery(INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(response.getBusinessInstitutions);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    institutions();
    setUserId(window.location.search.split("=")[1]);
  }, []);

  const { data: stateResult } = useQuery(STATES, {
    variables: {},
    fetchPolicy: "network-only",
    onCompleted() {
      if (stateResult.States) {
        setStatesData(stateResult.States);
      }
      setLoading(false);
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [checkUserName] = useLazyQuery(CHECK_USER_NAME, {
    variables: {
      data: userName,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckUserName: { status } }) {
      if (status) {
        setUsernameError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (userName !== "") {
        setUsernameError(formatedErrors);
      }
    },
  });

  const [checkEmailExist] = useLazyQuery(CHECK_EMAIL, {
    variables: {
      email: emailValue,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckEmail: { status } }) {
      if (status) {
        setEmailError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (emailValue !== "") {
        setEmailError(formatedErrors);
      }
    },
  });

  const { data: userResult } = useQuery(GETMEMBERBY_ID, {
    variables: {
      id: userId,
    },
    fetchPolicy: "network-only",
    onCompleted() {
      setDisplayData(userResult.GetMemberById);
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [userDetails] = useMutation(UPDATEUSER, {
    onCompleted() {
      setLoading(false);
      setSuccessMsg("Account information has been updated successfully.");
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const [userPassword] = useMutation(UPDATEUSERANDPASSWORD, {
    onCompleted() {
      setLoading(false);
      setUserSuccessMsg(
        "Username and password have been updated successfully."
      );
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const handleUserName = (e) => {
    checkUserName(e);
  };

  const handleSubmitClick = () => {
    setSubmitClicked(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required.";
    }

    if (values.email) {
      if (!commonFunctions.validateEmail(values.email)) {
        errors.email = "Enter valid email.";
      }
      setEmailValue(values.email);
    }
    if (!values.email) {
      errors.email = "email is required.";
      setEmailValue("");
    }
    if (!values.type) {
      if (submitClicked) {
        setAccountType(true);
      }
      errors.type = "please select Account type";
    } else {
      setAccountType(false);
    }
    if (!values.school) {
      if (submitClicked) {
        setSchool(true);
      }
      errors.school = "please select school";
    } else {
      setSchool(false);
    }
    return errors;
  };

  const validateOne = (values) => {
    const errors = {};
    if (!values.userName) {
      errors.userName = "username is required.";
    } else {
      setUserName(values.userName);
    }

    if (values.userName) {
      if (values.userName.length <= 3) {
        setUsernameError("User must be more that 4 charecters");
      } else {
        setUsernameError("");
      }
    }

    if (values.password) {
      if (values.password.length >= 8) {
        setPasswordLength(true);
      } else {
        errors.password = "";
        setPasswordLength(false);
      }
      if (!commonFunctions.validateAlpha(values.password)) {
        setAlphabetsExist(true);
      } else {
        errors.password = "";
        setAlphabetsExist(false);
      }
      if (!commonFunctions.validateNumeric(values.password)) {
        setNumericExist(true);
      } else {
        errors.password = "";
        setNumericExist(false);
      }
    } else {
      setPasswordLength(false);
      setAlphabetsExist(false);
      setNumericExist(false);
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = "confirm password is required.";
    } else {
      if (values.password_confirmation) {
        if (values.password === values.password_confirmation) {
          setPasswordMatch(true);
        } else {
          setPasswordMatch(false);
          errors.password_confirmation = "";
        }
      }
    }
    return errors;
  };

  const onSubmit = (values) => {
    setLoading(true);
    userDetails({
      variables: {
        data: {
          id: displaydata.id,
          account_type: values.type,
          user_fname: values.first_name,
          user_lname: values.last_name,
          email: emailValue,
          institution_id: values.school,
          school: values.schoolName ? values.schoolName : "",
          end_date: values.end_date,
          title: values.title ? values.title : "",
          grade: values.groupName ? values.groupName : "",
          streetaddress: values.streetaddress,
          streetaddress2: values.apartment ? values.apartment : "",
          city: values.city,
          state: values.State,
          zip_code: values.zipcode,
        },
      },
    });
  };
  const onSubmitOne = (values) => {
    setLoading(true);
    userPassword({
      variables: {
        data: {
          id: displaydata.id,
          user_name: values.userName,
          password: values.password,
          password_confirmation: values.password_confirmation,
        },
      },
    });
  };

  return (
    <Typography className="edit-page-new">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <div className={classes.backToAdmin}>
        <Link to="/school-user-management">
          <i className="fa fa-long-arrow-left" aria-hidden="true"></i> Back to
          School User Management
        </Link>
      </div>
      <div className={classes.formBlock}>
        <h3>Add User</h3>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Form
              validate={validate}
              onSubmit={onSubmit}
              initialValues={{
                type: displaydata.account_type,
                end_date: displaydata.end_date,
                first_name: displaydata.user_fname,
                last_name: displaydata.user_lname,
                email: displaydata.email,
                school:
                  displaydata.institution_id === null
                    ? ""
                    : displaydata.institution_id,
                schoolName:
                  displaydata.school === null ? "" : displaydata.school,
                streetaddress:
                  displaydata.streetaddress === null
                    ? ""
                    : displaydata.streetaddress,
                address:
                  displaydata.streetaddress2 === null
                    ? ""
                    : displaydata.streetaddress2,
                city: displaydata.city === null ? "" : displaydata.city,
                State: displaydata.state === null ? "" : displaydata.state,
                zipcode:
                  displaydata.zip_code === null ? "" : displaydata.zip_code,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <div className={classes.formGroup}>
                      <label>Account Type</label>
                      <Field
                        className={classNames(
                          classes.inputLeft,
                          AccountType ? classes.typeError : ""
                        )}
                        name="type"
                        component="select"
                        variant="outlined"
                      >
                        <option value="">Select Account Type </option>
                        <option value="">Select Account Type </option>
                        <option value="PF101 Only">PF101 Only</option>
                        <option value="PWI Only">PWI Only</option>
                        <option value="PWI + PF101">PWI + PF101</option>
                      </Field>
                    </div>
                    <div className={classes.formGroup}>
                      <label>End Date</label>
                      <Field
                        placeholder="MM/DD/YYYY"
                        id="outlined-basic"
                        variant="outlined"
                        type="date"
                        autoComplete="current-password"
                        name="end_date"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>

                    <div className={classes.formGroup}>
                      <label>First Name </label>
                      <Field
                        placeholder="ex. Mike"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="first_name"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Last Name</label>
                      <Field
                        placeholder="ex. Inkwiry"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="last_name"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Title</label>
                      <Field
                        placeholder="ex. Entrepreneur"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="title"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Email</label>
                      <Field
                        placeholder="ex. mike@inkwiry.com"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="email"
                        fullWidth
                        required
                        onBlur={(e) => {
                          checkEmailExist(e);
                        }}
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div>{emailError}</div>
                    <div className={classes.formGroup}>
                      <label>school</label>
                      <Field
                        className={classNames(
                          classes.inputLeft,
                          school ? classes.typeError : ""
                        )}
                        name="school"
                        component="select"
                        variant="outlined"
                      >
                        <option value="">Select Account Type </option>
                        {institutionData.length !== 0 &&
                          institutionData.map((l, index) => (
                            <option key={index} value={l.id}>
                              {l.institution_name}
                            </option>
                          ))}
                      </Field>
                    </div>
                    <div className={classes.formGroup}>
                      <label>School Name</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="schoolName"
                        placeholder="ex. Engineering"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Group</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="groupName"
                        placeholder="ex. Sophomore"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Street Address</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="streetAddress"
                        placeholder="ex. 123 Inkwiry Way"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Apartment, suite, unit, floor, etc.</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="apartment"
                        placeholder="ex. 3F"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>City</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="city"
                        placeholder="ex. Pennsylvania"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>State</label>
                      <Field
                        className={classes.inputLeft}
                        id="topicError"
                        name="State"
                        component="select"
                        variant="outlined"
                      >
                        <option value="">Select State </option>
                        {statesData.length !== 0 &&
                          statesData.map((l, index) => (
                            <option key={index} value={l.id}>
                              {l.state_name}
                            </option>
                          ))}
                      </Field>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Zip Code</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="zipcode"
                        placeholder="ex. 12345"
                        maxLength={5}
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    {successmsg && (
                      <div className={classes.sccessMsg}>
                        <span>{successmsg}</span>
                      </div>
                    )}
                    <div className={classes.updateBtn}>
                      <button type="submit" onClick={handleSubmitClick}>
                        UPDATE
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
            <Form
              validate={validateOne}
              onSubmit={onSubmitOne}
              initialValues={{
                userName: displaydata.user_name,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <h3>Username and Password</h3>
                    <div className={classes.formGroup}>
                      <label>Username</label>
                      <div className={classes.inputLeft}>
                        <Field
                          id="outlined-basic"
                          variant="outlined"
                          type="text"
                          autoComplete="current-password"
                          name="userName"
                          placeholder="Username"
                          fullWidth
                          required
                          onBlur={(e) => {
                            handleUserName(e);
                          }}
                          component={TextField}
                        />
                        {usernameError ? (
                          <div className={classes.nameError}>
                            {usernameError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>New Password</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="password"
                        autoComplete="current-password"
                        placeholder="Password"
                        name="password"
                        fullWidth
                        required
                        component={TextField}
                        onFocus={() => {
                          setPassCheck(true);
                        }}
                        onBlur={() => {
                          setPassCheck(false);
                        }}
                        className={classes.inputLeft}
                      />
                      <div
                        className={
                          passCheck
                            ? classes.resetPassCondition +
                              " " +
                              classes.resetPass
                            : classes.resetPass
                        }
                      >
                        <div className={classes.conditions}>
                          <p>
                            <span>
                              {passwordLength ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>Include at least 8 characters</label>
                          </p>
                          <p>
                            <span>
                              {alphabetsExist ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>
                              Include upper and lower case characters
                            </label>
                          </p>
                          <p>
                            <span>
                              {numericExist ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>Include at least 1 number</label>{" "}
                          </p>
                          <p>
                            <span>
                              {passwordMatch ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>Passwords match</label>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Confirm Password</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="password"
                        autoComplete="current-password"
                        name="password_confirmation"
                        placeholder="Confirm New Password"
                        fullWidth
                        required
                        component={TextField}
                        onFocus={() => {
                          setPassCheck(true);
                        }}
                        onBlur={() => {
                          setPassCheck(false);
                        }}
                        className={classes.inputLeft}
                      />
                    </div>
                    {userSuccessMsg && (
                      <div className={classes.sccessMsgDiv}>
                        <div className={classes.sccessMsg}>
                          <span>{userSuccessMsg}</span>
                        </div>
                      </div>
                    )}
                    {errorMessage && (
                      <div className={classes.noteError}>{errorMessage}</div>
                    )}
                    <div className={classes.updateBtn}>
                      <button type="submit" onClick={handleSubmitClick}>
                        UPDATE
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Typography>
  );
};
EditBusinessUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(EditBusinessUsers);
