
// import { withStyles,useTheme } from '@material-ui/core/styles';
// import styles from './styles'
// import { withApollo } from "react-apollo";
// import compose from 'recompose/compose';
// import PropTypes from 'prop-types';
// import { withRouter, Link } from 'react-router-dom';

    



// const Downloads =(props)=>{
  
//   return(
//       <div>downloads</div>
//   )
// }

// Downloads.propTypes = {
//     classes: PropTypes.object.isRequired,
// };
// const enhance = compose(
//     withStyles(styles),
//     withRouter,
//     withApollo
// );
// export default enhance(Downloads);



import React ,{useEffect} from 'react'
import { Typography } from "@material-ui/core";
import { loader } from 'graphql.macro';
import styles from './styles';
import { useQuery , useLazyQuery} from '@apollo/react-hooks';
import Pageloader from '../pageloader';
import compose from 'recompose/compose';
import { withApollo } from "react-apollo";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import  {withRouter} from 'react-router-dom';



 const DOWNLOAD_TOOLKITUSERS = loader('../../../graphql/schema/ui/downloads/downloadToolKitUsers.graphql');

function Downloads(props)  {
 const { classes} = props;
 const [loading,setLoading] = React.useState(true);
 const [toolkitUserData,setToolKitUserData] = React.useState([]);



 const [toolkitUsers] = useLazyQuery(DOWNLOAD_TOOLKITUSERS,{
     fetchPolicy: 'network-only',
     onCompleted(response) {
        
         setToolKitUserData(response.DownloadToolKitUsers);
         setLoading(false);
     },
     onError(error){
        
          setLoading(false);
     }
 })

 useEffect(() => {
     toolkitUsers()
     }
 ,[])

   const convertTime =(val,type)=>{
         const extractTime = new Date(val.split(' ')[0]);
         const forAmPm = (val.split(' ')[1]);
        
         if(type === 'time'){
             return forAmPm.substr(0,5)> '12' ? (forAmPm.slice(0,5).split(':')[0]-'12') +':'+ forAmPm.slice(0,5).split(':')[1]+'pm' : forAmPm.substr(0,5)+'am';
              
         }
          if(type === 'month')
             return ["January","February","March","April","May","June","July","August","September","October","November","December"][extractTime.getMonth()+1];
         
         if(type === 'year')
            return extractTime.getFullYear();
        
          if(type === 'day'){
             const date = extractTime.getDate();
              if(date){
                    const tens = date % 10
                    const   ones = date % 100;
                    if (tens == 1 && ones != 11) {
                        return date + "st";
                    }
                    if (tens == 2 && ones != 12) {
                        return date + "nd";
                    }
                    if (tens == 3 && ones != 13) {
                        return date + "rd";
                    }
                    return date + "th";
             }
         }

   }

 

  return (
    <Typography className="main-bannerRight">

         {loading === true ? <Pageloader loading={loading} /> : ""}
           <div className={classes.careerTableSec}>
                <div className={classes.careerTableDiv}>
                    <table>
                       <thead>
                           <tr>
                               <th>No</th>
                               <th>Full Name</th>
                               <th>Email</th>
                               <th>Status</th>
                               <th>Time</th>
                           </tr>
                       </thead>
                       <tbody>
                           {toolkitUserData.length!==0?
                                    toolkitUserData.map((val,index)=>(
                                        <tr key={index}>
                                        <td><span>{val.id}</span></td>
                                        <td><span>{val.name}</span></td>
                                        <td><span>{val.email}</span></td>
                                        <td><span>{val.status === '0' ? 'Not Downloaded':'Downloaded'}</span></td>
                                        <td><span>
                                           { convertTime(val.updated_at,'time')} {' '}{'EST'}{' '} 
                                           { convertTime(val.updated_at,'month')} {' '} {convertTime(val.updated_at,'day')} {' '} {convertTime(val.updated_at,'year')}
                                        </span></td>
                                        </tr>
                                    )):' '
                           }
                       </tbody>
                    </table>
                </div>
            </div>

    </Typography>
  )
}

Downloads.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles),
    withRouter,
    withApollo
);
export default enhance(Downloads);