import React from 'react';
import BusinessAdminMain from '../../components/business/BusinessAdmin'

const BusinessAdmin = () => {
    return (
        <div>
            <BusinessAdminMain />
        </div>
    )
}

export default BusinessAdmin