import * as commonFunctions from "../../utilities/commonFunctions";

import { Button, Grid } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  UserDetailsContext,
  useUserDetailsContext,
} from "../../context/userDetails";
import { isEmpty, orderBy, startCase } from "lodash";
import { useMutation, withApollo } from "react-apollo";

import Pageloader from "../../components/ui/pageloader";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import { useLazyQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const CHECK_USER_NAME = loader("../../graphql/schema/ui/CheckUserName.graphql");
const CHECK_EMAIL = loader("../../graphql/schema/ui/checkEmail.graphql");
const ADD_SCHOOL_ADMIN = loader(
  "../../graphql/schema/schools/saveSchoolAdmin.graphql"
);
const SCHOOL_INSTITUTIONS = loader(
  "../../graphql/schema/schools/get-schools.graphql"
);
const BUSINESS_INSTITUTIONS = loader(
  "../../graphql/schema/business/business-institutions.graphql"
);

// const type =
//   window.location.pathname === "/add-school-admin"
//     ? "school"
//     : "/add-business-admin" && "business";
const AddSchoolAdmin = (props) => {
  const { classes, history } = props;
  const {
    state: { search = "", admin_type },
    clearSearch,
  } = useUserDetailsContext();
  const location = useLocation();
  const [adminText, setAdminText] = useState();
  const [loading, setLoading] = React.useState(true);
  const [emailValue, setEmailValue] = useState("");
  const [passCheck, setPassCheck] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [alphabetsExist, setAlphabetsExist] = useState(false);
  const [numericExist, setNumericExist] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [userName, setUserName] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [institutionData, setInstitutionData] = useState([]);
  const [schoolError, setSchoolError] = useState(false);

  useEffect(() => {
    setAdminText(
      location.pathname.includes("school")
        ? "schools"
        : location.pathname.includes("business") && "businesses"
    );
    if (location.pathname === "/add-school-admin") {
      schoolInstitutions();
    } else if (location.pathname === "/add-business-admin") {
      businessInstitutions();
    }
  }, []);

  const [addschooladmin] = useMutation(ADD_SCHOOL_ADMIN, {
    onCompleted() {
      setLoading(false);
      history.push(`/${adminText}-admins`);
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setSchoolError(errorMsg);
      setLoading(false);
    },
  });

  const [checkUserName] = useLazyQuery(CHECK_USER_NAME, {
    variables: {
      data: userName,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckUserName: { status } }) {
      if (status) {
        setUsernameError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (userName !== "") {
        setUsernameError(formatedErrors);
      }
    },
  });

  const [schoolInstitutions] = useLazyQuery(SCHOOL_INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(
        orderBy(response.getSchoolInstitutions),
        ["institution_name"],
        ["asc"]
      );
      setLoading(false);
    },
  });

  const [businessInstitutions] = useLazyQuery(BUSINESS_INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(
        orderBy(response.getBusinessInstitutions, ["institution_name"], ["asc"])
      );
      setLoading(false);
    },
  });

  const [checkEmailExist] = useLazyQuery(CHECK_EMAIL, {
    variables: {
      email: emailValue,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckEmail: { status } }) {
      if (status) {
        setEmailError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (emailValue !== "") {
        setEmailError(formatedErrors);
      }
    },
  });

  const handleSubmitClick = () => {
    setSubmitClicked(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required.";
    }

    if (values.email) {
      if (!commonFunctions.validateEmail(values.email)) {
        errors.email = "Enter valid email.";
      }
    }
    if (!values.email) {
      errors.email = "email is required.";
    }

    if (!values.userName) {
      errors.userName = "username is required.";
    }

    if (values.user_name) {
      if (values.user_name.length <= 3) {
        errors.user_name = "User must be more that 4 charecters";
      }
    }

    if (!values.password) {
      errors.password = "password is required.";
    }

    if (values.password) {
      if (values.password.length >= 8) {
        setPasswordLength(true);
      } else {
        errors.password = "";
        setPasswordLength(false);
      }
      if (!commonFunctions.validateAlpha(values.password)) {
        setAlphabetsExist(true);
      } else {
        errors.password = "";
        setAlphabetsExist(false);
      }
      if (!commonFunctions.validateNumeric(values.password)) {
        setNumericExist(true);
      } else {
        errors.password = "";
        setNumericExist(false);
      }
    } else {
      setPasswordLength(false);
      setAlphabetsExist(false);
      setNumericExist(false);
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = "confirm password is required.";
    } else {
      if (values.password_confirmation) {
        if (values.password === values.password_confirmation) {
          setPasswordMatch(true);
        } else {
          setPasswordMatch(false);
          errors.password_confirmation = "";
        }
      }
    }
    if (!["school", "business"].includes(admin_type)) {
      if (!values.School) {
        errors.School = `please select ${startCase(adminText)}`;
      }
    }
    return errors;
  };

  const onSubmit = (values) => {
    setSubmitClicked(false);
    setLoading(true);
    addschooladmin({
      variables: {
        data: {
          name: values.first_name,
          user_lname: values.last_name,
          user_name: values.userName,
          email: values.email,
          password: values.password,
          confirm_password: values.password_confirmation,
          institution_id: ["school", "business"].includes(admin_type)
            ? [JSON.parse(localStorage.getItem("institution"))?.id]
            : values.School,
        },
      },
    });
  };

  return (
    <div className="formError">
      <Typography className="edit-page-new">
        {loading === true ? <Pageloader loading={loading} /> : ""}

        <div className={classes.backToAdmin}>
          <Link to={`/${adminText}-admins`}>
            <i className="fa fa-long-arrow-left" aria-hidden="true"></i>{" "}
            {`Back to
            ${startCase(adminText)} Admins`}
          </Link>
        </div>
        <div className={classes.formBlock}>
          <h3>{`Add New ${startCase(adminText)} Admin`}</h3>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Form
                validate={validate}
                onSubmit={onSubmit}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.fields}>
                      <div className={classes.formGroup}>
                        <label>First Name </label>
                        <div className={classes.inputLeftSelect}>
                          <Field name="first_name">
                            {({ input, meta }) => (
                              <div>
                                <TextField
                                  name={input?.name}
                                  placeholder="ex. Mike"
                                  type="text"
                                  value={input?.value}
                                  onChange={input?.onChange}
                                  required
                                  fullWidth
                                  className={classes.inputLeft}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span className={classes.noteError}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className={classes.formGroup}>
                        <label>Last Name</label>
                        <div className={classes.inputLeftSelect}>
                          <Field name="last_name">
                            {({ input, meta }) => (
                              <div>
                                <TextField
                                  name={input?.name}
                                  placeholder="ex. Inkwiry"
                                  type="text"
                                  value={input?.value}
                                  onChange={input?.onChange}
                                  required
                                  fullWidth
                                  className={classes.inputLeft}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span className={classes.noteError}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className={classes.formGroup}>
                        <label>Email</label>
                        <div className={classes.inputLeftSelect}>
                          <Field name="email">
                            {({ input, meta }) => (
                              <div>
                                <TextField
                                  name={input?.name}
                                  placeholder="ex. mike@inkwiry.com"
                                  type="text"
                                  value={input?.value}
                                  onChange={input?.onChange}
                                  required
                                  fullWidth
                                  className={classes.inputLeft}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span className={classes.noteError}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      {!["business", "school"].includes(admin_type) && (
                        <div className={classes.formGroup}>
                          <label>{startCase(adminText)}</label>
                          <div className={classNames(classes.inputLeft)}>
                            <Field name="School">
                              {({ input, meta }) => (
                                <div>
                                  <select
                                    onChange={input?.onChange}
                                    multiple
                                    name={input?.name}
                                    value={input?.value}
                                  >
                                    {institutionData.length !== 0 &&
                                      institutionData.map((l, index) => (
                                        <option key={l.id} value={l.id}>
                                          {l.institution_name}
                                        </option>
                                      ))}
                                  </select>
                                  {(meta.error || meta.submitError) &&
                                    meta.touched && (
                                      <span className={classes.noteError}>
                                        {meta.error || meta.submitError}
                                      </span>
                                    )}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={classes.fields}>
                      <h3>Username and Password</h3>
                      <div className={classes.formGroup}>
                        <label>Username</label>
                        <div className={classes.inputLeftSelect}>
                          <Field name="userName">
                            {({ input, meta }) => (
                              <div>
                                <TextField
                                  name={input?.name}
                                  placeholder="Username"
                                  type="text"
                                  value={input?.value}
                                  onChange={input?.onChange}
                                  required
                                  maxLength={5}
                                  fullWidth
                                  className={classes.inputLeft}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span className={classes.noteError}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>

                      <div className={classes.formGroup}>
                        <label>New Password</label>
                        <div className={classes.inputLeftSelect}>
                          <div className={classes.passwordPopup}>
                            <Field name="password">
                              {({ input, meta }) => (
                                <div>
                                  <TextField
                                    name={input?.name}
                                    placeholder="Password"
                                    type="password"
                                    value={input?.value}
                                    onChange={input?.onChange}
                                    required
                                    maxLength={5}
                                    fullWidth
                                    onFocus={() => {
                                      setPassCheck(true);
                                    }}
                                    onBlur={() => {
                                      setPassCheck(false);
                                    }}
                                    className={classes.inputLeft}
                                  />
                                  {(meta.error || meta.submitError) &&
                                    meta.touched && (
                                      <span className={classes.noteError}>
                                        {meta.error || meta.submitError}
                                      </span>
                                    )}
                                </div>
                              )}
                            </Field>
                            <div
                              className={
                                passCheck
                                  ? classes.resetPassCondition +
                                    " " +
                                    classes.resetPass
                                  : classes.resetPass
                              }
                            >
                              <div className={classes.conditions}>
                                <p>
                                  <span>
                                    {passwordLength ? (
                                      <img src="./img/pass.png" alt="" />
                                    ) : (
                                      <img src="./img/fail.png" alt="" />
                                    )}
                                  </span>
                                  <label>Include at least 8 characters</label>
                                </p>
                                <p>
                                  <span>
                                    {alphabetsExist ? (
                                      <img src="./img/pass.png" alt="" />
                                    ) : (
                                      <img src="./img/fail.png" alt="" />
                                    )}
                                  </span>
                                  <label>
                                    Include upper and lower case characters
                                  </label>
                                </p>
                                <p>
                                  <span>
                                    {numericExist ? (
                                      <img src="./img/pass.png" alt="" />
                                    ) : (
                                      <img src="./img/fail.png" alt="" />
                                    )}
                                  </span>
                                  <label>Include at least 1 number</label>{" "}
                                </p>
                                <p>
                                  <span>
                                    {passwordMatch ? (
                                      <img src="./img/pass.png" alt="" />
                                    ) : (
                                      <img src="./img/fail.png" alt="" />
                                    )}
                                  </span>
                                  <label>Passwords match</label>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.formGroup}>
                        <label>Confirm Password</label>
                        <div className={classes.inputLeftSelect}>
                          <Field name="password_confirmation">
                            {({ input, meta }) => (
                              <div>
                                <TextField
                                  name={input?.name}
                                  placeholder="Confirm New Password"
                                  type="password"
                                  value={input?.value}
                                  onChange={input?.onChange}
                                  required
                                  maxLength={5}
                                  fullWidth
                                  className={classes.inputLeft}
                                  onFocus={() => {
                                    setPassCheck(true);
                                  }}
                                  onBlur={() => {
                                    setPassCheck(false);
                                  }}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span className={classes.noteError}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            )}
                          </Field>
                          {!isEmpty(schoolError) && (
                            <div className={classes.nameError}>
                              {schoolError}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className={classes.centerBtn}>
                        <div className={classes.updateBtn}>
                          <button type="submit" onClick={handleSubmitClick}>
                            SUBMIT
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              />
            </Grid>
          </Grid>
        </div>
      </Typography>
    </div>
  );
};

AddSchoolAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object.isRequired,
};

const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(AddSchoolAdmin);
