// import PropTypes from "prop-types";
// import React from "react";
// import { Route } from "react-router-dom";

// const PublicRoute = ({ component, layout: Layout, ...rest }) => (
//   <Route {...rest}>
//     <Layout component={component} {...rest} />
//   </Route>
// );

// PublicRoute.propTypes = {
//   component: PropTypes.object.isRequired,
//   layout: PropTypes.object.isRequired,
// };

// export default PublicRoute;

import { Redirect, Route } from "react-router-dom";

import { ACCESS_TOKEN } from "../../utilities/userUtils";
import React from "react";
import Unauthorized from "../ui/unauthorized";
import Home from '../../screens/home/verify-otp/verify-otp'

const PublicRoute = ({ component, layout: Layout, role, ...rest }) => {
  const userRole = JSON.parse(localStorage.getItem("institution"))?.school_type;
  console.log(userRole);
  const isLoggedIn = Boolean(
    JSON.parse(localStorage.getItem(ACCESS_TOKEN))?.access_token
  );
  return (
    <Route
      path={
        !userRole || role.indexOf(userRole) === -1
          ? "/unauthorized"
          : rest?.path
      }
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          console.log("gone");
          return <Layout component={component} {...rest} />;
        } else if (!userRole || role.indexOf(userRole) === -1) {
          return <Layout component={() => <Home />} {...rest} />;
        } else {
          return <Layout component={component} {...rest} />;
        }
      }}
    />
  );
};

export default PublicRoute;
