import * as commonFunctions from "../../../utilities/commonFunctions";

import { Button, Grid } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import { isEmpty, isNil, omitBy, size, startCase } from "lodash";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import IsLoadingHOC from "../../../Hoc/IsLoadingHOC";
import { Link } from "react-router-dom";
import Pageloader from "../pageloader";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const ADDMEMBER = loader("../../../graphql/schema/ui/addMember.graphql");
const UPDATE_MEMBER = loader("../../../graphql/schema/ui/saveMember.graphql");
const STATES = loader("../../../graphql/schema/ui/states.graphql");
const CHECK_USER_NAME = loader(
  "../../../graphql/schema/ui/CheckUserName.graphql"
);
const GETMEMBERBY_ID = loader(
  "../../../graphql/schema/ui/GetMemberById.graphql"
);

const CHECK_EMAIL = loader("../../../graphql/schema/ui/checkEmail.graphql");

const AddUsers = (props) => {
  const { classes, history, setLoading } = props;

  const [errorMessage, setErrorMessage] = React.useState("");
  const [statesData, setStatesData] = React.useState([]);
  const [emailValue, setEmailValue] = useState("");
  const [passCheck, setPassCheck] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [alphabetsExist, setAlphabetsExist] = useState(false);
  const [numericExist, setNumericExist] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [AccountType, setAccountType] = useState(false);
  const [AccountDesc, setAccountDesc] = useState("");
  const [Identity, setIdentity] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [userName, setUserName] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [memeberByIdData, setMemeberByIdData] = useState([]);

  const [addNewUserData, { data: result }] = useMutation(ADDMEMBER, {
    onCompleted() {
      setLoading(false);
      history.push("./user-management");
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const [saveUserData] = useMutation(UPDATE_MEMBER, {
    onCompleted() {
      setLoading(false);
      history.push("./user-management");
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [GetMemberByIdAPI] = useLazyQuery(GETMEMBERBY_ID, {
    fetchPolicy: "network-only",
    onCompleted({ GetMemberById }) {
      if (GetMemberById) {
        setLoading(false);
        setMemeberByIdData(GetMemberById);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  React.useEffect(() => {
    setLoading(false);
    if (window.location.search.split("=")[1]) {
      setLoading(true);
      GetMemberByIdAPI({
        variables: {
          id: window.location.search.split("=")[1],
        },
      });
    }
  }, []);

  const { data: stateResult } = useQuery(STATES, {
    variables: {},
    fetchPolicy: "network-only",
    onCompleted() {
      if (stateResult.States) {
        setStatesData(stateResult.States);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [checkUserName] = useLazyQuery(CHECK_USER_NAME, {
    variables: {
      data: userName,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckUserName: { status } }) {
      if (status) {
        setUsernameError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (userName !== "") {
        setUsernameError(formatedErrors);
      }
    },
  });

  const [checkEmailExist] = useLazyQuery(CHECK_EMAIL, {
    variables: {
      email: emailValue,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckEmail: { status } }) {
      if (status) {
        setEmailError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (emailValue !== "") {
        setEmailError(formatedErrors);
      }
    },
  });

  const handleUserName = (e) => {
    checkUserName(e);
  };

  const handleSubmitClick = () => {
    setSubmitClicked(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.user_account_type) {
      errors.user_account_type = "Please Select Account Type";
    }
    if (!values.account_description?.trim()) {
      errors.account_description = "Please Select Account Description";
    }
    if (!values.user_identity) {
      errors.user_identity = "Please Select Account Identity";
    }
    if (!values.user_fname?.trim()) {
      errors.user_fname = "First name is required";
    }

    if (!values.user_lname?.trim()) {
      errors.user_lname = "Last name is required.";
    }

    if (values.email?.trim()) {
      if (!commonFunctions.validateEmail(values.email)) {
        errors.email = "Enter valid email.";
      }
    }
    if (!values.email?.trim()) {
      errors.email = "Email is required.";
    }
    if (!values.grade?.trim()) {
      errors.grade = "Group name is required.";
    }

    if (!values.user_name?.trim()) {
      errors.user_name = "username is required.";
    }

    if (values.user_name?.trim()) {
      if (values.user_name.length <= 3) {
        errors.user_name = "User must be more that 4 charecters";
      }
    }

    if (
      !window.location.search.split("=")[1] ||
      (window.location.search.split("=")[1] && values.password)
    ) {
      if (!values.password) {
        errors.password = "password is required.";
      }

      if (values.password) {
        if (values.password.length >= 8) {
          setPasswordLength(true);
        } else {
          setPasswordLength(false);
        }
        if (!commonFunctions.validateAlpha(values.password)) {
          setAlphabetsExist(true);
        } else {
          setAlphabetsExist(false);
        }
        if (!commonFunctions.validateNumeric(values.password)) {
          setNumericExist(true);
        } else {
          setNumericExist(false);
        }
      } else {
        setPasswordLength(false);
        setAlphabetsExist(false);
        setNumericExist(false);
      }
      if (!values.confirm_password) {
        errors.confirm_password = "confirm password is required.";
      }
      if (values.confirm_password) {
        if (values.password === values.confirm_password) {
          setPasswordMatch(true);
        } else {
          errors.confirm_password = "";
          setPasswordMatch(false);
        }
      }
    }

    return errors;
  };

  const onSubmit = ({
    user_fname,
    user_lname,
    user_name,
    email,
    user_type = "U",
    password,
    confirm_password,
    title,
    state,
    institution_name,
    city,
    grade,
    zip_code,
    user_account_type,
    user_identity,
    account_description,
    streetaddress,
    streetaddress2,
    member_source,
  }) => {
    const result = omitBy(
      {
        user_fname,
        user_lname,
        user_name,
        email,
        user_type: "U",
        password,
        confirm_password,
        title,
        state,
        institution_name,
        city,
        zip_code,
        grade,
        user_account_type,
        user_identity,
        account_description,
        streetaddress,
        streetaddress2,
        member_source,
      },
      isNil
    );

    addNewUserData({
      variables: {
        data: result,
      },
    });
  };

  return (
    <Typography className="edit-page-new formError">
      <div className={classes.backToAdmin}>
        <Link to="/user-management">
          <i className="fa fa-long-arrow-left" aria-hidden="true"></i> Back to
          Member Details
        </Link>
      </div>
      <div className={classes.formBlock}>
        <h3>Add User</h3>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Form
              validate={validate}
              onSubmit={onSubmit}
              initialValues={{
                user_fname: memeberByIdData?.user_fname,
                user_lname: memeberByIdData?.user_lname,
                user_name: memeberByIdData?.user_name,
                member_source: memeberByIdData?.member_source,
                email: memeberByIdData?.email,
                title: memeberByIdData?.title,
                institution_name: memeberByIdData?.institution_name,
                state: memeberByIdData?.state,
                city: memeberByIdData?.city,
                zip_code: memeberByIdData?.zip_code,
                grade: memeberByIdData?.grade,
                user_account_type: memeberByIdData?.user_account_type,
                user_identity: startCase(memeberByIdData?.user_identity),
                account_description: memeberByIdData?.account_description,
                streetaddress: memeberByIdData?.streetaddress,
                streetaddress2: memeberByIdData?.streetaddress2,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <div className={classes.formGroup}>
                      <label>Account Type</label>
                      <div className={classes.inputLeftSelect}>
                        <Field
                          name="user_account_type"
                          component="select"
                          variant="outlined"
                        >
                          {({ input, meta }) => (
                            <div>
                              <select
                                value={input?.value}
                                onChange={(value) => input.onChange(value)}
                              >
                                <option value="">Select Account Type </option>
                                <option value="pwi">
                                  Plan With Inkwiry for 30 days
                                </option>
                                <option value="gold">GOLD</option>
                                <option value="platinum">PLATINUM</option>
                                <option value="inkwiry_personnel">
                                  Inkwiry personnel
                                </option>
                              </select>
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Account Description</label>
                      <div className={classes.inputLeftSelect}>
                        <Field
                          name="account_description"
                          component="select"
                          variant="outlined"
                        >
                          {({ input, meta }) => (
                            <div>
                              <select
                                value={input?.value}
                                onChange={(value) => input.onChange(value)}
                              >
                                <option value="">
                                  Select Account Description{" "}
                                </option>
                                <option value="Friends and Family">
                                  Friends and Family
                                </option>
                                <option value="Tester">Tester</option>
                                <option value="School Prospect">
                                  School Prospect
                                </option>
                                <option value="Corporate Prospect">
                                  Corporate Prospect
                                </option>
                                <option value="Bank Prospect">
                                  Bank Prospect
                                </option>
                                <option value="Others">Others</option>
                              </select>
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Member Source</label>
                      <div className={classes.inputLeftSelect}>
                        <Field
                          name="member_source"
                          component="select"
                          variant="outlined"
                        >
                          {({ input, meta }) => (
                            <div>
                              <select
                                value={input?.value}
                                onChange={(value) => input.onChange(value)}
                              >
                                <option value="">Select Member Source </option>
                                <option value="High School">High School</option>
                                <option value="Technical High School">
                                  Technical High School
                                </option>
                                <option value="College / University">
                                  College / University
                                </option>
                                <option value="Community College">
                                  Community College
                                </option>
                                <option value="Career School">
                                  Career School
                                </option>
                                <option value="Graduate School">
                                  Graduate School
                                </option>
                                <option value="Business">Business</option>
                                <option value="Direct">Direct</option>
                              </select>
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Identity</label>
                      <div className={classes.inputLeftSelect}>
                        <Field
                          name="user_identity"
                          component="select"
                          variant="outlined"
                        >
                          {({ input, meta }) => (
                            <div>
                              <select
                                value={input?.value}
                                onChange={(value) => input.onChange(value)}
                              >
                                <option value="">Select Identity </option>
                                {!["Business", "Direct"].includes(
                                  values?.member_source
                                ) && (
                                  <>
                                    <option value="Student">Student</option>
                                    <option value="Parent">Parent</option>
                                  </>
                                )}
                                {["Business", "Direct"].includes(
                                  values?.member_source
                                ) && (
                                  <option value="Young Professional">
                                    Young Professional
                                  </option>
                                )}
                              </select>
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>First Name </label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="user_fname">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Mike"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Last Name </label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="user_lname">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Inkwiry"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Title</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="title">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Entrepreneur"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Email</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="email">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. mike@inkwiry.com"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                                onBlur={(e) => {
                                  checkEmailExist(e);
                                }}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    {/* <div className={classes.formGroup}>
                      <label>Email</label>
                      <Field
                        placeholder="ex. mike@inkwiry.com"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="email"
                        fullWidth
                        required
                        onBlur={(e) => {
                          checkEmailExist(e);
                        }}
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div>{emailError}</div> */}

                    {/* <div className={classes.formGroup}>
                      <label>School Name</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="schoolName"
                        placeholder="ex. Engineering"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div> */}
                    {[
                      "High School",
                      "Technical High School",
                      "College / University",
                      "Community College",
                      "Career School",
                      "Graduate School",
                      "Business",
                      "Direct",
                    ].includes(values?.member_source) && (
                      <div className={classes.formGroup}>
                        <label>
                          {[
                            "High School",
                            "Technical High School",
                            "College / University",
                            "Community College",
                            "Career School",
                            "Graduate School",
                          ].includes(values?.member_source)
                            ? "School Name"
                            : "Business Name"}
                        </label>
                        <div className={classes.inputLeftSelect}>
                          <Field name="institution_name">
                            {({ input, meta }) => (
                              <div>
                                <TextField
                                  name={input?.name}
                                  placeholder="ex. xyz"
                                  type="text"
                                  value={input?.value}
                                  onChange={input?.onChange}
                                  required
                                  fullWidth
                                  className={classes.inputLeft}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span className={classes.noteError}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    )}
                    <div className={classes.formGroup}>
                      <label>Group</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="grade">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Sophomore"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Street Address</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="streetaddress">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. 123 Inkwiry Way"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Apartment, suite, unit, floor, etc.</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="streetaddress2">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. 3F"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>City</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="city">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Pennsylvania"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>State</label>
                      <div className={classes.inputLeftSelect}>
                        <Field
                          name="state"
                          component="select"
                          variant="outlined"
                        >
                          {({ input, meta }) => (
                            <div>
                              <select
                                value={input?.value}
                                onChange={(value) => input.onChange(value)}
                              >
                                <option value="">Select State</option>
                                {statesData.length !== 0 &&
                                  statesData.map((l, index) => (
                                    <option key={index} value={l.id}>
                                      {l.state_name}
                                    </option>
                                  ))}
                              </select>
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className={classes.formGroup}>
                      <label>Zip Code</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="zip_code">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. 12345"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                maxLength={5}
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>

                  <div className={classes.fields}>
                    <h3>Username and Password</h3>
                    <div className={classes.formGroup}>
                      <label>Username</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="user_name">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="Username"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                maxLength={5}
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>New Password</label>
                      <div className={classes.inputLeftSelect}>
                        <div className={classes.passwordPopup}>
                          <Field name="password">
                            {({ input, meta }) => (
                              <div>
                                <TextField
                                  name={input?.name}
                                  placeholder="Password"
                                  type="password"
                                  value={input?.value}
                                  onChange={input?.onChange}
                                  required
                                  maxLength={5}
                                  fullWidth
                                  onFocus={() => {
                                    setPassCheck(true);
                                  }}
                                  onBlur={() => {
                                    setPassCheck(false);
                                  }}
                                  className={classes.inputLeft}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span className={classes.noteError}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            )}
                          </Field>
                          <div
                            className={
                              passCheck
                                ? classes.resetPassCondition +
                                  " " +
                                  classes.resetPass
                                : classes.resetPass
                            }
                          >
                            <div className={classes.conditions}>
                              <p>
                                <span>
                                  {passwordLength ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>Include at least 8 characters</label>
                              </p>
                              <p>
                                <span>
                                  {alphabetsExist ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>
                                  Include upper and lower case characters
                                </label>
                              </p>
                              <p>
                                <span>
                                  {numericExist ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>Include at least 1 number</label>{" "}
                              </p>
                              <p>
                                <span>
                                  {passwordMatch ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>Passwords match</label>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Confirm Password</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="confirm_password">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="Confirm New Password"
                                type="password"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                maxLength={5}
                                fullWidth
                                className={classes.inputLeft}
                                onFocus={() => {
                                  setPassCheck(true);
                                }}
                                onBlur={() => {
                                  setPassCheck(false);
                                }}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                        {!isEmpty(errorMessage) && (
                          <div className={classes.nameError}>
                            {errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={classes.centerBtn}>
                      <div className={classes.updateBtn}>
                        <button type="submit">
                          {isEmpty(memeberByIdData) ? "Submit" : "UPDATE"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Typography>
  );
};
AddUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);

export default enhance(AddUsers);
