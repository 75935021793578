import * as commonFunctions from "../../../utilities/commonFunctions";

import { Dialog, DialogTitle, Tooltip, Typography } from "@mui/material";
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import EmailPage from "./emailPage";
import { Grid } from "@material-ui/core";
import NotificationPage from "./notificationPage";
import Pageloader from "../pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTable from "../Table/ReactTable";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
// import history from "../../../utilities/history";
import moment from "moment";
import styles from "./styles";
import useDebounce from "../../../hooks/useDebounce";
import { useUserDetailsContext } from "../../../context/userDetails";
// import UserUtils from "../../../utilities/userUtils";
// import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const ALLUSERS = loader("../../../graphql/schema/ui/getUsersDetails.graphql");
const DELETEUSER = loader("../../../graphql/schema/ui/deleteMember.graphql");
const STATES = loader("../../../graphql/schema/ui/states.graphql");
const SCHOOLS = loader(
  "../../../graphql/schema/ui/getCompareSchoolList.graphql"
);
const DEACTIVATEUSER = loader(
  "../../../graphql/schema/ui/changeUserStatus.graphql"
);
const EXPORT_USERS = loader("../../../graphql/schema/ui/exportUsers.graphql");

const Users = (props) => {
  const { classes, history } = props;
  const {
    state: { search = "" },
    clearSearch,
  } = useUserDetailsContext();
  const debouncedSearch = useDebounce(search, 1000);
  const [level, setLevel] = useState({ key: "user_fname", val: null });
  const [memeberSource, setMemeberSource] = useState([]);
  const [userAccountType, setUserAccountType] = useState([]);
  const [userIdentity, setUserIdentity] = useState([]);

  const [loading, setLoading] = React.useState(true);
  const [usersData, setUsersData] = React.useState([]);
  const [statesData, setStatesData] = React.useState([]);
  const [schoolsData, setSchoolsData] = React.useState([]);
  const [selectedStateData, setSelectedStateData] = React.useState([]);
  const [selectedSchoolsData, setSelectedSchoolsData] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] =
    React.useState(false);
  const [resetFilter, setResetFilter] = useState(false);

  const [deleteId, setDeleteId] = React.useState("");
  const [openSuccesPopup, setOpenSuccesPopup] = React.useState(false);
  const [openFirstNameFilters, setOpenFirstNameFilters] = React.useState(false);
  const [openLastNameFilters, setOpenLastNameFilters] = React.useState(false);
  const [openEmailFilters, setOpenEmailFilters] = React.useState(false);
  const [openUserNameFilters, setOpenUserNameFilters] = React.useState(false);
  const [openZipcodeFilters, setOpenZipcodeFilters] = React.useState(false);
  const [openCityFilters, setOpenCityFilters] = React.useState(false);
  const [openStateFilters, setOpenStateFilters] = React.useState(false);
  const [openSchoolsFilters, setOpenSchoolsFilters] = React.useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [deActivateId, setDeActivateId] = React.useState("");
  const [openConfirmDeActivatePopup, setOpenConfirmDeActivatePopup] =
    React.useState(false);
  const [deActivatStatus, setDeActivatStatus] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [selectedCol, setSelectedCol] = React.useState("");
  const [selectedtype, setSelectedtype] = React.useState("");
  const [openMessagePopup, setOpenMessagePopup] = React.useState("");
  const [openNotification, setOpenNotification] = React.useState("");
  const [openEmail, setOpenEmail] = React.useState("");
  const [selectedNoficationEmails, setSelectedNoficationEmails] =
    React.useState([]);
  const [messageType, setMessageType] = React.useState("");
  const [count, setCount] = useState(1);
  const [perPage] = React.useState(20);

  const ref = React.useRef(null);
  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);
  const ref3 = React.useRef(null);
  const ref4 = React.useRef(null);
  const ref5 = React.useRef(null);
  const ref6 = React.useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenFirstNameFilters(false);
    } else if (ref1.current && !ref1.current.contains(event.target)) {
      setOpenLastNameFilters(false);
    } else if (ref2.current && !ref2.current.contains(event.target)) {
      setOpenEmailFilters(false);
    } else if (ref3.current && !ref3.current.contains(event.target)) {
      setOpenUserNameFilters(false);
    } else if (ref4.current && !ref4.current.contains(event.target)) {
      setOpenCityFilters(false);
    } else if (ref5.current && !ref5.current.contains(event.target)) {
      setOpenStateFilters(false);
    } else if (ref6.current && !ref6.current.contains(event.target)) {
      setOpenSchoolsFilters(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const [allUsers, { fetchMore }] = useLazyQuery(ALLUSERS, {
    variables: {
      filter: {
        selectedCol: "",
        selectedtype: "",
        state: [],
        schools: [],
        member_source: [],
        user_account_type: [],
        user_identity: [],
        name: "",
        page: 1,
        perPage: 20,
      },
    },
    fetchPolicy: "network-only",
    onCompleted({ GetMemberDetails }) {
      setLoading(false);
      setResetFilter(false);

      setUsersData(GetMemberDetails?.user);
      setActivePage(GetMemberDetails?.page);
      setPageFineshedCount(GetMemberDetails.totalCount);
      setSelectedNoficationEmails(
        GetMemberDetails.user.map(function (item) {
          return item["email"];
        })
      );
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  useEffect(
    () => {
      setLoading(true);
      setActivePage(1);
      setSelectedStateData([]);
      setMemeberSource([]);
      setUserAccountType([]);
      setUserIdentity([]);
      setLevel({ key: "name", val: null });
      allUsers({
        variables: {
          filter: {
            selectedCol: "",
            selectedtype: "",
            state: [],
            schools: [],
            member_source: [],
            user_account_type: [],
            user_identity: [],
            name: debouncedSearch,
            page: 1,
            perPage: 20,
          },
        },
      });
    },
    [debouncedSearch] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    allUsers();
  }, []);

  const { data: stateResult } = useQuery(STATES, {
    variables: {},
    fetchPolicy: "network-only",
    onCompleted() {
      if (stateResult.States) {
        setStatesData(stateResult.States);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const { data: schoolsResult } = useQuery(SCHOOLS, {
    variables: {},
    fetchPolicy: "network-only",
    onCompleted() {
      if (schoolsResult.getCompareSchoolList) {
        setSchoolsData(schoolsResult.getCompareSchoolList);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const [deleteUserData, { data: deleteResult }] = useMutation(DELETEUSER, {
    onCompleted() {
      setOpenConfirmDeletePopup(false);
      setDeleteId("");
      setLoading(false);
      allUsers();
      setOpenSuccesPopup(true);
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [exportUsersData, { data: exportData }] = useLazyQuery(EXPORT_USERS, {
    fetchPolicy: "network-only",
    onCompleted() {
      const data = exportData.exportUsers;
      if (data) {
        var link = document.createElement("a");
        link.download = "support";
        link.href = data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const exportUsersTableData = () => {
    const activeFilter = {
      selectedCol: selectedCol,
      selectedtype: selectedtype,
      state: selectedStateData,
      member_source: memeberSource,
      user_account_type: selectedStateData,
      user_identity: userIdentity,
      schools: selectedSchoolsData,
      name: searchText,
    };
    exportUsersData({
      variables: {
        filter: activeFilter,
      },
    });
  };

  React.useEffect(() => {
    if (deleteResult) {
      setSuccessMessage(deleteResult.deleteMember.message);
    }
  }, [deleteResult]);
  const [deActivateUserData, { data: deActivateResult }] = useMutation(
    DEACTIVATEUSER,
    {
      onCompleted() {
        setOpenConfirmDeActivatePopup(false);
        setDeActivateId("");
        setLoading(false);
        allUsers();
        setOpenSuccesPopup(true);
      },
      onError(errors) {
        let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
        setErrorMessage(errorMsg);
        setLoading(false);
      },
    }
  );
  React.useEffect(() => {
    if (deActivateResult) {
      setLoading(false);
      setSuccessMessage(deActivateResult.changeUserStatus.message);
    }
  }, [deActivateResult]);
  const handleOpenConfirmDeletePopup = (id) => {
    setDeleteId(id);
    setOpenConfirmDeletePopup(true);
  };
  const handleOpenConfirmDeActivePopup = (id, status) => {
    setDeActivateId(id);
    setDeActivatStatus(status === "N" ? 1 : 0);
    setOpenConfirmDeActivatePopup(true);
  };
  const handleCloseDeletePopup = () => {
    setOpenConfirmDeletePopup(false);
    setDeleteId("");
  };
  const handleCloseDeActivatePopup = () => {
    setOpenConfirmDeActivatePopup(false);
    setDeActivateId("");
  };
  const handleOpenMessagePopup = () => {
    setOpenMessagePopup(true);
  };
  const handleCloseMessagePopup = () => {
    setOpenMessagePopup(false);
  };
  const handleDeleteUser = () => {
    setLoading(true);
    deleteUserData({
      variables: {
        id: deleteId,
      },
    });
  };
  const handleDeActivateUser = () => {
    setLoading(true);
    deActivateUserData({
      variables: {
        data: {
          id: deActivateId,
          status: deActivatStatus,
        },
      },
    });
  };
  const handleUserEdit = (data) => {
    history.push("/edit-user-details?user_id=" + data);
    // UserUtils.setSavedEditDetails(JSON.stringify(data));
  };
  const handleClose = () => {
    setOpenSuccesPopup(false);
    setSuccessMessage("");
  };
  const handleFilters = (val) => {
    if (val === "user_fname") {
      setOpenFirstNameFilters(!openFirstNameFilters);
      setOpenLastNameFilters(false);
      setOpenEmailFilters(false);
      setOpenUserNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setSelectedCol(val);
    } else if (val === "user_lname") {
      setOpenLastNameFilters(!openLastNameFilters);
      setOpenFirstNameFilters(false);
      setOpenEmailFilters(false);
      setOpenUserNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setSelectedCol(val);
    } else if (val === "email") {
      setOpenEmailFilters(!openEmailFilters);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenUserNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setSelectedCol(val);
    } else if (val === "user_name") {
      setOpenUserNameFilters(!openUserNameFilters);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setSelectedCol(val);
    } else if (val === "zip_code") {
      setOpenUserNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenCityFilters(false);
      setOpenZipcodeFilters(!openZipcodeFilters);
      setSelectedCol(val);
    } else if (val === "city") {
      setOpenUserNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(!openCityFilters);
      setSelectedCol(val);
    } else if (val === "state") {
      setOpenUserNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setOpenStateFilters(true);
    } else if (val === "schools") {
      setOpenUserNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setOpenStateFilters(false);
      setOpenSchoolsFilters(true);
    }
  };
  const handleSorting = (row, val) => {
    setLoading(true);
    if (row === "state_name") {
      setSelectedStateData(val);
      allUsers({
        variables: {
          filter: {
            page: 1,
            perPage: 20,
            name: debouncedSearch,
            selectedCol: level?.key,
            selectedtype: level?.val,
            state: val,
            member_source: memeberSource,
            user_account_type: selectedStateData,
            user_identity: userIdentity,
          },
        },
      });
    } else if (row === "member_source") {
      setMemeberSource(val);
      allUsers({
        variables: {
          filter: {
            page: 1,
            perPage: 20,
            name: debouncedSearch,
            selectedCol: level?.key,
            selectedtype: level?.val,
            state: selectedStateData,
            member_source: val,
            user_account_type: userAccountType,
            user_identity: userIdentity,
          },
        },
      });
    } else if (row === "user_account_type") {
      setUserAccountType(val);
      allUsers({
        variables: {
          filter: {
            page: 1,
            perPage: 20,
            name: debouncedSearch,
            selectedCol: level?.key,
            selectedtype: level?.val,
            state: selectedStateData,
            member_source: memeberSource,
            user_account_type: val,
            user_identity: userIdentity,
          },
        },
      });
    } else if (row === "user_identity") {
      setUserIdentity(val);
      allUsers({
        variables: {
          filter: {
            page: 1,
            perPage: 20,
            name: debouncedSearch,
            selectedCol: level?.key,
            selectedtype: level?.val,
            state: selectedStateData,
            member_source: memeberSource,
            user_account_type: userAccountType,
            user_identity: val,
          },
        },
      });
    } else {
      setLevel({ key: row, val });
      allUsers({
        variables: {
          filter: {
            page: 1,
            perPage: 20,
            name: debouncedSearch,
            selectedCol: row,
            selectedtype: val,
            state: selectedStateData,
            member_source: memeberSource,
            user_account_type: selectedStateData,
            user_identity: userIdentity,
          },
        },
      });
    }
  };
  const handleStateChange = (val) => {
    const selectedIndex = selectedStateData.findIndex((x) => x === val);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStateData, val);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStateData.slice(1));
    } else if (selectedIndex === selectedStateData.length - 1) {
      newSelected = newSelected.concat(selectedStateData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStateData.slice(0, selectedIndex),
        selectedStateData.slice(selectedIndex + 1)
      );
    }
    setSelectedStateData(newSelected);
  };
  const handleSchoolsChange = (val) => {
    const selectedIndex = selectedSchoolsData.findIndex((x) => x === val);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSchoolsData, val);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedSchoolsData.slice(1));
    } else if (selectedIndex === selectedSchoolsData.length - 1) {
      newSelected = newSelected.concat(selectedSchoolsData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedSchoolsData.slice(0, selectedIndex),
        selectedSchoolsData.slice(selectedIndex + 1)
      );
    }
    setSelectedSchoolsData(newSelected);
  };
  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    fetchMore({
      variables: {
        filter: {
          selectedCol: level?.key,
          selectedtype: level?.val,
          state: selectedStateData,
          member_source: memeberSource,
          user_account_type: selectedStateData,
          user_identity: userIdentity,
          schools: selectedSchoolsData,
          name: debouncedSearch,
          page: pageNumber,
          perPage: 20,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setUsersData(fetchMoreResult?.GetMemberDetails?.user);
      },
    });
    setActivePage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };
  const convertPassword = (password) => {
    // let passwordStr = password.toString();
    let encryPass = "*";
    for (let i = 0; i < 10; i++) {
      encryPass = encryPass + "*";
    }
    return encryPass;
  };
  const handleOpenNotification = () => {
    setOpenNotification(true);
    setOpenMessagePopup(false);
  };
  const handleNotificationClose = () => {
    setOpenNotification(false);
  };
  const handleClearAllFilters = () => {
    setLoading(true);
    setResetFilter(true);
    setLevel({ key: "user_fname", val: null });
    setActivePage(1);
    clearSearch();
    setStatesData(null);
    setMemeberSource([]);
    setUserAccountType([]);
    setUserIdentity([]);
    allUsers({
      variables: {
        filter: {
          page: 1,
          perPage: 20,
          name: "",
          selectedCol: "",
          selectedtype: "",
          state: [],
          member_source: [],
          user_account_type: [],
          user_identity: [],
        },
      },
    });
  };
  const handleOpenEmail = (val) => {
    setOpenEmail(true);
    setOpenMessagePopup(false);
    setMessageType(val);
  };
  const handleEmailClose = () => {
    setOpenEmail(false);
  };
  return (
    <Typography className="main-bannerRight">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      {/* <h3>User Management</h3> */}
      <Grid container className={classes.searchRow} spacing={2}>
        <Grid item md={6} sm={6} xs={12}></Grid>
        <Grid item md={6} sm={6} xs={12}>
          <div>
            {pageFineshedCount > 0 && (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={20}
                totalItemsCount={pageFineshedCount}
                pageRangeDisplayed={5}
                onChange={handleGetFineshedMatchesPageClick}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <ReactTable
        resetFilter={resetFilter}
        filterFunction={(row, value) => {
          handleSorting(row, value);
        }}
        activePage={activePage}
        count={pageFineshedCount}
        columns={[
          {
            name: "First Name",
            row: "user_fname",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "Last Name",
            row: "user_lname",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "Email",
            row: "email",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "Username",
            row: "user_name",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "Password",
            row: "password",
            customRowComponent: (value) => convertPassword(value),
          },
          {
            name: "City",
            row: "city",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "State",
            row: "state_name",
            showCheckboxwithFilter: true,
            filterList: statesData?.map((l) => ({
              key: l.id,
              value: l.state_name,
            })),
          },
          {
            name: "Zip",
            row: "zip_code",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort Smallest to Largest" },
              { key: "DESC", value: "Sort Largest to Smallest" },
            ],
          },
          {
            name: "Birth Year",
            row: "birth_year",
          },
          {
            name: "Account Creation Date",
            row: "created_at",
            customRowComponent: (value) => moment(value).format("L"),
          },
          {
            name: "Account ID",
            row: "id",
          },
          {
            name: "Membership Start Date",
            row: "start_date",
            customRowComponent: (value) =>
              value ? moment(value).format("L") : "N/A",
          },
          {
            name: "Membership End Date",
            row: "end_date",
            customRowComponent: (value) =>
              value ? moment(value).format("L") : "N/A",
          },
          {
            name: "Last Login Day",
            row: "last_login_date",
            customRowComponent: (value) =>
              value ? moment(value).format("L") : "N/A",
          },
          {
            name: "Member Level",
            row: "user_account_type",
            showCheckboxwithFilter: true,
            filterList: [
              // { key: "", value: "None" },
              { key: "pwi", value: " Plan With Inkwiry for 30 days" },

              { key: "gold", value: "GOLD" },
              { key: "platinum", value: "PLATINUM" },
              { key: "inkwiry_personnel", value: " Inkwiry personnel" },
            ],
          },
          {
            name: "Member Source",
            row: "member_source",
            showCheckboxwithFilter: true,
            filterList: [
              // { key: "", value: "None" },
              { key: "High School", value: "High School" },
              { key: "Technical High School", value: "Technical High School" },
              { key: "College / University", value: "College / University" },
              { key: "Community College", value: "Community College" },
              { key: "Career School", value: "Career School" },
              { key: "Graduate School", value: "Graduate School" },
              { key: "Business", value: "Business" },
              { key: "Direct", value: "Direct" },
            ],
          },
          {
            name: "Member Identity",
            row: "user_identity",
            showCheckboxwithFilter: true,
            filterList: [
              // { key: "", value: "None" },
              { key: "student", value: "Student" },
              { key: "Young Professional", value: "Young Professional" },
              { key: "Parent", value: "Parent" },
              { key: "Other", value: "Other" },
            ],
          },
          {
            name: "Member Identity Other",
            row: "member_identity_other",
          },
          {
            name: "School Name",
            row: "school_name",
          },
          {
            name: "Business Name",
            row: "business_name",
          },
          {
            name: "Status",
            row: "status",
            customRowComponent: (value) => (
              <>
                {value === "A" ? (
                  <span className={classes.statusSuccess}>Active</span>
                ) : (
                  <span className={classes.statusInActive}>InActive</span>
                )}
              </>
            ),
          },
          {
            name: "Options",
            row: "id",
            customRowComponent: (value, row) => (
              <span className={classes.moreInfo}>
                <ul>
                  <li
                    className={classes.editAdmin}
                    onClick={() => handleUserEdit(value)}
                  >
                    <i className="fa fa-pencil" title="Edit"></i>
                  </li>
                  <li
                    className={classes.deleteAdmin}
                    onClick={() => handleOpenConfirmDeletePopup(value)}
                  >
                    <i className="fa fa-trash" title="Delete"></i>
                  </li>
                  <li
                    onClick={() =>
                      handleOpenConfirmDeActivePopup(value, row?.status)
                    }
                  >
                    <i
                      className="fa fa-ban"
                      title={row?.status === "A" ? "DeActivate" : "Activate"}
                    ></i>
                  </li>
                </ul>
              </span>
            ),
          },
        ]}
        rows={usersData}
      />

      <div className={classes.userTableSec}>
        <div className={classes.schoolsBtn}>
          <div className={classes.msgBtn}>
            <button onClick={handleOpenMessagePopup}>MESSAGE</button>
          </div>
          <div className={classes.addAdminBtn}>
            <Link to="/user-details">ADD MEMBER</Link>
          </div>
          <div className={classes.clearFilterBtn}>
            <button onClick={handleClearAllFilters}>CLEAR ALL FILTERS</button>
          </div>
          <Tooltip
            title=" This will export only the data that is currently shown above to
                excel. If you want to export all the data in this tab, make sure
                you clear all filters before using the export button."
          >
            <div className={classes.exportBtn}>
              <button data-tip data-for="main" onClick={exportUsersTableData}>
                EXPORT
              </button>
            </div>
          </Tooltip>
        </div>
      </div>

      <Dialog
        className="popup-model"
        open={openConfirmDeletePopup}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="model-content">
          <div className="modal-header">
            <button onClick={handleCloseDeletePopup}>
              {" "}
              <i className="fa fa-times"></i>
            </button>
            <h3>Delete User</h3>
          </div>
          <div
            className="model-body"
            id="customized-dialog-title"
            onClose={handleCloseDeletePopup}
          >
            Are you sure you want to delete this user? Deleting this user will
            immediately remove their account from the site. Plus, it will delete
            all their information and data from the database after 90 days.
          </div>
          <div className="model-footer">
            <button
              onClick={() => handleDeleteUser()}
              className="close-btn delete-btn"
            >
              Ok
            </button>
            <button onClick={handleCloseDeletePopup} className="close-btn">
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        className="popupDiv popupDiv-Loan"
        open={openConfirmDeActivatePopup}
        onClose={handleCloseDeActivatePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.colseButton}>
          <button onClick={handleCloseDeActivatePopup}>
            <i className="fa fa-times"></i>
          </button>
          <h3>Update User Status</h3>
        </div>

        <DialogTitle
          className="modal-header"
          id="customized-dialog-title"
          onClose={handleCloseDeActivatePopup}
        >
          Are You Sure You Want to{" "}
          {deActivatStatus === 1 ? "Activate" : "DeActivate"} this User?
        </DialogTitle>
        <div className={classes.openApply}>
          <button
            onClick={() => handleDeActivateUser()}
            className={classes.closeBtn + " " + classes.activeBtn}
          >
            Ok
          </button>
          <button
            onClick={handleCloseDeActivatePopup}
            className={classes.closeBtn}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <Dialog
        className="popupBankDetails"
        open={openSuccesPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.colseButton}>
          <button onClick={handleClose}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className={classes.sucessPopup}>
          <p>{successMessage || ""}</p>
          <figure>
            <img src="./img/success-img.png" alt="" />
          </figure>
        </div>
      </Dialog>
      <Dialog
        className="popup-model"
        open={openMessagePopup}
        onClose={handleCloseMessagePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="model-content">
          <div className="modal-header">
            <button onClick={handleCloseMessagePopup}>X</button>
            <h3>Send a message to all selected students</h3>
          </div>

          <div
            className="model-body"
            id="customized-dialog-title"
            onClose={handleCloseMessagePopup}
          >
            You can send a custom message to all selected students in the form
            of an Inkwiry notification, email or both. Use the buttons below to
            choose one of those options. Then, draft your message and send to
            your students.
          </div>
          <div className="model-footer">
            <button
              onClick={() => handleOpenNotification()}
              className="close-btn delete-btn-light"
            >
              Notification
            </button>
            <button
              onClick={() => handleOpenEmail("Email")}
              className="close-btn delete-btn-light"
            >
              Email
            </button>
            <button
              onClick={() => handleOpenEmail("Both")}
              className="close-btn delete-btn-light"
            >
              Both
            </button>
            <button onClick={handleCloseMessagePopup} className="close-btn">
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <NotificationPage
        handleClose={handleNotificationClose}
        open={openNotification}
        selectedNoficationEmails={selectedNoficationEmails}
      />
      <EmailPage
        handleClose={handleEmailClose}
        open={openEmail}
        selectedNoficationEmails={selectedNoficationEmails}
        messageType={messageType}
      />
    </Typography>
  );
};
Users.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);
export default enhance(Users);
