const styles = () => ({
  userTableSec: {
    // display: 'flex',
    // overflow: 'hidden',
    // position: 'relative',
    // overflowY: 'auto',
    marginBottom: "70px",
  },
  headingDiv: {
    display: "flex",
    alignItems: "center",
    float: "right",
  },
  searchInput: {
    padding: "21px",
    "& input": {
      borderRadius: "50px",
      outline: 0,
      width: "289px",
      marginLeft: "20px",
      padding: "5px",
      textIndent: "10px",
    },
  },
  userTableDiv: {
    width: "calc(100% - 4px)",
    background: "#fff",
    boxShadow: "0 0px 2px rgb(25 25 25 / 20%)",
    transition: "all .3s ease-in-out",
    marginLeft: "2px",
    marginBottom: "2px",
    overflow: "auto",
    borderRadius: "15px 11px 0px 0px",
    minHeight: "500px",
    "& table": {
      width: "100%",
      background: "#036",
      tableLayout: "fixed",
      border: "1px solid #ccc",
      borderSpacing: "0",
      borderCollapse: "collapse",
      "& thead": {
        width: "100%",
        borderRadius: "15px 11px 0 0",
        color: "#fff",
        "& th": {
          padding: "25px 10px 10px",
          fontSize: "14px",
          textAlign: "left",
          width: "calc(150px - 20px)",
          background: " #036",
          color: "white",
          "&:nth-child(1)": {
            width: "calc(100px - 20px)",
            textAlign: "center",
          },
          "&:nth-child(2)": {
            width: "calc(150px - 20px)",
          },
          "&:nth-child(3)": {
            width: "calc(150px - 20px)",
          },
          "&:nth-child(4)": {
            width: "calc(150px - 20px)",
          },
          "&:nth-child(5)": {
            width: "calc(130px - 20px)",
          },
          "&:nth-child(6)": {
            width: "calc(150px - 20px)",
          },
          "&:nth-child(7)": {
            width: "calc(240px - 20px)",
          },
          "&:nth-child(8)": {
            width: "calc(150px - 20px)",
          },
          "&:nth-child(9)": {
            width: "calc(150px - 20px)",
          },
          "&:nth-child(10)": {
            width: "calc(150px - 20px)",
          },
          "&:nth-child(11)": {
            width: "calc(150px - 20px)",
          },
          "&:nth-child(13)": {
            width: "calc(150px - 20px)",
          },
          "&:nth-child(14)": {
            width: "calc(150px - 20px)",
          },
          "&:nth-child(19)": {
            width: "calc(180px - 20px)",
          },
          "& i": {
            marginLeft: "5px",
            cursor: "pointer",
          },
        },
      },
      "& tbody": {
        background: "#fff",
        "& tr": {
          background: "#fff",
          "&:nth-child(even)": {
            background: "#f2f2f2",
          },
          "& td": {
            padding: "8px 10px",
            fontSize: "14px",
            // borderBottom: '1px solid #ededed',
            verticalAlign: "middle",
            color: "#333",
            wordBreak: "break-all",
            "&:nth-child(1)": {
              width: "calc(100px - 20px)",
              textAlign: "center",
            },
            "&:nth-child(2)": {
              width: "calc(150px - 20px)",
            },
            "&:nth-child(3)": {
              width: "calc(150px - 20px)",
            },
            "&:nth-child(4)": {
              width: "calc(240px - 20px)",
            },
            "&:nth-child(5)": {
              width: "calc(130px - 20px)",
            },
            "&:nth-child(6)": {
              width: "calc(170px - 20px)",
            },
            "&:nth-child(7)": {
              width: "calc(170px - 20px)",
            },
            "&:nth-child(8)": {
              width: "calc(150px - 20px)",
            },
            "&:nth-child(9)": {
              width: "calc(100px - 20px)",
            },
            "&:nth-child(10)": {
              width: "calc(125px - 20px)",
            },
            "&:nth-child(10)": {
              width: "calc(165px - 20px)",
            },
            "&:nth-child(11)": {
              width: "calc(210px - 20px)",
            },
            "&:nth-child(13)": {
              width: "calc(180px - 20px)",
            },
            "&:nth-child(14)": {
              width: "calc(180px - 20px)",
            },
            "&:nth-child(19)": {
              width: "calc(180px - 20px)",
            },
          },
        },
      },
    },
  },
  emailText: {
    width: "200px",
  },
  noteError: {
    color: "#ff0606",
    padding: "10px",
    fontSize: "14px",
    background: "#ffefef",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "1.66",
    marginBottom: "10px",
    "& td": {
      color: "#ff0606 !important",
      padding: "10px",
      fontSize: "14px",
      background: "#ffefef",
      textAlign: "left !important",
      fontWeight: "400",
      lineHeight: "1.66",
      marginBottom: "10px",
      fontWeight: "600",
      padding: "30px 40px !important",
    },
  },
  moreInfo: {
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        display: "inline-block",
        cursor: "pointer",
        paddingRight: "12px",
        fontSize: "15px",
      },
    },
  },
  editAdmin: {
    color: "#337ab7",
  },
  confirmDeleteBtn: {
    display: "inline-block",
    width: "auto",
    flex: "initial",
    padding: "0px !important",
    margin: "0px 10px",
    "& button": {
      background: "white",
    },
  },
  openApply: {
    padding: "15px",
    textAlign: "right",
    borderTop: "1px solid #e5e5e5",
  },
  closeBtn: {
    display: "inline-block",
    padding: "6px 12px",
    marginBottom: "0",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    userSelect: "none",
    border: "1px solid transparent",
    borderRadius: "4px",
    color: "#333",
    backgroundColor: "#fff",
    borderColor: "#ccc",
  },
  activeBtn: {
    backgroundColor: "#036",
    borderColor: "#036",
    padding: "5px 15px",
    boxShadow: "none",
    color: "#fff",
    marginRight: "10px",
  },
  modalHeader: {
    fontFamily: "museo-sans, sans-serif !important",
    padding: "15px",
    position: "relative",
    fontSize: "18px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "300",
    lineHeight: "1.5",
  },
  colseButton: {
    padding: "15px",
    borderBottom: "1px solid #e5e5e5",
    "& button": {
      float: "right",
      fontSize: "21px",
      lineHeight: "1",
      color: "#000",
      textShadow: "0 1px 0 #fff",
      filter: "alpha(opacity=20)",
      opacity: ".2",
      padding: "0",
      cursor: "pointer",
      background: "0 0",
      border: "0",
    },
    "& button:hover": {
      color: "#adadad",
      opacity: "1",
    },
    "& h3": {
      height: "0px",
      lineHeight: "1.42857143",
      fontWeight: "400",
      fontFamily: "MuseoSans-500",
      fontSize: "20px",
      margin: "0",
    },
  },
  uploadImageDiv: {
    maxWidth: "45%",
    borderLeft: "1px solid #ddd",
    padding: "0 30px",
  },
  srcImageDiv: {
    display: "inline-block",
    width: "100%",
    border: "1px solid #eaeaea",
    marginTop: "5px",
    borderRadius: "5px",
    position: "relative",
  },
  inputImageDiv: {
    position: "relative",
    textAlign: "center",
    height: "30px",
    lineHeight: "30px",
    background: "#f4f4f4",
    "& input": {
      position: "absolute",
      width: "100%",
      height: "30px",
      lineHeight: "30px",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
      zIndex: 9,
      opacity: 0,
    },
  },
  mainDiv: {
    padding: "20px",
    display: "inline-block",
    width: "100%",
  },
  inputDiv: {
    marginBottom: "15px",
    "& label": {
      width: "154px",
      display: "-webkit-inline-box",
    },
    "& input": {
      position: "relative",
      width: "57%",
      outline: 0,
      height: "17px",
      padding: "6px 12px",
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "4px",
      webkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
    },
    "& textarea": {
      position: "relative",
      width: "57%",
      outline: 0,
      height: "44px",
      padding: "6px 12px",
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "4px",
      webkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
    },
  },
  errorBorder: {
    "& input": {
      borderColor: "#a94442",
    },
  },
  stateInput: {
    marginBottom: "15px",
    "& label": {
      width: "154px",
      display: "-webkit-inline-box",
    },
    "& >div": {
      width: "60%",
      "& div > div": {
        position: "relative",
        outline: 0,
        height: "17px",
        padding: "6px 12px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: "4px",
        webkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
        boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      },
    },
  },
  updateBtn: {
    marginLeft: "auto",
    // marginRight: 'auto',
    width: "60%",
    "& span": {
      color: "white",
      transform: "none",
    },
  },
  updateText: {
    marginBottom: "15px",
    color: "#3070bf",
  },
  sucessPopup: {
    textAlign: "center",
    padding: "50px 30px",
    "& p": {
      fontWeight: "500",
      fontSize: "14px",
    },
    "& img": {
      maxWidth: "150px",
    },
  },
  statusSuccess: {
    display: "inline",
    padding: ".2em .6em .3em",
    fontSize: "75%",
    color: "#fff",
    backgroundColor: "#5cb85c",
    borderRadius: ".25em",
  },
  statusInActive: {
    display: "inline",
    padding: ".2em .6em .3em",
    fontSize: "75%",
    color: "#fff",
    backgroundColor: "#e23d63",
    borderRadius: ".25em",
  },
  filterDrop: {
    display: "block",
    position: "absolute",
    background: "#e9e6e6",
    color: "#000",
    padding: 0,
    left: "50%",
    transform: "translatex(-50%)",
    minWidth: "220px",
    fontFamily: "museosans-100",
    listStyle: "none",
    zIndex: 1,
    top: "29px",
    margin: "0px 0px 10px",
    "&::before": {
      borderLeft: "9px solid transparent",
      borderRight: "9px solid transparent",
      content: '""',
      position: "absolute",
      width: 0,
      background: "transparent none repeat scroll 0 0",
      borderBottom: "9px solid #e9e6e6",
      borderTop: "10px solid transparent",
      height: "auto",
      left: "50%",
      top: "-19px",
      transform: "translateX(-50%)",
    },
    "& li": {
      padding: "4px 20px",
      fontSize: "14px",
      lineHeight: "1.4",
      cursor: "pointer",
      color: "#333",
      "&:hover": {
        color: "#0069aa",
      },
    },
  },
  filterTwoDrop: {
    "& li": {
      "&:first-child": {
        paddingLeft: "45px",
      },
      "& ul": {
        padding: "0 4px",
        listStyle: "none",
        maxHeight: "300px",
        overflow: "auto",
        cursor: "auto",
        "& li": {
          color: "#333",
          padding: "2px 2px 2px 20px",
          "&:first-child": {
            paddingLeft: "20px",
          },
          "& label": {
            cursor: "pointer",
            marginBottom: "0",
            display: "inline-block",
            maxWidth: "100%",
            fontWeight: "700",
            color: "#333",
            "& input[type=checkbox]": {
              margin: "0 6px 0px -17px",
              verticalAlign: "middle",
            },
          },
          "&:hover": {
            "& label": {
              color: "#0069aa",
            },
          },
        },
      },
    },
  },
  filterThreeDrop: {
    left: "60.9%",
  },
  filterFourDrop: {
    left: "81.5%",
  },
  filterFiveDrop: {
    left: "80.5%",
  },
  filterSixDrop: {
    left: "91%",
    height: "300px",
    overflow: "auto",
  },
  filterSchoolDrop: {
    height: "300px",
    overflow: "auto",
    left: "57.5%",
  },
  filterZipcodeDrop: {
    left: "57.7%",
  },
  schoolsBtn: {
    display: "flex",
    float: "right",
    margin: "10px 0px",
    "& > div": {
      border: "1px solid transparent",
    },
    "& a": {
      textAlign: "center",
      display: "inline-block",
      fontWeight: "400",
      padding: "5px 12px",
      marginBottom: "0",
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      WebkitUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textDecoration: "none",
      WebkitTransition: "all .5s ease 0s",
      MozTransition: "all .5s ease 0s",
      color: "#fff",
      cursor: "pointer",
    },
    "& button": {
      textAlign: "center",
      display: "inline-block",
      fontWeight: "400",
      padding: "5px 12px",
      marginBottom: "0",
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      WebkitUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textDecoration: "none",
      WebkitTransition: "all .5s ease 0s",
      MozTransition: "all .5s ease 0s",
      color: "#fff",
      cursor: "pointer",
    },
  },
  msgBtn: {
    "& a": {
      backgroundColor: "#036",
    },
    "& button": {
      backgroundColor: "#036",
    },
  },
  addAdminBtn: {
    "& a": {
      backgroundColor: "green",
    },
    "& button": {
      backgroundColor: "green",
    },
  },
  clearFilterBtn: {
    "& a": {
      backgroundColor: "#caa336",
    },
    "& button": {
      backgroundColor: "#caa336",
    },
  },
  importFilterBtn: {
    "& a": {
      backgroundColor: "#f89b22",
    },
    "& button": {
      backgroundColor: "#f89b22",
    },
  },
  exportBtn: {
    "& a": {
      backgroundColor: "#006AAA",
    },
    "& button": {
      backgroundColor: "#006AAA",
    },
  },
  resetPass: {
    position: "absolute",
    width: "calc(216px - 40px)",
    height: "calc(155px - 16px)",
    padding: "8px 20px",
    top: "-62px",
    right: "30px",
    border: "2px solid #0069aa",
    background: "#fff",
    zIndex: "99",
    borderRadius: "15px",
    opacity: "0",
    visibility: "hidden",
    WebkitTransition: "all .3s ease-in-out",
    MozTransition: "all .3s ease-in-out",
    MsTransition: "all .3s ease-in-out",
    OTransition: "all .3s ease-in-out",
    "&:before": {
      position: "absolute",
      top: "58px",
      left: "-22px",
      background: "#fff",
      width: "40px",
      height: "40px",
      border: "2px solid #0069aa",
      transform: "rotate(45deg) skew(-6deg,-6deg)",
      WebkitTransform: "rotate(45deg) skew(-6deg,-6deg)",
      MozTransform: "rotate(45deg) skew(-6deg,-6deg)",
      MsTransform: "rotate(45deg) skew(-6deg,-6deg)",
      OTransform: "rotate(45deg) skew(-6deg,-6deg)",
      borderRight: "none",
      borderTop: "none",
      zIndex: "-1",
      content: '""',
    },
  },
  importHeader: {
    fontSize: "20px",
  },
  conditions: {
    padding: "17px 0 0 0",
    color: "green",
    "& p": {
      lineHeight: "14px",
      fontSize: "14px",
      color: "#999",
      margin: "0 0 8px",
      display: "flex",
      alignItems: "center",
      "& span": {
        marginRight: "6px",
        "& img": {
          width: "12px",
          height: "14px",
        },
      },
      "& label": {
        display: "inline-block",
        verticalAlign: "middle",
        width: "80%",
        paddingTop: "0px",
        fontSize: "85%",
        color: "#999",
        lineHeight: "14px",
        fontWeight: "100",
      },
    },
  },
  resetPassCondition: {
    opacity: "1",
    visibility: "visible",
  },

  searchBar: {
    position: "relative",
    "& input": {
      borderRadius: "15px",
      border: "1px solid #ccc",
      padding: "7px 15px",
      outline: "0",
      width: "calc(100% - 30px)",
      color: "#555",
      font: "inherit",
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      fontSize: "14px !important",
      fontWeight: "100 !important",
      maxWidth: "380px",
      "&::placeholder": {
        color: "#7c7c7c",
      },
    },
  },
  searchRow: {
    marginBottom: "15px",
  },
  filterIcon: {
    position: "relative",
    "& img": {
      maxWidth: "15px",
      marginLeft: "5px",
      cursor: "pointer",
      position: "relative",
      top: "2px",
    },
  },
  noData: {
    "& td": {
      fontWeight: "600",
      textAlign: "left !important",
      padding: "30px 40px !important",
    },
  },
  active: {
    color: "#0069aa !important",
  },
  uploadDiv: {
    marginBottom: "15px",
    maxWidth: "100%",
    padding: "25px 25px",
    borderRadius: "5px",
  },
});

export default styles;
