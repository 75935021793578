import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ChangeClientModal from "../Modal/ChangeClientModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { compose } from "recompose";
import { styled } from "@mui/system";
import styles from "./styles";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const ListItemButtonCustom = styled(ListItemButton)(() => ({
  // padding: "5px 10px",
}));

const ListItemTextCustom = styled(ListItemText)(({ theme }) => ({
  color: "#fff",
  fontSize: theme.typography.h3,
  p: {
    color: "#fff",
  },
}));

const getActiveTab = (pathname) => {
  switch (pathname) {
    case "/pf101-dashboard":
    case "/pf101-student-tracker":
    case "/pf101-member-survey":
    case "/pf101-baseline-assessment":
    case "/pf101-final-assessment":
      return "personal_finance";

    case "/mcs-dashboard":
    case "/mcs-student-tacker":
      return "carrer_sketch";

    default:
      break;
  }
};

const SchoolSidebar = (props) => {
  const location = useLocation();

  const { classes, history, setSideBar } = props;
  const [expanded, setExpanded] = useState(false);
  const [copied, setCopied] = useState(false);
  const [openSelectPopup, setOpenSelectPopup] = React.useState(false);
  const handleChange = (panel) => {
    setExpanded(panel === expanded ? false : panel);
  };
  useEffect(() => {
    setExpanded(() => getActiveTab(location.pathname));
  }, []);

  const handleChangeSchoolType = () => {
    setOpenSelectPopup(true);
  };

  const copiedClear = () => {
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <List
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: 360,
          backgroundColor: "#036",
          color: "#fff",
          height: "100%",
          paddingTop: "20px",
        }}
        dense={true}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButtonCustom
          selected={location.pathname === "/client-dashboard"}
          component={NavLink}
          to="/client-dashboard"
        >
          <ListItemTextCustom secondary="Dashboard" />
        </ListItemButtonCustom>

        <ListItemButtonCustom onClick={() => handleChange("personal_finance")}>
          <ListItemTextCustom secondary="Personal Finance 101" />
          {expanded === "personal_finance" ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </ListItemButtonCustom>
        <Collapse
          in={expanded === "personal_finance"}
          timeout="auto"
          unmountOnExit
        >
          <List dense={true} component="div" disablePadding>
            <ListItemButton
              selected={location.pathname === "/pf101-dashboard"}
              component={NavLink}
              to="/pf101-dashboard"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Dashboard" />
            </ListItemButton>
            {/* <ListItemButton
              component={NavLink}
              to="/pf101-level-reports"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Level Reports" />
            </ListItemButton> */}
            <ListItemButton
              selected={location.pathname === "/pf101-student-tracker"}
              component={NavLink}
              to="/pf101-student-tracker"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Student Tracker" />
            </ListItemButton>
            {/* <ListItemButton
              component={NavLink}
              to="/pf101-challenge-analytics"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Challenge Reports" />
            </ListItemButton> */}
            <ListItemButton
              selected={location.pathname === "/pf101-member-survey"}
              component={NavLink}
              to="/pf101-member-survey"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Survey" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/pf101-baseline-assessment"}
              component={NavLink}
              to="/pf101-baseline-assessment"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Baseline Assessment" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/pf101-final-assessment"}
              component={NavLink}
              to="/pf101-final-assessment"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Final Assessment" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButtonCustom onClick={() => handleChange("carrer_sketch")}>
          <ListItemTextCustom secondary="My Career Sketch" />
          {expanded === "carrer_sketch" ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </ListItemButtonCustom>
        <Collapse
          in={expanded === "carrer_sketch"}
          timeout="auto"
          unmountOnExit
        >
          <List dense={true} component="div" disablePadding>
            <ListItemButton
              selected={location.pathname === "/mcs-dashboard"}
              sx={{ pl: 4 }}
              component={NavLink}
              to="/mcs-dashboard"
            >
              <ListItemTextCustom secondary="Dashboard" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname === "/mcs-student-tacker"}
              component={NavLink}
              to="/mcs-student-tacker"
            >
              <ListItemTextCustom secondary="Student Tracker" />
            </ListItemButton>
            {/* <ListItemButton sx={{ pl: 4 }}>
              <ListItemTextCustom secondary="Awareness Challenge Analytics" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemTextCustom secondary="Discovery Challenge Analytics" />
            </ListItemButton> */}
          </List>
        </Collapse>
        <ListItemButtonCustom
          selected={location.pathname === "/students_management"}
          component={NavLink}
          to="/students_management"
        >
          <ListItemTextCustom secondary="Student Management" />
        </ListItemButtonCustom>
        <ListItemButtonCustom
          selected={location.pathname === "/schools-admins"}
          component={NavLink}
          to="/schools-admins"
        >
          <ListItemTextCustom secondary="Admin Users" />
        </ListItemButtonCustom>
        <ListItemButtonCustom
          selected={location.pathname === "/document-center"}
          component={NavLink}
          to="/document-center"
        >
          <ListItemTextCustom secondary="Document Center" />
        </ListItemButtonCustom>
        <ListItemButtonCustom onClick={handleChangeSchoolType}>
          <ListItemTextCustom secondary="Change School" />
        </ListItemButtonCustom>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
          }}
        >
          <Divider sx={{ backgroundColor: "#fff" }} />
          <ListItemButtonCustom>
            <CopyToClipboard
              text="support@inkwiry.com"
              onCopy={() => {
                setCopied(true);
                copiedClear();
              }}
            >
              <Tooltip
                title={copied ? "Copied" : "Click to copy support email"}
              >
                <ListItemTextCustom
                  sx={{ textAlign: "center" }}
                  secondary="Need help? Contact
                support@inkwiry.com"
                />
              </Tooltip>
            </CopyToClipboard>
          </ListItemButtonCustom>
        </Box>
      </List>
      {openSelectPopup && (
        <ChangeClientModal
          header="Select School"
          setSideBar={setSideBar}
          open={openSelectPopup}
          handleClose={() => setOpenSelectPopup(!openSelectPopup)}
          {...props}
        />
      )}
    </>
  );
};

const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(SchoolSidebar);
