import React from "react";
import { compose } from "recompose";
import styles from "./styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";

const PasswordPopup = ({ password, ...props }) => {
  const { classes } = props;
  const {
    passCheck = false,
    passLength = false,
    passAlpha = false,
    passNumeric = false,
    passConfirm = false,
  } = password;
  return (
    <div
      className={
        passCheck
          ? classes.resetPassCondition + " " + classes.resetPass
          : classes.resetPass
      }
    >
      <div className={classes.conditions}>
        <p>
          <span>
            {passLength ? (
              <img src="./img/pass.png" alt="" />
            ) : (
              <img src="./img/fail.png" alt="" />
            )}
          </span>
          <label>Include at least 8 characters</label>
        </p>
        <p>
          <span>
            {passAlpha ? (
              <img src="./img/pass.png" alt="" />
            ) : (
              <img src="./img/fail.png" alt="" />
            )}
          </span>
          <label>Include upper and lower case characters</label>
        </p>
        <p>
          <span>
            {passNumeric ? (
              <img src="./img/pass.png" alt="" />
            ) : (
              <img src="./img/fail.png" alt="" />
            )}
          </span>
          <label>Include at least 1 number</label>{" "}
        </p>
        <p>
          <span>
            {passConfirm ? (
              <img src="./img/pass.png" alt="" />
            ) : (
              <img src="./img/fail.png" alt="" />
            )}
          </span>
          <label>Passwords match</label>
        </p>
      </div>
    </div>
  );
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(React.memo(PasswordPopup));
