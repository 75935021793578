const styles = () => ({
    headerVillanova:{
      background: '#fff',
    	padding: '15px 0',
    },
		container: {
			maxWidth: '1170px',
			padding: '0 15px',
			margin: '0px auto',
			boxSizing: 'border-box',
			'& img': {
				maxWidth: '100%',
			},
			'@media (max-width: 767px)': {
				'& h3': {
					fontSize: '25px !important',
					lineHeight: '30px !important',
				},
				'& h2': {
					fontSize: '25px !important',
					lineHeight: '30px !important',
				},
				'& h4': {
					fontSize: '25px !important',
					lineHeight: '30px !important',
				},
				'& p': {
					fontSize: '17px !important',
					lineHeight: '28px !important',
				},
			},
		},
		containerAdmin:{
			width: '100%',
			margin: '0',
			maxWidth: '100%',
		},
		villanovaLogoLeft:{
			maxWidth: '275px',
			padding: '0px',
		},
		villanovaLogoMiddle:{
			padding: '0px 15px',
		},
		villanovaLogo:{
			lineHeight: '0px',
			'& img':{
				maxWidth: '200px',
				width: '100%',
			}
		},
		villanovaMainHead:{
			'& h2':{
				display: 'inline-block',
				paddingRight: '12px',
				width: 'calc(270px - 12px)',
				fontSize: '20px',
				fontWeight: 'normal',
				color: '#0069aa',
				fontFamily: 'MuseoSans-500',
				lineHeight: '1.1',
			}
		},
		searchBar:{
			width: 'calc(100% - 270px)',
			position: 'relative',
			float: 'right',
			'& input':{
				borderRadius: '15px',
				border: '1px solid #ccc',
				padding: '8px 15px',
				outline: '0',
				width: 'calc(100% - 30px)',
  			color: '#555',
				font: 'inherit',
				fontFamily: 'inherit',
				fontSize: 'inherit',
				lineHeight: 'inherit',
				fontSize: '14px !important',
				fontWeight: '100 !important',
				'&::placeholder':{
					color: '#7c7c7c',
				}
			}
		},
		villanovaPager:{
			float: 'right',
		},
		pagination:{
			display: 'inline-block',
			margin: '7px 0 0',
    	borderRadius: '4px',
		},
		flexCenter:{
			display: 'flex',
			alignItems: 'center',
		}
});

export default styles;
