// import Users from '../ui/users/allUsers';
import {
  AddUser,
  AdminCreation,
  ContactUs,
  Downloads,
  EditUser,
  ForgotPassword,
  InkwiryAdmins,
  ResetPassword,
  Schools,
  Users,
  VerifyOtp,
  editAdmin,
} from "../index";

import AddBusinessAdmin from "../business/AddBusinessAdmin";
import AddBusinessUser from "../business/AddBusinessUser";
import AddMember from "../ui/member-management/add-member";
import AddSchoolAdmin from "../schools/AddSchoolAdmin";
import Business from "../ui/business/business";
import BusinessAdmin from "../../screens/business";
import BusinessDashboard from "../../screens/business-dashboard/index";
import BusinessUser from "../business/BusinessUser";
import Bussinesscareers from "../ui/careers/index";
import DashBoard from "../../screens/pf101/dashboard";
import Dashboard from "../ui/dashboard/dashboard";
import DocumentCenter from "../ui/document-center/index";
import EditBusinessAdmin from "../business/EditBusinessAdmin";
import EditBusinessUser from "../business/EditBusinessUser";
import EditSchoolAdmin from "../schools/EditSchoolAdmin";
import EditSchoolMember from "../ui/member-management/edit-member";
import EditUsers from "../ui/add-users/edit-users";
import GeneralSettings from "../ui/general-settings/";
// import LayoutWithBusinessSidebar from '../layout/business-sidebar';
import Home from "../../screens/home";
import LayoutWithSidebar from "../layout/with-sidbar";
// import LayoutWithoutSidebar from '../components/layout/without-sidebar';
import LayoutWithoutHeaderFooter from "../layout/without-header-footer";
import McsDashboard from "../mcs/mcs-dashboard";
import McsTracker from "../mcs/mcs-tracker";
import MemberDashboard from "../ui/users/dashboard";
import MemberManagement from "../ui/member-management/mamber-management";
import PF101BaslineAssessment from "../../screens/pf101/BaselineAssessment";
import PF101ChallengeAnalytics from "../../screens/pf101/ChallengeAnalytics";
import PF101FinalAssessment from "../../screens/pf101/FinalAssessment";
import PF101LevelReports from "../../screens/pf101/level/";
import PF101MemberSurvey from "../../screens/pf101/MemberSurvey";
import PF101StudentSurvey from "../../screens/pf101/StudentSurvey";
import PF101Survey from "../../screens/pf101/SurveryContainer";
import PF101SurveyDevon from "../../screens/pf101/SurveyDevon";
import PNF from "../../screens/page-not-found/index";
import Pf101Tracker from "../../screens/pf101/MemberTracker";
import SchoolAdmin from "../../screens/home/schools-admin";
import SchoolUsersManagement from "../schools/schoolUserManagement";
import Unauthorized from "../ui/unauthorized";
import addSchoolUser from "../schools/addSchoolUser";
import editSchoolMangement from "../schools/editSchoolMangement";

export const publicRoutes = [
  {
    key: "home",
    exact: true,
    path: "/",
    component: Home,
    layout: LayoutWithoutHeaderFooter,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "user-management",
    exact: true,
    path: "/user-management",
    component: Users,
    layout: LayoutWithSidebar,
    name: "Member Details",
    role: ["inkwiry"],
  },
  // {
  //   key:'career-sketch',
  //   exact: true,
  //   path: '/career-sketch',
  //   component:careers,
  //   layout:LayoutWithSidebar,
  // },
  // {
  //   key: "user",
  //   exact: true,
  //   path: "/edit-user",
  //   component: EditUser,
  //   layout: LayoutWithSidebar,
  //   searchBar: false,
  // },
  {
    key: "admin-creation",
    exact: true,
    path: "/admin-creation",
    component: AdminCreation,
    layout: LayoutWithSidebar,
    searchBar: false,
    name: "Create New Inkwiry Admin",
    role: ["inkwiry"],
  },
  {
    key: "inkwiry-admins",
    exact: true,
    path: "/inkwiry-admins",
    component: InkwiryAdmins,
    layout: LayoutWithSidebar,
    role: ["inkwiry"],
  },
  {
    key: "verify-otp",
    exact: true,
    path: "/verify-otp",
    component: VerifyOtp,
    layout: LayoutWithoutHeaderFooter,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "forgotpassword",
    exact: true,
    path: "/forgotpassword",
    component: ForgotPassword,
    layout: LayoutWithoutHeaderFooter,
    searchBar: false,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "resetpassword",
    exact: true,
    path: "/resetpassword",
    component: ResetPassword,
    layout: LayoutWithoutHeaderFooter,
    searchBar: false,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "add-user",
    exact: true,
    path: "/user-details",
    component: AddUser,
    layout: LayoutWithSidebar,
    searchBar: false,
    role: ["inkwiry"],
  },
  {
    key: "edit-user",
    exact: true,
    path: "/edit-user-details",
    component: EditUsers,
    layout: LayoutWithSidebar,
    searchBar: false,
    role: ["inkwiry"],
  },
  {
    key: "edit-admin",
    exact: true,
    path: "/edit-admin",
    component: editAdmin,
    layout: LayoutWithSidebar,
    searchBar: false,
    name: "Edit Inkwiry Admin",
    role: ["inkwiry"],
  },
  {
    key: "schools",
    exact: true,
    path: "/schools",
    component: Schools,
    layout: LayoutWithSidebar,
    role: ["inkwiry"],
  },
  {
    key: "schoolsAdmin",
    exact: true,
    path: "/schools-admins",
    component: SchoolAdmin,
    layout: LayoutWithSidebar,
    name: "School Admins",
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "businessAdmin",
    exact: true,
    path: "/businesses-admins",
    component: BusinessAdmin,
    layout: LayoutWithSidebar,
    name: "Business Admins",
    role: ["inkwiry", "business"],
  },
  {
    key: "AddschoolsAdmin",
    exact: true,
    path: "/add-school-admin",
    component: AddSchoolAdmin,
    layout: LayoutWithSidebar,
    searchBar: false,
    name: "Create New School Admin",
    role: ["inkwiry", "school"],
  },
  {
    key: "EditschoolsAdmin",
    exact: true,
    path: "/edit-school-admin",
    component: EditSchoolAdmin,
    layout: LayoutWithSidebar,
    searchBar: false,
    name: "Edit School Admin",
    role: ["inkwiry", "school"],
  },
  {
    key: "AddBusinessAdmin",
    exact: true,
    path: "/add-business-admin",
    component: AddSchoolAdmin,
    layout: LayoutWithSidebar,
    searchBar: false,
    name: "Create New Business Admin",
    role: ["inkwiry", "business"],
  },
  {
    key: "EditBusinessAdmin",
    exact: true,
    path: "/edit-business-admin",
    component: EditSchoolAdmin,
    layout: LayoutWithSidebar,
    searchBar: false,
    name: "Edit Business Admin",
    role: ["inkwiry", "business"],
  },
  {
    key: "contact-us",
    exact: true,
    path: "/contact-us",
    component: ContactUs,
    layout: LayoutWithSidebar,
  },
  {
    key: "downloads",
    exact: true,
    path: "/downloads",
    component: Downloads,
    layout: LayoutWithSidebar,
  },
  {
    key: "pf101-dashboard",
    exact: true,
    path: "/pf101-dashboard",
    component: DashBoard,
    layout: LayoutWithSidebar,
    name: "PF101 Dashboard",
    searchBar: false,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "business",
    exact: true,
    path: "/business",
    component: Business,
    layout: LayoutWithSidebar,
    role: ["inkwiry"],
  },
  // {
  //   key: "AddBusinessAdmin",
  //   exact: true,
  //   path: "/add-business-admin",
  //   component: AddBusinessAdmin,
  //   layout: LayoutWithSidebar,
  //   searchBar: false,
  // },
  // {
  //   key: "EditBusinessAdmin",
  //   exact: true,
  //   path: "/edit-business-admin",
  //   component: EditBusinessAdmin,
  //   layout: LayoutWithSidebar,
  //   searchBar: false,
  // },
  {
    key: "SchoolUserManagement",
    exact: true,
    path: "/school-user-management",
    component: SchoolUsersManagement,
    layout: LayoutWithSidebar,
  },
  {
    key: "addSchoolUser",
    exact: true,
    path: "/add-school-user",
    component: addSchoolUser,
    layout: LayoutWithSidebar,
    searchBar: false,
  },
  {
    key: "editSchoolUser",
    exact: true,
    path: "/edit-student-user",
    component: editSchoolMangement,
    layout: LayoutWithSidebar,
    searchBar: false,
    role: ["school"],
  },
  {
    key: "editMemberUser",
    exact: true,
    path: "/edit-member-user",
    component: editSchoolMangement,
    layout: LayoutWithSidebar,
    searchBar: false,
    role: ["business"],
  },
  {
    key: "BusinessUserManagement",
    exact: true,
    path: "/business-user-management",
    component: BusinessUser,
    layout: LayoutWithSidebar,
  },
  {
    key: "addBusinessUser",
    exact: true,
    path: "/add-business-user",
    component: AddBusinessUser,
    layout: LayoutWithSidebar,
    searchBar: false,
  },
  {
    key: "editBusinessUser",
    exact: true,
    path: "/edit-business-user",
    component: EditBusinessUser,
    layout: LayoutWithSidebar,
    searchBar: false,
  },
  {
    key: "GeneralSettings",
    exact: true,
    path: "/general-settings",
    component: GeneralSettings,
    layout: LayoutWithSidebar,
  },
  {
    key: "pf101-level-reports",
    exact: true,
    path: "/pf101-level-reports",
    component: PF101LevelReports,
    layout: LayoutWithSidebar,
    name: "PF101 Level Reports",
    searchBar: false,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "pf101-challenge-analytics",
    exact: true,
    path: "/pf101-challenge-analytics",
    component: PF101ChallengeAnalytics,
    layout: LayoutWithSidebar,
    name: "PF101 Challenge Analytics",
    searchBar: false,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "pf101-baseline-assessment",
    exact: true,
    path: "/pf101-baseline-assessment",
    component: PF101BaslineAssessment,
    layout: LayoutWithSidebar,
    name: "PF101 Baseline Challenge",
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "pf101-final-assessment",
    exact: true,
    path: "/pf101-final-assessment",
    component: PF101FinalAssessment,
    layout: LayoutWithSidebar,
    name: "PF101 Final Challenge",
    role: ["inkwiry", "school", "business"],
  },

  {
    key: "pf101-survey-devon",
    exact: true,
    path: "/pf101-survey-devon",
    component: PF101SurveyDevon,
    layout: LayoutWithSidebar,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "pf101-member-survey",
    exact: true,
    path: "/pf101-member-survey",
    component: PF101MemberSurvey,
    layout: LayoutWithSidebar,
    name: "PF101 Survey",
    searchBar: false,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "pf101-member-tracker",
    exact: true,
    path: "/pf101-member-tracker",
    component: Pf101Tracker,
    layout: LayoutWithSidebar,
    name: "PF101 Member Tracker",
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "pf101-student-tracker",
    exact: true,
    path: "/pf101-student-tracker",
    component: Pf101Tracker,
    layout: LayoutWithSidebar,
    name: "PF101 Student Tracker",
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "pf101-survey",
    exact: true,
    path: "/pf101-survey",
    component: PF101Survey,
    layout: LayoutWithSidebar,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "pf101-student-survey",
    exact: true,
    path: "/pf101-student-survey",
    component: PF101StudentSurvey,
    layout: LayoutWithSidebar,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "mcs-tracker",
    exact: true,
    path: "/mcs-student-tacker",
    component: McsTracker,
    layout: LayoutWithSidebar,
    name: "My Career Sketch Tracker",
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "mcs-dashboard",
    exact: true,
    path: "/mcs-dashboard",
    component: McsDashboard,
    layout: LayoutWithSidebar,
    name: "My Career Sketch Dashboard",
    searchBar: false,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "dashboard",
    exact: true,
    path: "/dashboard",
    component: Dashboard,
    layout: LayoutWithSidebar,
  },
  {
    key: "BusinessDashboard",
    exact: true,
    path: "/client-dashboard",
    component: BusinessDashboard,
    layout: LayoutWithSidebar,
    name: "Dashboard",
    searchBar: false,
    role: ["school", "business"],
  },
  {
    key: "CareerSketching",
    exact: true,
    path: "/career-sketch",
    component: Bussinesscareers,
    layout: LayoutWithSidebar,
  },
  {
    key: "AddStudent",
    exact: true,
    path: "/add_student",
    component: AddMember,
    layout: LayoutWithSidebar,
    searchBar: false,
    role: ["school"],
  },
  {
    key: "AddMember",
    exact: true,
    path: "/add_member",
    component: AddMember,
    layout: LayoutWithSidebar,
    searchBar: false,
    role: ["business"],
  },
  {
    key: "StudentManagement",
    exact: true,
    path: "/students_management",
    component: MemberManagement,
    layout: LayoutWithSidebar,
    name: "Student Management",
    role: ["school"],
  },
  {
    key: "MemberManagement",
    exact: true,
    path: "/member_management",
    component: MemberManagement,
    layout: LayoutWithSidebar,
    name: "Member Management",
    role: ["business"],
  },
  {
    key: "DocumentCenter",
    exact: true,
    path: "/document-center",
    component: DocumentCenter,
    layout: LayoutWithSidebar,
    name: "Document Center",
    searchBar: false,
    role: ["inkwiry", "school", "business"],
  },
  {
    key: "MemberDashboard",
    exact: true,
    path: "/member-dashboard",
    component: MemberDashboard,
    layout: LayoutWithSidebar,
  },
  {
    key: "EditSchoolMember",
    exact: true,
    path: "/edit-member",
    component: EditSchoolMember,
    layout: LayoutWithSidebar,
    searchBar: false,
  },
  {
    key: "Unauthorized",
    exact: true,
    path: "/unauthorized",
    component: Unauthorized,
    layout: LayoutWithSidebar,
    searchBar: false,
  },
];
export const privateRoutes = [];
