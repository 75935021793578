import * as commonFunctions from "../../../utilities/commonFunctions";

import { Field, Form } from "react-final-form";
import React, { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Pageloader from "../pageloader";
import PropTypes from "prop-types";
import { TextField } from "final-form-material-ui";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "../add-users/styles";
import { useUserDetailsContext } from "../../../context/userDetails";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const CHECK_EMAIL = loader("../../../graphql/schema/ui/checkEmail.graphql");
const SCHOOL_USER_REGISTRATION = loader(
  "../../../graphql/schema/student-registration/school-user-registration.graphql"
);
const GETMEMBERBY_ID = loader(
  "../../../graphql/schema/ui/GetMemberById.graphql"
);
const UPDATE_ADMIN_PASSWORD = loader(
  "../../../graphql/schema/schools/update-school-admin-password.graphql"
);
const CHECK_USER_NAME = loader(
  "../../../graphql/schema/schools/check-admin-username.graphql"
);
const UPDATEUSER = loader("../../../graphql/schema/ui/saveMember.graphql");

const EditSchoolMember = (props) => {
  const { classes, history } = props;
  const { state } = useUserDetailsContext();
  const [passwordLength, setPasswordLength] = useState(false);
  const [alphabetsExist, setAlphabetsExist] = useState(false);
  const [numericExist, setNumericExist] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [business, setBusiness] = useState("");
  const [displayData, setDisplayData] = useState({});
  const [userName, setUserName] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [userSuccessMsg, setUserSuccessMsg] = useState("");
  const [userId, setUserId] = React.useState("");
  const [passCheck, setPassCheck] = useState(false);
  const [successmsg, setSuccessMsg] = useState("");

  React.useEffect(() => {
    const id = JSON.parse(localStorage.getItem("institution"));
    setBusiness(
      id !== null && id !== undefined && id !== "" ? id.institution_name : ""
    );
  }, []);
  React.useEffect(() => {
    setUserId(window.location.search.split("=")[1]);
  }, []);

  const [checkEmailExist] = useLazyQuery(CHECK_EMAIL, {
    variables: {
      email: emailValue,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckEmail: { status } }) {
      if (status) {
        setEmailError("");
        setEmailSuccess(true);
      }
    },
    onError(errors) {
      setEmailSuccess(false);
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (emailValue !== "") {
        setEmailError(formatedErrors);
      }
    },
  });

  const { data: userResult } = useQuery(GETMEMBERBY_ID, {
    variables: {
      id: window.location.search.split("=")[1],
    },
    fetchPolicy: "network-only",
    onCompleted({ GetMemberById }) {
      setDisplayData(GetMemberById);
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [userDetails] = useMutation(UPDATEUSER, {
    onCompleted() {
      setLoading(false);
      setSuccessMsg("Account information has been updated successfully.");
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required.";
    }

    if (values.email) {
      if (!commonFunctions.validateEmail(values.email)) {
        errors.email = "Enter valid email.";
      }
      setEmailValue(values.email);
    }
    if (!values.email) {
      errors.email = "email is required.";
      setEmailValue("");
    }

    if (!values.confirm_email) {
      errors.confirm_email = "confirm email is required.";
    } else {
      if (emailValue !== values.confirm_email) {
        setConfirmEmail("wrong");
      } else {
        setConfirmEmail("right");
      }
    }

    if (!values.groupName) {
      errors.groupName = "group is required.";
    }
    return errors;
  };

  const onSubmit = (values) => {
    setLoading(true);
    userDetails({
      variables: {
        data: {
          id: userId,
          user_fname: values.first_name,
          user_lname: values.last_name,
          email: values.email,
          grade: values.groupName,
          school: business,
        },
      },
    });
  };

  const validateOne = (values) => {
    const errors = {};

    if (!values.userName) {
      errors.userName = "username is required.";
    } else {
      setUserName(values.userName);
    }

    if (values.userName) {
      if (values.userName.length <= 3) {
        setUsernameError("User must be more that 4 charecters");
      } else {
        setUsernameError("");
      }
    }

    if (values.password) {
      if (values.password.length >= 8) {
        setPasswordLength(true);
      } else {
        errors.password = "";
        setPasswordLength(false);
      }
      if (!commonFunctions.validateAlpha(values.password)) {
        setAlphabetsExist(true);
      } else {
        errors.password = "";
        setAlphabetsExist(false);
      }
      if (!commonFunctions.validateNumeric(values.password)) {
        setNumericExist(true);
      } else {
        errors.password = "";
        setNumericExist(false);
      }
    } else {
      setPasswordLength(false);
      setAlphabetsExist(false);
      setNumericExist(false);
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = "confirm password is required.";
    } else {
      if (values.password_confirmation) {
        if (values.password === values.password_confirmation) {
          setPasswordMatch(true);
        } else {
          setPasswordMatch(false);
          errors.password_confirmation = "";
        }
      }
    }
    return errors;
  };

  const [updateSchoolAdminPassword] = useMutation(UPDATE_ADMIN_PASSWORD, {
    onCompleted() {
      setLoading(false);
      setUserSuccessMsg(
        "Username and password have been updated successfully."
      );
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const onSubmitOne = (values) => {
    updateSchoolAdminPassword({
      variables: {
        data: {
          id: userId,
          user_name: userName,
          password: values.password,
          confirm_password: values.password_confirmation,
        },
      },
    });
  };

  const [checkUserName] = useLazyQuery(CHECK_USER_NAME, {
    variables: {
      user_name: userName,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckAdminName: { status } }) {
      if (status) {
        setUsernameError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (userName !== "") {
        setUsernameError(formatedErrors);
      }
    },
  });

  return (
    <Typography className="edit-page-new formError">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <div className={classes.backToAdmin}>
        <Link to="/students_management">
          <i className="fa fa-long-arrow-left" aria-hidden="true"></i> Back to
          User Management
        </Link>
      </div>
      <div className={classes.formBlock}>
        <h3>Edit User</h3>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Form
              validate={validate}
              onSubmit={onSubmit}
              initialValues={{
                business: business,
                first_name: displayData.user_fname,
                last_name: displayData.user_lname,
                email: displayData.email,
                groupName: displayData.grade,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <div className={classes.formGroup}>
                      <label>First Name </label>
                      <Field
                        placeholder="ex. Mike"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="first_name"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Last Name</label>
                      <Field
                        placeholder="ex. Inkwiry"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="last_name"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Email</label>
                      <Field
                        placeholder="ex. mike@inkwiry.com"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="email"
                        fullWidth
                        required
                        onBlur={(e) => {
                          checkEmailExist(e);
                        }}
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>School</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="business"
                        placeholder="ex. Sophomore"
                        fullWidth
                        required
                        disabled
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>Group</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        autoComplete="current-password"
                        name="groupName"
                        placeholder="ex. Sophomore"
                        fullWidth
                        required
                        component={TextField}
                        className={classes.inputLeft}
                      />
                    </div>
                    {successmsg && (
                      <div className={classes.sccessMsg}>
                        <span>{successmsg}</span>
                      </div>
                    )}
                    <div className={classes.updateBtn}>
                      <button type="submit" onClick={onSubmit}>
                        UPDATE
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />

            {errorMessage && (
              <div className={classes.noteError}>{errorMessage}</div>
            )}

            <Form
              validate={validateOne}
              onSubmit={onSubmitOne}
              initialValues={{
                userName: displayData.user_name,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <h3>Username and Password</h3>
                    {/* <div className={classes.updateText}>
																					<span>Username and Password</span>
																			</div> */}
                    <div className={classes.formGroup}>
                      <label>Username</label>
                      <div className={classes.inputLeft}>
                        <Field
                          id="outlined-basic"
                          variant="outlined"
                          type="text"
                          autoComplete="current-password"
                          name="userName"
                          placeholder="Username"
                          fullWidth
                          required
                          onBlur={(e) => {
                            checkUserName(e);
                          }}
                          component={TextField}
                        />
                        {usernameError ? (
                          <div className={classes.nameError}>
                            {usernameError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className={classes.formGroup}>
                      <label>New Password</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="password"
                        autoComplete="current-password"
                        placeholder="Password"
                        name="password"
                        fullWidth
                        required
                        component={TextField}
                        onFocus={() => {
                          setPassCheck(true);
                        }}
                        onBlur={() => {
                          setPassCheck(false);
                        }}
                        className={classes.inputLeft}
                      />
                      <div
                        className={
                          passCheck
                            ? classes.resetPassCondition +
                              " " +
                              classes.resetPass
                            : classes.resetPass
                        }
                      >
                        <div className={classes.conditions}>
                          <p>
                            <span>
                              {passwordLength ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>Include at least 8 characters</label>
                          </p>
                          <p>
                            <span>
                              {alphabetsExist ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>
                              Include upper and lower case characters
                            </label>
                          </p>
                          <p>
                            <span>
                              {numericExist ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>Include at least 1 number</label>
                          </p>
                          <p>
                            <span>
                              {passwordMatch ? (
                                <img src="./img/pass.png" alt="" />
                              ) : (
                                <img src="./img/fail.png" alt="" />
                              )}
                            </span>
                            <label>Passwords match</label>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Confirm Password</label>
                      <Field
                        id="outlined-basic"
                        variant="outlined"
                        type="password"
                        autoComplete="current-password"
                        name="password_confirmation"
                        placeholder="Confirm New Password"
                        fullWidth
                        required
                        component={TextField}
                        onFocus={() => {
                          setPassCheck(true);
                        }}
                        onBlur={() => {
                          setPassCheck(false);
                        }}
                        className={classes.inputLeft}
                      />
                    </div>
                    {userSuccessMsg && (
                      <div className={classes.sccessMsgDiv}>
                        <div className={classes.sccessMsg}>
                          <span>{userSuccessMsg}</span>
                        </div>
                      </div>
                    )}

                    <div className={classes.updateBtn}>
                      <button type="submit">UPDATE</button>
                    </div>
                  </div>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Typography>
  );
};
EditSchoolMember.propTypes = {
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(EditSchoolMember);
