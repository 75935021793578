const styles = () => ({
  TableDiv2: {
    width: "calc(100% - 4px)",
    background: "#fff",
    boxShadow: "0 0px 2px rgb(25 25 25 / 20%)",
    transition: "all .3s ease-in-out",
    marginLeft: "2px",
    marginBottom: "2px",
    overflow: "auto",
    borderRadius: "10px",
    "& table": {
      width: "100%",
      overflow: "hidden",
      borderCollapse: "collapse",
      tableLayout: "fixed",
      border: "1px solid #ccc",
      "& thead": {
        "& th": {
          padding: "25px 10px 10px",
          fontSize: "13px",
          // background: '#f4f4f4',
          textAlign: "left",
          // lineHeight: '24px',
          // verticalAlign: 'top',
          width: "150px",
          background: " #036",
          color: "white",
          "& i": {
            marginLeft: "5px",
            cursor: "pointer",
          },
        },
      },
      "& tbody": {
        background: "#fff",
        "& tr": {
          background: "#fff",
          "&:nth-child(even)": {
            background: "#f2f2f2",
          },
          "& td": {
            padding: "8px 10px",
            fontSize: "13px",
            borderBottom: "1px solid #ededed",
            verticalAlign: "middle",
          },
        },
      },
    },
  },
  userTableDiv: {
    width: "calc(100% - 4px)",
    background: "#fff",
    // boxShadow: '0 0px 2px rgb(25 25 25 / 20%)',
    transition: "all .3s ease-in-out",
    marginLeft: "2px",
    marginBottom: "2px",
    overflow: "auto",
    borderRadius: "15px 11px 0px 0px",
    minHeight: "500px",
    "& > div": {
      minHeight: "500px",
      maxWidth: "60%",
      overflow: "hidden",
      borderRadius: "15px 15px 0px 0px",
    },
    "& table": {
      width: "100%",
      background: "#036",
      tableLayout: "fixed",
      border: "1px solid #ccc",
      borderSpacing: "0",
      borderCollapse: "collapse",
      "& thead": {
        width: "100%",
        borderRadius: "15px 11px 0 0",
        color: "#fff",
        "& th": {
          padding: "25px 10px 10px",
          fontSize: "14px",
          textAlign: "left",
          width: "150px",
          background: " #036",
          color: "white",
          "&:nth-child(1)": {
            width: "calc(42px - 20px)",
            textAlign: "center",
          },
          "&:nth-child(2)": {
            width: "calc(125px - 20px)",
          },
          "&:nth-child(3)": {
            width: "calc(125px - 20px)",
          },
          "&:nth-child(4)": {
            width: "calc(125px - 20px)",
          },
          "&:nth-child(5)": {
            width: "calc(125px - 20px)",
          },
          "&:nth-child(6)": {
            width: "calc(88px - 20px)",
          },
          "&:nth-child(7)": {
            width: "150px",
          },
          "&:nth-child(8)": {
            width: "100px",
          },
          "& i": {
            marginLeft: "5px",
            cursor: "pointer",
          },
        },
      },
      "& tbody": {
        background: "#fff",
        "& tr": {
          background: "#fff",
          "&:nth-child(even)": {
            background: "#f2f2f2",
          },
          "& td": {
            padding: "8px 10px",
            fontSize: "14px",
            // borderBottom: '1px solid #ededed',
            verticalAlign: "middle",
            color: "#333",
            wordBreak: "break-all",
            "&:nth-child(1)": {
              width: "calc(42px - 20px)",
              textAlign: "center",
            },
            "&:nth-child(2)": {
              width: "calc(125px - 20px)",
            },
            "&:nth-child(3)": {
              width: "calc(125px - 20px)",
            },
            "&:nth-child(4)": {
              width: "calc(125px - 20px)",
            },
            "&:nth-child(5)": {
              width: "calc(125px - 20px)",
            },
            "&:nth-child(6)": {
              width: "calc(88px - 20px)",
            },
            "&:nth-child(7)": {
              width: "150px",
            },
            "&:nth-child(8)": {
              width: "100px",
            },
          },
        },
      },
    },
  },

  msgBtn: {
    "& a": {
      backgroundColor: "#036",
    },
    "& button": {
      backgroundColor: "#036",
    },
  },
  addAdminBtn: {
    "& a": {
      backgroundColor: "green",
    },
    "& button": {
      backgroundColor: "green",
    },
  },
  clearFilterBtn: {
    "& a": {
      backgroundColor: "#caa336",
    },
    "& button": {
      backgroundColor: "#caa336",
    },
  },
  exportBtn: {
    "& a": {
      backgroundColor: "#006AAA",
    },
    "& button": {
      backgroundColor: "#006AAA !important",
    },
  },
  clearFilters: {
    "& a": {
      background: "#caa336",
      padding: "5px 12px",
      marginBottom: 0,
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      color: "#fff",
      textDecoration: "none",
    },
    "& button": {
      background: "#caa336",
      padding: "5px 12px",
      marginBottom: 0,
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      color: "#fff",
      textDecoration: "none",
    },
  },
  export: {
    "& a": {
      background: "#006aaa",
      padding: "5px 12px",
      marginBottom: 0,
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      color: "#fff",
      textDecoration: "none",
    },
    "& button": {
      background: "#006aaa",
      padding: "5px 12px",
      marginBottom: 0,
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      color: "#fff",
      textDecoration: "none",
    },
  },
  dashboardTabs: {
    position: "relative",
  },
  colGrid: {
    margin: "17px 0px 10px",
    width: "100%",
    "& > div": {
      padding: "0px !important",
    },
  },
  colLeft: {
    "& ul": {
      float: "none",
    },
  },
  colRight: {
    textAlign: "right",
  },
  tab3: {
    display: "none !important",
  },
  dashboardPage: {
    marginBottom: "70px",
  },
  questionExpalin: {
    fontSize: "14px",
    paddingBottom: "5px",
    "& span": {
      display: "table-cell",
      minWidth: "88px",
      fontFamily: "museosans-700",
      fontWeight: "normal",
    },
  },
  awarenessOptions: {
    display: "flex",
    margin: "0px 0px 10px",
    "& li": {
      minWidth: "40px",
      marginRight: "30px",
    },
  },
  awarenessOptionsFull: {
    margin: "0px 0px 10px",
    "& li": {
      minWidth: "40px",
      marginRight: "30px",
    },
  },
  correct: {
    color: "#0ad00a",
    fontWeight: "700",
  },
  questionPara: {
    paddingLeft: "24px",
    margin: "0px 0px 10px",
  },
  arrowStatistics: {
    display: "inline-block !important",
    minWidth: "auto !important",
    "& img": {
      display: "block",
      width: "18px",
    },
  },
  noData: {
    textAlign: "center",
    fontWeight: "700",
    padding: "50px 50px",
  },

  filterIcon: {
    position: "relative",
    "& img": {
      maxWidth: "15px",
      marginLeft: "5px",
      cursor: "pointer",
      position: "relative",
      top: "2px",
    },
  },
  filterDrop: {
    display: "block",
    position: "absolute",
    background: "#e9e6e6",
    color: "#000",
    padding: 0,
    left: "50%",
    transform: "translatex(-50%)",
    minWidth: "220px",
    fontFamily: "museosans-100",
    listStyle: "none",
    zIndex: 10,
    top: "29px",
    margin: "0px 0px 10px",
    "&::before": {
      borderLeft: "9px solid transparent",
      borderRight: "9px solid transparent",
      content: '""',
      position: "absolute",
      width: 0,
      background: "transparent none repeat scroll 0 0",
      borderBottom: "9px solid #e9e6e6",
      borderTop: "10px solid transparent",
      height: "auto",
      left: "50%",
      top: "-19px",
      transform: "translateX(-50%)",
    },
    "& li": {
      padding: "4px 20px",
      fontSize: "14px",
      lineHeight: "1.4",
      cursor: "pointer",
      color: "#333",
      "&:hover": {
        color: "#0069aa",
      },
    },
  },
  percentageRow: {
    position: "relative",
  },
  percentageText: {
    display: "inline-block",
    position: "absolute",
    top: "6px",
    fontSize: "14px",
    color: "#00f",
    right: "15px",
    zIndex: "9",
  },
  questionPaddingLeft: {
    paddingLeft: "24px",
  },
  mt50: {
    marginTop: "50px",
  },
  extraLoanTb: {
    borderSpacing: "0",
    borderCollapse: "collapse",
    "& tr": {
      "&:nth-child(odd)": {
        backgroundColor: "#cfd5ea",
      },
      "&:nth-child(even)": {
        backgroundColor: "#e9ebf5",
      },
      "& th": {
        textAlign: "left",
        padding: "5px 8px",
        border: "1px solid #fff",
        width: "180px",
        fontSize: "18px",
        fontWeight: "700",
        verticalAlign: "middle",
        backgroundColor: "#4472c4",
        color: "#fff",
      },
      "& td": {
        textAlign: "left",
        padding: "5px 8px",
        border: "1px solid #fff",
        width: "180px",
        fontSize: "18px",
        fontWeight: "700",
        verticalAlign: "middle",
      },
    },
  },
  bNetWorth: {
    margin: "12px 0px",
  },
  studentsAnalyticsDiv: {
    minHeight: "500px",
    overflow: "auto",
    borderRadius: "15px 15px 0 0",
    width: "100%",
  },
  studentsAnalytics: {
    width: "100%",
    background: "#036",
    tableLayout: "fixed",
    border: "1px solid #ccc",
    borderSpacing: "0",
    borderCollapse: "collapse",
    "& thead": {
      width: "100%",
      borderRadius: "15px 11px 0 0",
      color: "#fff",
      "& th": {
        width: "calc(125px - 20px)",
        padding: "8px 10px",
        textAlign: "left",
        verticalAlign: "bottom",
        "&:first-child": {
          padding: "25px 10px 10px",
          width: "calc(42px - 20px)",
          textAlign: "center",
        },
        "&:nth-child(2)": {
          width: "calc(155px - 20px)",
        },
        "&:last-child": {
          width: "calc(180px - 20px)",
        },
      },
    },
    "& tbody": {
      background: "#fff",
      "& tr": {
        background: "#fff",
        "&:nth-child(even)": {
          background: "#f2f2f2",
        },
        "& td": {
          width: "calc(125px - 20px)",
          padding: "8px 10px",
          "&:first-child": {
            width: "calc(42px - 26px)",
            textAlign: "center",
            padding: "8px 13px",
          },
          "&:nth-child(2)": {
            width: "calc(155px - 20px)",
          },
          "&:last-child": {
            width: "calc(180px - 20px)",
          },
        },
      },
    },
  },
  incorrect: {
    "& span": {
      color: "#bf0000",
      float: "left",
      fontSize: "13.5px",
      textAlign: "left",
      width: "100%",
      margin: "0px",
    },
  },
  correctText: {
    "& span": {
      color: "green",
      float: "left",
      fontSize: "13.5px",
      textAlign: "left",
      width: "100%",
      margin: "0px",
    },
  },
  optionsLeft: {
    display: "grid",
    marginLeft: "64px",
  },
  dashboardPageNew: {
    position: "relative",
  },
  paginationTop: {
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  paginationBottom: {
    display: "flex",
    alignItems: "center",
  },
  paginationLeft: {
    width: "70%",
    "& ul": {
      float: "none",
    },
  },
  schoolsBtn: {
    display: "flex",
    float: "right",
    margin: "10px 0px",
    "& > div": {
      border: "1px solid transparent",
    },
    "& a": {
      textAlign: "center",
      display: "inline-block",
      fontWeight: "400",
      padding: "5px 12px",
      marginBottom: "0",
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      WebkitUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textDecoration: "none",
      WebkitTransition: "all .5s ease 0s",
      MozTransition: "all .5s ease 0s",
      color: "#fff",
    },
    "& button": {
      textAlign: "center",
      display: "inline-block",
      fontWeight: "400",
      padding: "5px 12px",
      marginBottom: "0",
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      WebkitUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textDecoration: "none",
      WebkitTransition: "all .5s ease 0s",
      MozTransition: "all .5s ease 0s",
      color: "#fff",
      cursor: "pointer",
    },
  },
  activeTab: {
    marginRight: "10px",
    color: "white !important",
    background: "#003366",
    border: "1px solid #003366",
    borderColor: "#003366",
    borderRadius: "5px",
    padding: "5px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
  },
  // surveyButtonContainer:{
  // 	display: 'flex',
  // 	justifyContent: 'end',
  // },
  surveyButton: {
    marginRight: "10px",
    color: "#003366",
    background: "white",
    border: "1px solid #003366",
    borderColor: "#003366",
    borderRadius: "5px",
    padding: "5px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
  },
  submenuList: {
    textDecoration: "none",
  },
  submenuListActive: {
    textDecoration: "none",
    color: "white",
  },
});
export default styles;
