export function validateEmail(email) {
  const re = new RegExp(
    [
      '^(([^<>()\\[\\]\\,;:\\s@"]+(\\.[^<>()\\[\\]\\,;:\\s@"]+)*)',
      '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])',
      "|(([a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð:\\-0-9]+\\.)",
      "+[a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð:]{2,}))$",
    ].join("")
  );

  return re.test(email);
}

export function validateAlpha(value) {
  let isValidName = true;
  if (/^[A-z]/.test(value)) {
    isValidName = false;
  }
  return isValidName;
}

export function validateLowerAlpha(value) {
  let isValidName = true;
  if (/[a-z]/.test(value)) {
    isValidName = false;
  }
  return isValidName;
}
export function validateUpperAlpha(value) {
  let isValidName = true;
  if (/[A-Z]/.test(value)) {
    isValidName = false;
  }
  return isValidName;
}

export function validateNumeric(value) {
  let isValidNumeric = true;
  if (/\d+/g.test(value)) {
    isValidNumeric = false;
  }
  return isValidNumeric;
}

export const escapeRegExp = (string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const replaceAll = (str, term, replacement) =>
  str.replace(new RegExp(escapeRegExp(term), "g"), replacement);

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
  });
};
export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export function parseGraphQLErrorMessage(error) {
  let errorMessage;
  errorMessage = error.toString().replace("Error: GraphQL error: ", "");
  errorMessage = errorMessage.toString().replace("400 - ", "");
  errorMessage = errorMessage.toString().replace("401 - ", "");

  //If the error message is a valid JSON then, retrieve the error message from the object
  if (IsJsonString(errorMessage)) {
    let errorObj = JSON.parse(errorMessage);
    errorMessage = errorObj.error.message;
  }

  // If the message contains network error
  if (errorMessage.toLowerCase().indexOf("network error") !== -1) {
    //errorMessage = errorMessage.toString().replace('Error: Network error: ', '');
    //Error: Network error: Response not successful: Received status code 400
    errorMessage = "Failed to fetch";
  }
  return errorMessage;
}

export const isUrlValid = (userInput) => {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
};

export const convertSecondsToTime = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  var sec = Math.floor((seconds % 3600) % 60);

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (sec < 10) {
    sec = "0" + sec;
  }

  if (hours === "00") {
    if (minutes === "00") {
      return sec + " Sec";
    } else {
      return minutes + ":" + sec + " Min";
    }
  } else {
    return hours + ":" + minutes + ":" + sec + " Hrs";
  }
};
