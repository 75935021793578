import React, { useEffect, useState } from "react";
import { Typography, Tabs, Tab, Box, Grid, Tooltip } from "@mui/material";
import { withStyles, useTheme } from "@material-ui/core/styles";
import * as commonFunctions from "../../../utilities/commonFunctions";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import scrollToComponent from "react-scroll-to-component";
import { withApollo } from "react-apollo";
import compose from "recompose/compose";
import classNames from "classnames";
import { withRouter, Link } from "react-router-dom";
import styles from "../styles";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/react-hooks";
import Pageloader from "../../../components/ui/pageloader";
import ReactTable from "../../../components/ui/Table/ReactTable";
import IsLoadingHOC from "../../../Hoc/IsLoadingHOC";
import { useUserDetailsContext } from "../../../context/userDetails";
import useDebounce from "../../../hooks/useDebounce";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  if (index !== 3)
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const GET_PF101_BASELINE_ASSESSMENT = loader(
  "../../../graphql/schema/pf101/baselineAssessment/getPF101BaseLineChallengeTableView.graphql"
);
const GET_PF101_ASSESSMENT_GRAPH = loader(
  "../../../graphql/schema/pf101/getPF101AssesmentGraph.graphql"
);
const EXPORT_BASE_LINE = loader(
  "../../../graphql/schema/pf101/baselineAssessment/exportPF101BaseLineChallenge.graphql"
);

const PF101BaslineAssessment = (props) => {
  const { classes, setLoading } = props;
  const {
    state: { search = "" },
    clearSearch,
  } = useUserDetailsContext();
  const debouncedSearch = useDebounce(search, 1000);
  const [value, setValue] = useState(0);
  const [offset] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("");
  const [openFullNameFilters, setOpenFullNameFilters] = React.useState(false);
  const [baselineAssessment, setBaselineAssessment] = React.useState([]);
  const [AssessmentGraph, setBaselineGraph] = React.useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const [resetFilter, setResetFilter] = useState(false);

  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const theme = useTheme();
  const ref = React.useRef(null);
  const [groupId, setGroup] = React.useState([]);

  const [baselineAssessment_f, { fetchMore }] = useLazyQuery(
    GET_PF101_BASELINE_ASSESSMENT,
    {
      variables: {
        data: {
          page: 1,
          perPage: 20,
          orderByName: selectedType,
          filter_schools: groupId,
          time_offset: "1",
          name: "",
        },
      },
      fetchPolicy: "network-only",
      onCompleted({ getPF101BaseLineChallengeTableView }) {
        setBaselineAssessment(getPF101BaseLineChallengeTableView?.result);
        setActivePage(getPF101BaseLineChallengeTableView?.page);
        setPageFineshedCount(getPF101BaseLineChallengeTableView.totalCount);
        setLoading(false);
        setResetFilter(false);
      },
      onError(error) {
        setLoading(false);
      },
    }
  );
  const [assessmentGraph_f] = useLazyQuery(GET_PF101_ASSESSMENT_GRAPH, {
    variables: {
      data: "baseline",
    },
    fetchPolicy: "network-only",
    onCompleted(response) {
      response.getPF101AssesmentGraph &&
        setBaselineGraph(response.getPF101AssesmentGraph);

      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  const [exportBaseline] = useLazyQuery(EXPORT_BASE_LINE, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = response.exportPF101BaseLineChallenge.url;
      hiddenElement.download = "pf101_baseline.csv";
      hiddenElement.click();
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  useEffect(
    () => {
      setLoading(true);
      setActivePage(1);
      baselineAssessment_f({
        variables: {
          data: {
            page: 1,
            perPage: 20,
            orderByName: selectedType,
            filter_schools: groupId,
            time_offset: "1",
            name: debouncedSearch,
          },
        },
      });
    },
    [debouncedSearch] // Only call effect if debounced search term changes
  );

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenFullNameFilters(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  React.useEffect(() => {
    setLoading(true);
    baselineAssessment_f();
    assessmentGraph_f();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // if(newValue !== 1) assessmentGraph_f();
  };
  const handleSorting = (row, val) => {
    setLoading(true);
    setSelectedType(val);
    setActivePage(1);
    baselineAssessment_f({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          orderByName: val,
          filter_schools: groupId,
          time_offset: "1",
          name: debouncedSearch,
        },
      },
    });
  };
  useEffect(() => {
    setLoading(false);
  }, []);

  const handleClearFilter = () => {
    setLoading(true);
    setResetFilter(true);
    setActivePage(1);
    setSelectedType("");
    setGroup([]);
    clearSearch();
    baselineAssessment_f({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          orderByName: "",
          filter_schools: [],
          time_offset: "1",
          name: "",
        },
      },
    });
  };

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    setActivePage(pageNumber);
    fetchMore({
      variables: {
        data: {
          page: pageNumber,
          perPage: 20,
          orderByName: selectedType,
          filter_schools: groupId,
          time_offset: "1",
          name: debouncedSearch,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setBaselineAssessment(
          fetchMoreResult?.getPF101BaseLineChallengeTableView?.result
        );
        // return Object.assign({}, prev, {
        //   feed: [...prev.feed, ...fetchMoreResult.feed]
        // });
      },
    });
  };
  return (
    <div>
      <div className="dashboard-page">
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.dashboardTabs}
        >
          <Tab label="Table View" {...a11yProps(0)} />
          <Tab label="Graph View" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Grid
            container
            className={classes.searchRow}
            style={{ marginBottom: "10px" }}
          >
            <Grid item md={6} sm={6} xs={12}></Grid>
            <Grid item md={6} sm={6} xs={12}>
              <div>
                {pageFineshedCount > 0 && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={20}
                    totalItemsCount={pageFineshedCount}
                    pageRangeDisplayed={5}
                    onChange={handleGetFineshedMatchesPageClick}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <ReactTable
            resetFilter={resetFilter}
            activePage={activePage}
            count={pageFineshedCount}
            rows={baselineAssessment}
            filterFunction={(row, value) => {
              handleSorting(row, value);
            }}
            columns={[
              {
                name: "Full Name",
                row: "name",
                filterList: [
                  { key: "", value: "None" },
                  { key: "ASE", value: "Sort A to Z" },
                  { key: "DESC", value: "Sort Z to A" },
                ],
              },
              {
                name: "Question 01",
                row: "question_1_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 02",
                row: "question_2_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 03",
                row: "question_3_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 04",
                row: "question_4_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 05",
                row: "question_5_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 06",
                row: "question_6_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 07",
                row: "question_7_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 08",
                row: "question_8_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 09",
                row: "question_9_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 10",
                row: "question_10_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 11",
                row: "question_11_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 12",
                row: "question_12_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 13",
                row: "question_13",
              },
              {
                name: "Question 14",
                row: "question_14",
              },
              {
                name: "Question 15",
                row: "question_15",
              },
              {
                name: "Time Spend",
                row: "total_time",
                customRowComponent: (value) =>
                  commonFunctions.convertSecondsToTime(value),
              },
              {
                name: "Completed Date",
                row: "created_date",
              },
            ]}
          />
          <div className={classes.schoolsBtn}>
            <div className={classes.clearFilterBtn}>
              <button onClick={handleClearFilter}>CLEAR ALL FILTERS</button>
            </div>
            <div className={classes.exportBtn}>
              <Tooltip
                title="This will export only the data that is currently shown above to
            excel. If you want to export all the data in this tab, make sure you
            clear all filters before using the export button."
              >
                <button
                  data-tip
                  data-for="main"
                  onClick={() => {
                    exportBaseline({
                      variables: {
                        data: {
                          orderByName: selectedType,
                          filter_schools: groupId,
                          time_offset: "1",
                          name: debouncedSearch,
                        },
                      },
                    });
                    setLoading(true);
                  }}
                >
                  EXPORT
                </button>
              </Tooltip>
            </div>
          </div>
          <div>
            <div className={classNames(classes.questionExpalin, classes.mt50)}>
              <span class="question_list" id="details_question_1">
                Question 1:
              </span>
              <span>
                True or False: A Roth IRA is an employer-sponsored retirement
                account in which contributions are made with before-tax dollars,
                and your money grows tax-deferred until you make withdrawals in
                retirement.
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>True</li>
                <li className={classes.correct}>False</li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 2:
              </span>
              <span>
                You earned a $5,000 after-tax year-end bonus. Which of the
                following options below would be the best use of that money?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li className={classes.correct}>
                  Pay off $5,000 credit card balance
                </li>
                <li>Invest in a bond paying a 6% coupon every six months</li>
                <li>Repay $5,000 car loan balance</li>
                <li>
                  Invest in an ETF that has returned 8% per year for the past
                  five years
                </li>
              </ul>
              <span class="question_list" id="details_question_3">
                Question 3:{" "}
              </span>
              <span>Which of the following is true about a credit score?</span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>When you check your credit score, you hurt your score</li>
                <li>
                  Having a high income will translate into a good credit score
                </li>
                <li className={classes.correct}>
                  Your history of making debt payments is the most important
                  factor
                </li>
                <li>
                  When you cancel and close out credit cards, you improve your
                  credit score
                </li>
              </ul>
              <span class="question_list" id="details_question_4">
                Question 4:{" "}
              </span>
              <span>
                Mike breaks his hand playing in his weekly basketball league and
                needs surgery. The hand surgery costs $10,000 total.
                <br /> If Mike's health insurance plan has a $5,000 deductible,
                an 80%/20% coinsurance rate, and he has used $500 of his
                deductible so far this year, how much of the new medical bill
                will Mike's insurance company pay for?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>$4,000</li>
                <li className={classes.correct}>$4,400</li>
                <li>$7,600</li>
                <li>$8,000</li>
              </ul>

              <span class="question_list" id="details_question_4">
                Question 5:{" "}
              </span>
              <span>
                Which of the following best describes diversification when it
                comes to making investments?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>
                  You make investments that are riskier, but also have the
                  chance for higher returns in the long term
                </li>
                <li>
                  You make investments focused on preserving your investments
                  and generating more predictable, consistent income
                </li>
                <li className={classes.correct}>
                  You invest in a basket of securities rather than one stock or
                  one bond to lower your risk while gaining exposure to many
                  different investments
                </li>
                <li>
                  invest in a basket of high-risk stocks rather than just one
                  stock to gain exposure to many different high return potential
                  investments
                </li>
              </ul>

              <span class="question_list" id="details_question_4">
                Question 6:{" "}
              </span>
              <span>
                Which of the following is the main reason to use a high-yield
                savings account instead of a traditional savings account?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>
                  When interest rates rise and fall, your savings rate stays the
                  same
                </li>
                <li>
                  High-yield savings accounts don't require minimum balances and
                  don’t charge fees
                </li>
                <li>
                  High-yield savings accounts allow you to make an unlimited
                  number of transactions a month
                </li>
                <li className={classes.correct}>
                  High-yield savings accounts pay more interest than a
                  traditional savings account
                </li>
              </ul>

              <span class="question_list" id="details_question_4">
                Question 7:{" "}
              </span>
              <span>
                The monthly payment for a loan is made up of two parts:
                __________ and interest.
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>Balance</li>
                <li>Capital</li>
                <li className={classes.correct}>Principal Repayment</li>
                <li>Amortization</li>
              </ul>

              <span class="question_list" id="details_question_4">
                Question 8:{" "}
              </span>
              <span>
                Use the following details to calculate Mike's net worth:
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>$128,500</li>
                <li>$99,500</li>
                <li>$138,500</li>
                <li className={classes.correct}>$119,500</li>
              </ul>

              <span class="question_list" id="details_question_4">
                Question 9:{" "}
              </span>
              <span>
                Each year the value of a dollar falls because the prices for
                goods and services tends to rise over time. The rise (or fall)
                in the price of goods and services is called __________.{" "}
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>Consumer product growth</li>
                <li className={classes.correct}>Inflation</li>
                <li>Price of goods growth</li>
                <li>Purchasing power</li>
              </ul>

              <span class="question_list" id="details_question_4">
                Question 10:{" "}
              </span>
              <span>
                What is the tax form that details exactly how much you earned at
                your job in the past year and how much you have already paid in
                taxes?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>1040</li>
                <li>1099</li>
                <li>W-4</li>
                <li className={classes.correct}>W-2</li>
              </ul>

              <span class="question_list" id="details_question_4">
                Question 11:{" "}
              </span>
              <span>What is a mutual fund?</span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li className={classes.correct}>
                  A pool of money given by investors that is actively managed by
                  a fund manager
                </li>
                <li>
                  A basket of financial assets that are not stocks, bonds, or
                  cash
                </li>
                <li>
                  A loan you give to your local municipality with an interest
                  rate and maturity date
                </li>
                <li>
                  A basket of different investments that is traded daily on a
                  stock exchange
                </li>
              </ul>

              <span class="question_list" id="details_question_4">
                Question 12:{" "}
              </span>
              <span>
                True or False: Federal income taxes are calculated by taxing
                your entire income at one rate.{" "}
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>True</li>
                <li className={classes.correct}>False</li>
              </ul>

              <span class="question_list" id="details_question_4">
                Question 13:{" "}
              </span>
              <span>
                How confident are you in managing your personal
                finances—budgeting and saving, making investments, repaying
                debt, and selecting insurance?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              ></ul>

              <span class="question_list" id="details_question_4">
                Question 14:{" "}
              </span>
              <span>
                How confident are you in achieving your financial life goals?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              ></ul>

              <span class="question_list" id="details_question_4">
                Question 15:{" "}
              </span>
              <span>
                How important do you feel personal finance skills and knowledge
                are to your future?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              ></ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* {allPF101Levels ? */}
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                data: {
                  table: "datatable",
                },
                chart: {
                  type: "column",
                  height: 700,
                },
                plotOptions: {
                  column: {
                    stacking: "normal",
                  },
                  series: {
                    cursor: "pointer",
                    point: {
                      events: {
                        click: function () {
                          // goToView(this.series.index+1,this.category);
                        },
                      },
                    },
                  },
                },
                title: {
                  text: "PF101 Baseline Challenge",
                },
                credits: {
                  enabled: false,
                },
                yAxis: {
                  min: 0,
                  tickInterval: 1,
                  max: 10,
                  gridLineWidth: 0,
                  labels: {
                    format: "{value}",
                    style: {
                      fontSize: "14px",
                    },
                  },
                  title: {
                    text: " ",
                  },
                },
                xAxis: {
                  categories: ["Question 13", "Question 14", "Question 15"],
                  labels: {
                    style: {
                      fontSize: "14px",
                    },
                  },
                  title: {
                    text: " ",
                  },
                },
                tooltip: {
                  formatter: function () {
                    return (
                      "<b>" +
                      this.x +
                      "</b><br/>" +
                      this.series.name +
                      ": " +
                      parseFloat(this.y).toFixed(2) +
                      "<br/>"
                    );
                  },
                  fontSize: "14px",
                },
                legend: {
                  itemStyle: {
                    fontSize: "14px",
                  },
                  align: "center",
                },
                series: [
                  {
                    name: "PF101 Basline Challenge",
                    data: [
                      AssessmentGraph["question_13"],
                      AssessmentGraph["question_14"],
                      AssessmentGraph["question_15"],
                    ],
                    color: "#006aaa",
                    legendIndex: 0,
                    borderColor: "#006aaa",
                  },
                ],
              }}
            />
            <div
              className={classNames(
                classes.questionExpalin,
                "full_length_quesions ng-scope"
              )}
            >
              <span id="details_question_1">Question 13: </span>
              <span>
                How confident are you in managing your personal
                finances—budgeting and saving, making investments, repaying
                debt, and selecting insurance?
              </span>
              <ul className={classNames(classes.awarenessOptionsFull)}> </ul>
              <span class="question_list" id="details_question_2">
                Question 14:{" "}
              </span>
              <span>
                How confident are you in achieving your financial life goals?
              </span>
              <ul className={classNames(classes.awarenessOptionsFull)}> </ul>
              <span class="question_list" id="details_question_3">
                Question 15:{" "}
              </span>
              <span>
                How important do you feel personal finance skills and knowledge
                are to your future?
              </span>
              <ul className={classNames(classes.awarenessOptionsFull)}> </ul>
            </div>
          </div>
          {/* : <div>No data display graph</div>         
                    } */}
        </TabPanel>
      </div>
    </div>
  );
};

PF101BaslineAssessment.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);

export default enhance(PF101BaslineAssessment);
