import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  ThemeProvider,
  createTheme,
  styled,
  useTheme,
} from "@mui/material/styles";
import { useHistory, withRouter } from "react-router-dom";

import { ACCESS_TOKEN } from "../../utilities/userUtils";
import AppBar from "../ui/header/header";
import BusinessSidBar from "../ui/sidbar/business-sidbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import Footer from "../ui/footer/footer";
import { Grid } from "@material-ui/core";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MuiAppBar from "@mui/material/AppBar";
import PropTypes from "prop-types";
import SchoolSidebar from "../ui/sidbar/school-sidebar";
import SidBar from "../ui/sidbar/sidbar";
import { compose } from "recompose";
import { isEmpty } from "lodash";
import styles from "./styles";
import { useUserDetailsContext } from "../../context/userDetails";

const drawerWidth = 275;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  maxHeight: "64px",
  alignItems: "center",
  padding: theme.spacing(2, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const LayoutWithSidebar = (props) => {
  const { history } = useHistory();

  const [sideBar, setSideBar] = useState(null);
  const { state } = useUserDetailsContext();
  const [schoolType, setSchoolType] = useState(null);
  const [openSidebar, setOpenSidebar] = React.useState(true);

  // const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useLayoutEffect(() => {
    if (
      isEmpty(localStorage.getItem("institution")) ||
      isEmpty(localStorage.getItem(ACCESS_TOKEN))
    ) {
      props.history.push("/");
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(localStorage.getItem("institution"))) {
      if (state?.institution?.school_type.toLowerCase() === "business") {
        setSideBar("business_admin");
      } else if (state?.institution?.school_type.toLowerCase() === "school") {
        setSideBar("school_admin");
      } else if (state?.institution?.school_type.toLowerCase() === "inkwiry") {
        setSideBar("main_admin");
      }
    }
  }, [state?.institution?.school_type]);

  useEffect(() => {
    if (!isEmpty(state)) {
      setSchoolType(state?.school_type);
    }
  }, [state]);

  const sidebarOperations = () => {
    setOpenSidebar(!openSidebar);
  };

  const {
    classes,
    component: Component,
    loggedInUser,
    name,
    searchBar,
  } = props;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        loggedInUser={loggedInUser}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        name={name}
        searchBar={searchBar}
      />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor:
              JSON.parse(localStorage.getItem("institution_details")) !==
                null &&
              JSON.parse(localStorage.getItem("institution_details"))
                ?.logo_link !== null
                ? "#fff"
                : "#036",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img
            style={{
              width: "auto",
              height: "100%",
            }}
            src={
              JSON.parse(localStorage.getItem("institution_details")) !==
                null &&
              JSON.parse(localStorage.getItem("institution_details"))
                ?.logo_link !== null
                ? JSON.parse(localStorage.getItem("institution_details"))
                    ?.logo_link
                : "/img/logo-white.png"
            }
            alt="logo"
          />
          <Tooltip title="Hide menu" arrow>
            <IconButton
              color={
                JSON.parse(localStorage.getItem("institution_details")) !==
                  null &&
                JSON.parse(localStorage.getItem("institution_details"))
                  ?.logo_link !== null
                  ? "default"
                  : "neutral"
              }
              sx={{ marginRight: -1.5 }}
              onClick={handleDrawerClose}
            >
              <MenuOpenIcon
                color={
                  JSON.parse(localStorage.getItem("institution_details")) !==
                    null &&
                  JSON.parse(localStorage.getItem("institution_details"))
                    ?.logo_link !== null
                    ? "default"
                    : "neutral"
                }
              />
            </IconButton>
          </Tooltip>
        </DrawerHeader>
        {sideBar === "main_admin" ? (
          <SidBar setSideBar={setSideBar} {...props} />
        ) : sideBar === "school_admin" ? (
          <SchoolSidebar setSideBar={setSideBar} {...props} />
        ) : (
          sideBar === "business_admin" && (
            <BusinessSidBar setSideBar={setSideBar} {...props} />
          )
        )}
      </Drawer>
      <Main open={open} sx={{ overflow: "hidden" }}>
        <DrawerHeader />
        <Component />
      </Main>
    </Box>
  );
};

LayoutWithSidebar.defaultProps = {
  loggedInUser: null,
};

LayoutWithSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
};

export default compose(withStyles(styles), withRouter)(LayoutWithSidebar);
