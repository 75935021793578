const styles = () => ({
	clicksCount:{
		textAlign: 'center',
		// paddingTop: '75px',
		'& h3':{
			fontSize: '20px',
			fontFamily: 'MuseoSans-700',
    	fontWeight: 'normal',
			margin: '0px',
		}
	},
	comingSoonHours:{
		paddingTop: '30px',
		'& span':{
			width: '25px',
			background: '#2c2c2c',
			color: '#fff',
			padding: '10px',
			borderRadius: '4px',
			fontSize: '18px',
			margin: '2px',
		}
	},
	MemberGrapgh:{
		'& img':{
			width: '100%',
		}
	},
	bdRight:{
		borderRight: '1px solid #666',
	},
	colRowMiddle:{
		borderTop: '1px solid #666',
	},
	noDataFound:{
		fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
	}
});
export default styles;