import * as commonFunctions from "../../../utilities/commonFunctions";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import _, { isEmpty, size } from "lodash";
import { useTheme, withStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import GroupFilter from "../../../components/ui/GroupFilter/GroupFilter";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import IsLoadingHOC from "../../../Hoc/IsLoadingHOC";
import NumberFormat from "react-number-format";
import Pageloader from "../../../components/ui/pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import scrollToComponent from "react-scroll-to-component";
import styles from "../styles";
import { useLazyQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

const GET_PF101_LEVEL_REPORTS = loader(
  "../../../graphql/schema/pf101/levelReports/getPf101LevelGraph.graphql"
);
const ALL_PF101_LEVEL_REPORTS = loader(
  "../../../graphql/schema/pf101/levelReports/getAllPf101LevelGraph.graphql"
);
const GET_SCHOOLS_GROUP_NAMES = loader(
  "../../../graphql/schema/pf101/get-schools-groups-names.graphql"
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  if (index !== 3)
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const PF101LevelReports = (props) => {
  const { classes, setLoading } = props;
  const [groupNames, setGroupNames] = React.useState([]);

  const [value, setValue] = useState(0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [allPF101Levels, setAllPF101Levels] = React.useState({});
  const [offset] = React.useState(0);
  const [groupId, setGroup] = useState([]);

  const [getSchoolGroupNames] = useLazyQuery(GET_SCHOOLS_GROUP_NAMES, {
    fetchPolicy: "network-only",
    onCompleted({ getSchoolsGroupNames }) {
      setLoading(false);
      setGroupNames(getSchoolsGroupNames);
    },
    onError() {
      setLoading(false);
    },
  });
  const [getPf101LevelGraph] = useLazyQuery(GET_PF101_LEVEL_REPORTS, {
    fetchPolicy: "network-only",
    variables: {
      data: {
        level_type: value ? value : "all_levels",
        groups: groupId,
      },
    },
    onCompleted({ getPf101LevelGraph }) {
      if (
        isEmpty(getPf101LevelGraph?.for_one_level) &&
        size(getPf101LevelGraph?.for_one_level) === 0
      ) {
        setAllPF101Levels(getPf101LevelGraph?.for_all_level);
      } else {
        setAllPF101Levels(getPf101LevelGraph?.for_one_level);
      }
      setLoading(false);
    },
    onError(error) {
      console.log(error);
    },
  });

  useEffect(() => {
    getPf101LevelGraph();
    getSchoolGroupNames();
    setLoading(false);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (Number(newValue) !== 0) {
      setLoading(true);
      setAllPF101Levels([]);
      getPf101LevelGraph({
        variables: {
          data: {
            level_type: `${newValue}`,
            groups: groupId,
          },
        },
      });
    } else {
      setLoading(true);
      setAllPF101Levels([]);
      getPf101LevelGraph({
        variables: {
          data: {
            level_type: `all_levels`,
            groups: groupId,
          },
        },
      });
    }
  };

  return (
    <div>
      {groupNames && groupNames.length > 0 && (
        <div>
          <GroupFilter
            data={groupNames}
            getFilterValue={(val) => {
              setGroup(val);
              getPf101LevelGraph({
                variables: {
                  data: {
                    level_type: value !== 0 ? value : "all_levels",
                    groups: val,
                  },
                },
              });
            }}
          />
        </div>
      )}
      <div className={classNames(classes.dashboardPage, "dashboard-page")}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.dashboardTabs}
        >
          <Tab label="All Levels" {...a11yProps(0)} />
          <Tab label="Level 1" {...a11yProps(1)} />
          <Tab label="Level 2" {...a11yProps(2)} />
          <Tab label="Level 3" {...a11yProps(3)} className={classes.tab3} />
          <Tab label="Level 4" {...a11yProps(4)} />
          <Tab label="Level 5" {...a11yProps(5)} />
          <Tab label="Level 6" {...a11yProps(6)} />
          <Tab label="Level 7" {...a11yProps(7)} />
          <Tab label="Level 8" {...a11yProps(8)} />
          <Tab label="Level 9" {...a11yProps(9)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          {allPF101Levels ? (
            <div>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                  },

                  title: {
                    text: "All Levels",
                    align: "left",
                  },

                  xAxis: {
                    categories: allPF101Levels.question,
                  },

                  yAxis: [
                    {
                      // Tertiary yAxis

                      min: 0,
                      tickInterval: 1,
                      max: 4,
                      title: {
                        text: "Average Attempts",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      labels: {
                        formatter: function () {
                          return parseFloat(this.value).toFixed(1);
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                    {
                      // Tertiary yAxis
                      opposite: true,
                      min: 0,
                      tickInterval: 1,
                      // max: 4,
                      title: {
                        text: "Average Time",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      labels: {
                        formatter: function () {
                          return this.value + " Minutes";
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                  ],
                  tooltip: {
                    formatter: function () {
                      return (
                        "<b> " +
                        this.x +
                        "</b><br/>" +
                        this.series.name +
                        ": " +
                        parseFloat(this.y).toFixed(1)
                      );
                    },
                  },
                  plotOptions: {
                    series: {
                      cursor: "pointer",
                      point: {
                        events: {
                          click: function () {
                            const id = this.category.split(" ");
                            const level = parseInt(id[1]);
                            if (level !== 3) {
                              setLoading(true);
                              handleChange("", level);
                            }
                          },
                        },
                      },
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  series: [
                    {
                      type: "column",
                      name: "Average Number of Attempts per Question",
                      data: allPF101Levels?.level_attempts,
                      yAxis: 0,
                      color: "#6982ce",
                    },
                    {
                      name: "Average Number of Attempts for all Levels",
                      data: allPF101Levels?.total_level_attempts,
                      yAxis: 0,
                      type: "line",
                      color: "#333333",
                      marker: {
                        enabled: false,
                      },
                    },
                    {
                      type: "column",
                      yAxis: 1,
                      name: "Average Time Spent on 1 Activity",
                      data: allPF101Levels?.time_details,
                      color: "orange",
                    },
                    {
                      type: "line",
                      yAxis: 1,
                      name: "Average Time Spent on 1 Activity for all Levels",
                      data: allPF101Levels?.total_time_details,
                      color: "gold",
                      marker: {
                        enabled: false,
                      },
                    },
                  ],
                }}
              />
            </div>
          ) : (
            <div className={classes.noData}>No data display graph</div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {allPF101Levels[0] ? (
            <div>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                  },

                  title: {
                    text: "What is Personal Finance?: Average Attempts",
                    align: "left",
                  },

                  xAxis: {
                    categories: allPF101Levels[0].question,
                  },

                  yAxis: [
                    {
                      // Tertiary yAxis

                      min: 0,
                      tickInterval: 1,
                      max: 4,
                      title: {
                        text: "Average Attempts",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      labels: {
                        formatter: function () {
                          return parseFloat(this.value).toFixed(1);
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                  ],
                  tooltip: {
                    formatter: function () {
                      return (
                        "<b> " +
                        this.x +
                        "</b><br/>" +
                        this.series.name +
                        ": " +
                        parseFloat(this.y).toFixed(1)
                      );
                    },
                  },
                  plotOptions: {
                    series: {
                      cursor: "pointer",
                      point: {
                        events: {
                          click: function () {
                            var id = this.category.split(" ");
                            var level = parseInt(id[1]);
                            scrollToComponent(
                              document.getElementById(
                                `details_question_${level}`
                              ),
                              { offset: offset, align: "top", duration: 500 }
                            );
                          },
                        },
                      },
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  series: [
                    {
                      name: "Average Attempts",
                      data: allPF101Levels[0].attempts,
                      yAxis: 0,
                      color: "#6982ce",
                    },
                    {
                      name: "Average Number of Attempts for all Questions",
                      data: allPF101Levels[0].total_attempts,
                      yAxis: 0,
                      type: "line",
                      color: "orange",
                      marker: {
                        enabled: false,
                      },
                    },
                  ],
                }}
              />
              <div className={classes.questionExpalin}>
                <span id="details_question_1">Question 1: </span>
                <span>Assets - Liabilities = ?</span>
                <ul className={classes.awarenessOptions}>
                  <li className={classNames(classes.correct)}>Net Worth</li>
                  <li>Net Income</li>
                  <li>Adjusted Gross Income</li>
                  <li>Net Change in Cash</li>
                </ul>
                <span id="details_question_2">Question 2: </span>
                <span>Which of the follow is an asset?</span>
                <ul className={classes.awarenessOptions}>
                  <li>Student loans</li>
                  <li>Car loans</li>
                  <li>Mortgages</li>
                  <li className={classNames(classes.correct)}>
                    401(k) Retirement Account
                  </li>
                </ul>
                <span id="details_question_3">Question 3: </span>
                <span>Which of the follow is a liability?</span>
                <ul className={classes.awarenessOptions}>
                  <li>A car</li>
                  <li>A house</li>
                  <li>An investment</li>
                  <li className={classNames(classes.correct)}>A credit card</li>
                </ul>
                <span id="details_question_4">Question 4: </span>
                <span>
                  Use the following details to calculate Mike's net worth:
                </span>
                <ul className={classes.awarenessOptions}>
                  <li className={classes.correct}>$9,500</li>
                  <li>$19,500</li>
                  <li>$4,500</li>
                  <li>$25,500</li>
                </ul>
              </div>
            </div>
          ) : (
            <div className={classes.noData}>No data display graph</div>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className={classNames(classes.chart_0)}>
            {allPF101Levels[0] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "3 Types of Income: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[0].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_2_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[0].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[0].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_2_1">Question 1: </span>
                  <span>
                    Is the following an example of earned, portfolio, or passive
                    income?{" "}
                  </span>
                  <p className={classes.questionPara}>
                    Mike bought stock in Microsoft six months ago for $106.89.
                    He just recently sold that stock for $137.00 earning $30.11
                    in income.{" "}
                  </p>
                  <ul className={classes.awarenessOptions}>
                    <li>Earned Income</li>
                    <li className={classes.correct}>Portfolio Income</li>
                    <li>Passive Income</li>
                  </ul>
                  <span id="details_question_2_2">Question 2: </span>
                  <span>
                    Is the following an example of earned, portfolio, or passive
                    income?
                  </span>
                  <p className={classes.questionPara}>
                    Mary owns a townhome. She receives $2,000 in rental income
                    each month from her tenants.{" "}
                  </p>
                  <ul className={classes.awarenessOptions}>
                    <li>Earned Income</li>
                    <li>Portfolio Income</li>
                    <li className={classes.correct}>Passive Income</li>
                  </ul>
                  <span id="details_question_2_3">Question 3: </span>
                  <span>
                    Is the following an example of earned, portfolio, or passive
                    income?
                  </span>
                  <p className={classes.questionPara}>
                    John earns $70,000 a year in salary as a financial analyst.
                    He works roughly 2,000 hours a year.{" "}
                  </p>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Earned Income</li>
                    <li>Portfolio Income</li>
                    <li>Passive Income </li>
                  </ul>
                  <span id="details_question_2_4">Question 4: </span>
                  <span>
                    Which type of income is typically taxed at the highest rate?
                    This type of income is taxed at about 30% as you can see by
                    the graphic below. That means for every dollar you earn,
                    $0.30 goes to taxes.
                  </span>
                  <p className={classes.questionPara}>
                    An Average Wage Worker in the U.S. Faced a Tax{" "}
                  </p>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Earned Income</li>
                    <li>Portfolio Income</li>
                    <li>Passive Income </li>
                  </ul>
                  <span id="details_question_2_5">Question 5: </span>
                  <span>
                    Which type of income is typically taxed at the lowest rate?
                    This type of income is usually taxed at a rate of 0-15%.{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Earned Income </li>
                    <li>Portfolio Income </li>
                    <li className={classes.correct}>Passive Income </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className={classNames(classes.chart_1)}>
            {allPF101Levels[1] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Personal Income Taxes 101: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[1].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_3_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[1].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[1].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_3_1">Question 1: </span>
                  <span>
                    Which of the following is the largest source of revenue for
                    the federal government?{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Personal Income Taxes</li>
                    <li>Corporate Taxes </li>
                    <li>Sales Taxes</li>
                    <li>Property Taxes</li>
                  </ul>
                  <span id="details_question_3_2">Question 2: </span>
                  <span>
                    What percentage of the federal government's budget goes
                    towards defense, Social Security, and Medicare / Medicaid?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>50%</li>
                    <li className={classes.correct}>65%</li>
                    <li>24%</li>
                    <li>26%</li>
                  </ul>
                  <span id="details_question_3_3">Question 3: </span>
                  <span>
                    Specific expenses that reduce your total income to arrive at
                    Adjusted Gross Income (AGI) are called _______
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>State and local income taxes</li>
                    <li className={classes.correct}>Adjustments</li>
                    <li>Deductions</li>
                    <li>Tax Credits</li>
                  </ul>
                  <span id="details_question_3_4">Question 4: </span>
                  <span>
                    A portion of your income that is not subject to federal
                    income taxes is called _________.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>State and local income taxes</li>
                    <li>Adjustments</li>
                    <li className={classes.correct}>Deductions</li>
                    <li>Tax Credits</li>
                  </ul>
                  <span id="details_question_3_5">Question 5: </span>
                  <span>
                    Use the details in the table below to calculate Mike's total
                    income taxes:{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>$13,976</li>
                    <li className={classes.correct}>$13,886</li>
                    <li>$13,337</li>
                    <li>$13,000</li>
                  </ul>
                  <span id="details_question_3_6">Question 6: </span>
                  <span>
                    When you are self-employed, your Social Security and
                    Medicare taxes change. How do they change?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>
                      You still have to pay Medicare, but not Social Security
                      taxes{" "}
                    </li>
                    <li>
                      You still have to pay Social Security, but not Medicare
                      taxes
                    </li>
                    <li>They are cut in half from 7.65% to 3.825%</li>
                    <li className={classes.correct}>
                      They double from 7.65% to 15.30%
                    </li>
                  </ul>
                  <span id="details_question_3_7">Question 7: </span>
                  <span>
                    <b>True or False:</b> All US states and cities charge the
                    same income taxes.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>True</li>
                    <li className={classes.correct}>False</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className={classNames(classes.chart_2)}>
            {allPF101Levels[2] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Federal Income Taxes: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[2].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_4_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[2].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[2].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_4_1">Question 1: </span>
                  <span>
                    <b>True or False:</b> Federal income taxes are calculated by
                    taxing your entire income at one rate.{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>True</li>
                    <li className={classes.correct}>False</li>
                  </ul>
                  <span id="details_question_4_2">Question 2: </span>
                  <span>
                    Federal income taxes are calculated using ________ tax
                    rates, which means that as your income increases, you are
                    taxed at higher rates.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Proportional</li>
                    <li>Social</li>
                    <li>Regressive</li>
                    <li className={classes.correct}>Progressive</li>
                  </ul>
                  <span id="details_question_4_3">Question 3: </span>
                  <span>
                    The standard deduction is a portion of your income that is
                    not subject to federal income taxes. In 2019, the standard
                    deduction for a single taxpayer is __________.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>$10,200</li>
                    <li className={classes.correct}>$12,200</li>
                    <li>$20,400 </li>
                    <li>$24,400 </li>
                  </ul>
                  <span id="details_question_4_4">Question 4: </span>
                  <span>
                    What is Mike's Federal Income Tax Rate? To calculate Mike's
                    federal income taxes, solve for the question marks.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>12.55%</li>
                    <li>33.56%</li>
                    <li>22.00%</li>
                    <li>24.00%</li>
                  </ul>
                  <span id="details_question_4_5">Question 5: </span>
                  <span>
                    Solve for Joan's total federal income taxes. To calculate
                    Joan's federal income taxes, solve for the question marks.{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>$10,060.00</li>
                    <li>$12,072.00</li>
                    <li>$12,500.00</li>
                    <li className={classes.correct}>$13,849.00</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className={classNames(classes.chart_3)}>
            {allPF101Levels[3] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Filling Your Taxes: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[3].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_5_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[3].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[3].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_5_1">Question 1: </span>
                  <span>What day is Tax Day in 2020? </span>
                  <ul className={classes.awarenessOptions}>
                    <li>4/13/20</li>
                    <li className={classes.correct}>4/15/20 </li>
                    <li>4/17/20</li>
                    <li>4/21/20</li>
                  </ul>
                  <span id="details_question_5_2">Question 2: </span>
                  <span>Step 1 to filing your taxes is ________.</span>
                  <ul className={classes.awarenessOptions}>
                    <li>
                      Decide if you're going to use online software or a tax
                      professional{" "}
                    </li>
                    <li className={classes.correct}>Gather tax information </li>
                    <li>File before tax day </li>
                  </ul>
                  <span id="details_question_5_3">Question 3: </span>
                  <span>Step 2 to filing your taxes is ________.</span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>
                      Decide if you're going to use online software or a tax
                      professional
                    </li>
                    <li>Gather tax information</li>
                    <li>File before tax day</li>
                  </ul>
                  <span id="details_question_5_4">Question 4: </span>
                  <span>Most Americans ________ to do their taxes.</span>
                  <ul className={classes.awarenessOptions}>
                    <li>Call the IRS</li>
                    <li>Adjustments</li>
                    <li className={classes.correct}>Hire a tax professional</li>
                    <li>Use online tax software</li>
                    <li>Print out and complete IRS tax forms </li>
                  </ul>
                  <span id="details_question_5_5">Question 5: </span>
                  <span>
                    Which of the following is a piece of information you'll need
                    to file your taxes?{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>W-2 form</li>
                    <li>1099 forms</li>
                    <li>Social Security Number</li>
                    <li className={classes.correct}>All of the above</li>
                  </ul>
                  <span id="details_question_5_6">Question 6: </span>
                  <span>
                    A form that details exactly how much you earned in the past
                    year and how much you have already paid in taxes. Each job
                    is required to supply you with this form no later than
                    January 31st so you can file your taxes on time.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>1099 form</li>
                    <li className={classes.correct}>W-2 form</li>
                    <li>W-4 form</li>
                    <li>1040 form</li>
                  </ul>
                  <span id="details_question_5_7">Question 7: </span>
                  <span>
                    The comprehensive income tax form that is divided into
                    sections and details every number in your income taxes
                    calculations. This form will be a great starting point for
                    filing this year's taxes.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>1099 form</li>
                    <li>W-2 form</li>
                    <li>W-4 form</li>
                    <li className={classes.correct}>1040 form</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className={classes.chart_4}>
            {allPF101Levels[0] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Checking v Savings Accounts: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[0].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_6_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[0].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[0].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_6_1">Question 1: </span>
                  <span>
                    Which of the following is the main reason to use a
                    high-yield savings account instead of a traditional savings
                    account?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>
                      High-yield savings accounts don't require minimum balances{" "}
                    </li>
                    <li className={classes.correct}>
                      High-yield savings accounts pay up to 24x more interest to
                      you than a traditional savings account{" "}
                    </li>
                    <li>
                      High-yield savings accounts allow you to make an unlimited
                      number of transactions a month{" "}
                    </li>
                    <li>High-yield savings accounts never charge fees </li>
                  </ul>
                  <span id="details_question_6_2">Question 2: </span>
                  <span>
                    Which account do you need to write personal checks, withdraw
                    cash from an ATM, and pay bills and other day-to-day
                    expenses?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Checking Account</li>
                    <li>Traditional Savings Account</li>
                    <li>High-Yield Savings Account</li>
                  </ul>
                  <span id="details_question_6_3">Question 3: </span>
                  <span>
                    {" "}
                    Which account should you use if you are trying to store and
                    grow money for later use? For example, you want to put away
                    $500 a month and earn interest on that money for a down
                    payment on a future new car.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Checking Account</li>
                    <li>Traditional Savings Account</li>
                    <li className={classes.correct}>
                      High-Yield Savings Account
                    </li>
                  </ul>
                  <span id="details_question_6_4">Question 4: </span>
                  <span>
                    Mary forgot to transfer money from her savings account and
                    only has $500 in her checking account. She uses her debit
                    card to make her $600 rent payment. What fee is she going to
                    have to pay because she spent more than she has in her
                    checking account?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Annual fee</li>
                    <li className={classes.correct}>Overdraft fee</li>
                    <li>Maintainance fee</li> <li>Debt fee </li>
                  </ul>
                  <span id="details_question_6_5">Question 5: </span>
                  <span>
                    A fee that is charged for not maintaining a minimum balance
                    in your account is called _______.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Annual fee</li>
                    <li>Overdraft fee</li>
                    <li className={classes.correct}>Maintainance fee</li>
                    <li>Debt fee</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className={classes.chart_5}>
            {allPF101Levels[1] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Debit v Credit Cards: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[1].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_7_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[1].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[1].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_7_1">Question 1: </span>
                  <span>
                    When you make a purchase with your ________, you pull money
                    directly from your checking account. When you make a
                    purchase with a ________, you pay with borrowed money that
                    you agree to pay back later.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>
                      debit card; credit card{" "}
                    </li>
                    <li>credit card; debit card </li>
                    <li>debit card; gift card </li>
                    <li>High-yield savings accounts never charge fees </li>
                  </ul>
                  <span id="details_question_7_2">Question 2: </span>
                  <span>
                    Mary's going to a restaurant that only accepts cash for
                    payment. Which card should she use to withdraw money from an
                    ATM?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Debit card </li>
                    <li>Credit card </li>
                  </ul>
                  <span id="details_question_7_3">Question 3: </span>
                  <span>
                    Mary buys a new camera on her credit card for $500. When her
                    payment comes due, she forgets to pay off her credit card
                    balance. What happens next?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>
                      Mary can repay her balance with no penalty since she
                      honestly just forgot{" "}
                    </li>
                    <li>
                      Mary can repay her balance within 30 days after the due
                      date with no penalty{" "}
                    </li>
                    <li className={classes.correct}>
                      Mary will be charged a late fee and interest will
                      immediately start accruing to her balance{" "}
                    </li>
                    <li>
                      Mary can cancel the credit card and ignore her credit card
                      company with no consequences{" "}
                    </li>
                  </ul>
                  <span id="details_question_7_4">Question 5: </span>
                  <span>
                    Which of the following is a positive reason to use a credit
                    card instead of debit cards and cash?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>
                      Missing credit card payments can lead to high interest
                      charges{" "}
                    </li>
                    <li>Credit cards sometimes charge annual fees </li>
                    <li className={classes.correct}>
                      Credit cards provide fraud protection that debit cards and
                      cash do not provide{" "}
                    </li>
                    <li>
                      Credit cards are very convenient and can lead to
                      overspending{" "}
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className={classes.chart_6}>
            {allPF101Levels[2] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "7 Tips to Avoid Fees and Bank Responsibly: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[2].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_8_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[2].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[2].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_8_1">Question 1: </span>
                  <span>
                    What is the most expensive debt? In other words, which debt
                    has the highest interest rates?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Student Loans</li>
                    <li>Mortgages</li>
                    <li>Car Loans</li>
                    <li className={classes.correct}>Credit Card</li>
                  </ul>
                  <span id="details_question_8_2">Question 2: </span>
                  <span>
                    What is the fundamental rule of personal finance that will
                    help you create wealth?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Avoid banking fees at all costs</li>
                    <li className={classes.correct}>
                      Spend less than you earn
                    </li>
                    <li>Build a budget</li>
                    <li>Pay your credit card on time </li>
                  </ul>
                  <span id="details_question_8_3">Question 3: </span>
                  <span>How do you stay out of credit card debt?</span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>Always know how much money you have in the bank</li>
                    <li>Repay your credit card balance in full each month</li>
                    <li>
                      Never spend money on your credit card that you don't
                      already have in the bank
                    </li>
                    <li className={classes.correct}>All of the above</li>
                  </ul>
                  <span id="details_question_8_4">Question 4: </span>
                  <span>
                    Pay yourself and your debts first. What's a good savings
                    target as a percentage of your after-tax income?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>3-5%</li>
                    <li>5-8%</li>
                    <li className={classes.correct}>11-14%</li>
                    <li>15-20%</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
        </TabPanel>

        <TabPanel value={value} index={5}>
          <div className="chart_7">
            {allPF101Levels[0] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Online Banking: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[0].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_9_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[0].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[0].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_9_1">Question 1: </span>
                  <span>
                    ________ is a common feature of online banking that Mike
                    could use to pay all his bills � including making payments
                    by check � in one place and simplify his financial life.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Bank transfer</li>
                    <li className={classes.correct}>Online bill pay</li>
                    <li>Direct deposit</li> <li>Deposit a check</li>
                  </ul>
                  <span id="details_question_9_2">Question 2: </span>
                  <span>
                    Mike just received an end of the year bonus. Instead of
                    going to his bank or his bank's ATM, he can _________ by
                    taking a picture of it on his mobile banking app.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Bank transfer</li>
                    <li>Online bill pay</li>
                    <li>Direct deposit</li>
                    <li className={classes.correct}>Deposit a check </li>
                  </ul>
                  <span class="question_list" id="details_question_9_3">
                    Question 3:{" "}
                  </span>
                  <span>
                    Mike just started a new job. Instead of his employer sending
                    his paychecks in the mail, Mike uses a feature of online
                    banking called ________, which is much safer and faster.
                    This is much safer as it eliminates the chances of somebody
                    stealing your paychecks and getting your information.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Bank transfer</li>
                    <li>Online bill pay </li>
                    <li className={classes.correct}>Direct deposit </li>
                    <li>Deposit a check </li>
                  </ul>
                  <span id="details_question_9_4">Question 4: </span>
                  <span>
                    Mike bought some clothes online using his debit card but
                    returned them a couple days later. The store said he will
                    receive a full refund for his purchase. A few days later,
                    Mike's lying in bed wondering if his refund was processed.
                    What should he do?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>
                      He should call his bank, leave a voicemail with his
                      financial details, and wait for a call back the next
                      business day{" "}
                    </li>
                    <li className={classes.correct}>
                      Mike should go to his bank's website and login to his
                      account to view his account activity because online
                      banking is available 365/24/7{" "}
                    </li>
                    <li>
                      Mike should go to the nearest ATM to check his balance and
                      see if the money is still in his checking account{" "}
                    </li>
                    <li>
                      Mike should call the store, leave a voicemail with his
                      financial details, and wait for a call back the next
                      business day{" "}
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_8">
            {allPF101Levels[1] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Mobile Banking: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[1].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_10_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[1].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[1].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_10_1">Question 1: </span>
                  <span>
                    Mary and her friends went out to dinner. Mary's friend,
                    Jade, paid and all the others including Mary used Venmo to
                    pay Jade back.{" "}
                  </span>
                  <p className={classes.questionPara}>
                    {" "}
                    Venmo is an example of a ________.
                  </p>
                  <ul className={classes.awarenessOptions}>
                    <li>Digital wallet</li>
                    <li>Mobile bank</li>
                    <li className={classes.correct}>
                      Person-to-Person (P2P) payment
                    </li>
                    <li>Cash advance</li>
                  </ul>
                  <span id="details_question_10_2">Question 2: </span>
                  <span>
                    Jade transferred the funds from Venmo to her checking
                    account after dinner last night. She woke up the next
                    morning and used her mobile banking app to review her
                    checking account activity and was surprised when she didn't
                    see the money from Venmo. What happened?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>Venmo transfers usually aren't processed overnight</li>
                    <li>
                      Jade did not pay the extra fee to process her Venmo
                      transfer immediately{" "}
                    </li>
                    <li>
                      P2P payments are fast, but usually take 1-3 business days
                      to process{" "}
                    </li>
                    <li className={classes.correct}>All of the above </li>
                  </ul>
                  <span id="details_question_10_3">Question 3: </span>
                  <span>
                    Why was $40.80 moved instead of $40? All P2P payments are
                    free, right?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>Sales tax was added to her Venmo</li>
                    <li className={classes.correct}>
                      Mary links her credit card to Venmo and Venmo charges 2-3%
                      on credit card transactions
                    </li>
                    <li>
                      There's no explanation for this and Mary should call Venmo
                      immediately{" "}
                    </li>
                    <li>
                      Venmo charges you an $0.80 fee every time you send money
                    </li>
                  </ul>
                  <span id="details_question_10_4">Question 4: </span>
                  <span>
                    The woman in front of you at the coffee shop used a
                    different way to pay for day-to-day expenses. You don't need
                    to have cash, your debit card, or your credit card on you to
                    pay for stuff anymore. With a ________, all you need is your
                    phone.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Digital wallet</li>
                    <li>Mobile bank</li>
                    <li>Person-to-Person (P2P) payment</li>
                    <li>Cash advance</li>
                  </ul>
                  <span id="details_question_10_5">Question 5: </span>
                  <span>
                    Which of the following is an example of a digital wallet?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Apple Pay </li>
                    <li>Google Pay </li>
                    <li>Samsung Pay </li>
                    <li className={classes.correct}>All of the above </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_9">
            {allPF101Levels[2] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Safe Online Banking Tips: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[2].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_11_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[2].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[2].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_11_1">Question 1: </span>
                  <span>
                    Because of your Personal Finance 101 course, you know that
                    this call is an example of ________. Your bank would never
                    call you asking for your complete account details, so you
                    promptly hang up the phone.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Phishing </li>
                    <li>Bank hacking </li> <li>Cold calling </li>
                    <li>Solicitous calling </li>
                  </ul>
                  <span id="details_question_11_2">Question 2: </span>
                  <span>
                    D4uhgwn?99 is a strong password because it has which of the
                    below features. Having a strong password that you change
                    once a quarter is crucial to protecting your personal
                    information.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>At least 10 characters </li>
                    <li>A mix of upper- and lower-case letters </li>
                    <li> Numbers and special characters ($#?) </li>
                    <li className={classes.correct}>All of the above </li>
                  </ul>
                  <span id="details_question_11_3">Question 3: </span>
                  <span>
                    While being organized is great, why is storing passwords on
                    a file a bad idea?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>
                      Storing passwords in a file on your computer is safe, but
                      storing passwords on your phone is not safe
                    </li>
                    <li>It's very easy to lose a file on your computer</li>
                    <li>
                      Other people can see your phone or computer over your
                      shoulder
                    </li>
                    <li className={classes.correct}>
                      If you lose your phone or computer or someone borrows your
                      phone or computer for a moment, you make it very easy for
                      someone to steal your information
                    </li>
                  </ul>
                  <span id="details_question_11_4">Question 4: </span>
                  <span>
                    You sit down in the local library, sign in to the public
                    WiFi from your laptop, and start going through your personal
                    finances. This is not a safe banking practice because:
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>Public WiFi is usually not secure</li>
                    <li>
                      Public WiFi is usually not secure and should not be used
                      when review personal information{" "}
                    </li>
                    <li className={classes.correct}>
                      Public WiFi is always monitored and reviewed by the owner.
                      If you are in the local library, the staff will be
                      watching your internet activity
                    </li>
                    <li>Public WiFi is too slow to handle online banking</li>
                  </ul>
                  <span id="details_question_11_5">Question 5: </span>
                  <span>
                    The best practice to keeping your identity safe is ________
                    at least twice a month. If you see anything unusual, call
                    your bank or financial institution immediately and report
                    the issue.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Changing your passwords</li>
                    <li>Changing your security questions </li>
                    <li className={classes.correct}>
                      Checking each of your financial accounts and credit cards
                      statements
                    </li>
                    <li>Calling an identity theft hotline </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div className="chart_10">
            {allPF101Levels[0] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Credit Score 101: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[0].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_12_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[0].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[0].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_12_1">Question 1: </span>
                  <span>
                    What is the most important factor in your credit score? This
                    factor is weighed at 35% and looks at your past debt
                    repayment, how much you owed, and if you missed any
                    payments.{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Payment history</li>
                    <li>Utilization rate</li>
                    <li>Length of credit history</li>
                    <li>Credit mix</li>
                  </ul>
                  <span id="details_question_12_2">Question 2: </span>
                  <span>
                    A higher credit score usually means ______ interest rates on
                    new debt. A lower credit score usually means ______ interest
                    rates on new debt. The higher the interest rate, the more
                    expensive the debt!
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Higher; lower</li>
                    <li className={classes.correct}>Lower; higher</li>
                  </ul>
                  <span id="details_question_12_3">Question 3: </span>
                  <span>
                    Mary knows that she can request one free credit report each
                    year. Which of the following companies is a major credit
                    reporting agency that Mary could contact for her latest
                    credit report?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Bloomberg</li>
                    <li>Moody's</li>
                    <li className={classes.correct}>Experian</li>
                    <li>Credit Sesame</li>
                  </ul>
                  <span id="details_question_12_4">Question 4: </span>
                  <span>
                    Mary wants to cancel the green card and get a new red card
                    instead. Why would this negatively impact her credit score?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>
                      The green card was opened five years before the blue card.
                      By closing the green card, Mary has shortened her credit
                      history.{" "}
                    </li>
                    <li>
                      Mary's current utilization rate is 25% because she has a
                      $1,000 balance and a $4,000 total limit. If she cancels
                      the green card, her utilization rate jumps to 50% because
                      she has a $1,000 balance, but now a $2,000 total limit.{" "}
                    </li>
                    <li>
                      When Mary applies for new credit, the credit card company
                      will check her credit report. When a lender such as a
                      credit card company or bank checks your report, it
                      negatively impacts your credit score.{" "}
                    </li>
                    <li className={classes.correct}>All of the above </li>
                  </ul>
                  <span id="details_question_12_4">Question 5: </span>
                  <span>
                    {" "}
                    According to Credit Karma, a good credit score is ________.
                    Good credit scores get better interest rates on debt.
                  </span>
                  <p className={classes.questionPara}>
                    Better interest rates -&gt; lower cost of borrowing -&gt;
                    more money in your pocket!
                  </p>
                  <ul className={classes.awarenessOptions}>
                    <li>650 and Higher</li>
                    <li className={classes.correct}>700 and Higher</li>
                    <li>750 and Higher</li>
                    <li>800 and Higher</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_11">
            {allPF101Levels[1] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Debunking Credit Score Myths: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[1].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_13_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[1].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[1].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_13_1">Question 1: </span>
                  <span>
                    <b>True or False:</b> The only way you can have a high
                    credit score is if you have a high income.{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>True</li>
                    <li className={classes.correct}>False</li>
                  </ul>
                  <span id="details_question_13_2">Question 2: </span>
                  <span>
                    <b>True or False:</b> Closing out credit cards is a sign of
                    responsible banking and you will be rewarded with a higher
                    credit score right away.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>True</li>
                    <li className={classes.correct}>False</li>
                  </ul>
                  <span id="details_question_13_3">Question 3: </span>
                  <span>
                    Remember, you never want your utilization rate above 30%.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>11%</li>
                    <li>15%</li>
                    <li>22%</li>
                    <li className={classes.correct}>50%</li>
                  </ul>
                  <span id="details_question_13_4">Question 4: </span>
                  <span>
                    <b>True or False:</b> Every person will have multiple credit
                    scores because banks, financial institutions, and other
                    lenders all use different scoring models when you apply for
                    different types of credit.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>True </li>
                    <li>False </li>
                  </ul>
                  <span id="details_question_13_5">Question 5: </span>
                  <span>
                    Why would Mary have a higher credit score than Mike even
                    though they have the same amount of debt?
                  </span>
                  <p className={classes.questionPara}>
                    Better interest rates -&gt; lower cost of borrowing -&gt;
                    more money in your pocket!
                  </p>
                  <ul className={classes.awarenessOptionsFull}>
                    <li className={classes.correct}>
                      Not all debt is equal. Since Mary has less credit card
                      debt than Mike, her score would most likely be higher.{" "}
                    </li>
                    <li>
                      Mary has a much higher income than Mike, so she is more
                      likely to repay her debt on time.{" "}
                    </li>
                    <li>
                      Credit scores don't take into account credit card debt so
                      Mike and Mary would have similar credit scores.{" "}
                    </li>
                    <li>
                      Using your credit card a lot is a fast way to get better
                      credit. Mike uses all his $20,000 credit limit on his card
                      so his score would actually be higher than Mary's credit
                      score.{" "}
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_12">
            {allPF101Levels[2] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Loans 101: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[2].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_14_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[2].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[2].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_14_1">Question 1: </span>
                  <span>
                    Most people don't have enough cash in the bank to pay for
                    expensive assets such as a car, a home, or attending higher
                    education. A(n) ________ provides the cash needed to
                    purchase that asset.{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Broker</li>
                    <li>Financial advisor </li>
                    <li className={classes.correct}>Loan</li>
                    <li>Investor</li>
                  </ul>
                  <span id="details_question_14_2">Question 2: </span>
                  <span>
                    Actual Cost of Buying an Asset = Total Price Paid + ??
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Total Interest Paid</li>
                    <li>Commission Fees</li>
                    <li>Penalty Fees </li>
                    <li>Renting Fees</li>
                  </ul>
                  <span id="details_question_14_3">Question 3: </span>
                  <span>
                    A(n) _______ is used to help fund the purchase of the home.
                    For example, Mike wants to buy a $500,000 home and has
                    $100,000 in cash. He would apply for a _______ to pay for
                    the other $400,000 needed to buy the home.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Personal loan</li>
                    <li>Student loan</li>
                    <li>Auto loan</li>
                    <li className={classes.correct}>Mortgage</li>
                  </ul>
                  <span id="details_question_14_4">Question 4: </span>
                  <span>
                    A(n) ________ is used to help fund the purchase of a car,
                    truck, or other vehicle. This type of loan usually has the
                    second lowest interest rate.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Personal loan</li>
                    <li>Student loan</li>
                    <li className={classes.correct}>Auto loan</li>
                    <li>Credit Card</li>
                  </ul>
                  <span id="details_question_14_5">Question 5: </span>
                  <span>
                    A(n) ________ is typically the most expensive type of debt
                    with the highest interest rates ranging from 14-30%.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Personal loan</li>
                    <li>Student loan</li>
                    <li>Auto loan</li>
                    <li className={classes.correct}>Credit Card</li>
                  </ul>
                  <span id="details_question_14_6">Question 6: </span>
                  <span>
                    {" "}
                    Use the following details to calculate the monthly interest
                    payment:
                  </span>
                  <p className={classes.questionPara}>
                    Outstanding Loan Amount = $10,000
                    <br />
                    Annual Interest Rate = 5.00%
                  </p>
                  <ul className={classes.awarenessOptions}>
                    <li>$500.00</li>
                    <li>$250.25</li>
                    <li>$85.71</li>
                    <li className={classes.correct}>$41.67</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_13">
            {allPF101Levels[3] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Student Loans: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[3].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_15_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[3].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[3].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_15_1">Question 1: </span>
                  <span>
                    The average college graduate in 2018 had how much in student
                    loans?{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>$15,000 - $20,000</li> <li>$20,001 - $25,000</li>
                    <li className={classes.correct}>$25,001 - $30,000</li>{" "}
                    <li>$30,000 - $35,001</li>
                  </ul>
                  <span id="details_question_15_2">Question 2: </span>
                  <span>
                    The median monthly student loan payment for a 2018 college
                    graduate was how much?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>$125 - $150</li>
                    <li>$151 - $175</li>
                    <li>$176 - $200</li>
                    <li className={classes.correct}>$200 - $225</li>
                  </ul>
                  <span id="details_question_15_3">Question 3: </span>
                  <span>Who's correct in this situation?</span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>Mike and Mary are both wrong. </li>
                    <li>
                      Mary is right. Subsidized loans have a 12-month grace
                      period compared to the six-month grace period for
                      unsubsidized loans.{" "}
                    </li>
                    <li className={classes.correct}>
                      Mike is right. Subsidized loans do not charge any interest
                      until your grace period ends. Unsubsidized loans start
                      charging interest the day you receive your money.{" "}
                    </li>
                    <li>
                      Mike and Mary are both right. Subsidized loans have a
                      different grace period than unsubsidized. Plus, subsidized
                      loans don't accrue interest during school.{" "}
                    </li>
                  </ul>
                  <span id="details_question_15_4">Question 4: </span>
                  <span>
                    The grace period for a student loan is almost always
                    ________ months. The grace period is a period of time after
                    you graduate, leave school, or drop below half-time status
                    before repayment starts.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Three</li>
                    <li className={classes.correct}>Six</li>
                    <li>Nine</li> <li>Twelve</li>
                  </ul>
                  <span id="details_question_15_5">Question 5: </span>
                  <span>
                    The extended repayment plan requires a ________ amount each
                    month for 20 years or 240 months of payments. With this
                    plan, you�ll pay ________ interest than the standard
                    repayment plan, which is 10 years or 120 months of payments.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Larger; more </li>
                    <li>Larger; less </li>
                    <li className={classes.correct}>Smaller; more </li>
                    <li>Smaller; less </li>
                  </ul>
                  <span id="details_question_15_6">Question 6: </span>
                  <span>
                    This is the application you need to complete each year to
                    receive federal student loans.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>IRS</li>
                    <li>1270B</li>
                    <li>1040A</li>
                    <li className={classes.correct}>FAFSA</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_14">
            {allPF101Levels[4] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Loan Repayment: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[4].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_16_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[4].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[4].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_16_1">Question 1: </span>
                  <span>
                    ________ is the process of repaying your loans with fixed
                    payments over a specific period of time.{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Amortization</li>
                    <li>Principal repayment</li>
                    <li>Interest repayment</li>
                    <li>Accruing Interest</li>
                  </ul>
                  <span id="details_question_16_2">Question 2: </span>
                  <span>
                    Assume that Mary can afford both monthly payments. Which
                    loan is the better option?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Loan A</li>
                    <li>Loan B</li>
                  </ul>
                  <span id="details_question_16_3">Question 3: </span>
                  <span>
                    What's the relationship between term of the loan, monthly
                    payment, and total interest paid?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>
                      The longer the term of the loan equals a smaller monthly
                      payment, but you will pay less in total interest
                    </li>
                    <li className={classes.correct}>
                      The longer the term of the loan equals a smaller monthly
                      payment, but you will pay more in total interest
                    </li>
                    <li>
                      The shorter the term of the loan equals a smaller monthly
                      payment, but you will pay more in total interest
                    </li>
                    <li>
                      There is no direct relationship between these variables
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_15">
            {allPF101Levels[5] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "How to Lower the Cost of a Loan: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[5].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_17_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[5].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[5].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_17_1">Question 1: </span>
                  <span>
                    There are two common ways to lower the cost of a loan. The
                    first is to make extra payments each month for that loan.
                    What's the second way you can lower the cost of a loan?{" "}
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li>
                      Take a personal loan instead since they're one of the
                      cheapest types of debt{" "}
                    </li>
                    <li className={classes.correct}>
                      Refinance your loan to a lower interest rate{" "}
                    </li>
                    <li>
                      Make the term of the loan longer so your monthly payments
                      are smaller, which means less interest{" "}
                    </li>
                    <li>
                      There is no other way to lower the cost of the loan{" "}
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <div className="chart_16">
            {allPF101Levels[0] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Investing 101: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[0].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_18_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[0].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[0].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_18_2">Question 2: </span>
                  <span>
                    Understanding risk-to-reward before making any investment is
                    a must. Which one of the following accurately explains the
                    relationship between risk and reward?
                  </span>
                  <ul className={classes.awarenessOptionsFull}>
                    <li className={classes.correct}>
                      Risk{" "}
                      <span className={classes.arrowStatistics}>
                        <img src="/img/up_arrow.png" alt="" />
                      </span>
                      Potential Reward{" "}
                      <span className={classes.arrowStatistics}>
                        <img src="/img/up_arrow.png" alt="" />
                      </span>
                      Likelihood to Achieve Reward
                      <span className={classes.arrowStatistics}>
                        <img src="/img/up_arrow.png" alt="" />
                      </span>
                    </li>
                    <li>
                      Risk{" "}
                      <span className={classes.arrowStatistics}>
                        <img src="/img/up_arrow.png" alt="" />
                      </span>
                      Potential Reward{" "}
                      <span className={classes.arrowStatistics}>
                        <img src="/img/up_arrow.png" alt="" />
                      </span>
                      Likelihood to Achieve Reward
                      <span className={classes.arrowStatistics}>
                        <img src="/img/down_arrow.png" alt="" />
                      </span>
                    </li>
                    <li>
                      Risk{" "}
                      <span className={classes.arrowStatistics}>
                        <img src="/img/up_arrow.png" alt="" />
                      </span>
                      Potential Reward
                      <span className={classes.arrowStatistics}>
                        <img src="/img/down_arrow.png" alt="" />
                      </span>
                      Likelihood to Achieve Reward
                      <span className={classes.arrowStatistics}>
                        <img src="/img/down_arrow.png" alt="" />
                      </span>
                    </li>
                    <li>There is no relationship between risk and reward</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_17">
            {allPF101Levels[1] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Stocks and Bonds: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[1].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_19_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[1].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[1].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_19_4">Question 4: </span>
                  <span>
                    When you buy a stock, you are buying ________ in a company.
                    For example, let's say you bought 10 shares of Microsoft.
                    With 7.635 billion shares of stock outstanding, that 10
                    shares equates to 0.00000013% of the company.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Interest payments</li>
                    <li>A loan</li>
                    <li className={classes.correct}>Ownership</li>
                    <li>None of the above</li>
                  </ul>
                  <span class="question_list" id="details_question_19_5">
                    Question 5:{" "}
                  </span>
                  <span>
                    The higher the interest rate (coupon rate) on a bond,
                    typically that means the bond is _____________. (
                    <i>Hint: think about risk-to-reward</i>)
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Less risky </li>
                    <li>A better investment </li>
                    <li className={classes.correct}>More risky </li>
                    <li>None of the above </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_18">
            {allPF101Levels[2] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Funds and Alternative Investments: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[2].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_20_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[2].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[2].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_20_1">Question 1: </span>
                  <span>
                    "Don't put all your eggs in one basket" is great investing
                    advice. This investing strategy is called ____________ and
                    is used to lower your risk by spreading out your money in
                    different investments.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Diversification</li>
                    <li>Technical investing </li>
                    <li>Alpha investing </li>
                    <li>Fundamental Investing </li>
                  </ul>
                  <span id="details_question_20_2">Question 2: </span>
                  <span>What investment would you suggest to Mary?</span>
                  <ul className={classes.awarenessOptions}>
                    <li>ETF </li>
                    <li className={classes.correct}>Mutual fund</li>
                    <li>Alternative investment</li>
                    <li>401(k)</li>
                  </ul>
                  <span id="details_question_20_3">Question 3: </span>
                  <span>What investment would you suggest to Mike?</span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>ETF</li>
                    <li>Mutual fund</li>
                    <li>Real estate</li>
                    <li>401(k)</li>
                  </ul>
                  <span id="details_question_20_4">Question 4: </span>
                  <span>
                    Gold, crude oil, real estate, hedge funds, and collectible
                    art are all examples of ________________.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>
                      Alternative investments{" "}
                    </li>
                    <li>Managed investments</li>
                    <li>Commodities</li>
                    <li>Funds</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_19">
            {allPF101Levels[3] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Retirement Accounts: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[3].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_21_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[3].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[3].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_21_1">Question 1: </span>
                  <span>
                    The power of planning is real. Retirement may be far away
                    but having a plan now can pay big dividends over time.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>50%</li>
                    <li>115%</li>
                    <li>180%</li>
                    <li className={classes.correct}>250% </li>
                  </ul>
                  <span id="details_question_21_2">Question 2: </span>
                  <span>Which retirement account am I? </span>
                  <ul className={classes.awarenessOptions}>
                    <li>SEP IRA</li>
                    <li className={classes.correct}>401(k) or 403(b)</li>
                    <li>Roth IRA</li>
                    <li>Pension</li>
                  </ul>
                  <span id="details_question_21_3">Question 3: </span>
                  <span>
                    Mary works for a marketing and advertising firm and earns a
                    $70,000 salary. She invests in her retirement by making
                    regular contributions to her company's 401(k) program. Use
                    the following details to calculate Mary's total 401(k)
                    contribution this year.{" "}
                  </span>
                  <p className={classes.questionPara}>
                    {" "}
                    Mary's contribution = 10% of her salary
                    <br /> Employer matching = 100% of Mary's contribution
                    <br /> Employer matching limit = 5% of Mary's salary
                    <br /> Total 401(k) contribution = ??
                  </p>
                  <ul className={classes.awarenessOptions}>
                    <li>$13,500</li>
                    <li>$12,000</li>
                    <li className={classes.correct}>$10,500</li>
                    <li>$9,000</li>
                  </ul>
                  <span id="details_question_21_4">Question 4: </span>
                  <span>Which retirement account am I?</span>
                  <ul className={classes.awarenessOptions}>
                    <li>Traditional IRA</li> <li>401(k) or 403(b)</li>
                    <li className={classes.correct}>Roth IRA</li>
                    <li>Pension</li>
                  </ul>
                  <span id="details_question_21_5">Question 5: </span>
                  <span>Which retirement account am I?</span>
                  <ul className={classes.awarenessOptions}>
                    <li>Traditional IRA</li>
                    <li className={classes.correct}>SEP IRA</li>
                    <li>Roth IRA </li>
                    <li>Pension</li>
                  </ul>
                  <span id="details_question_21_6">Question 6: </span>
                  <span>Which retirement account am I?</span>
                  <ul className={classes.awarenessOptions}>
                    <li>SEP IRA</li>
                    <li>401(k) or 403(b)</li>
                    <li>Roth IRA </li>
                    <li className={classes.correct}>Pension</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className="chart_20">
            {allPF101Levels[4] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "How to Start Investing: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[4].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_22_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[4].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[4].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_22_1">Question 1: </span>
                  <span>
                    Mary plans on investing in a mutual fund that returns 6% per
                    year. How long will it take her investment to double? Use
                    the Rule of 72.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>8 years</li>
                    <li>10 years</li>
                    <li className={classes.correct}>12 years</li>
                    <li>14 years</li>
                  </ul>
                  <span id="details_question_22_2">Question 2: </span>
                  <span>What investment strategy is Mike using? </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Liquidity investing </li>
                    <li className={classes.correct}>Income investing </li>
                    <li>Growth investing </li>
                    <li>Diversification</li>
                  </ul>
                  <span id="details_question_22_3">Question 3: </span>
                  <span>
                    Liquidity is a measure of how easily an asset can be
                    converted into cash. Which of the following assets is most
                    liquid?{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>High-Yield Savings Account</li>
                    <li>Certificates of Deposit</li>
                    <li>Mutual Funds</li>
                    <li className={classes.correct}>ETFs</li>
                  </ul>
                  <span id="details_question_22_4">Question 4: </span>
                  <span>
                    If an investment takes about 9 years to double, that means
                    the investment is earning a ___% return each year.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>6% </li>
                    <li className={classes.correct}>8% </li>
                    <li>10% </li>
                    <li>12% </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={8}>
          <div className={classes.chart_21}>
            {allPF101Levels[0] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "What is Health Insurance?: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[0].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_23_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[0].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[0].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_23_1">Question 1: </span>
                  <span>
                    Typically, if your deductible for health insurance is
                    higher, your monthly premiums will be ________.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Higher</li>
                    <li className={classes.correct}>Lower</li>
                    <li>Double</li>
                    <li>Deductible has no effect on monthly premiums </li>
                  </ul>
                  <span id="details_question_23_2">Question 2: </span>
                  <span>
                    If Mike's health insurance plan has a $3,000 deductible, an
                    100%/0% coinsurance rate, and he hasn't used any of that
                    deductible yet this year, how much of the surgery will
                    Mike's insurance company pay for?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>$15,000</li>
                    <li className={classes.correct}>$12,000</li>
                    <li>$10,000</li>
                    <li>$9,000</li>
                  </ul>
                  <span id="details_question_23_3">Question 3: </span>
                  <span>
                    If Mike's health insurance plan has a $3,000 deductible, an
                    80%/20% coinsurance rate, and he has used all his deductible
                    on the hand surgeries, how much of the new medical bill will
                    Mike's insurance company pay for?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>$800</li>
                    <li>$200</li>
                    <li>$500</li>
                    <li>$0</li>
                  </ul>
                  <span id="details_question_23_4">Question 4: </span>
                  <span>
                    Later in that same year, Mike goes for his routine checkup
                    with his doctor. When he gets to the doctor's office and
                    checks in, they charge him a $25 fee. What's the name of
                    this fee?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Deductible</li>
                    <li className={classes.correct}>Copay</li>
                    <li>Maintenance fee</li>
                    <li>Checkup fee</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className={classes.chart_22}>
            {allPF101Levels[1] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Life Insurance 101: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[1].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_24_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[1].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[1].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_24_1">Question 1: </span>
                  <span>
                    Typically, whole life insurance monthly premiums are
                    ________ term life insurance because whole life provides
                    coverage for life.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Much higher than </li>
                    <li>Much lower than </li>
                    <li>Double</li>
                    <li>None of the above </li>
                  </ul>
                  <span id="details_question_24_2">Question 2: </span>
                  <span>
                    For example, you might buy this type of insurance for a
                    30-year period if you just started having kids and want life
                    insurance until they graduate from higher education.{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Whole life insurance</li>
                    <li className={classes.correct}>Term life insurance</li>
                  </ul>
                  <span id="details_question_24_3">Question 3: </span>
                  <span>
                    If you want a life insurance policy that has a cash value
                    that grows over time and provides coverage for your entire
                    life, which type of insurance would you buy?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>Whole life insurance</li>
                    <li>Term life insurance</li>
                  </ul>
                  <span id="details_question_24_4">Question 4: </span>
                  <span>
                    Death benefit is the fixed amount of money that will be paid
                    to your beneficiaries if you die while your health insurance
                    policy is active. What's a beneficiary?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Your kids </li>
                    <li className={classes.correct}>
                      People you select to receive your death benefit{" "}
                    </li>
                    <li>Your spouse </li>
                    <li>None of the above </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
          <div className={classes.chart_23}>
            {allPF101Levels[2] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "Asset Insurance: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[2].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_25_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[2].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[2].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_25_1">Question 1: </span>
                  <span>
                    If Mike has a $1,000 deductible, how much will Mike's
                    insurance company pay to repair his car?{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>$5,000</li>
                    <li className={classes.correct}>$4,000</li>
                    <li>$3,000</li>
                    <li>$2,000</li>
                  </ul>
                  <span id="details_question_25_2">Question 2: </span>
                  <span>
                    <b>True or False:</b> Auto insurance is not required in
                    every state, but the lender who helps you finance the car
                    will require auto insurance.{" "}
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>True</li>
                    <li className={classes.correct}>False</li>
                  </ul>
                  <span id="details_question_25_3">Question 3: </span>
                  <span>
                    <b>True or False:</b> Homeowner's insurance is not required
                    by law in every state. Your mortgage lender will require
                    homeowner's insurance though.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li className={classes.correct}>True</li>
                    <li>False</li>
                  </ul>
                  <span id="details_question_25_4">Question 4: </span>
                  <span>
                    There are three major categories of homeowner's insurance.
                    Which one of the following is not one of them.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Personal belongings</li>
                    <li>Interior / Exterior Damage</li>
                    <li className={classes.correct}>Accident forgiveness</li>
                    <li>Personal injury </li>
                  </ul>
                  <span id="details_question_25_5">Question 5: </span>
                  <span>
                    There are three major categories of homeowner's insurance.
                    Which one of the following is not one of them.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>$35,000</li>
                    <li>$17,500</li>
                    <li>$3,500</li>
                    <li className={classes.correct}>$1,750</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={9}>
          <div className={classes.chart_24}>
            {allPF101Levels[0] ? (
              <div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },

                    title: {
                      text: "SMART Goals: Average Attempts",
                      align: "left",
                    },

                    xAxis: {
                      categories: allPF101Levels[0].question,
                    },

                    yAxis: [
                      {
                        // Tertiary yAxis

                        min: 0,
                        tickInterval: 1,
                        max: 4,
                        title: {
                          text: "Average Attempts",
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        labels: {
                          formatter: function () {
                            return parseFloat(this.value).toFixed(1);
                          },
                          style: {
                            color: "#000000",
                            fontSize: "14px",
                          },
                        },
                        gridLineWidth: 0,
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          "<b> " +
                          this.x +
                          "</b><br/>" +
                          this.series.name +
                          ": " +
                          parseFloat(this.y).toFixed(1)
                        );
                      },
                    },
                    plotOptions: {
                      series: {
                        cursor: "pointer",
                        point: {
                          events: {
                            click: function () {
                              var id = this.category.split(" ");
                              var level = parseInt(id[1]);
                              scrollToComponent(
                                document.getElementById(
                                  `details_question_26_${level}`
                                ),
                                { offset: offset, align: "top", duration: 500 }
                              );
                            },
                          },
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    series: [
                      {
                        name: "Average Attempts",
                        data: allPF101Levels[0].attempts,
                        yAxis: 0,
                        color: "#6982ce",
                      },
                      {
                        name: "Average Number of Attempts for all Questions",
                        data: allPF101Levels[0].total_attempts,
                        yAxis: 0,
                        type: "line",
                        color: "orange",
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  }}
                />
                <div className={classes.questionExpalin}>
                  <span id="details_question_26_1">Question 1: </span>
                  <span>
                    You are ____% more likely to achieve your goals if you write
                    them down on a regular basis.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>20%</li>
                    <li>22%</li>
                    <li>35%</li>
                    <li className={classes.correct}>42%</li>
                  </ul>
                  <span id="details_question_2">Question 2: </span>
                  <span>
                    The 'S' in a smart goal stands for _________. When creating
                    a goal, you want to write down exactly what you are trying
                    to achieve including as much detail as possible.
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Smart</li>
                    <li className={classes.correct}>Specific</li>
                    <li>Strategic</li>
                    <li>Special</li>
                  </ul>
                  <span id="details_question_3">Question 3: </span>
                  <span>
                    Which one of the below goals would be an example of a SMART
                    goal?
                  </span>
                  <ul className={classes.awarenessOptions}>
                    <li>Save money for the future </li>
                    <li className={classes.correct}>
                      Build an emergency fund of $2,500 over the next 3 months{" "}
                    </li>
                    <li>Invest money in stocks </li>{" "}
                    <li>Don't spend too much money </li>
                  </ul>
                  <span id="details_question_4">Question 4: </span>
                  <span>Why isn't the goal below a SMART goal: </span>
                  <p className={classes.questionPara}>
                    {" "}
                    I'm going to learn how to code Javascript and Python so I
                    can earn a promotion in my career.
                  </p>
                  <ul className={classes.awarenessOptions}>
                    <li>The goal isn't specific </li>
                    <li className={classes.correct}>The goal isn't timely </li>
                    <li>The goal isn't measurable </li>
                    <li>
                      None of the above because this is a great example of a
                      SMART goal{" "}
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className={classes.noData}>No data display graph</div>
            )}
          </div>
        </TabPanel>
      </div>
    </div>
  );
};

PF101LevelReports.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);

export default enhance(PF101LevelReports);
