const styles = () => ({
    sidbarDiv: {
			'& ul':{
				listStyle: 'none',
				background: '#036',
				color: '#fff',
				padding: '15px 18px',
				marginBottom: '15px',
				marginTop: '0px',
				'& li':{
					padding: '11px 5px',
					fontSize: '15px',
					color: '#fff',
					position: 'relative',
					'& a':{
						cursor: 'pointer',
						color: '#fff',
						textDecoration: 'none',
						// display: 'block',
						'&:hover':{
							color: '#ccc',
						},
						'&:focus-visible':{
							outline: 'none',
						},
						'& span':{
							float: 'right',
						}
					}
				}
			}
    },
		dropDownMenu: {
			transform: 'scale(1)',
			MozTransform: 'scale(1)',
			WebkitTransform: 'scale(1)',
			position: 'absolute',
			top: '100%',
			left: '0',
			float: 'left',
			minWidth: '236px',
			// width: '100%',
			padding: '5px 0 !important',
			margin: '2px 0 0',
			fontSize: '14px',
			listStyle: 'none',
			backgroundColor: '#fff !important',
			WebkitBackgroundClip: 'padding-box',
			backgroundClip: 'padding-box',
			border: '1px solid #ccc',
			border: '1px solid rgba(0,0,0,.15)',
			borderRadius: '4px',
			WebkitBoxShadow: '0 6px 12px rgb(0 0 0 / 18%) !important',
			boxShadow: '0 6px 12px rgb(0 0 0 / 18%) !important',
			zIndex: '999',
			'&:before':{
				borderLeft: '9px solid transparent',
				borderRight: '9px solid transparent',
				content: '""',
				height: '0',
				position: 'absolute',
				top: '-10px',
				width: '0',
				borderBottom: '11px solid #fff',
				left: '205px',
			},
			'& li':{
				padding: '10px 5px',
				fontSize: '15px',
				color: '#fff',
				'& a':{
					// color: '#333 !important',
					display: 'block !important',
					padding: '3px 20px',
					lineHeight: '1.42857143',
					whiteSpace: 'nowrap',
					'&:hover':{
						backgroundColor: '#f5f5f5',
					}
				}
			}
		},
		listActive:{
				color: '#fd9840 !important',
		},
		submenuList:{
			color: '#333 !important',
		},
		subMenActive:{
			backgroundColor: '#337ab7',
			color: '#fd9840 !important',
			'&:hover':{
				color: '#333 !important',
				backgroundColor: '#337ab7 !important',
			}
		},
    filterDrop: {
        display: 'block',
        position: 'absolute',
        background: '#fff',
        color: '#000',
        padding: 0,
        left: '19.4%',
        transform: 'translatex(-50%)',
        minWidth: '200px',
        fontFamily: 'museosans-100',
        listStyle: 'none',
        zIndex: 1,
        '&::before': {
            borderLeft: '9px solid transparent',
            borderRight: '9px solid transparent',
            content: '""',
            position: 'absolute',
            width: 0,
            background: 'transparent none repeat scroll 0 0',
            borderBottom: '9px solid #e9e6e6',
            borderTop: '10px solid transparent',
            height: 'auto',
            left: '50%',
            top: '-19px',
            transform: 'translateX(-50%)',
        },
        '& li': {
            padding: '4px 20px',
            fontSize: '14px',
            lineHeight: '1.4',
            cursor: 'pointer',
            color: '#333'
        }
    },
		villanovaFlex:{
			display: 'flex',
		},
		villanovaTxt:{
			width: '98%',
		},
	
});
export default styles;
