import React, { useEffect } from "react";
import { useLazyQuery, withApollo } from "react-apollo";

import GroupFilter from "../ui/GroupFilter/GroupFilter";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import IsLoadingHOC from "../../Hoc/IsLoadingHOC";
import Pageloader from "../../components/ui/pageloader";
import { compose } from "recompose";
import { loader } from "graphql.macro";
import styles from "./styles";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";

const MCS_DASHBOARD = loader("../../graphql/schema/mcs/mcs-dashboard.graphql");
const GET_SCHOOLS_GROUP_NAMES = loader(
  "../../graphql/schema/pf101/get-schools-groups-names.graphql"
);

const McsDashboard = (props) => {
  const { setLoading } = props;
  const [graphData, setGraphData] = React.useState([]);
  const [groupId, setGroup] = React.useState([]);
  const [groupNames, setGroupNames] = React.useState([]);

  const [getSchoolGroupNames] = useLazyQuery(GET_SCHOOLS_GROUP_NAMES, {
    fetchPolicy: "network-only",
    onCompleted({ getSchoolsGroupNames }) {
      setLoading(false);
      setGroupNames(getSchoolsGroupNames);
    },
    onError() {
      setLoading(false);
    },
  });

  const [mcsDashboardDetails] = useLazyQuery(MCS_DASHBOARD, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        groups: groupId,
      },
    },
    onCompleted(response) {
      setGraphData(response.getMcsDashboardDetails);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  React.useEffect(() => {
    mcsDashboardDetails();
    getSchoolGroupNames();
  }, []);

  return (
    <div>
      {groupNames && groupNames.length > 0 && (
        <div>
          <GroupFilter
            data={groupNames}
            getFilterValue={(value) => {
              setGroup(value);
            }}
          />
        </div>
      )}
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "column",
              height: 600,
            },
            plotOptions: {
              column: {
                stacking: "normal",
              },
            },
            series: {
              cursor: "pointer",
              // point: {
              //     events: {
              //         click: function () {
              //             goToView(this.series.index + 1, this.category);
              //         }
              //     }
              // }
            },
            title: {
              text: "Progress Report",
            },
            credits: {
              enabled: false,
            },
            yAxis: {
              min: 0,
              tickInterval: 20,
              max: 100,
              gridLineWidth: 0,
              labels: {
                format: "{value}%",
                style: {
                  fontSize: "14px",
                },
              },
              title: {
                text: " ",
              },
            },
            xAxis: {
              categories: graphData.lessons,
              labels: {
                style: {
                  fontSize: "14px",
                },
              },
              title: {
                text: " ",
              },
            },
            tooltip: {
              formatter: function () {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "%<br/>"
                );
              },
              fontSize: "14px",
            },
            legend: {
              itemStyle: {
                fontSize: "14px",
              },
              align: "center",
            },
            series: [
              {
                dashStyle: "LongDash",
                name: "Not Completed",
                data: graphData.not_completed,
                color: "#FFFFFF",
                stack: "male",
                legendIndex: 1,
                borderColor: "#A6A6A6",
              },
              {
                name: "Completed",
                data: graphData.completed,
                color: "#4472C4",
                stack: "male",
                legendIndex: 0,
                borderColor: "#4472C4",
                dashStyle: "",
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);

export default enhance(McsDashboard);
