import * as commonFunctions from "../../utilities/commonFunctions";

import { Button, Grid } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import Pageloader from "../../components/ui/pageloader";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import { startCase } from "lodash";
import styles from "./styles";
import { useUserDetailsContext } from "../../context/userDetails";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

// const UPDATEUSER = loader('../../../graphql/schema/ui/saveMember.graphql');
const GETMEMBERBY_ID = loader("../../graphql/schema/ui/GetMemberById.graphql");
const UPDATEUSERANDPASSWORD = loader(
  "../../graphql/schema/schools/update-schoolUser-password.graphql"
);
const UPDATEUSER = loader("../../graphql/schema/ui/saveMember.graphql");
const ADDMEMBER = loader(
  "../../graphql/schema/schools/add-school-user.graphql"
);
const STATES = loader("../../graphql/schema/ui/states.graphql");
const CHECK_USER_NAME = loader("../../graphql/schema/ui/CheckUserName.graphql");
const CHECK_EMAIL = loader("../../graphql/schema/ui/checkEmail.graphql");
const INSTITUTIONS = loader("../../graphql/schema/schools/get-schools.graphql");

const EditSchoolUsers = (props) => {
  const { classes, history } = props;
  const {
    state: { school_type = "" },
  } = useUserDetailsContext();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [statesData, setStatesData] = React.useState([]);
  const [emailValue, setEmailValue] = useState("");
  const [passCheck, setPassCheck] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [alphabetsExist, setAlphabetsExist] = useState(false);
  const [numericExist, setNumericExist] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [AccountType, setAccountType] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [userName, setUserName] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [institutionData, setInstitutionData] = useState([]);
  const [school, setSchool] = useState(false);
  const [userId, setUserId] = React.useState("");
  const [displaydata, setDisplayData] = useState("");
  const [successmsg, setSuccessMsg] = useState("");
  const [userSuccessMsg, setUserSuccessMsg] = useState("");
  const [admin_type, setAdmin_type] = useState("");
  const location = useLocation();

  useEffect(() => {
    setAdmin_type(
      location.pathname === "/edit-student-user"
        ? "school"
        : location.pathname === "/edit-member-user" && "business"
    );
  }, []);

  //   const [institutions] = useLazyQuery(INSTITUTIONS, {
  //     fetchPolicy: "network-only",
  //     onCompleted(response) {
  //       setInstitutionData(response.getSchoolInstitutions);
  //       setLoading(false);
  //     },
  //     onError() {
  //       setLoading(false);
  //     },
  //   });

  useEffect(() => {
    setUserId(window.location.search.split("=")[1]);
  }, []);

  const [checkUserName] = useLazyQuery(CHECK_USER_NAME, {
    variables: {
      data: userName,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckUserName: { status } }) {
      if (status) {
        setUsernameError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (userName !== "") {
        setUsernameError(formatedErrors);
      }
    },
  });

  const [checkEmailExist] = useLazyQuery(CHECK_EMAIL, {
    variables: {
      email: emailValue,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckEmail: { status } }) {
      if (status) {
        setEmailError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (emailValue !== "") {
        setEmailError(formatedErrors);
      }
    },
  });

  const { data: userResult } = useQuery(GETMEMBERBY_ID, {
    variables: {
      id: window.location.search.split("=")[1],
    },
    fetchPolicy: "network-only",
    onCompleted({ GetMemberById }) {
      setDisplayData(GetMemberById);
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [userDetails] = useMutation(UPDATEUSER, {
    onCompleted() {
      setLoading(false);
      setSuccessMsg("Account information has been updated successfully.");
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const [userPassword] = useMutation(UPDATEUSERANDPASSWORD, {
    onCompleted() {
      setLoading(false);
      setUserSuccessMsg(
        "Username and password have been updated successfully."
      );
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const handleUserName = (e) => {
    checkUserName(e);
  };

  const handleSubmitClick = () => {
    setSubmitClicked(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required.";
    }

    if (values.email) {
      if (!commonFunctions.validateEmail(values.email)) {
        errors.email = "Enter valid email.";
      }
      setEmailValue(values.email);
    }
    if (!values.email) {
      errors.email = "email is required.";
      setEmailValue("");
    }
    // if (!values.type) {
    //   if (submitClicked) {
    //     setAccountType(true);
    //   }
    //   errors.type = "please select Account type";
    // } else {
    //   setAccountType(false);
    // }
    // if (!values.school) {
    //   if (submitClicked) {
    //     setSchool(true);
    //   }
    //   errors.school = "please select school";
    // } else {
    //   setSchool(false);
    // }
    return errors;
  };

  const validateOne = (values) => {
    const errors = {};
    if (!values.userName) {
      errors.userName = "username is required.";
    } else {
      setUserName(values.userName);
    }

    if (values.userName) {
      if (values.userName.length <= 3) {
        setUsernameError("User must be more that 4 charecters");
      } else {
        setUsernameError("");
      }
    }

    if (values.password) {
      if (values.password.length >= 8) {
        setPasswordLength(true);
      } else {
        errors.password = "";
        setPasswordLength(false);
      }
      if (!commonFunctions.validateAlpha(values.password)) {
        setAlphabetsExist(true);
      } else {
        errors.password = "";
        setAlphabetsExist(false);
      }
      if (!commonFunctions.validateNumeric(values.password)) {
        setNumericExist(true);
      } else {
        errors.password = "";
        setNumericExist(false);
      }
    } else {
      setPasswordLength(false);
      setAlphabetsExist(false);
      setNumericExist(false);
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = "confirm password is required.";
    } else {
      if (values.password_confirmation) {
        if (values.password === values.password_confirmation) {
          setPasswordMatch(true);
        } else {
          setPasswordMatch(false);
          errors.password_confirmation = "";
        }
      }
    }
    return errors;
  };

  const onSubmit = (values) => {
    setLoading(true);
    userDetails({
      variables: {
        data: {
          id: displaydata.id,
          user_fname: values.first_name,
          user_lname: values.last_name,
          alias_name: values.alias_name,
          email: emailValue,
          //   school: values.school ? values.school : "",
          grade: values.groupName ? values.groupName : "",
        },
      },
    });
  };
  const onSubmitOne = (values) => {
    setLoading(true);
    userPassword({
      variables: {
        data: {
          id: displaydata.id,
          user_name: values.userName,
          password: values.password,
          // password_confirmation: values.password_confirmation,
        },
      },
    });
  };

  return (
    <Typography className="edit-page-new">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <div className={classes.backToAdmin}>
        <Link
          to={
            admin_type === "school"
              ? "/students_management"
              : "member_management"
          }
        >
          <i className="fa fa-long-arrow-left" aria-hidden="true"></i>{" "}
          {`Back to
          ${startCase(admin_type)} User Management`}
        </Link>
      </div>
      <div className={classes.formBlock}>
        <h3>Edit User</h3>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Form
              validate={validate}
              onSubmit={onSubmit}
              initialValues={{
                first_name: displaydata.user_fname,
                last_name: displaydata.user_lname,
                email: displaydata.email,
                school: displaydata.institution_name,
                groupName: displaydata.grade,
                alias_name: displaydata?.alias_name,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <div className={classes.formGroup}>
                      <label>First Name </label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="first_name">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Mike"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Last Name</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="last_name">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Inkwiry"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Alias Name</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="alias_name">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Inkwiry"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Email</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="email">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. mike@inkwiry.com"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>{startCase(admin_type)}</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="school">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Engineering"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                disabled
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className={classes.formGroup}>
                      <label>Group</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="groupName">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. community"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    {successmsg && (
                      <div className={classes.sccessMsg}>
                        <span>{successmsg}</span>
                      </div>
                    )}
                    <div className={classes.centerBtn}>
                      <div className={classes.updateBtn}>
                        <button type="submit" onClick={handleSubmit}>
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
            <Form
              validate={validateOne}
              onSubmit={onSubmitOne}
              initialValues={{
                userName: displaydata.user_name,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <h3>Username and Password</h3>
                    <div className={classes.formGroup}>
                      <label>Username</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="userName">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="Username"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                maxLength={5}
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>New Password</label>
                      <div className={classes.inputLeftSelect}>
                        <div className={classes.passwordPopup}>
                          <Field name="password">
                            {({ input, meta }) => (
                              <div>
                                <TextField
                                  name={input?.name}
                                  placeholder="Password"
                                  type="password"
                                  value={input?.value}
                                  onChange={input?.onChange}
                                  required
                                  maxLength={5}
                                  fullWidth
                                  onFocus={() => {
                                    setPassCheck(true);
                                  }}
                                  onBlur={() => {
                                    setPassCheck(false);
                                  }}
                                  className={classes.inputLeft}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span className={classes.noteError}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            )}
                          </Field>
                          <div
                            className={
                              passCheck
                                ? classes.resetPassCondition +
                                  " " +
                                  classes.resetPass
                                : classes.resetPass
                            }
                          >
                            <div className={classes.conditions}>
                              <p>
                                <span>
                                  {passwordLength ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>Include at least 8 characters</label>
                              </p>
                              <p>
                                <span>
                                  {alphabetsExist ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>
                                  Include upper and lower case characters
                                </label>
                              </p>
                              <p>
                                <span>
                                  {numericExist ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>Include at least 1 number</label>{" "}
                              </p>
                              <p>
                                <span>
                                  {passwordMatch ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>Passwords match</label>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Confirm Password</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="password_confirmation">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="Confirm New Password"
                                type="password"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                maxLength={5}
                                fullWidth
                                className={classes.inputLeft}
                                onFocus={() => {
                                  setPassCheck(true);
                                }}
                                onBlur={() => {
                                  setPassCheck(false);
                                }}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    {userSuccessMsg && (
                      <div className={classes.sccessMsgDiv}>
                        <div className={classes.sccessMsg}>
                          <span>{userSuccessMsg}</span>
                        </div>
                      </div>
                    )}
                    {errorMessage && (
                      <div className={classes.noteError}>{errorMessage}</div>
                    )}
                    <div className={classes.centerBtn}>
                      <div className={classes.updateBtn}>
                        <button type="submit" onClick={handleSubmitClick}>
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Typography>
  );
};
EditSchoolUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(EditSchoolUsers);
