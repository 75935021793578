import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { useLazyQuery, withApollo } from "react-apollo";

import { Close } from "@mui/icons-material";
import { Grid } from "@material-ui/core";
import GroupFilter from "../ui/GroupFilter/GroupFilter";
import IsLoadingHOC from "../../Hoc/IsLoadingHOC";
import Pageloader from "../../components/ui/pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTable from "../ui/Table/ReactTable";
import ReactTooltip from "react-tooltip";
import compose from "recompose/compose";
import { isEmpty } from "lodash";
import { loader } from "graphql.macro";
import { question } from "../../utilities/mcsTrackerQuestion";
import styles from "./styles";
import useDebounce from "../../hooks/useDebounce";
import { useEffect } from "react";
import { useUserDetailsContext } from "../../context/userDetails";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const MCS_TRACKER = loader("../../graphql/schema/mcs/mcs-tracker.graphql");
const MCS_TRACKER_DETAILS = loader(
  "../../graphql/schema/mcs/mcs-tracker-details.graphql"
);
const EXPORT_MCS_TRACKER = loader(
  "../../graphql/schema/mcs/export-mcs-tracker.graphql"
);
const GET_SCHOOLS_GROUP_NAMES = loader(
  "../../graphql/schema/pf101/get-schools-groups-names.graphql"
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const McsTracker = (props) => {
  const { classes, setLoading } = props;
  const {
    state: { search = "" },
    clearSearch,
  } = useUserDetailsContext();
  const debouncedSearch = useDebounce(search, 1000);
  const [level, setLevel] = useState({ key: "level_1", val: null });

  const [trackerData, setTrackerData] = useState([]);
  const [perPage, setPerPage] = useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const [searchText, setSearchText] = React.useState("");
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const [levelDetail, setLevelDetail] = useState();
  const [userId, setUserId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [mcsData, setMcsData] = useState([]);
  const [count] = useState(15);
  const [groupId, setGroup] = React.useState([]);
  const [groupNames, setGroupNames] = React.useState([]);
  const [resetFilter, setResetFilter] = useState(false);

  const [getSchoolGroupNames] = useLazyQuery(GET_SCHOOLS_GROUP_NAMES, {
    fetchPolicy: "network-only",
    onCompleted({ getSchoolsGroupNames }) {
      setLoading(false);
      setGroupNames(getSchoolsGroupNames);
    },
    onError() {
      setLoading(false);
    },
  });

  const [mcsTracker, { fetchMore }] = useLazyQuery(MCS_TRACKER, {
    variables: {
      data: {
        page: 1,
        perPage: 20,
        name: "",
        groups: groupId,
        filter: {
          [level?.key]: level?.val,
        },
      },
    },
    fetchPolicy: "network-only",
    onCompleted({ getStudentMcsTracker }) {
      setTrackerData(getStudentMcsTracker?.result);
      setActivePage(getStudentMcsTracker?.page);
      setPageFineshedCount(getStudentMcsTracker.totalCount);
      setLoading(false);
      setResetFilter(false);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    setActivePage(1);
    mcsTracker({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          name: debouncedSearch,
          groups: groupId,
          filter: {
            [level?.key]: level?.val,
          },
        },
      },
    });
  }, [debouncedSearch]);

  const [mcsTrackerDetails] = useLazyQuery(MCS_TRACKER_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted({ getMcsTrackDetails }) {
      const updateData = question[levelDetail]["questions"].map((item, i) => ({
        ...item,
        ...getMcsTrackDetails?.result[i],
      }));
      setMcsData(updateData);
      setOpen(true);
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  const [exportMscTracker] = useLazyQuery(EXPORT_MCS_TRACKER, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = response.exportStudentMcsTracker.url;
      hiddenElement.download = "mcs_tracker.csv";
      hiddenElement.click();
      setLoading(false);
    },
  });

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    fetchMore({
      variables: {
        data: {
          page: pageNumber,
          perPage: 20,
          name: debouncedSearch,
          groups: groupId,
          filter: {
            [level?.key]: level?.val,
          },
        },
      },

      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setTrackerData(fetchMoreResult?.getStudentMcsTracker?.result);
        // return Object.assign({}, prev, {
        //   feed: [...prev.feed, ...fetchMoreResult.feed]
        // });
      },
    });
    setActivePage(pageNumber);
  };

  const handleClearFilter = () => {
    setLoading(true);
    setResetFilter(true);
    setLevel({ key: "level_1", val: null });
    setGroup([]);
    setActivePage(1);
    clearSearch();
    mcsTracker({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          name: "",
          groups: [],
          filter: {
            level_1: null,
          },
        },
      },
    });
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleSorting = (row, val) => {
    setLoading(true);
    setLevel({ key: row, val });
    mcsTracker({
      variables: {
        data: {
          page: activePage,
          perPage: 20,
          name: debouncedSearch,
          groups: groupId,
          filter: {
            [row]: val,
          },
        },
      },
    });
  };

  useEffect(() => {
    mcsTracker();
    getSchoolGroupNames();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Grid container className={classes.searchRow} spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          {groupNames && groupNames.length > 0 && (
            <div>
              <GroupFilter
                resetFilter={resetFilter}
                data={groupNames}
                getFilterValue={(value) => {
                  setGroup(value);
                  mcsTracker({
                    variables: {
                      data: {
                        page: activePage,
                        perPage: 20,
                        name: debouncedSearch,
                        groups: value,
                        filter: {
                          [level?.key]: level?.val,
                        },
                      },
                    },
                  });
                }}
              />
            </div>
          )}
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <div>
            {pageFineshedCount > 0 && (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={20}
                totalItemsCount={pageFineshedCount}
                pageRangeDisplayed={5}
                onChange={handleGetFineshedMatchesPageClick}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <ReactTable
        resetFilter={resetFilter}
        activePage={activePage}
        count={pageFineshedCount}
        rows={trackerData}
        filterFunction={(row, value) => {
          handleSorting(row, value);
        }}
        columns={[
          {
            name: "Full Name",
            row: "name",
          },
          {
            name: "level 01",
            row: "level_1",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => {
              return (
                <Box
                  onClick={() => {
                    setUserName(name);
                    setLevelDetail("level1");
                    setLoading(true);
                    mcsTrackerDetails({
                      variables: {
                        data: {
                          user_id: userId,
                          lesson: 1,
                        },
                      },
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      value >= Number("100")
                        ? "green"
                        : isEmpty(value) || value === "0"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value >= Number("100")
                    ? "Completed"
                    : isEmpty(value) || value === "0"
                    ? "Not Completed"
                    : `${value}%`}
                </Box>
              );
            },
          },
          {
            name: "level 02",
            row: "level_2",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => {
              return (
                <Box
                  onClick={() => {
                    setUserName(name);
                    setLevelDetail("level2");
                    setLoading(true);
                    mcsTrackerDetails({
                      variables: {
                        data: {
                          user_id: userId,
                          lesson: 2,
                        },
                      },
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      value >= Number("100")
                        ? "green"
                        : isEmpty(value) || value === "0"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value >= Number("100")
                    ? "Completed"
                    : isEmpty(value) || value === "0"
                    ? "Not Completed"
                    : `${value}%`}
                </Box>
              );
            },
          },
          {
            name: "level 03",
            row: "level_3",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => {
              return (
                <Box
                  onClick={() => {
                    setUserName(name);
                    setLevelDetail("level3");
                    setLoading(true);
                    mcsTrackerDetails({
                      variables: {
                        data: {
                          user_id: userId,
                          lesson: 3,
                        },
                      },
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      value >= Number("100")
                        ? "green"
                        : isEmpty(value) || value === "0"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value >= Number("100")
                    ? "Completed"
                    : isEmpty(value) || value === "0"
                    ? "Not Completed"
                    : `${value}%`}
                </Box>
              );
            },
          },
          {
            name: "level 04",
            row: "level_4",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => {
              return (
                <Box
                  onClick={() => {
                    setUserName(name);
                    setLevelDetail("level4");
                    setLoading(true);
                    mcsTrackerDetails({
                      variables: {
                        data: {
                          user_id: userId,
                          lesson: 4,
                        },
                      },
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      value >= Number("100")
                        ? "green"
                        : isEmpty(value) || value === "0"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value >= Number("100")
                    ? "Completed"
                    : isEmpty(value) || value === "0"
                    ? "Not Completed"
                    : `${value}%`}
                </Box>
              );
            },
          },
          {
            name: "level 05",
            row: "level_5",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => {
              return (
                <Box
                  onClick={() => {
                    setUserName(name);
                    setLevelDetail("level5");
                    setLoading(true);
                    mcsTrackerDetails({
                      variables: {
                        data: {
                          user_id: userId,
                          lesson: 5,
                        },
                      },
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      value >= Number("100")
                        ? "green"
                        : isEmpty(value) || value === "0"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value >= Number("100")
                    ? "Completed"
                    : isEmpty(value) || value === "0"
                    ? "Not Completed"
                    : `${value}%`}
                </Box>
              );
            },
          },
          {
            name: "level 06",
            row: "level_6",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => {
              return (
                <Box
                  onClick={() => {
                    setUserName(name);
                    setLevelDetail("level6");
                    setLoading(true);
                    mcsTrackerDetails({
                      variables: {
                        data: {
                          user_id: userId,
                          lesson: 6,
                        },
                      },
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      value >= Number("100")
                        ? "green"
                        : isEmpty(value) || value === "0"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value >= Number("100")
                    ? "Completed"
                    : isEmpty(value) || value === "0"
                    ? "Not Completed"
                    : `${value}%`}
                </Box>
              );
            },
          },
          {
            name: "level 07",
            row: "level_7",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => {
              return (
                <Box
                  onClick={() => {
                    setUserName(name);
                    setLevelDetail("level7");
                    setLoading(true);
                    mcsTrackerDetails({
                      variables: {
                        data: {
                          user_id: userId,
                          lesson: 8,
                        },
                      },
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      value >= Number("100")
                        ? "green"
                        : isEmpty(value) || value === "0"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value >= Number("100")
                    ? "Completed"
                    : isEmpty(value) || value === "0"
                    ? "Not Completed"
                    : `${value}%`}
                </Box>
              );
            },
          },
          {
            name: "level 08",
            row: "level_8",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => {
              return (
                <Box
                  onClick={() => {
                    setUserName(name);
                    setLevelDetail("level8");
                    setLoading(true);
                    mcsTrackerDetails({
                      variables: {
                        data: {
                          user_id: userId,
                          lesson: 8,
                        },
                      },
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      value >= Number("100")
                        ? "green"
                        : isEmpty(value) || value === "0"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value >= Number("100")
                    ? "Completed"
                    : isEmpty(value) || value === "0"
                    ? "Not Completed"
                    : `${value}%`}
                </Box>
              );
            },
          },
          {
            name: "Wrapup + Review",
            row: "level_9",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId }) => {
              return (
                <Box
                  // onClick={() => {
                  //   mcsTrackerDetails({
                  //     variables: {
                  //       data: {
                  //         user_id: userId,
                  //         lesson: 9,
                  //       },
                  //     },
                  //   });
                  // }}
                  sx={{
                    cursor: "pointer",
                    color:
                      value >= Number("100")
                        ? "green"
                        : isEmpty(value) || value === "0"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value >= Number("100")
                    ? "Completed"
                    : isEmpty(value) || value === "0"
                    ? "Not Completed"
                    : `${value}%`}
                </Box>
              );
            },
          },
        ]}
      />
      <div className={classes.userTableSec}>
        <div className={classes.schoolsBtn}>
          <div className={classes.clearFilterBtn}>
            <button onClick={handleClearFilter}>CLEAR ALL FILTERS</button>
          </div>
          <Tooltip
            title=" This will export only the data that is currently shown above to
                excel. If you want to export all the data in this tab, make sure
                you clear all filters before using the export button."
          >
            <div className={classes.exportBtn}>
              <button
                data-tip
                data-for="main"
                onClick={() => {
                  exportMscTracker({
                    variables: {
                      data: {
                        name: debouncedSearch,
                        groups: groupId,
                        filter: {
                          [level?.key]: level?.val,
                        },
                      },
                    },
                  });
                  setLoading(true);
                }}
              >
                EXPORT
              </button>
            </div>
          </Tooltip>
        </div>
      </div>
      {open && (
        <BootstrapDialog
          maxWidth="md"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Box display={"flex"} justifyContent="space-between" mr={5}>
              <Typography variant="h6">
                {question[levelDetail]["modalHeaderName"]}
              </Typography>
              <Typography variant="h6">{userName}</Typography>
            </Box>
          </BootstrapDialogTitle>
          <DialogContent dividers={"paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <ReactTable
                showNo={false}
                rows={mcsData}
                columns={[
                  {
                    name: "Task",
                    row: "id",
                  },
                  {
                    name: "Description",
                    row: "name",
                  },
                  {
                    name: "Status",
                    row: "completed_date",
                  },
                ]}
              />
            </DialogContentText>
          </DialogContent>
        </BootstrapDialog>
      )}
    </div>
  );
};

McsTracker.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);

export default enhance(McsTracker);
