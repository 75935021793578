// import * as commonFunctions from "../../utilities/commonFunctions";

import * as commonFunctions from "../../../utilities/commonFunctions";

import { Dialog, Grid, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useQuery, withApollo } from "react-apollo";

import { Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUpload from "../FileUpload/fileUpload";
import IconButton from "@mui/material/IconButton";
import Pageloader from "../pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTable from "../Table/ReactTable";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import moment from "moment";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";

const IMPORT_SCHOOL_DOCUMENT = loader(
  "../../../graphql/schema/schools/schoolDocument.graphql"
);
const GET_LIST_SCHOOL_DOCUMENT = loader(
  "../../../graphql/schema/schools/getSchoolDocumentList.graphql"
);
const DELETE_SCHOOL_DOC = loader(
  "../../../graphql/schema/schools/deleteSchoolDocument.graphql"
);

function DocumentCenter(props) {
  const { classes } = props;
  const inputRef = useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [schoolId, setSchoolId] = useState(
    JSON.parse(localStorage.getItem("institution"))
  );
  const [activePage, setActivePage] = React.useState(1);
  const [schoolDocData, setSchoolDocData] = useState([]);
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);

  const [successMessage, setSuccessMessage] = React.useState("");
  const [fileUploadErrorMsg, setFileUploadErrorMsg] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [file, setFile] = React.useState("");
  const [deleteId, setDeleteId] = React.useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] =
    React.useState(false);
  const [schoolDocumentCenter] = useMutation(IMPORT_SCHOOL_DOCUMENT, {
    onCompleted(data) {
      setLoading(false);
      setSuccessMessage(data?.schoolDocumentCenter?.message);
      GetDocumentDetails();
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setFileUploadErrorMsg(errorMsg);
      setLoading(false);
    },
  });

  const [GetDocumentDetails, { fetchMore }] = useLazyQuery(
    GET_LIST_SCHOOL_DOCUMENT,
    {
      variables: {
        data: {
          page: 1,
          perPage: 20,
          school_id: schoolId?.id,
        },
      },
      fetchPolicy: "network-only",
      onCompleted(response) {
        setSchoolDocData(response?.GetDocumentDetails?.documents);
        setLoading(false);
        setActivePage(response?.GetDocumentDetails?.page);
        setPageFineshedCount(response.GetDocumentDetails.totalCount);
      },
      onError(error) {
        let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
        setErrorMessage(errorMsg);
        setLoading(false);
      },
    }
  );

  const [schoolDocumentDelete, { data: deleteResult }] = useMutation(
    DELETE_SCHOOL_DOC,
    {
      onCompleted() {
        setOpenConfirmDeletePopup(false);
        setDeleteId("");
        setLoading(false);
        GetDocumentDetails();
      },
      onError(errors) {
        let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
        setErrorMessage(errorMsg);
        setLoading(false);
      },
    }
  );

  React.useEffect(() => {
    if (deleteResult) {
      setSuccessMessage(deleteResult.schoolDocumentDelete.message);
    }
  }, [deleteResult]);

  const handleFileChange = (e) => {
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      return;
    }
    setFile(e.target.files[0]);
  };

  const handleSubmit = (value) => {
    setLoading(true);
    return schoolDocumentCenter({
      variables: {
        file: {
          file: value,
          school_id: schoolId?.id,
          school_type: schoolId?.school_type,
        },
      },
    });
  };
  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    fetchMore({
      variables: {
        data: {
          page: pageNumber,
          perPage: 20,
          school_id: schoolId?.id,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setSchoolDocData(fetchMoreResult?.GetDocumentDetails?.documents);
        // return Object.assign({}, prev, {
        //   feed: [...prev.feed, ...fetchMoreResult.feed]
        // });
      },
    });
    setActivePage(pageNumber);
  };

  const handleOpenConfirmDeletePopup = (id) => {
    setDeleteId(id);
    setOpenConfirmDeletePopup(true);
  };
  const handleCloseDeletePopup = () => {
    setOpenConfirmDeletePopup(false);
    setDeleteId("");
  };
  const handleDeleteUser = () => {
    setLoading(true);
    schoolDocumentDelete({
      variables: {
        id: deleteId,
      },
    });
  };

  const handleDownloadFile = (link_url) => {
    const link = document.createElement("a");
    link.download = link_url;
    link.href = link_url;
    link.click();
  };

  useEffect(() => {
    GetDocumentDetails();
  }, []);

  return (
    <Typography className="main-bannerRight">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <Grid container className={classes.searchRow} spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <Box display={"flex"}>
            <div className={classes.uploadDiv}>
              <FileUpload
                onUpload={(value) => {
                  return handleSubmit(value);
                }}
                successMessage={successMessage}
                errorMessage={errorMessage}
              />
            </div>
          </Box>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <div>
            {pageFineshedCount > 0 && (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={20}
                totalItemsCount={pageFineshedCount}
                pageRangeDisplayed={5}
                onChange={handleGetFineshedMatchesPageClick}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <ReactTable
        activePage={activePage}
        count={pageFineshedCount}
        resetFilter={resetFilter}
        columns={[
          {
            name: "Admin Name",
            row: "admin",
            customRowComponent: (value) => value?.name,
          },
          {
            name: "Document Name",
            row: "file_original_name",
          },
          {
            name: "Date and Time",
            row: "created_at",
            customRowComponent: (value) => moment(value).format("lll"),
          },
          {
            name: "Actions",
            row: "id",
            customRowComponent: (value, row) => (
              <span className={classes.moreInfo}>
                <ul>
                  <li
                    className={classes.deleteAdmin}
                    onClick={() => handleDownloadFile(row.download_link)}
                  >
                    <i className="fa fa-download" title="Download"></i>
                  </li>
                  <li
                    className={classes.deleteAdmin}
                    onClick={() => handleOpenConfirmDeletePopup(value)}
                  >
                    <i className="fa fa-trash" title="Delete"></i>
                  </li>
                </ul>
              </span>
            ),
          },
        ]}
        rows={schoolDocData}
      />

      {openConfirmDeletePopup && (
        <Dialog
          className="popup-model"
          open={openConfirmDeletePopup}
          onClose={handleCloseDeletePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="model-content">
            <div className="modal-header">
              {/* <button onClick={handleCloseDeletePopup} >X</button> */}
              <h3>Delete Document</h3>
            </div>

            <div
              className="model-body"
              id="customized-dialog-title"
              onClose={handleCloseDeletePopup}
            >
              Are you sure you want to delete this document?
            </div>
            <div className="model-footer">
              <button
                onClick={() => handleDeleteUser()}
                className="close-btn delete-btn"
              >
                Ok
              </button>
              <button onClick={handleCloseDeletePopup} className="close-btn">
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </Typography>
  );
}

DocumentCenter.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);
export default enhance(DocumentCenter);
