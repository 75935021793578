import * as commonFunctions from "../../../utilities/commonFunctions";

import { Box, Grid, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLazyQuery, withApollo } from "react-apollo";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Filter from "../filter";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import IsLoadingHOC from "../../../Hoc/IsLoadingHOC";
import PF101Survey from "../SurveryContainer/index";
import Pageloader from "../../../components/ui/pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTable from "../../../components/ui/Table/ReactTable";
import classNames from "classnames";
import compose from "recompose/compose";
import filter from "../../../components/ui/contact-us/filter";
import { loader } from "graphql.macro";
import moment from "moment/moment";
import scrollToComponent from "react-scroll-to-component";
import styles from "./style";
import { useUserDetailsContext } from "../../../context/userDetails";

const GET_PF101_SURVEY = loader(
  "../../../graphql/schema/pf101/Survey/getPF101SurveyAll.graphql"
);
const GET_PF101_SURVEY_GRAPH = loader(
  "../../../graphql/schema/pf101/Survey/getPF101SurveyAllGraphDetails.graphql"
);
const EXPORT_MEMBER_SURVEY = loader(
  "../../../graphql/schema/pf101/Survey/exportSurveyAll.graphql"
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  if (index !== 3)
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
}

function PF101MemberSurvey(props) {
  const DATE = moment().format("DD-MM-YYYY");
  const { classes, setLoading } = props;
  const {
    state: { admin_type },
  } = useUserDetailsContext();
  const [value, setValue] = useState(0);
  const [selectedType, setSelectedType] = React.useState("");
  const [offset] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [activePage, setActivePage] = React.useState(1);
  const [typeFilter, setTypeFilter] = useState("");
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const [surveyResultData, setSurveyResultData] = useState([]);
  const [surveyGraphData, setSurveyGraphData] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState("");
  const [surveyResult, { fetchMore }] = useLazyQuery(GET_PF101_SURVEY, {
    variables: {
      data: {
        page: 1,
        perPage: 20,
        date: DATE,
        type:
          admin_type === "school"
            ? "student"
            : admin_type === "business"
            ? "member"
            : "",
        filter: [],
      },
    },
    fetchPolicy: "network-only",
    onCompleted({ getPF101SurveyAll }) {
      setSurveyResultData(getPF101SurveyAll?.data);
      setActivePage(getPF101SurveyAll?.page);
      setPageFineshedCount(getPF101SurveyAll.totalCount);
      setLoading(false);
      setResetFilter(false);
    },
    onError(error) {
      setLoading(false);
    },
  });
  const [surveyGraphResult] = useLazyQuery(GET_PF101_SURVEY_GRAPH, {
    variables: {
      data: {
        date: DATE,
        type:
          admin_type === "School"
            ? "student"
            : admin_type === "business"
            ? "member"
            : "",
      },
    },
    fetchPolicy: "network-only",
    onCompleted({ getPF101SurveyAllGraphDetails }) {
      setSurveyGraphData(getPF101SurveyAllGraphDetails?.bar);
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    if (value === 0) {
      surveyResult();
    } else if (value === 1) {
      surveyGraphResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [exportMemberSurvey] = useLazyQuery(EXPORT_MEMBER_SURVEY, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = response.exportSurveyAll.url;
      hiddenElement.download = "pf101_finalAssessment.csv";
      hiddenElement.click();
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    if (typeFilter === "question") {
      fetchMore({
        variables: {
          data: {
            page: pageNumber,
            perPage: 20,
            date: DATE,
            type: "student",
            filter: {
              [selectedType?.key]: selectedType?.val,
            },
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoading(false);
          if (!fetchMoreResult) return prev;
          setSurveyResultData(fetchMoreResult?.getPF101SurveyAll?.data);
          // return Object.assign({}, prev, {
          //   feed: [...prev.feed, ...fetchMoreResult.feed]
          // });
        },
      });
    } else {
      fetchMore({
        variables: {
          data: {
            page: pageNumber,
            perPage: 20,
            date: DATE,
            type: "student",
            filter_date: selectedType?.val,
            filter: [],
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoading(false);
          if (!fetchMoreResult) return prev;
          setSurveyResultData(fetchMoreResult?.getPF101SurveyAll?.data);
          // return Object.assign({}, prev, {
          //   feed: [...prev.feed, ...fetchMoreResult.feed]
          // });
        },
      });
    }
    setActivePage(pageNumber);
  };

  React.useEffect(() => {
    surveyResult();
  }, []);

  const handleSorting = (row, val) => {
    setLoading(true);
    setSelectedType({ key: row, val });
    if (row.includes("question")) {
      setTypeFilter("question");
      surveyResult({
        variables: {
          data: {
            page: activePage,
            perPage: 20,
            date: DATE,
            type:
              admin_type === "school"
                ? "student"
                : admin_type === "business"
                ? "member"
                : "",
            filter: {
              [row]: val,
            },
          },
        },
      });
    } else {
      setTypeFilter("date");
      surveyResult({
        variables: {
          data: {
            page: activePage,
            perPage: 20,
            date: DATE,
            type:
              admin_type === "school"
                ? "student"
                : admin_type === "business"
                ? "member"
                : "",
            filter: [],
            filter_date: val,
          },
        },
      });
    }
  };

  const handleClearFilter = () => {
    setLoading(true);
    setResetFilter(true);
    setActivePage(1);
    setSelectedType();
    surveyResult({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          date: DATE,
          type:
            admin_type === "school"
              ? "student"
              : admin_type === "business"
              ? "member"
              : "",
          filter: [],
          filter_date: "",
        },
      },
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className="dashboard-page">
        {/* <PF101Survey /> */}
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.dashboardTabs}
        >
          <Tab label="Table View" {...a11yProps(0)} />
          <Tab label="Graph View" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <div>
            <Grid
              container
              className={classes.searchRow}
              style={{ marginBottom: "10px" }}
            >
              <Grid item md={6} sm={6} xs={12}></Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div>
                  {pageFineshedCount > 0 && (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={20}
                      totalItemsCount={pageFineshedCount}
                      pageRangeDisplayed={5}
                      onChange={handleGetFineshedMatchesPageClick}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
            <ReactTable
              resetFilter={resetFilter}
              rows={surveyResultData}
              activePage={activePage}
              count={pageFineshedCount}
              filterFunction={(row, value) => {
                handleSorting(row, value);
              }}
              columns={[
                {
                  name: "Question 01",
                  row: "question_1",
                  showCheckboxwithFilter: true,
                  filterList: Array.from({ length: 10 }, (_, i) => i + 1)?.map(
                    (l) => ({
                      key: l,
                      value: l,
                    })
                  ),
                },
                {
                  name: "Question 02",
                  row: "question_2",
                  showCheckboxwithFilter: true,
                  filterList: Array.from({ length: 10 }, (_, i) => i + 1)?.map(
                    (l) => ({
                      key: l,
                      value: l,
                    })
                  ),
                },
                {
                  name: "Question 03",
                  row: "question_3",
                  showCheckboxwithFilter: true,
                  filterList: Array.from({ length: 10 }, (_, i) => i + 1)?.map(
                    (l) => ({
                      key: l,
                      value: l,
                    })
                  ),
                },
                {
                  name: "Question 04",
                  row: "question_4",
                  showCheckboxwithFilter: true,
                  filterList: Array.from({ length: 10 }, (_, i) => i + 1)?.map(
                    (l) => ({
                      key: l,
                      value: l,
                    })
                  ),
                },
                {
                  name: "Question 05",
                  row: "question_5",
                  showCheckboxwithFilter: true,
                  filterList: Array.from({ length: 10 }, (_, i) => i + 1)?.map(
                    (l) => ({
                      key: l,
                      value: l,
                    })
                  ),
                },
                {
                  name: "Question 06",
                  row: "question_6",
                  showCheckboxwithFilter: true,
                  filterList: Array.from({ length: 10 }, (_, i) => i + 1)?.map(
                    (l) => ({
                      key: l,
                      value: l,
                    })
                  ),
                },
                {
                  name: "Question 07",
                  row: "question_7",
                  showCheckboxwithFilter: true,
                  filterList: Array.from({ length: 10 }, (_, i) => i + 1)?.map(
                    (l) => ({
                      key: l,
                      value: l,
                    })
                  ),
                },
                {
                  name: "Question 08",
                  row: "question_8",
                  minWidth: "50rem",
                },
                {
                  name: "Question 09",
                  row: "question_9",
                  minWidth: "50rem",
                },
                {
                  name: "Question 10",
                  row: "question_10",
                },
                {
                  name: "Date & Time",
                  row: "date_time",
                  filterList: [
                    { key: "", value: "None" },
                    { key: "today", value: "Today" },
                    { key: "this_week", value: "This Week" },
                    { key: "this_month", value: "This Month" },
                    { key: "last_30", value: "Last 30 days" },
                    { key: "last_3_m", value: "Last 3 months" },
                    { key: "last_6_m", value: "Last 6 months" },
                    { key: "ytd", value: "Year to Date" },
                    { key: "last_12_m", value: "Last 12 months" },
                  ],
                },
              ]}
            />
            <div className={classes.schoolsBtn}>
              <div className={classes.clearFilterBtn}>
                <button onClick={handleClearFilter}>CLEAR ALL FILTERS</button>
              </div>
              <div className={classes.exportBtn}>
                <Tooltip
                  title="This will export only the data that is currently shown above to
            excel. If you want to export all the data in this tab, make sure you
            clear all filters before using the export button."
                >
                  <button
                    data-tip
                    data-for="main"
                    onClick={() => {
                      if (typeFilter === "date") {
                        exportMemberSurvey({
                          variables: {
                            data: {
                              date: DATE,
                              filter_date: selectedType?.val,
                              type:
                                admin_type === "school"
                                  ? "student"
                                  : admin_type === "business"
                                  ? "member"
                                  : "",
                              filter: [],
                            },
                          },
                        });
                      } else {
                        exportMemberSurvey({
                          variables: {
                            data: {
                              date: DATE,
                              type:
                                admin_type === "school"
                                  ? "student"
                                  : admin_type === "business"
                                  ? "member"
                                  : "",
                              filter: {
                                [selectedType?.key]: selectedType?.val,
                              },
                            },
                          },
                        });
                      }
                      setLoading(true);
                    }}
                  >
                    EXPORT
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className={classes.questionExpalin}>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_1">
                  Question 1:
                </span>
                <span>
                  Personal Finance 101 improved my money skills and knowledge.
                </span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_2">
                  Question 2:
                </span>
                <span>I wish I could learn more about personal finance.</span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_3">
                  Question 3:
                </span>
                <span>
                  I believe others would benefit from taking Personal Finance
                  101.
                </span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_4">
                  Question 4:
                </span>
                <span>Personal Finance 101 was engaging and easy-to-use.</span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_5">
                  Question 5:
                </span>
                <span>
                  Would you recommend Personal Finance 101 to a friend?
                </span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_6">
                  Question 6:
                </span>
                <span>
                  I want to build a financial life plan for my career,
                  investments, and home buying.
                </span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_7">
                  Question 7:
                </span>
                <span>
                  I want to know what my net worth will be in 5, 10, 15 years.
                </span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_8">
                  Question 8:
                </span>
                <span>What was your favorite thing about PF101?</span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_9">
                  Question 9:
                </span>
                <span>What was one thing you would change about PF101?</span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_10">
                  Question 10:
                </span>
                <span>
                  Do you have any other comments about how we can improve?
                  (Optional)
                </span>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* {allPF101Levels ? */}
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                data: {
                  table: "datatable",
                },
                chart: {
                  type: "column",
                  height: 700,
                },
                plotOptions: {
                  column: {
                    stacking: "normal",
                  },
                  series: {
                    cursor: "pointer",
                    point: {
                      events: {
                        click: function () {
                          // goToView(this.series.index+1,this.category);
                        },
                      },
                    },
                  },
                },
                title: {
                  text: "PF101 Member Survey",
                },
                credits: {
                  enabled: false,
                },
                yAxis: {
                  min: 0,
                  tickInterval: 1,
                  max: 10,
                  gridLineWidth: 0,
                  labels: {
                    format: "{value}",
                    style: {
                      fontSize: "14px",
                    },
                  },
                  title: {
                    text: " ",
                  },
                },
                xAxis: {
                  categories: [
                    "Question 1",
                    "Question 2",
                    "Question 3",
                    "Question 4",
                    "Question 5",
                    "Question 6",
                    "Question 7",
                  ],
                  labels: {
                    style: {
                      fontSize: "14px",
                    },
                  },
                  title: {
                    text: " ",
                  },
                },
                tooltip: {
                  formatter: function () {
                    return (
                      "<b>" +
                      this.x +
                      "</b><br/>" +
                      this.series.name +
                      ": " +
                      parseFloat(this.y).toFixed(2) +
                      "<br/>"
                    );
                  },
                  fontSize: "14px",
                },
                legend: {
                  itemStyle: {
                    fontSize: "14px",
                  },
                  align: "center",
                },
                series: [
                  {
                    name: "PF101 Member Survey",
                    data: Object.values(surveyGraphData).map((num) =>
                      parseFloat(num)
                    ),
                    color: "#006aaa",
                    legendIndex: 0,
                    borderColor: "#006aaa",
                  },
                ],
              }}
            />
            <div className={classes.questionExpalin}>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_1">
                  Question 1:
                </span>
                <span>
                  Personal Finance 101 improved my money skills and knowledge.
                </span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_2">
                  Question 2:
                </span>
                <span>I wish I could learn more about personal finance.</span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_3">
                  Question 3:
                </span>
                <span>
                  I believe others would benefit from taking Personal Finance
                  101.
                </span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_4">
                  Question 4:
                </span>
                <span>Personal Finance 101 was engaging and easy-to-use.</span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_5">
                  Question 5:
                </span>
                <span>
                  Would you recommend Personal Finance 101 to a friend?
                </span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_6">
                  Question 6:
                </span>
                <span>
                  I want to build a financial life plan for my career,
                  investments, and home buying.
                </span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_7">
                  Question 7:
                </span>
                <span>
                  I want to know what my net worth will be in 5, 10, 15 years.
                </span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_8">
                  Question 8:
                </span>
                <span>What was your favorite thing about PF101?</span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_9">
                  Question 9:
                </span>
                <span>What was one thing you would change about PF101?</span>
              </div>
              <div
                className={classNames(
                  classes.questionExpalin,
                  "full_length_quesions"
                )}
              >
                <span class="question_list" id="details_question_10">
                  Question 10:
                </span>
                <span>
                  Do you have any other comments about how we can improve?
                  (Optional)
                </span>
              </div>
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
PF101MemberSurvey.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);

export default enhance(PF101MemberSurvey);
