import * as commonFunctions from "../../../utilities/commonFunctions";

import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Utilities, { ACCESS_TOKEN } from "../../../utilities/userUtils";

import ChangeClientModal from "../../../components/ui/Modal/ChangeClientModal";
import { Grid } from "@material-ui/core";
import Pageloader from "../../../components/ui/pageloader";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import { startCase } from "lodash";
import styles from "../styles.css";
import { useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { useUserDetailsContext } from "../../../context/userDetails";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const VERIFYADMINOTP = loader(
  "../../../graphql/schema/ui/verifyAdminOTP.graphql"
);
const INSTITUTIONS = loader("../../../graphql/schema/ui/Institutions.graphql");
const UPDATE_INSTITUTION_ID = loader(
  "../../../graphql/schema/ui/updateInstitutions.graphql"
);
const Home = (props) => {
  const [sideBar, setSideBar] = useState(null);

  const { history } = props;
  const { state, selectSchoolhandler } = useUserDetailsContext();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = React.useState(false);
  const [mobileotp, setMobileotp] = React.useState(false);
  const [openSelectPopup, setOpenSelectPopup] = React.useState(false);
  const [selectSchool, setSelectSchool] = React.useState("");
  const [institutionData, setInstitutionData] = useState([]);
  const [adminOption, setAdminOption] = useState(false);
  const [AccessData, setAccessData] = useState({});
  useEffect(() => {
    const verifyAdminOTP = {
      access_token: Utilities.getAccessToken(),
      admin_type: Utilities.getAdminType()
    } 
    
    if(verifyAdminOTP.access_token) {
      if (verifyAdminOTP.admin_type === "inkwiry") {
        setAdminOption(!adminOption);
      }
      Utilities.setAccessToken({
        admin_type: verifyAdminOTP.admin_type,
        access_token: verifyAdminOTP.access_token,
      });
      setAccessData(verifyAdminOTP.admin_type);
      institutions();
      setOpenSelectPopup(true);
      setLoading(true);
    }
  }, [])
  const [submitOtpData] = useMutation(VERIFYADMINOTP, {
    onCompleted({ verifyAdminOTP }) {
      if (verifyAdminOTP?.admin_type === "inkwiry") {
        setAdminOption(!adminOption);
      }
      Utilities.setAccessToken({
        admin_type: verifyAdminOTP.admin_type,
        access_token: verifyAdminOTP.access_token,
      });
      setAccessData(verifyAdminOTP.admin_type);
      institutions();
      setOpenSelectPopup(true);
      setLoading(true);
      setErrorMessage("");
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const handleOtpInputChange = (e) => {
    setMobileotp(e.currentTarget.value);
    setErrorMessage("");
  };
  const handleSubmitOtp = () => {
    let userName = Utilities.getUserName();
    if (mobileotp) {
      if (mobileotp.length !== 6) {
        setErrorMessage("Please Enter Valid OTP");
      } else {
        setLoading(true);
        // history.push('/inkwiry-admins');
        submitOtpData({
          variables: {
            data: {
              username: userName,
              otp: mobileotp,
            },
          },
        });
      }
    } else {
      setErrorMessage("Please enter your OTP.");
    }
  };
  const handleClose = () => {
    setOpenSelectPopup(false);
  };
  const handleSchoolChange = (e) => {
    setSelectSchool(e.target.value);
  };

  const [updateInstitutionId] = useMutation(UPDATE_INSTITUTION_ID, {
    onCompleted({ updateInstitutionId }) {
      setLoading(false);
      const { admin } = updateInstitutionId;
      localStorage.setItem(
        "institution_details",
        JSON.stringify(admin?.selected_institution)
      );
      if (admin?.institution_id === null) {
        history.push("/inkwiry-admins");
      } else {
        history.push("/client-dashboard");
      }
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const handleGOClick = () => {
    if (selectSchool === "Proceed as Main Admin") {
      setLoading(true);
      updateInstitutionId({
        variables: {
          institution_id: null,
        },
      });
      // history.push("/inkwiry-admins");
    } else {
      selectSchoolhandler(selectSchool);
      setLoading(true);
      updateInstitutionId({
        variables: {
          institution_id: selectSchool?.id,
        },
      });
      localStorage.setItem("institution", JSON.stringify(selectSchool));
      // history.push("/client-dashboard");
    }
  };

  const [institutions] = useLazyQuery(INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(response.Institutions);
      setLoading(false);
    },
  });

  return (
    <>
      <Typography className="main-banner-login">
        {loading === true ? <Pageloader loading={loading} /> : ""}
        <Grid container className="container">
          <Grid item lg={7} md={7} sm={12} xs={12} className="col-login">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmitOtp();
              }}
            >
              <div className="login">
                <figure>
                  <img src="./img/logo2.png" alt="" />
                </figure>
                <div className="login-box">
                  <h3>Verify Identity</h3>
                  <p>
                    Please enter the one-time security code sent to your email.
                  </p>
                  <div>
                    <div className="form-group">
                      <input
                        labelText="code"
                        id="code"
                        onChange={handleOtpInputChange}
                        type="text"
                        placeholder="Enter authentication code"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="noteError mb10">{errorMessage}</div>
                  )}
                  <div className="text-center">
                    <button
                      className="text-center otp-button"
                      onClick={() => handleSubmitOtp()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Grid>
        </Grid> 

        {/* <Dialog
          open={openSelectPopup}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="school-popup"
        >
          <DialogTitle id="alert-dialog-title">{"Select School"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <label>
                Select the school from the drop down to go to that school's
                admin tool.
              </label>
              <div>
                <FormControl variant="filled" className="formControl">
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={selectSchool}
                    onChange={handleSchoolChange}
                    placeholder="Select School"
                    label="Select School"
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Select School</em>
                    </MenuItem>
                    {JSON.parse(localStorage.getItem(ACCESS_TOKEN))
                      ?.admin_type === "inkwiry" && (
                      <MenuItem value="Proceed as Main Admin">
                        {" "}
                        Proceed as Main Admin{" "}
                      </MenuItem>
                    )}
                    {institutionData.length > 0 &&
                      institutionData.map((l, index) => {
                        return (
                          <MenuItem value={l}> {l.institution_name} </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="model-footer">
            <Button onClick={handleGOClick} color="primary" autoFocus>
              GO
            </Button>
          </DialogActions>
        </Dialog> */}
        <footer className="desktop_footer">
          <div className="container_footer">
            <Grid container>
              {/* <Grid item xl lg={1} md={1} sm={1} xs={1} /> */}
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                className="copyright copyright_txt"
              >
                <p>Copyright © 2022 // All rights reserved</p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                className="copyright powered-text"
              >
                <p className="logoDesc">
                  Informed and Powered by{" "}
                  <Link to="/" className="logoLink">
                    <img src="./img/logo2.png" alt="" />
                  </Link>
                </p>
              </Grid>
            </Grid>
          </div>
          <div className="container-fluid new_gray_footer">
            <p className="terms_links">
              <Link
                to={{ pathname: "https://inkwiry.com/termsofservice" }}
                target="_blank"
              >
                Terms of Service
              </Link>
              &nbsp;//&nbsp;
              <Link
                to={{ pathname: "https://inkwiry.com/privacy" }}
                target="_blank"
              >
                Privacy Policy
              </Link>
              &nbsp;//&nbsp;
              <Link
                to={{ pathname: "https://inkwiry.com/security" }}
                target="_blank"
              >
                Security
              </Link>
            </p>
          </div>
        </footer>
      </Typography>
      {openSelectPopup && (
        <ChangeClientModal
          header={`Select Admin Tool`}
          open={openSelectPopup}
          handleClose={() => setOpenSelectPopup(!openSelectPopup)}
          setSideBar={setSideBar}
          {...props}
        />
      )}
    </>
  );
};
Home.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(Home);
