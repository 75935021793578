import { Button, Snackbar, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const FileUpload = (props) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.size > props.maxSize) {
      setError(
        `File size is too large. Max file size is ${
          props.maxSize / 1024 / 1024
        } MB.`
      );
    } else if (!selectedFile) {
      setError("No file selected.");
    } else {
      setFile(selectedFile);
      setError("");
    }
  };

  const handleUpload = async () => {
    if (file) {
      if (props.validateFile) {
        const validationError = props.validateFile(file);
        if (validationError) {
          setError(validationError);
          setOpen(true);
          return;
        }
      }

      try {
        const success = await props.onUpload(file);
        if (success) {
          setFile(null);
          setOpen(true);
          setSuccess(props.successMessage || "File uploaded successfully");
        } else {
          setOpen(true);
          setError(props.errorMessage || "File upload failed");
        }
      } catch (err) {
        setOpen(true);
        setError("An error occurred while uploading the file");
      }
    }
  };

  return (
    <>
      <TextField
        size="small"
        id="file"
        type="file"
        accept={props.accept}
        onChange={handleFileChange}
        error={error !== ""}
        helperText={
          <Typography variant="caption" sx={{ color: error ? "red" : "green" }}>
            {error || success}
          </Typography>
        }
        variant="outlined"
        fullWidth
      />
      <Button
        sx={{ marginLeft: "10px" }}
        size="small"
        variant="contained"
        color="primary"
        disabled={error !== "" || success !== ""}
        onClick={handleUpload}
      >
        {props.uploadButtonText || "UPLOAD"}
      </Button>
    </>
  );
};

FileUpload.defaultProps = {
  maxSize: 2 * 1024 * 1024,
  uploadButtonText: "Upload",
  accept: "",
  validateFile: null,
  onUpload: () => {},
  successMessage: "",
  errorMessage: "",
};

export default FileUpload;
