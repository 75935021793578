import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { useLazyQuery, withApollo } from "react-apollo";

import BasicTable from "../../../components/ui/Table/Table";
import { Close } from "@mui/icons-material";
import GroupFilter from "../../../components/ui/GroupFilter/GroupFilter";
import IsLoadingHOC from "../../../Hoc/IsLoadingHOC";
import Pageloader from "../../../components/ui/pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTable from "../../../components/ui/Table/ReactTable";
import ReactTooltip from "react-tooltip";
import compose from "recompose/compose";
import { isEmpty } from "lodash";
import { loader } from "graphql.macro";
import { question } from "../../../utilities/pf101TrackerQuestion";
import styles from "./styles";
import useDebounce from "../../../hooks/useDebounce";
import { useEffect } from "react";
import { useUserDetailsContext } from "../../../context/userDetails";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const MCS_TRACKER = loader(
  "../../../graphql/schema/pf101/pf101-tracker.graphql"
);
const PF101_TRACKER_DETAILS = loader(
  "../../../graphql/schema/pf101/pf101-tracker-details.graphql"
);
const EXPORT_MCS_TRACKER = loader(
  "../../../graphql/schema/pf101/export-pf101-tracker.graphql"
);
const GET_SCHOOLS_GROUP_NAMES = loader(
  "../../../graphql/schema/pf101/get-schools-groups-names.graphql"
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Pf101Tracker = (props) => {
  const {
    state: { search = "" },
    clearSearch,
  } = useUserDetailsContext();
  const debouncedSearch = useDebounce(search, 1000);
  const { classes, setLoading } = props;
  const [groupNames, setGroupNames] = React.useState([]);
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const [level, setLevel] = useState({ key: "level1", val: null });
  const [levelDetail, setLevelDetail] = useState();
  const [trackerData, setTrackerData] = useState([]);
  const [perPage, setPerPage] = useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const [userId, setUserId] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [mcsData, setMcsData] = useState([]);
  const [count] = useState(15);
  const [groupId, setGroup] = React.useState([]);
  const [resetFilter, setResetFilter] = useState(false);

  const [getSchoolGroupNames] = useLazyQuery(GET_SCHOOLS_GROUP_NAMES, {
    fetchPolicy: "network-only",
    onCompleted({ getSchoolsGroupNames }) {
      setLoading(false);
      setGroupNames(getSchoolsGroupNames);
    },
    onError() {
      setLoading(false);
    },
  });

  const [getPf101Tracker, { fetchMore }] = useLazyQuery(MCS_TRACKER, {
    variables: {
      data: {
        page: 1,
        perPage: 20,
        data: {
          name: "",
          groups: groupId,
          filter: {
            [level?.key]: level?.val,
          },
        },
      },
    },
    fetchPolicy: "network-only",
    onCompleted({ getPf101Tracker }) {
      setTrackerData(getPf101Tracker?.result);
      setActivePage(getPf101Tracker?.page);
      setPageFineshedCount(getPf101Tracker.totalCount);
      setLoading(false);
      setResetFilter(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    setActivePage(1);
    getPf101Tracker({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          data: {
            name: debouncedSearch,
            groups: groupId,
            filter: {
              [level?.key]: level?.val,
            },
          },
        },
      },
    });
  }, [debouncedSearch]);

  const [pf101TrackerDetails] = useLazyQuery(PF101_TRACKER_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted({ getPf101TrackDetails }) {
      let temp = [];
      if (
        [
          "level1",
          "level2",
          "level3",
          "level4",
          "level5",
          "level6",
          "level7",
          "level8",
          "level9",
        ].includes(levelDetail)
      ) {
        const updateData = question[levelDetail]["questions"].map(
          (item, i) => ({
            ...item,
            ...getPf101TrackDetails?.level_data?.level_data[i],
          })
        );
        let l = Math.min(
          updateData.length,
          getPf101TrackDetails?.average?.avg_time_data.length
        );
        for (let i = 0; i < l; i++) {
          temp.push(
            { ...updateData[i] },
            {
              id: "Average",
              name: "--",
              points: "--",
              exercise_date: "--",
              activity_time:
                getPf101TrackDetails?.average?.avg_time_data[i]
                  ?.avg_activity_time,
              exercise_time:
                getPf101TrackDetails?.average?.avg_time_data[i]
                  ?.avg_exercise_time,
            }
          );
        }
        temp.push(
          ...updateData.slice(l),
          ...getPf101TrackDetails?.average?.avg_time_data
            .slice(l)
            .map((item) => ({
              id: "Average",
              name: "--",
              points: "--",
              exercise_date: "--",
              activity_time: item?.avg_activity_time,
              exercise_time: item?.avg_exercise_time,
            })),
          {
            id: "Total",
            name: "--",
            exercise_date: "--",
            points: getPf101TrackDetails?.total_points,
            activity_time: getPf101TrackDetails?.total_spent_reading_time,
            exercise_time: getPf101TrackDetails?.total_activity_time,
          }
        );
      } else if (
        ["challenge1", "challenge2", "challenge3"].includes(levelDetail)
      ) {
        temp = [
          {
            ...getPf101TrackDetails?.challenge_data,
            id: levelDetail.charAt(levelDetail.length - 1),
            status: "Completed",
          },
        ];
      } else if ("level10" === levelDetail) {
        const [toolkitDate, certificateDownload] =
          getPf101TrackDetails?.level_10_data;
        temp = [
          {
            toolkitDate,
            certificateDownload,
            activity: "Wrapup + Review",
            id: 1,
          },
        ];
      }
      setMcsData(temp);
      setOpen(true);
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  const [exportPf101memberTracker] = useLazyQuery(EXPORT_MCS_TRACKER, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = response.exportPf101Tracker.url;
      hiddenElement.download = "pf101_tracker.csv";
      hiddenElement.click();
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    fetchMore({
      variables: {
        data: {
          page: pageNumber,
          perPage: 20,
          data: {
            name: debouncedSearch,
            groups: groupId,
            filter: {
              [level?.key]: level?.val,
            },
          },
        },
      },

      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setTrackerData(fetchMoreResult?.getPf101Tracker?.result);
        // return Object.assign({}, prev, {
        //   feed: [...prev.feed, ...fetchMoreResult.feed]
        // });
      },
    });
    setActivePage(pageNumber);
  };

  useEffect(() => {
    getSchoolGroupNames();
    getPf101Tracker();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClearFilter = () => {
    setLoading(true);
    setLevel({ key: "level1", val: null });
    setResetFilter(true);
    setGroup([]);
    setActivePage(1);
    clearSearch();
    getPf101Tracker({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          data: {
            name: "",
            groups: [],
            filter: {
              level1: null,
            },
          },
        },
      },
    });
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleSorting = (row, val) => {
    setLoading(true);
    setLevel({ key: row, val });
    setActivePage(1);
    getPf101Tracker({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          data: {
            name: debouncedSearch,
            groups: groupId,
            filter: {
              [row]: val,
            },
          },
        },
      },
    });
  };

  return (
    <div>
      <Grid container className={classes.searchRow} spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          {groupNames && groupNames.length > 0 && (
            <div>
              <GroupFilter
                resetFilter={resetFilter}
                data={groupNames}
                getFilterValue={(value) => {
                  setGroup(value);
                  getPf101Tracker({
                    variables: {
                      data: {
                        page: activePage,
                        perPage: 20,
                        data: {
                          name: debouncedSearch,
                          groups: value,
                          filter: {
                            [level?.key]: level?.val,
                          },
                        },
                      },
                    },
                  });
                }}
              />
            </div>
          )}
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <div>
            {pageFineshedCount > 0 && (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={20}
                totalItemsCount={pageFineshedCount}
                pageRangeDisplayed={5}
                onChange={handleGetFineshedMatchesPageClick}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <ReactTable
        resetFilter={resetFilter}
        activePage={activePage}
        count={pageFineshedCount}
        rows={trackerData}
        filterFunction={(row, value) => {
          handleSorting(row, value);
        }}
        columns={[
          {
            name: "Full Name",
            row: "name",
          },
          {
            name: "Level 1",
            row: "level1",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => {
              return (
                <Box
                  onClick={() => {
                    setUserName(name);
                    setLevelDetail("level1");
                    setLoading(true);
                    pf101TrackerDetails({
                      variables: {
                        data: {
                          user_id: userId,
                          lesson: 1,
                        },
                      },
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      value >= Number("100")
                        ? "green"
                        : isEmpty(value) || value === "0"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value >= Number("100")
                    ? "Completed"
                    : isEmpty(value) || value === "0"
                    ? "Not Completed"
                    : `${value}%`}
                </Box>
              );
            },
          },
          {
            name: "Level 2",
            row: "level2",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLoading(true);
                  setUserName(name);
                  setLevelDetail("level2");
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 2,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Level 3",
            row: "level3",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLoading(true);
                  setLevelDetail("level3");
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 3,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Challenge 1",
            row: "level11",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLoading(true);
                  setLevelDetail("challenge1");
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 11,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Level 4",
            row: "level4",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLevelDetail("level4");
                  setLoading(true);
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 4,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Level 5",
            row: "level5",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLevelDetail("level5");
                  setLoading(true);
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 5,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Level 6",
            row: "level6",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLevelDetail("level6");
                  setLoading(true);
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 6,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Challenge 2",
            row: "level12",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLevelDetail("challenge2");
                  setLoading(true);
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 12,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Level 7",
            row: "level7",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLevelDetail("level7");
                  setLoading(true);
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 7,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Level 8",
            row: "level8",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLevelDetail("level8");
                  setLoading(true);
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 8,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Level 9",
            row: "level9",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLevelDetail("level9");
                  setLoading(true);
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 9,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Challenge 3",
            row: "level13",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLevelDetail("challenge3");
                  setLoading(true);
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 13,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
          {
            name: "Level 10",
            row: "level10",
            filterList: [
              { key: "", value: "None" },
              { key: "Completed", value: "Completed" },
              { key: "Not Completed", value: "Not Completed" },
            ],
            customRowComponent: (value, { userId, name }) => (
              <Box
                onClick={() => {
                  setLevelDetail("level10");
                  setLoading(true);
                  setUserName(name);
                  pf101TrackerDetails({
                    variables: {
                      data: {
                        user_id: userId,
                        lesson: 10,
                      },
                    },
                  });
                }}
                sx={{
                  cursor: "pointer",
                  color:
                    value >= Number("100")
                      ? "green"
                      : isEmpty(value) || value === "0"
                      ? "red"
                      : "orange",
                }}
              >
                {value >= Number("100")
                  ? "Completed"
                  : isEmpty(value) || value === "0"
                  ? "Not Completed"
                  : `${value}%`}
              </Box>
            ),
          },
        ]}
      />
      <div className={classes.schoolsBtn}>
        <div className={classes.clearFilterBtn}>
          <button onClick={handleClearFilter}>CLEAR ALL FILTERS</button>
        </div>
        <div className={classes.exportBtn}>
          <Tooltip
            title="This will export only the data that is currently shown above to
            excel. If you want to export all the data in this tab, make sure you
            clear all filters before using the export button."
          >
            <button
              data-tip
              data-for="main"
              onClick={() => {
                exportPf101memberTracker({
                  variables: {
                    data: {
                      data: {
                        name: debouncedSearch,
                        groups: groupId,
                        filter: {
                          [level?.key]: level?.val,
                        },
                      },
                    },
                  },
                });
                setLoading(true);
              }}
            >
              EXPORT
            </button>
          </Tooltip>
        </div>
      </div>

      {open && (
        <BootstrapDialog
          maxWidth="xl"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Box display={"flex"} justifyContent="space-between" mr={5}>
              <Typography variant="h6">
                {question[levelDetail]["modalHeaderName"]}
              </Typography>
              <Typography variant="h6">{userName}</Typography>
            </Box>
          </BootstrapDialogTitle>
          <DialogContent dividers={"paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <ReactTable
                showNo={false}
                rows={mcsData}
                columns={
                  [
                    "level1",
                    "level2",
                    "level3",
                    "level4",
                    "level5",
                    "level6",
                    "level7",
                    "level8",
                    "level9",
                  ].includes(levelDetail)
                    ? [
                        {
                          name: "Activity",
                          row: "id",
                        },
                        {
                          name: "Name",
                          row: "name",
                        },
                        {
                          name: "Time Spent Reading",
                          row: "activity_time",
                        },
                        {
                          name: "Activity Time",
                          row: "exercise_time",
                        },
                        {
                          name: "Completion Date",
                          row: "exercise_date",
                        },
                        {
                          name: "Points Earned",
                          row: "points",
                        },
                      ]
                    : ["challenge1", "challenge2", "challenge3"].includes(
                        levelDetail
                      )
                    ? [
                        {
                          name: "Challenge",
                          row: "id",
                        },
                        {
                          name: "Time to Completion",
                          row: "exercise_time",
                        },

                        {
                          name: "Status",
                          row: "status",
                        },
                        {
                          name: "Completion Date",
                          row: "created_at",
                        },
                      ]
                    : [
                        {
                          name: "Task",
                          row: "id",
                        },
                        {
                          name: "Activity",
                          row: "activity",
                        },

                        {
                          name: "Toolkit Download Date & time",
                          row: "toolkitDate",
                        },
                        {
                          name: "Certificate Download Date & time",
                          row: "certificateDownload",
                        },
                      ]
                }
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </BootstrapDialog>
      )}
    </div>
  );
};

Pf101Tracker.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);

export default enhance(Pf101Tracker);
