import { BrowserRouter, Switch } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";

import PrivateRoute from "./private-route";
import PublicRoute from "./public-route";
import React from "react";

const Router = () => (
  <BrowserRouter>
    <Switch>
      {publicRoutes.map(
        ({
          key,
          exact,
          path,
          component,
          layout,
          allowedRoles,
          name,
          searchBar = true,
          role = [],
        }) => (
          <PublicRoute
            key={key}
            exact={exact}
            path={path}
            component={component}
            layout={layout}
            allowedRoles={allowedRoles}
            name={name}
            searchBar={searchBar}
            role={role}
          />
        )
      )}
      {/* {privateRoutes.map(
        ({ key, exact, path, component, layout, allowedRoles }) => (
          <PrivateRoute
            key={key}
            exact={exact}
            path={path}
            component={component}
            layout={layout}
            allowedRoles={allowedRoles}
          />
        )
      )} */}
    </Switch>
  </BrowserRouter>
);

export default Router;
