const styles = () => ({
    
	userTableSec: {
		// display: 'flex',
		// overflow: 'hidden',
		// position: 'relative',
		// overflowY: 'auto',
		marginBottom: '70px',
	},
	userTableDiv: {
		width: 'calc(100% - 4px)',
		background: '#fff',
		boxShadow: '0 0px 2px rgb(25 25 25 / 20%)',
		transition: 'all .3s ease-in-out',
		marginLeft: '2px',
		marginBottom: '20px',
		overflow: 'auto',
		borderRadius: '15px 11px 0px 0px',
		'& table': {
				width: '100%',
				background: '#036',
				tableLayout: 'fixed',
				border: '1px solid #ccc',
				borderSpacing: '0',
				borderCollapse: 'collapse',
				'& thead': {
					width: '100%',
					borderRadius: '15px 11px 0 0',
					color: '#fff',
						'& th': {
								padding: '25px 10px 10px',
								fontSize: '14px',
								textAlign: 'left',
								width: '125px',
								background: ' #036',
								color: 'white',
								fontFamily: 'museosans-700',
								fontWeight: 'normal',
								'&:nth-child(1)':{
									width: '42px',
									textAlign: 'center',
									padding: '25px 10px 10px',			
								},
								'&:nth-child(2)':{
									width: '300px',
								},
								'&:nth-child(3)':{
									width: '250px',
								},
								'&:nth-child(4)':{
									width: '350px',
								},
								'&:nth-child(5)':{
									width: '200px',
								},
								'&:nth-child(7)':{
									width: '100px',
								},
								'&:nth-child(8)':{
									width: '100px',
								},
								'&:nth-child(9)':{
									width: '200px',
								},
								'& i': {
										marginLeft: '5px',
										cursor: 'pointer',
								}
						},
				},
				'& tbody': {
					background: '#fff',
					'& tr':{
						background: '#fff',
						'&:nth-child(even)':{
							background: '#f2f2f2',
						},
							'& td': {
									padding: '8px 10px',
									fontSize: '14px',
									borderBottom: '1px solid #ededed',
									verticalAlign: 'middle',
									color: '#333',
									wordBreak: 'break-all',
									'&:nth-child(1)':{
										width: '42px',
										textAlign: 'center',
										padding: '8px 13px',
									},
									'&:nth-child(2)':{
										width: '300px',
									},
									'&:nth-child(3)':{
										width: '250px',
									},
									'&:nth-child(4)':{
										width: '350px',
									},
									'&:nth-child(5)':{
										width: '200px',
									},
									'&:nth-child(7)':{
										width: '100px',
									},
									'&:nth-child(8)':{
										width: '100px',
									},
									'&:nth-child(9)':{
										width: '200px',
									},
									'& select':{
										font: 'inherit',
										color: 'inherit',
									}
								}
						}
				},
		}
},
    headingDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        '& span':{
            '& ul': {
                margin: '0px',
                margingTop: '10px'
            },
        }
    },
    heading: {
        fontSize: '20px',
        color: '#0069AA',
        margin: '0px',
    },
    noteError: {
        color: '#ff0606',
        padding: '10px',
        fontSize: '14px',
        background: '#ffefef',
        textAlign: 'center',
        fontWeight: '400',
        lineHeight: '1.66',
        marginBottom: '10px',
				width: "100%",
    },
    closeBtn: {
        // display: 'inline-block',
        // padding: '6px 12px',
        // marginBottom: '0',
        // fontSize: '14px',
        // fontWeight: '400',
        // lineHeight: '1.42857143',
        // textAlign: 'center',
        // whiteSpace: 'nowrap',
        // verticalAlign: 'middle',
        // touchAction: 'manipulation',
        // cursor: 'pointer',
        // userSelect: 'none',
        // border: '1px solid transparent',
        // borderRadius: '4px',
        // color: '#333',
        // backgroundColor: '#fff',
        // borderColor: '#ccc',
					border: '0px',
					padding: '0px',
					background: 'transparent',
					'&:focus':{
						outline: '0',
					},
				'& img':{
					width: '22px',
					height: '22px',
					cursor: 'pointer',
				}
    },
		statusBtnSpan:{
			display: 'block',
			textAlign: 'center',
		},
    closedAtBtn:{
        backgroundColor: '#FEC001',  
        padding: '5px 15px',
        boxShadow: 'none',
        color: '#fff',
        marginRight: '10px',
    },
    activeBtn: {
        backgroundColor: '#036',
        borderColor: '#036',
        padding: '5px 15px',
        boxShadow: 'none',
        color: '#fff',
        marginRight: '10px',
    },
    colseButton: {
        padding: '15px',
        borderBottom: '1px solid #e5e5e5',
        '& button': {
            float: 'right',
            fontSize: '21px',
            lineHeight: '1',
            color: '#000',
            textShadow: '0 1px 0 #fff',
            filter: 'alpha(opacity=20)',
            opacity: '.2',
            padding: '0',
            cursor: 'pointer',
            background: '0 0',
            border: '0',
        },
        '& h3': {
            height: '0px',
        }
    },
    updateBtn: {
        marginLeft: 'auto',
        // marginRight: 'auto',
        width: '60%',
        '& span': {
            color: 'white',
            transform: 'none',
        }
    },
    updateText: {
        marginBottom: '15px',
        color: '#3070bf',

    },
    sucessPopup: {
        textAlign: 'center',
        padding: "50px 30px",
        '& p': {
            fontWeight: '500',
            fontSize: '14px',
        },
        '& img': {
            maxWidth: '150px',
        }
    },
    statusSuccess: {
        display: 'inline',
        padding: '.2em .6em .3em',
        fontSize: '75%',
        color: '#fff',
        backgroundColor: '#5cb85c',
        borderRadius: '.25em'
    },
    statusInActive: {
        display: 'inline',
        padding: '.2em .6em .3em',
        fontSize: '75%',
        color: '#fff',
        backgroundColor: '#e23d63',
        borderRadius: '.25em'
    },
    statusClose:{
        // color: '#fff',
        // background: 'red',
        // borderRadius: '50px',
        // padding: '3px 8px',
    },
    statusBtn:{
        // background: 'green',
        // borderRadius: '50px',
        // color: '#fff',
        // padding: '3px 8px',
    },
    High:{
			width: "100%",
			cursor: "pointer",
			'& i':{
        color: "#C60808",
        fontSize: "20px",
			}
    },
    Medium:{
			width: "100%",
			cursor: "pointer",
			'& i':{
        color: "#D6B73B",
        fontSize: "20px",
			}
    },
    Low:{
			width: "100%",
			cursor: "pointer",
			'& i':{
        color: "#63AD63",
        fontSize: "20px",
			}
    },
    None:{
			width: "100%",
			cursor: "pointer",
			'& i':{
        color: "#333333",
        fontSize: "20px",
			}
    },
    prioritySelect: {
        cursor: "pointer",
				display: 'block',
				textAlign: 'center',
				position: 'relative',
    },
		prioritySelectDiv:{
			display: 'inline-block',
		},
    priorityList: {
			backgroundColor: '#F5F2F2',
			left: '-105px',
			bottom: '-62px',
			transform: 'scale(1)',
			MozTransform: 'scale(1)',
			WebkitTransform: 'scale(1)',
			margin: '0',
			background: '#f5f2f2',
			padding: '8px 0px',
			textAlign: 'left',
			borderRadius: '4px',
			minWidth: '128px',
			top: 'auto',
			zIndex: '999',
			position: 'absolute',
			fontSize: '14px',
			border: '1px solid rgba(0,0,0,.15)',
			boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
			'&:before':{
				content: '""',
				position: 'absolute',
				bottom: '50%',
				width: '-1px',
				height: '0px',
				borderRight: '10px solid transparent',
				borderLeft: '10px solid #b3b1b1',
				borderTop: '10px solid transparent',
				borderBottom: '10px solid transparent',
				marginBottom: '-10px',
				right: '-20px',
			},
			'& span':{
				display: 'block',
				padding: '5px 15px',
				cursor: 'pointer',
				width: 'calc(100% - 30px)',
				fontSize: '14px',
				lineHeight: '1.42857143',
				color: '#333',
				'&:hover':{
					backgroundColor: '#D8D8D8',
				},
				'& i':{
					fontSize: '20px',
					paddingRight: '8px',
				}
			}
    },
    activePriority:{
        width: "100%",
        backgroundColor: "#D8D8D8",
    },
		filterDrop: {
			display: 'block',
			position: 'absolute',
			background: '#e9e6e6',
			color: '#000',
			padding: 0,
			left: '50%',
			transform: 'translatex(-50%)',
			minWidth: '220px',
			fontFamily: 'museosans-100',
			listStyle: 'none',
			zIndex: 1,
			top: '29px',
			margin: '0px 0px 10px',
			'&::before': {
					borderLeft: '9px solid transparent',
					borderRight: '9px solid transparent',
					content: '""',
					position: 'absolute',
					width: 0,
					background: 'transparent none repeat scroll 0 0',
					borderBottom: '9px solid #e9e6e6',
					borderTop: '10px solid transparent',
					height: 'auto',
					left: '50%',
					top: '-19px',
					transform: 'translateX(-50%)',
			},
			'& li': {
					padding: '4px 20px',
					fontSize: '14px',
					lineHeight: '1.4',
					cursor: 'pointer',
					color: '#333',
					display: 'flex',
					'&:hover':{
						color: '#0069aa',
					},
					'&:first-child':{
						paddingLeft: '43px',
						'& p':{
							margin: '0px',
						}
					},
				}
		},
		goBtn:{
			color: '#fff',
			background: '#016aab',
			padding: '4px 13px',
			display: 'inline-block',
			marginLeft: '40px',
			marginBottom: '4px',
			borderRadius: '5px',
			cursor: 'pointer',
			border: '0px',
    	fontSize: '14px',
			'&:hover':{
				color: 'orange',
				textDecoration: 'underline',
				background: '#036',
			}
		},
    filterTwoDrop: {
        '& li':{
				'& ul':{
					padding: '0 4px',
					listStyle: 'none',
					maxHeight: '300px',
					overflow: 'auto',
					cursor: 'auto',
					'& li':{
						color: '#333',
    				padding: '2px 2px 2px 20px',
						'& label':{
							cursor: 'pointer',
							marginBottom: '0',
							display: 'inline-block',
							maxWidth: '100%',
							fontWeight: '700',
							color: '#333',
							'& input[type=checkbox]':{
								margin: '0 3px 0px -17px',
								verticalAlign: 'middle',
							}
						},
						'&:hover':{
							'& label':{
								color: '#0069aa',
							}
						},
					}
				}
			}
    },
    exportBtn: {
        backgroundColor: "#006AAA",
        color: '#fff'
    },
    dialog: {
        position: 'fixed',
        transform: 'translateX(-50%)',
        top: "5%",
        left: "50%",
        maxWidth: '550px',
        backgroundColor: "#fff",
        borderRadius: "5px",
        padding: "10px",
        boxSizing: 'border-box',
        zIndex: '2',
        boxShadow: "0px 0px 5px rgba(0,0,0,0.4)",
    },
    title: {
        display: 'flex',
        justifyContent: "space-between",
        borderBottom: "1px solid #ddd",
        '& h2': {
            margin: '10px 0px 0px 10px',
        }
    },
    content: {
        padding: '10px',
        borderBottom: "1px solid #ddd",
        width: '100%',
        marginBottom: '10px',
        '& label': {
            
        },
        '& input': {
            width: '90%',
            borderRadius: "5px",
            border: "1px solid #ddd",
            padding: '10px',
            margin: "0px",
            color: "#777"
        }

    },
		notesDiv:{
			position: 'relative',
		},
		notes: {
        padding: '0px 10px',
        margin: '0px',
				position: 'relative',
				'& a':{
					color: '#337ab7',
					textDecoration: 'none',
					cursor: 'pointer',
					'&:hover':{
						color: '#23527c',
					}
				}
    },
     dialogNotesList: {
			position: 'absolute',
			background: '#fff',
			right: '70px',
			bottom: '-50px',
			marginBottom: '-120px',
			maxHeight: '240px',
			overflow: 'auto',
			height: '280px',
			width: '595px',
			textAlign: 'left',
			padding: '15px',
			zIndex: '999',
			borderRadius: '4px',
			boxShadow: '0 0 2px 1px #b5b5b5',
			'& h3':{
				fontSize: '16px',
				lineHeight: '18px',
				fontWeight: '700',
				textDecoration: 'underline',
				border: 'none',
				margin: '0 0 5px',
			},
			'& ul':{
				padding: '0',
				display: 'block',
				margin: '0',
				'& li':{
					display: 'block',
					padding: '0 0 4px',
					lineHeight: '20px',
					fontSize: '14px',
					borderBottom: '1px solid #ccc',
					margin: '0 0 8px',
					'&:last-child':{
						borderBottom: '0px solid #ccc',
					}
				}
			}
    },
		noteDate:{
			color: '#0069aa',
			display: 'block',
			textAlign: 'right',
		},
    list: {
        width: '100%',
        margin: '0px',
        listStyle: 'none',
        padding: "0px",
        '& li':{
            borderBottom: '1px solid #ddd',
        },
        '&:nth-child(1)':{
            borderBottom: 'none',
        }

    },
    listItem: {
        width: 'calc(100% - 40px)',
        position: 'relative',
        padding: '5px 0px 10px 0px',
        '& a':{
            float: 'right',
            textDecoration: 'none',
            color: "#0069AA",
        },
				'& input':{
					margin: '2px 5px',
				}
    },
		searchBar:{
			position: 'relative',
			'& input':{
					borderRadius: '15px',
					border: '1px solid #ccc',
					padding: '7px 15px',
					outline: '0',
					width: 'calc(100% - 30px)',
				color: '#555',
					font: 'inherit',
					fontFamily: 'inherit',
					fontSize: 'inherit',
					lineHeight: 'inherit',
					fontSize: '14px !important',
					fontWeight: '100 !important',
					maxWidth: '380px',
					'&::placeholder':{
							color: '#7c7c7c',
					}
			}
	},
	searchRow:{
			marginBottom: '15px',
	},
	filterIcon:{
		position: 'relative',
		'& img':{
			maxWidth: '15px',
			marginLeft: '5px',
			cursor: 'pointer',
			position: 'relative',
			top: '2px',
		}
	},
	filterIconNew:{
		'& ul':{
			minWidth: '135px',
		}
	},
	paginationBottom:{
		display: 'flex',
		alignItems: 'center',
	},
	paginationLeft:{
		width: '70%',
		'& ul':{
			float: 'none',
		}
	},
	schoolsBtn: {
		width: '30%',
		display: 'flex',
		float: 'right',
		margin: '10px 0px',
		justifyContent: 'end',
		'& > div':{
			border: '1px solid transparent',
		},
		'& a':{
			textAlign: 'center',
			display: 'inline-block',
			fontWeight: '400',
			padding: '5px 12px',
			marginBottom: '0',
			fontSize: '13px',
			lineHeight: '1.42857143',
			whiteSpace: 'nowrap',
			verticalAlign: 'middle',
			touchAction: 'manipulation',
			WebkitUserSelect: 'none',
			MsUserSelect: 'none',
			userSelect: 'none',
			backgroundImage: 'none',
			border: '1px solid transparent',
			borderRadius: '4px',
			textDecoration: 'none',
			WebkitTransition: 'all .5s ease 0s',
			MozTransition: 'all .5s ease 0s',
			color: '#fff',
			cursor: 'pointer',
		},
		'& button':{
			textAlign: 'center',
			display: 'inline-block',
			fontWeight: '400',
			padding: '5px 12px',
			marginBottom: '0',
			fontSize: '13px',
			lineHeight: '1.42857143',
			whiteSpace: 'nowrap',
			verticalAlign: 'middle',
			touchAction: 'manipulation',
			WebkitUserSelect: 'none',
			MsUserSelect: 'none',
			userSelect: 'none',
			backgroundImage: 'none',
			border: '1px solid transparent',
			borderRadius: '4px',
			textDecoration: 'none',
			WebkitTransition: 'all .5s ease 0s',
			MozTransition: 'all .5s ease 0s',
			color: '#fff',
			cursor: 'pointer',
		}
	},
msgBtn:{
	'& a':{
		backgroundColor: '#036',
	},
	'& button':{
		backgroundColor: '#036',
	}
},
addAdminBtn:{
	'& a':{
		backgroundColor: 'green',
	},
	'& button':{
		backgroundColor: 'green',
	}
},
clearFilterBtn:{
	'& a':{
		backgroundColor: '#caa336',
	},
	'& button':{
		backgroundColor: '#caa336',
	}
},
exportBtn:{
	'& a':{
		backgroundColor: '#006AAA',
	},
	'& button':{
		backgroundColor: '#006AAA',
	}
},
noData:{
	'& td':{
		fontWeight: '600',
		padding: '30px 0px !important',
	}
},
});

export default styles;
