import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import styles from './styles';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';

function Filter(props) {
  const {list, activeFilter, handleFilterChange, type, classes, loadFilterData} = props;
  return (
    <ul className={classNames(classes.filterDrop, classes.filterDropTopic)} >
      <li className = {classNames(classes.listItem)} key={type} onClick={() => handleFilterChange("All", type)}>
        <p>All</p>
      </li>
      {
        list.length > 0 && (
          list.map((value, index) =>
            <li className = {classNames(classes.listItem)} key={`${type}${index}`}>
              <input
                id={`${type}${index}`}
                type="checkbox"
                checked={activeFilter.includes(value)}
                onClick={() => handleFilterChange(value, type)}
              />
              <label htmlFor={`${type}${index}`}>{value}</label>
            </li>
          )
        )
      }
      <button onClick={()=>loadFilterData(type)} className={classes.goBtn}>Go</button>
    </ul>
  )
}

Filter.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);
export default enhance(Filter);
