import {
  Button,
  DialogContentText,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import {
  UserDetailsContext,
  useUserDetailsContext,
} from "../../../context/userDetails";
import { useMutation, useQuery } from "react-apollo";

import { ACCESS_TOKEN } from "../../../utilities/userUtils";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import IsLoadingHOC from "../../../Hoc/IsLoadingHOC";
import Typography from "@mui/material/Typography";
import { compose } from "recompose";
import { isEmpty } from "lodash";
import { loader } from "graphql.macro";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";

const INSTITUTIONS = loader("../../../graphql/schema/ui/Institutions.graphql");
const UPDATE_INSTITUTION_ID = loader(
  "../../../graphql/schema/ui/updateInstitutions.graphql"
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  position: "absolute",
  left: 10,
  top: 50,
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ChangeClientModal = ({
  open,
  handleClose,
  setSideBar,
  header = "",
  setLoading,
  ...props
}) => {
  const { selectedInstitutionHandler } = useUserDetailsContext();
  const [institutionData, setInstitutionData] = useState([]);
  const [selectSchool, setSelectSchool] = React.useState(
    JSON.parse(localStorage.getItem("institution"))
  );
  const [showError, setShowError] = useState(false);
  const { loading } = useQuery(INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setLoading(false);
      setInstitutionData(response.Institutions);
    },
  });

  const [updateInstitutionId] = useMutation(UPDATE_INSTITUTION_ID, {
    onCompleted({ updateInstitutionId }) {
      setLoading(false);
      const { admin } = updateInstitutionId;
      localStorage.setItem(
        "institution_details",
        JSON.stringify(admin?.selected_institution)
      );
      if (admin?.institution_id === "0") {
        setSideBar("main_admin");
        localStorage.setItem(
          "institution",
          JSON.stringify({
            id: null,
            institution_name: "Main Admin",
            school_type: "inkwiry",
          })
        );
        selectedInstitutionHandler({
          id: null,
          institution_name: "Main Admin",
          school_type: "inkwiry",
        });
        props.history.push("/inkwiry-admins");
      } else if (selectSchool?.school_type.toLowerCase() === "school") {
        localStorage.setItem("institution", JSON.stringify(selectSchool));
        selectedInstitutionHandler(selectSchool);
        setSideBar("school_admin");
        props.history.push("/client-dashboard");
      } else if (selectSchool?.school_type.toLowerCase() === "business") {
        localStorage.setItem("institution", JSON.stringify(selectSchool));
        selectedInstitutionHandler(selectSchool);
        setSideBar("business_admin");
        props.history.push("/client-dashboard");
      }
      handleClose();
    },
    onError(errors) {
      //   let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      //   setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const handleClientChange = (e) => {
    if (e.target.value !== "") {
      setSelectSchool(e.target.value);
      setShowError(false);
    }
  };

  const handleSaveClientChange = () => {
    if (isEmpty(selectSchool)) {
      setShowError(true);
    } else if (selectSchool && selectSchool === "Proceed as Main Admin") {
      setLoading(true);
      updateInstitutionId({
        variables: {
          institution_id: null,
        },
      });
      //   localStorage.removeItem("institution");
    } else if (!isEmpty(selectSchool)) {
      setLoading(true);
      updateInstitutionId({
        variables: {
          institution_id: selectSchool?.id,
        },
      });
      // history.push("/client-dashboard");
      //   setSideBar("B");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="school-popup"
    >
      <DialogTitle
        sx={{ fontSize: "18px", fontWeight: 500 }}
        id="alert-dialog-title"
      >
        {header}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "15px !important" }}>
        <DialogContentText id="alert-dialog-description">
          <label style={{ fontSize: "16px" }}>
            Use the drop down to navigate to the appropriate admin tool
          </label>
          <div>
            <FormControl
              variant="filled"
              className="formControl"
              error={showError}
            >
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                // value={selectSchool}
                onChange={handleClientChange}
                placeholder={header}
                label="Select School"
                displayEmpty
                defaultValue={""}
                sx={{ fontSize: "14px", lineHeight: 2 }}
              >
                <MenuItem sx={{ fontSize: "14px" }} value="">
                  <em>{header}</em>
                </MenuItem>
                {JSON.parse(localStorage.getItem(ACCESS_TOKEN))?.admin_type ===
                  "inkwiry" && (
                  <MenuItem
                    sx={{ fontSize: "14px" }}
                    value="Proceed as Main Admin"
                  >
                    {" "}
                    Proceed as Main Admin{" "}
                  </MenuItem>
                )}
                {institutionData.length > 0 &&
                  institutionData.map((l, index) => {
                    return (
                      <MenuItem sx={{ fontSize: "14px" }} value={l}>
                        {" "}
                        {l.institution_name}{" "}
                      </MenuItem>
                    );
                  })}
              </Select>
              {showError && (
                <FormHelperText>{"Please Select Admin"}</FormHelperText>
              )}
            </FormControl>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="model-footer">
        <Button onClick={handleSaveClientChange} color="primary" autoFocus>
          GO
        </Button>
      </DialogActions>
    </Dialog>
    // <BootstrapDialog
    //   onClose={handleClose}
    //   aria-labelledby="customized-dialog-title"
    //   open={open}
    // >
    //   <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
    //     {header}
    //   </BootstrapDialogTitle>
    //   <DialogContent dividers>
    //     <Typography gutterBottom>
    //       Select the school from the drop down to go to that school's admin
    //       tool.
    //     </Typography>
    //     <div>
    //       <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
    //         <Select
    //           disabled={loading}
    //           size="small"
    //           labelId="demo-dialog-select-label"
    //           id="demo-dialog-select"
    //           placeholder="{header}"
    //           defaultValue={selectSchool}
    //           onChange={handleClientChange}
    //         >
    //           <MenuItem value="">
    //             <em>{header}</em>
    //           </MenuItem>
    //           {JSON.parse(localStorage.getItem(ACCESS_TOKEN))?.admin_type ===
    //             "inkwiry" && (
    //             <MenuItem value="Proceed as Main Admin">
    //               {" "}
    //               Proceed as Main Admin{" "}
    //             </MenuItem>
    //           )}
    //           {institutionData.length > 0 &&
    //             institutionData.map((l, index) => {
    //               return <MenuItem value={l}> {l.institution_name} </MenuItem>;
    //             })}
    //         </Select>
    //       </FormControl>
    //     </div>
    //   </DialogContent>
    //   <DialogActions>
    //     <Button variant="contained" onClick={handleSaveClientChange}>
    //       Go
    //     </Button>
    //   </DialogActions>
    // </BootstrapDialog>
  );
};
const enhance = compose(IsLoadingHOC);

export default enhance(ChangeClientModal);
