const styles = () => ({
  userTableSec: {
    // display: 'flex',
    // overflow: 'hidden',
    // position: 'relative',
    // overflowY: 'auto',
    marginBottom: "70px",
  },
  searchRow: {
    marginBottom: "15px",
  },
  searchBar: {
    position: "relative",
    "& input": {
      borderRadius: "15px",
      border: "1px solid #ccc",
      padding: "7px 15px",
      outline: "0",
      width: "calc(100% - 30px)",
      color: "#555",
      font: "inherit",
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      fontSize: "14px !important",
      fontWeight: "100 !important",
      maxWidth: "380px",
      "&::placeholder": {
        color: "#7c7c7c",
      },
    },
  },
  headingDiv: {
    display: "flex",
    alignItems: "center",
    float: "right",
  },
  searchInput: {
    padding: "21px",
    "& input": {
      borderRadius: "50px",
      outline: 0,
      width: "289px",
      marginLeft: "20px",
      padding: "5px",
      textIndent: "10px",
    },
  },
  userTableDiv: {
    width: "calc(100% - 4px)",
    background: "#fff",
    boxShadow: "0 0px 2px rgb(25 25 25 / 20%)",
    transition: "all .3s ease-in-out",
    marginLeft: "2px",
    marginBottom: "2px",
    overflow: "auto",
    borderRadius: "15px 11px 0px 0px",
    minHeight: "500px",
    "& table": {
      width: "100%",
      background: "#036",
      tableLayout: "fixed",
      border: "1px solid #ccc",
      borderSpacing: "0",
      borderCollapse: "collapse",
      "& thead": {
        width: "100%",
        borderRadius: "15px 11px 0 0",
        color: "#fff",
        "& th": {
          padding: "25px 10px 10px",
          fontSize: "14px",
          textAlign: "left",
          width: "150px",
          background: " #036",
          color: "white",
          "&:nth-child(1)": {
            width: "calc(42px - 20px)",
            textAlign: "center",
          },
          "&:nth-child(2)": {
            width: "calc(155px - 20px)",
          },
          "&:nth-child(3)": {
            width: "calc(165px - 20px)",
          },
          "&:nth-child(4)": {
            width: "calc(240px - 20px)",
          },
          "&:nth-child(5)": {
            width: "calc(160px - 20px)",
          },
          "&:nth-child(6)": {
            width: "calc(88px - 20px)",
          },
          "&:nth-child(7)": {
            width: "150px",
          },
          "&:nth-child(8)": {
            width: "100px",
          },
          "& i": {
            marginLeft: "5px",
            cursor: "pointer",
          },
        },
      },
      "& tbody": {
        background: "#fff",
        "& tr": {
          background: "#fff",
          "&:nth-child(even)": {
            background: "#f2f2f2",
          },
          "& td": {
            padding: "8px 10px",
            fontSize: "14px",
            // borderBottom: '1px solid #ededed',
            verticalAlign: "middle",
            color: "#333",
            wordBreak: "break-all",
            "&:nth-child(1)": {
              width: "calc(42px - 20px)",
              textAlign: "center",
            },
            "&:nth-child(2)": {
              width: "calc(155px - 20px)",
            },
            "&:nth-child(3)": {
              width: "calc(165px - 20px)",
            },
            "&:nth-child(4)": {
              width: "calc(240px - 20px)",
            },
            "&:nth-child(5)": {
              width: "calc(160px - 20px)",
            },
            "&:nth-child(6)": {
              width: "calc(88px - 20px)",
            },
            "&:nth-child(7)": {
              width: "150px",
            },
            "&:nth-child(8)": {
              width: "100px",
            },
          },
        },
      },
    },
  },
  emailText: {
    width: "200px",
  },
  noteError: {
    color: "#ff0606",
    padding: "10px",
    fontSize: "14px",
    background: "#ffefef",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "1.66",
    marginBottom: "10px",
    "& td": {
      color: "#ff0606 !important",
      padding: "10px",
      fontSize: "14px",
      background: "#ffefef",
      textAlign: "center",
      fontWeight: "400",
      lineHeight: "1.66",
      marginBottom: "10px",
      fontWeight: "600",
    },
  },
  moreInfo: {
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        display: "inline-block",
        cursor: "pointer",
        paddingRight: "12px",
        fontSize: "15px",
      },
    },
  },
  editAdmin: {
    color: "#337ab7",
  },
  openApply: {
    padding: "15px",
    textAlign: "right",
    borderTop: "1px solid #e5e5e5",
  },
  confirmDeleteBtn: {
    display: "inline-block",
    padding: "6px 12px",
    marginBottom: "0",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    userSelect: "none",
    border: "1px solid transparent",
    borderRadius: "4px",
    backgroundColor: "#84a84d",
    color: "#fff",
    borderColor: "#4cae4c",
  },
  closeBtn: {
    display: "inline-block",
    padding: "6px 12px",
    marginBottom: "0",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    userSelect: "none",
    border: "1px solid transparent",
    borderRadius: "4px",
    color: "#333",
    backgroundColor: "#fff",
    borderColor: "#ccc",
  },
  activeBtn: {
    backgroundColor: "#036",
    borderColor: "#036",
    padding: "5px 15px",
    boxShadow: "none",
    color: "#fff",
    marginRight: "10px",
  },
  colseButton: {
    padding: "15px",
    borderBottom: "1px solid #e5e5e5",
    "& button": {
      float: "right",
      fontSize: "21px",
      lineHeight: "1",
      color: "#000",
      textShadow: "0 1px 0 #fff",
      filter: "alpha(opacity=20)",
      opacity: ".2",
      padding: "0",
      cursor: "pointer",
      background: "0 0",
      border: "0",
    },
    "& h3": {
      height: "0px",
    },
  },
  uploadImageDiv: {
    maxWidth: "100%",
    // padding: "0 30px",
  },
  srcImageDiv: {
    display: "inline-block",
    width: "100%",
    border: "1px solid #eaeaea",
    marginTop: "5px",
    borderRadius: "5px",
    position: "relative",
  },
  inputImageDiv: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    "& input": {
      fontSize: "100px",
      position: "absolute",
      left: 0,
      top: 0,
      opacity: 0,
    },
  },
  fileUploadBtn: {
    border: "2px solid gray",
    color: "gray",
    backgroundColor: "white",
    padding: "8px 20px",
    borderRadius: "8px",
  },
  mainDiv: {
    position: "relative",
    padding: "15px",
  },
  inputDiv: {
    marginBottom: "15px",
    "& label": {
      width: "154px",
      display: "-webkit-inline-box",
    },
    "& input": {
      position: "relative",
      width: "92%",
      outline: 0,
      height: "17px",
      padding: "6px 12px",
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "4px",
      webkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
    },
  },
  stateInput: {
    marginBottom: "15px",
    "& label": {
      width: "154px",
      display: "-webkit-inline-box",
    },
    "& >div": {
      width: "60%",
      "& div > div": {
        position: "relative",
        outline: 0,
        height: "17px",
        padding: "6px 12px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: "4px",
        webkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
        boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      },
    },
  },
  updateBtn: {
    marginLeft: "auto",
    // marginRight: 'auto',
    width: "60%",
    "& span": {
      color: "white",
      transform: "none",
    },
  },
  updateText: {
    marginBottom: "15px",
    color: "#3070bf",
  },
  sucessPopup: {
    textAlign: "center",
    padding: "50px 30px",
    "& p": {
      fontWeight: "500",
      fontSize: "14px",
    },
    "& img": {
      maxWidth: "150px",
    },
  },
  statusSuccess: {
    display: "inline",
    padding: ".2em .6em .3em",
    fontSize: "75%",
    color: "#fff",
    backgroundColor: "#5cb85c",
    borderRadius: ".25em",
  },
  statusInActive: {
    display: "inline",
    padding: ".2em .6em .3em",
    fontSize: "75%",
    color: "#fff",
    backgroundColor: "#f0ad4e",
    borderRadius: ".25em",
    cursor: "pointer",
  },
  schoolBtns: {
    "& a": {
      padding: "5px 12px",
      marginBottom: 0,
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      color: "#fff",
      textDecoration: "none",
      float: "right",
      marginTop: "10px",
    },
  },
  schoolsBtn: {
    display: "flex",
    float: "right",
    margin: "10px 0px",
    "& > div": {
      border: "1px solid transparent",
    },
    "& a": {
      textAlign: "center",
      display: "inline-block",
      fontWeight: "400",
      padding: "5px 12px",
      marginBottom: "0",
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      WebkitUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textDecoration: "none",
      WebkitTransition: "all .5s ease 0s",
      MozTransition: "all .5s ease 0s",
      color: "#fff",
      cursor: "pointer",
    },
    "& button": {
      textAlign: "center",
      display: "inline-block",
      fontWeight: "400",
      padding: "5px 12px",
      marginBottom: "0",
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      WebkitUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textDecoration: "none",
      WebkitTransition: "all .5s ease 0s",
      MozTransition: "all .5s ease 0s",
      color: "#fff",
      cursor: "pointer",
    },
  },
  msgBtn: {
    "& a": {
      backgroundColor: "#036",
    },
    "& button": {
      backgroundColor: "#036",
    },
  },
  addAdminBtn: {
    "& a": {
      backgroundColor: "green",
    },
    "& button": {
      backgroundColor: "green",
    },
  },
  clearFilterBtn: {
    "& a": {
      backgroundColor: "#caa336",
    },
    "& button": {
      backgroundColor: "#caa336",
    },
  },
  exportBtn: {
    "& a": {
      backgroundColor: "#006AAA",
    },
    "& button": {
      backgroundColor: "#006AAA",
    },
  },

  filterDrop: {
    display: "block",
    position: "absolute",
    background: "#e9e6e6",
    color: "#000",
    padding: 0,
    left: "50%",
    transform: "translatex(-50%)",
    minWidth: "220px",
    fontFamily: "museosans-100",
    listStyle: "none",
    zIndex: 1,
    top: "29px",
    margin: "0px 0px 10px",
    "&::before": {
      borderLeft: "9px solid transparent",
      borderRight: "9px solid transparent",
      content: '""',
      position: "absolute",
      width: 0,
      background: "transparent none repeat scroll 0 0",
      borderBottom: "9px solid #e9e6e6",
      borderTop: "10px solid transparent",
      height: "auto",
      left: "50%",
      top: "-19px",
      transform: "translateX(-50%)",
    },
    "& li": {
      padding: "4px 20px",
      fontSize: "14px",
      lineHeight: "1.4",
      cursor: "pointer",
      color: "#333",
      "&:hover": {
        color: "#0069aa",
      },
    },
  },
  pl43: {
    "& li": {
      padding: "4px 20px 4px 43px",
    },
  },
  filterIcon: {
    position: "relative",
    "& img": {
      maxWidth: "15px",
      marginLeft: "5px",
      cursor: "pointer",
      position: "relative",
      top: "2px",
    },
  },
  noData: {
    "& td": {
      fontWeight: "600",
      padding: "30px 0px !important",
    },
  },
  active: {
    color: "#0069aa !important",
  },
  createNew: {
    "& a": {
      background: "green",
      padding: "5px 12px",
      marginBottom: 0,
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      color: "#fff",
      textDecoration: "none",
      marginTop: "10px",
    },
  },
  formField: {
    marginBottom: "20px !important",
  },
  inputLeft: {
    display: "inline-block",
    float: "left",
    padding: "0 25px 0 0",
    position: "relative",
    width: "calc(57% - 25px)",
    "& input": {
      width: "calc(100% - 10px)",
      height: "calc(34px - 6px)",
      padding: "2px 5px",
      backgroundColor: "#fff",
      backgroundImage: "none",
      border: "1px solid #ccc",
      borderRadius: "4px",
      WebkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      WebkitTransition:
        "border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s",
      OTransition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
      transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
      fontFamily: "MuseoSans-300",
      fontWeight: "normal",
      color: "#555",
      fontSize: "14px",
      "&::placeholder": {
        color: "#625c5c",
      },
      "&:focus": {
        borderColor: "#66afe9",
        outline: "0",
        WebkitBoxShadow:
          "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
        boxShadow:
          "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
        borderWidth: "1px",
      },
    },
    // '& fieldset':{
    // 	display: 'none',
    // },
    "& select": {
      width: "calc(100% - 0px)",
      maxHeight: "calc(78px - 4px)",
      padding: "2px 5px",
      backgroundColor: "#fff",
      backgroundImage: "none",
      border: "1px solid #ccc",
      borderRadius: "4px",
      WebkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      WebkitTransition:
        "border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s",
      OTransition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
      transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
      color: "#555",
      "&:focus": {
        borderColor: "#66afe9",
        outline: "0",
        WebkitBoxShadow:
          "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
        boxShadow:
          "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
        borderWidth: "1px",
      },
    },
    "& p": {
      marginLeft: "0",
      marginRight: "0",
    },
  },
  imageDetails: {
    fontSize: "14px",
  },
  formGroup: {
    padding: "0",
    // marginBottom: "20px",
    "& label": {
      fontSize: "14px",
      color: "#333",
      fontFamily: "MuseoSans-700",
      fontWeight: "700",
    },
  },
});

export default styles;
