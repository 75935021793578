import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Dialog } from "@material-ui/core";
import compose from 'recompose/compose';
import Pageloader from '../pageloader';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import * as commonFunctions from '../../../utilities/commonFunctions';
import ReactTooltip from 'react-tooltip';

const EMAILS = loader('../../../graphql/schema/ui/sendEmailsToUsers.graphql');
const EMAILSANDNOTIFICATIONS = loader('../../../graphql/schema/ui/SendMessagesAndNotificationsToUsers.graphql');

const NotificationPage = (props) => {
    const { classes, open, selectedNoficationEmails, messageType } = props;
    const [fromName, setFromName] = React.useState("");
    const [titleName, setTitleName] = React.useState("");
    const [bodyName, setBodyName] = React.useState("");
    const [subjectName, setSubjectName] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [fromErrorMessage, setFromErrorMessage] = React.useState("");
    const [titleErrorMessage, setTitleErrorMessage] = React.useState("");
    const [subjectErrorMessage, setSubjectErrorMessage] = React.useState("");


    const [emailsData, { data: result }] = useMutation(EMAILS, {
        onCompleted() {
            setLoading(false);
            props.handleClose();
            setFromName('');
            setTitleName('');
            setBodyName('');
            setSubjectName('');
        },
        onError(errors) {
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
            setErrorMessage(errorMsg);
            setLoading(false);
        },
    });
    const [emailsAndNotificationsData, { data: emailsAndNotificationsResult }] = useMutation(EMAILSANDNOTIFICATIONS, {
        onCompleted() {
            setLoading(false);
            props.handleClose();
            setFromName('');
            setTitleName('');
            setBodyName('');
            setSubjectName('');
        },
        onError(errors) {
            let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
            setErrorMessage(errorMsg);
            setLoading(false);
        },
    });
    const handleClose = () => {
        props.handleClose();
        setFromName('');
        setTitleName('');
        setBodyName('');
        setSubjectName('');
    }
    const handleChange = (e) => {
        setErrorMessage('');
        if (e.currentTarget.id === 'from') {
            setFromName(e.currentTarget.value);
            setFromErrorMessage('');
        } else if (e.currentTarget.id === 'title') {
            setTitleName(e.currentTarget.value);
            setTitleErrorMessage('');
        } else if (e.currentTarget.id === 'body') {
            setBodyName(e.currentTarget.value);
        } else if (e.currentTarget.id === 'subject') {
            setSubjectName(e.currentTarget.value);
            setSubjectErrorMessage('');
        }
    }
    const handleSendEmail = () => {
        if (fromName === "") {
            setFromErrorMessage('Please provide from Name');
        } else if (subjectName === "") {
            setSubjectErrorMessage('Please provide subject Name');
        } else if (titleName === "") {
            setTitleErrorMessage('Please provide title Name');
        } else if (messageType === "Email") {
            setLoading(true);
            emailsData({
                variables: {
                    data: {
                        from: fromName,
                        title: titleName,
                        subject: subjectName,
                        body: bodyName,
                        selectedEmails: selectedNoficationEmails
                    }
                },
            });
        } else {
            setLoading(true);
            emailsAndNotificationsData({
                variables: {
                    data: {
                        from: fromName,
                        title: titleName,
                        subject: subjectName,
                        body: bodyName,
                        selectedEmails: selectedNoficationEmails
                    }
                },
            });
        }
    }
    return (
        <Dialog
            className="popup-model popup-model-new"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {loading === true ? <Pageloader loading={loading} /> : ""}
            <div className="model-content">
                <div className="modal-header">
                    <button onClick={handleClose} >X</button>
                    <h3 className="text-light">Send a message to all selected student</h3>
                </div>
                <div className="model-body">
                    <div className={fromErrorMessage !== '' ? "input-div" + ' ' + classes.errorBorder : "input-div"}>
                        <label>From <span className="tool-info" data-tip data-for="From"><img src="./img/info-icon.svg" alt="" /></span></label>
                        <input id="from" value={fromName} onChange={handleChange} placeholder="admin" />
                        <ReactTooltip id='From' type='info' place='top'>
                            <span>When you send a notification or email, the From field will tell your students who sent the message. You can enter your name to send a personal message, your school name for a general message, or anything else you prefer.</span>
                        </ReactTooltip>
                    </div>
                    <div className={subjectErrorMessage !== '' ? "input-div" + ' ' + classes.errorBorder : "input-div"}>
                        <label>Subject <span className="tool-info" data-tip data-for="Subject"><img src="./img/info-icon.svg" alt="" /></span></label>
                        <input id="subject" value={subjectName} onChange={handleChange} placeholder="Subject" />
                        <ReactTooltip id='Subject' type='info' place='top'>
                            <span>Subject</span>
                        </ReactTooltip>
                    </div>
                    <div className={titleErrorMessage !== '' ? "input-div" + ' ' + classes.errorBorder : "input-div"}>
                        <label>Title <span className="tool-info"  data-tip data-for="Title"><img src="./img/info-icon.svg" alt="" /></span></label>
                        <input id="title" value={titleName} onChange={handleChange} placeholder="Title" />
                        <ReactTooltip id='Title' type='info' place='top'>
                            <span>When your students receive the notification or email, this title will be at the top of that message in larger, blue print. Craft your title to grab their attention.</span>
                        </ReactTooltip>
                    </div>
                    <div className="input-div">
                        <label>Body <span className="tool-info" data-tip data-for="main"><img src="./img/info-icon.svg" alt="" /></span></label>
                        <textarea id="body" rows="9" value={bodyName} onChange={handleChange} placeholder="Hi [First Name]," />
                        <ReactTooltip id='main' type='info' place='top'>
                            <span>The Hi [First Name], is default for the body. It will automatically start your message by saying hi and the student's first name. Click anywhere else to start typing your message. Feel free to sign off - just like in an email -with your name, school, or anything else you prefer.</span>
                        </ReactTooltip>
                    </div>
                </div>
                {errorMessage && (<div className={classes.noteError}>{errorMessage}</div>)}
                <div className="model-footer">
                    <button className="delete-btn" onClick={handleSendEmail}>Send</button>
                    <button onClick={handleClose} className="close-btn">Cancel</button>
                </div>
            </div>
        </Dialog>
    )
}
NotificationPage.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles),
);
export default enhance(NotificationPage);
