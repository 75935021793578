import * as commonFunctions from "../../../utilities/commonFunctions";

import {
  Backdrop,
  Box,
  Collapse,
  Divider,
  Fade,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Typography,
  styled,
} from "@mui/material/";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/react-hooks";

import { ACCESS_TOKEN } from "../../../utilities/userUtils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ChangeClientModal from "../Modal/ChangeClientModal";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Pageloader from "../pageloader";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import { useUserDetailsContext } from "../../../context/userDetails";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const INSTITUTIONS = loader("../../../graphql/schema/ui/Institutions.graphql");
const UPDATE_INSTITUTION_ID = loader(
  "../../../graphql/schema/ui/updateInstitutions.graphql"
);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const ListItemButtonCustom = styled(ListItemButton)(() => ({
  // padding: "5px 10px",
}));

const ListItemTextCustom = styled(ListItemText)(({ theme }) => ({
  color: "#fff",
  fontSize: theme.typography.h3,
  p: {
    color: "#fff",
  },
}));

const getActiveTab = (pathname) => {
  switch (pathname) {
    case "/pf101-dashboard":
    case "/pf101-member-tracker":
    case "/pf101-member-survey":
    case "/pf101-baseline-assessment":
    case "/pf101-final-assessment":
      return "personal_finance";

    case "/mcs-dashboard":
    case "/mcs-student-tacker":
      return "carrer_sketch";

    default:
      break;
  }
};

const BusinessSidbar = (props) => {
  const location = useLocation();

  const { classes, history, setSideBar, schoolType } = props;
  const { state, selectSchoolhandler } = useUserDetailsContext();
  const [openSelectPopup, setOpenSelectPopup] = React.useState(false);

  const [expanded, setExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = React.useState(true);
  const [pf101Dashboard, setPF101Dashboard] = React.useState(false);
  const [sideBarCareerSketch, setSideBarCareerSketch] = React.useState(false);
  const [openMessagePopup, setOpenMessagePopup] = React.useState(false);
  const [selectSchool, setSelectSchool] = React.useState("");
  const [institutionData, setInstitutionData] = React.useState([]);
  const apolloClient = useApolloClient();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [institution] = useLazyQuery(INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(response.Institutions);
      setLoading(false);
    },
  });

  const handleChange = (panel) => {
    setExpanded(panel === expanded ? false : panel);
  };
  useEffect(() => {
    setExpanded(() => getActiveTab(location.pathname));
  }, []);

  const handleChangeSchoolType = () => {
    setOpenSelectPopup(true);
  };

  useEffect(() => {
    institution();
  }, []);

  const handleCareerSideBar = () => {
    setSideBarCareerSketch(!sideBarCareerSketch);
    setPF101Dashboard(false);
  };

  const handlePFSideBar = () => {
    setPF101Dashboard(!pf101Dashboard);
    setSideBarCareerSketch(false);
  };

  const handleSelect = (val) => {
    setSelectSchool(val.target.value);
    if (val.target.value !== "Proceed as Main Admin") {
      selectSchoolhandler(val.target.value);
      localStorage.setItem("institution", JSON.stringify(val.target.value));
    } else {
      localStorage.removeItem("institution");
    }
  };

  const [updateInstitutionId] = useMutation(UPDATE_INSTITUTION_ID, {
    onCompleted({ updateInstitutionId }) {
      setLoading(false);
      setOpenMessagePopup(false);
      const { admin } = updateInstitutionId;
      localStorage.setItem(
        "institution_details",
        JSON.stringify(admin?.selected_institution)
      );
      if (admin?.institution_id === null) {
        history.push("/inkwiry-admins");
      } else {
        history.push("/client-dashboard");
      }
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const handleGOClick = () => {
    if (selectSchool === "Proceed as Main Admin") {
      setSideBar("A");
      setLoading(true);
      updateInstitutionId({
        variables: {
          institution_id: null,
        },
      });
      localStorage.removeItem("institution");
    } else {
      setLoading(true);
      updateInstitutionId({
        variables: {
          institution_id: selectSchool?.id,
        },
      });
      // history.push("/client-dashboard");
      setSideBar("B");
    }
  };

  const changeSchool = () => {
    setOpenMessagePopup(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("Inqwiry::access_token");
    localStorage.removeItem("Inqwiry::user_name");
    localStorage.removeItem("institution");
    apolloClient.resetStore();
    history.push("/");
  };
  return (
    <>
      <List
        dense={true}
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: 360,
          backgroundColor: "#036",
          color: "#fff",
          height: "100%",
          paddingTop: "20px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButtonCustom
          selected={location.pathname === "/client-dashboard"}
          component={NavLink}
          to="/client-dashboard"
        >
          <ListItemTextCustom secondary="Dashboard" />
        </ListItemButtonCustom>

        <ListItemButtonCustom onClick={() => handleChange("personal_finance")}>
          <ListItemTextCustom secondary="Personal Finance 101" />
          {expanded === "personal_finance" ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </ListItemButtonCustom>
        <Collapse
          in={expanded === "personal_finance"}
          timeout="auto"
          unmountOnExit
        >
          <List dense={true} component="div" disablePadding>
            <ListItemButton
              selected={location.pathname === "/pf101-dashboard"}
              component={NavLink}
              to="/pf101-dashboard"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Dashboard" />
            </ListItemButton>
            {/* <ListItemButton
              selected={location.pathname === "/pf101-level-reports"}

              component={NavLink}
              to="/pf101-level-reports"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="PF101 Level Reports" />
            </ListItemButton> */}
            <ListItemButton
              selected={location.pathname === "/pf101-member-tracker"}
              component={NavLink}
              to="/pf101-member-tracker"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Member Tracker" />
            </ListItemButton>
            {/* <ListItemButton
              selected={location.pathname === "/pf101-challenge-analytics"}

              component={NavLink}
              to="/pf101-challenge-analytics"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="PF101 Challenge Reports" />
            </ListItemButton> */}
            <ListItemButton
              selected={location.pathname === "/pf101-member-survey"}
              component={NavLink}
              to="/pf101-member-survey"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Survey" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/pf101-baseline-assessment"}
              component={NavLink}
              to="/pf101-baseline-assessment"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Baseline Assessment" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/pf101-final-assessment"}
              component={NavLink}
              to="/pf101-final-assessment"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Final Assessment" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButtonCustom onClick={() => handleChange("carrer_sketch")}>
          <ListItemTextCustom secondary="My Career Sketch" />
          {expanded === "carrer_sketch" ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </ListItemButtonCustom>
        <Collapse
          in={expanded === "carrer_sketch"}
          timeout="auto"
          unmountOnExit
        >
          <List dense={true} component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname === "/mcs-dashboard"}
              component={NavLink}
              to="/mcs-dashboard"
            >
              <ListItemTextCustom secondary="Dashboard" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname === "/mcs-student-tacker"}
              component={NavLink}
              to="/mcs-student-tacker"
            >
              <ListItemTextCustom secondary="Member Tracker" />
            </ListItemButton>
            {/* <ListItemButton sx={{ pl: 4 }}>
              <ListItemTextCustom secondary="Awareness Challenge Analytics" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemTextCustom secondary="Discovery Challenge Analytics" />
            </ListItemButton> */}
          </List>
        </Collapse>
        <ListItemButtonCustom
          selected={location.pathname === "/member_management"}
          component={NavLink}
          to="/member_management"
        >
          <ListItemTextCustom secondary="Member Management" />
        </ListItemButtonCustom>
        <ListItemButtonCustom
          selected={location.pathname === "/businesses-admins"}
          component={NavLink}
          to="/businesses-admins"
        >
          <ListItemTextCustom secondary="Admin Users" />
        </ListItemButtonCustom>
        <ListItemButtonCustom
          selected={location.pathname === "/document-center"}
          component={NavLink}
          to="/document-center"
        >
          <ListItemTextCustom secondary="Document Center" />
        </ListItemButtonCustom>
        <ListItemButtonCustom onClick={handleChangeSchoolType}>
          <ListItemTextCustom secondary="Change Business" />
        </ListItemButtonCustom>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
          }}
        >
          <Divider sx={{ backgroundColor: "#fff" }} />
          <ListItemButtonCustom>
            <ListItemTextCustom
              sx={{ textAlign: "center" }}
              secondary="Need help? Contact
          support@inkwiry.com"
            />
          </ListItemButtonCustom>
        </Box>
      </List>
      {openSelectPopup && (
        <ChangeClientModal
          header="Select Business"
          setSideBar={setSideBar}
          open={openSelectPopup}
          handleClose={() => setOpenSelectPopup(!openSelectPopup)}
          {...props}
        />
      )}

      {/* <Dialog
        open={openMessagePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="school-popup"
      >
        <Grid container="true" display="flex" justifyContent="space-between">
          <DialogTitle id="alert-dialog-title">{"Select School"}</DialogTitle>
          <IconButton
            aria-label="clear"
            onClick={() => setOpenMessagePopup(false)}
          >
            <ClearIcon />
          </IconButton>
        </Grid>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <label>
              Select the school from the drop down to go to that school's admin
              tool.
            </label>

            <div>
              <FormControl variant="filled" className="formControl">
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={selectSchool}
                  onChange={handleSelect}
                  placeholder="Select School"
                  label="Select School"
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select School</em>
                  </MenuItem>
                  {JSON.parse(localStorage.getItem(ACCESS_TOKEN))
                    ?.admin_type === "inkwiry" && (
                    <MenuItem value="Proceed as Main Admin">
                      {" "}
                      Proceed as Main Admin{" "}
                    </MenuItem>
                  )}
                  {institutionData.length > 0 &&
                    institutionData.map((l, index) => {
                      return (
                        // <MenuItem value={l.id}> {l.institution_name} </MenuItem>
                        <MenuItem value={l}> {l.institution_name} </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
            {errorMessage && (
              <div className="noteError mb10">{errorMessage}</div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="model-footer">
          <Button onClick={handleGOClick} color="primary" autoFocus>
            Go
          </Button>
        </DialogActions>
      </Dialog> */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Coming soon...!
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <Button
                onClick={handleClose}
                style={{
                  color: "white",
                  background: "#003366",
                  marginTop: "10px",
                }}
              >
                Ok
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

// export default withStyles(styles)(BusinessSidbar);
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(BusinessSidbar);
