import Pagination from "react-js-pagination";
import React, { useEffect, useState } from "react";
import { Typography, Tabs, Tab, Box, Grid, Tooltip } from "@mui/material";
import { withStyles, useTheme } from "@material-ui/core/styles";
import * as commonFunctions from "../../../utilities/commonFunctions";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import scrollToComponent from "react-scroll-to-component";
import { withApollo } from "react-apollo";
import compose from "recompose/compose";
import classNames from "classnames";
import { withRouter, Link } from "react-router-dom";
import styles from "../styles";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/react-hooks";
import Pageloader from "../../../components/ui/pageloader";
import ReactTable from "../../../components/ui/Table/ReactTable";
import IsLoadingHOC from "../../../Hoc/IsLoadingHOC";
import { useUserDetailsContext } from "../../../context/userDetails";
import useDebounce from "../../../hooks/useDebounce";
import { isEmpty } from "lodash";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  if (index !== 3)
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const GET_PF101_ASSESSMENT_GRAPH = loader(
  "../../../graphql/schema/pf101/getPF101AssesmentGraph.graphql"
);
const GET_PF101_FINAL_ASSESSMENT = loader(
  "../../../graphql/schema/pf101/finalAssessment/getPF101FinalAssessmentTableView.graphql"
);
const EXPORT_FINAL_ASSESSMENT = loader(
  "../../../graphql/schema/pf101/finalAssessment/exportPF101FinalAssessment.graphql"
);

const PF101FinalAssessment = (props) => {
  const { classes, setLoading } = props;
  const {
    state: { search = "" },
    clearSearch,
  } = useUserDetailsContext();
  const debouncedSearch = useDebounce(search, 1000);
  const [value, setValue] = useState(0);
  const [offset] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("");
  const [openFullNameFilters, setOpenFullNameFilters] = React.useState(false);
  const [baselineAssessment, setBaselineAssessment] = React.useState([]);
  const [AssessmentGraph, setBaselineGraph] = React.useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const theme = useTheme();
  const ref = React.useRef(null);
  const [groupId, setGroup] = React.useState([]);
  const [resetFilter, setResetFilter] = useState(false);

  const [baselineAssessment_f, { fetchMore }] = useLazyQuery(
    GET_PF101_FINAL_ASSESSMENT,
    {
      variables: {
        data: {
          page: 1,
          perPage: 20,
          orderByName: selectedType,
          filter_schools: groupId,
          time_offset: "1",
          name: "",
        },
      },
      fetchPolicy: "network-only",
      onCompleted({ getPF101FinalAssessmentTableView }) {
        setBaselineAssessment(getPF101FinalAssessmentTableView?.result);
        setActivePage(getPF101FinalAssessmentTableView?.page);
        setPageFineshedCount(getPF101FinalAssessmentTableView.totalCount);
        setLoading(false);
        setResetFilter(false);
      },
      onError(error) {
        setLoading(false);
      },
    }
  );
  const [assessmentGraph_f] = useLazyQuery(GET_PF101_ASSESSMENT_GRAPH, {
    variables: {
      data: "final",
    },
    fetchPolicy: "network-only",
    onCompleted(response) {
      response.getPF101AssesmentGraph &&
        setBaselineGraph(response.getPF101AssesmentGraph);
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  const [exportFinalAssessment] = useLazyQuery(EXPORT_FINAL_ASSESSMENT, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = response.exportPF101FinalAssessment.url;
      hiddenElement.download = "pf101_finalAssessment.csv";
      hiddenElement.click();
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  useEffect(
    () => {
      setLoading(true);
      setActivePage(1);
      baselineAssessment_f({
        variables: {
          data: {
            page: 1,
            perPage: 20,
            orderByName: selectedType,
            filter_schools: groupId,
            time_offset: "1",
            name: debouncedSearch,
          },
        },
      });
    },
    [debouncedSearch] // Only call effect if debounced search term changes
  );

  const handleClearFilter = () => {
    setLoading(true);
    setResetFilter(true);
    setActivePage(1);
    setSelectedType("");
    setGroup([]);
    clearSearch();
    baselineAssessment_f({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          orderByName: "",
          filter_schools: [],
          time_offset: "1",
          name: "",
        },
      },
    });
  };

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    fetchMore({
      variables: {
        data: {
          page: pageNumber,
          perPage: 20,
          orderByName: selectedType,
          filter_schools: groupId,
          time_offset: "1",
          name: debouncedSearch,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setBaselineAssessment(
          fetchMoreResult?.getPF101FinalAssessmentTableView?.result
        );
        // return Object.assign({}, prev, {
        //   feed: [...prev.feed, ...fetchMoreResult.feed]
        // });
      },
    });
    setActivePage(pageNumber);
  };

  React.useEffect(() => {
    baselineAssessment_f();
    assessmentGraph_f();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // if(newValue !== 1) assessmentGraph_f();
  };
  const handleSorting = (row, val) => {
    setLoading(true);
    setSelectedType(val);
    setActivePage(1);
    baselineAssessment_f({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          orderByName: val,
          filter_schools: groupId,
          time_offset: "1",
          name: debouncedSearch,
        },
      },
    });
  };
  return (
    <div>
      <div className={classNames(classes.dashboardPageNew, "dashboard-page")}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.dashboardTabs}
        >
          <Tab label="Table View" {...a11yProps(0)} />
          <Tab label="Graph View" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid
            container
            className={classes.searchRow}
            style={{ marginBottom: "10px" }}
          >
            <Grid item md={6} sm={6} xs={12}></Grid>
            <Grid item md={6} sm={6} xs={12}>
              <div>
                {pageFineshedCount > 0 && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={20}
                    totalItemsCount={pageFineshedCount}
                    pageRangeDisplayed={5}
                    onChange={handleGetFineshedMatchesPageClick}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <ReactTable
            resetFilter={resetFilter}
            activePage={activePage}
            count={pageFineshedCount}
            rows={baselineAssessment}
            filterFunction={(row, value) => {
              handleSorting(row, value);
            }}
            columns={[
              {
                name: "Full Name",
                row: "name",
                filterList: [
                  { key: "", value: "None" },
                  { key: "asc", value: "Sort A to Z" },
                  { key: "desc", value: "Sort Z to A" },
                ],
              },
              {
                name: "Question 01",
                row: "question_1_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 02",
                row: "question_2_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 03",
                row: "question_3_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 04",
                row: "question_4_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 05",
                row: "question_5_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 06",
                row: "question_6_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 07",
                row: "question_7_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 08",
                row: "question_8_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 09",
                row: "question_9_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 10",
                row: "question_10_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 11",
                row: "question_11_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 12",
                row: "question_12_status",
                customRowComponent: (value) => (
                  <Box sx={{ color: value === "C" ? "green" : "red" }}>
                    {value === "C" ? "Correct" : "Incorrect"}
                  </Box>
                ),
              },
              {
                name: "Question 13",
                row: "question_13",
              },
              {
                name: "Question 14",
                row: "question_14",
              },
              {
                name: "Question 15",
                row: "question_15",
              },
              {
                name: "Time Spend",
                row: "total_time",
                customRowComponent: (value) =>
                  commonFunctions.convertSecondsToTime(value),
              },
              {
                name: "Completed Date",
                row: "created_date",
              },
            ]}
          />
          <div className={classes.schoolsBtn}>
            <div className={classes.clearFilterBtn}>
              <button onClick={handleClearFilter}>CLEAR ALL FILTERS</button>
            </div>
            <div className={classes.exportBtn}>
              <Tooltip
                title="This will export only the data that is currently shown above to
            excel. If you want to export all the data in this tab, make sure you
            clear all filters before using the export button."
              >
                <button
                  data-tip
                  data-for="main"
                  onClick={() => {
                    exportFinalAssessment({
                      variables: {
                        data: {
                          orderByName: selectedType,
                          filter_schools: groupId,
                          time_offset: "1",
                          name: debouncedSearch,
                        },
                      },
                    });
                    setLoading(true);
                  }}
                >
                  EXPORT
                </button>
              </Tooltip>
            </div>
          </div>
          <div>
            <div className={classNames(classes.questionExpalin, classes.mt50)}>
              <span class="question_list" id="details_question_1">
                Question 1:
              </span>
              <span>
                Complete the table below to calculate Mike’s federal income
                taxes and effective federal income tax rate for 2020. Round to
                two decimals for the Tax Rate percentage.
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>9,988.00</li>
                <li>15,103.50</li>
                <li>15.10</li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 2:
              </span>
              <span>
                There are two common ways to lower the cost of a loan. One is to
                refinance and receive a new loan with a lower interest rate.
                What is the other way to lower the cost of a loan?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li className={classes.correct}>
                  Make extra payments on the loan each month
                </li>
                <li>
                  Refinance and receive a new loan with a longer repayment term
                  to lower the monthly payment
                </li>
                <li>
                  Refinance using a personal loan since personal loans have very
                  low interest rates
                </li>
                <li>Refinancing is the only way to lower the cost of a loan</li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 3:
              </span>
              <span>
                Given to all taxpayers, __________ is a portion of your income
                that is not subject to federal income taxes.
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>Adjustments to Income</li>
                <li className={classes.correct}>Standard Deduction</li>
                <li>Itemized Deductions</li>
                <li>Interest Tax Deduction</li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 4:
              </span>
              <span>
                Mary is looking to diversify her investments rather than
                investing in individual stocks. She's willing to pay more in
                fees to have a fund that is professionally managed and wants
                exposure to stocks, bonds, and alternative investments. What
                investment would you suggest to Mary?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>Brokerage Account</li>
                <li>401(k) Retirement Account</li>
                <li className={classes.correct}>Mutual Fund</li>
                <li>Exchange-Traded Fund (ETF)</li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 5:
              </span>
              <span>
                Mike thinks that technology companies are great investments. He
                wants to invest in a basket of technology companies rather than
                picking individual stocks on his own. He wants an investment
                with low fees that can be traded daily because liquidity is
                important to him. What investment would you suggest to Mike?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>Brokerage Account</li>
                <li>401(k) Retirement Account</li>
                <li>Mutual Fund</li>
                <li className={classes.correct}>Exchange-Traded Fund (ETF)</li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 6:
              </span>
              <span>
                What is the standard repayment time for a student loan?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li className={classes.correct}>10 years</li>
                <li>15 years</li>
                <li>20 years</li>
                <li>30 years</li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 7:
              </span>
              <span>Which of the following is a myth about credit scores?</span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>Checking your credit score does not hurt your score</li>
                <li className={classes.correct}>
                  Your income is a factor in your credit score calculation
                </li>
                <li>There are many ways to calculate a credit score</li>
                <li>
                  Different debts are weighed differently when calculating your
                  score
                </li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 8:
              </span>
              <span>
                Which of the following represents the correct order going from
                the lowest interest rate to the highest interest rate?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>Mortgage, Credit Card, Car Loan</li>
                <li>Credit Card, Car Loan, Mortgage</li>
                <li className={classes.correct}>
                  Mortgage, Car Loan, Credit Card
                </li>
                <li>Credit Card, Mortgage, Car Loan</li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 9:
              </span>
              <span>
                Which of the following best describes the relationship between
                term of the loan, monthly payment, and total interest paid?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>
                  Longer term of the loan = higher monthly payment and higher
                  total interest paid
                </li>
                <li>
                  Longer term of the loan = lower monthly payment and lower
                  total interest paid
                </li>
                <li>
                  Shorter term of the loan = lower monthly payment and higher
                  total interest paid
                </li>
                <li className={classes.correct}>
                  Shorter term of the loan = higher monthly payment and lower
                  total interest paid
                </li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 10:
              </span>
              <span>
                Use the Rule of 72 to estimate how long would it take a $10,000
                investment to grow to $20,000 if that investment earns 8% per
                year?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>6 years</li>
                <li>7 years</li>
                <li>8 years</li>
                <li className={classes.correct}>9 years</li>
              </ul>

              <span class="question_list" id="details_question_2">
                Question 11:
              </span>
              <span>
                Student loan monthly payments are made up of two parts:
                ___________ and interest.
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>Balance</li>
                <li>Capital</li>
                <li>Principal Repayment</li>
                <li className={classes.correct}>Amortization</li>
              </ul>
              <span class="question_list" id="details_question_2">
                Question 12:
              </span>
              <span>
                I am an employer sponsored plan. I let you make contributions
                with pre-tax dollars, which lowers your income taxes. Your
                employer typically matches your contributions with limits. Which
                retirement account am I?
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                <li>Balance</li>
                <li>Capital</li>
                <li>Principal Repayment</li>
                <li className={classes.correct}>Amortization</li>
              </ul>

              <span class="question_list" id="details_question_4">
                Question 13:{" "}
              </span>
              <span>
                Additional personal financial education such as going into more
                detail on Personal Finance 101 topics or building my own
                financial life plan would benefit me.
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              ></ul>

              <span class="question_list" id="details_question_4">
                Question 14:{" "}
              </span>
              <span>
                I feel more confident in my financial life skills and knowledge
                after completing Personal Finance 101.
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              ></ul>

              <span class="question_list" id="details_question_4">
                Question 15:{" "}
              </span>
              <span ng-if="admin_school_type != 'business'">
                Future students would benefit from taking a personal finance
                course.
              </span>
              {/* <span ng-if="admin_school_type == 'business'">
                  Future students and professionals would benefit from taking a
                  personal finance course.
                </span> */}

              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              ></ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* {allPF101Levels ? */}
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                data: {
                  table: "datatable",
                },
                chart: {
                  type: "column",
                  height: 700,
                },
                plotOptions: {
                  column: {
                    stacking: "normal",
                  },
                  series: {
                    cursor: "pointer",
                    point: {
                      events: {
                        click: function () {
                          // goToView(this.series.index+1,this.category);
                        },
                      },
                    },
                  },
                },
                title: {
                  text: "PF101 Final Challenge",
                },
                credits: {
                  enabled: false,
                },
                yAxis: {
                  min: 0,
                  tickInterval: 1,
                  max: 10,
                  gridLineWidth: 0,
                  labels: {
                    format: "{value}",
                    style: {
                      fontSize: "14px",
                    },
                  },
                  title: {
                    text: " ",
                  },
                },
                xAxis: {
                  categories: ["Question 13", "Question 14", "Question 15"],
                  labels: {
                    style: {
                      fontSize: "14px",
                    },
                  },
                  title: {
                    text: " ",
                  },
                },
                tooltip: {
                  formatter: function () {
                    return (
                      "<b>" +
                      this.x +
                      "</b><br/>" +
                      this.series.name +
                      ": " +
                      parseFloat(this.y).toFixed(2) +
                      "<br/>"
                    );
                  },
                  fontSize: "14px",
                },
                legend: {
                  itemStyle: {
                    fontSize: "14px",
                  },
                  align: "center",
                },
                series: [
                  {
                    name: "PF101 Final Challenge",
                    data: [
                      AssessmentGraph["question_13"],
                      AssessmentGraph["question_14"],
                      AssessmentGraph["question_15"],
                    ],
                    color: "#006aaa",
                    legendIndex: 0,
                    borderColor: "#006aaa",
                  },
                ],
              }}
            />
            <div
              className={classNames(
                classes.questionExpalin,
                "full_length_quesions ng-scope"
              )}
            >
              <span class="question_list" id="details_question_1">
                Question 13:{" "}
              </span>
              <span>
                Additional personal financial education such as going into more
                detail on Personal Finance 101 topics or building my own
                financial life plan would benefit me.
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                {" "}
              </ul>
              <span class="question_list" id="details_question_2">
                Question 14:{" "}
              </span>
              <span>
                I feel more confident in my financial life skills and knowledge
                after completing Personal Finance 101.
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                {" "}
              </ul>
              <span class="question_list" id="details_question_3">
                Question 15:{" "}
              </span>
              <span>
                Future students would benefit from taking a personal finance
                course.
              </span>
              <ul
                className={classNames(
                  classes.awarenessOptionsFull,
                  classes.optionsLeft
                )}
              >
                {" "}
              </ul>
            </div>
          </div>
          {/* : <div>No data display graph</div>         
                    } */}
        </TabPanel>
      </div>
    </div>
  );
};

PF101FinalAssessment.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);

export default enhance(PF101FinalAssessment);
