import * as commonFunctions from "../../../utilities/commonFunctions";

import { Dialog, Typography } from "@material-ui/core";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import Filter from "./filter";
import { Grid } from "@material-ui/core";
import IsLoadingHOC from "../../../Hoc/IsLoadingHOC";
import Pageloader from "../pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import React from "react";
import ReactTable from "../Table/ReactTable";
import _ from "lodash";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const GET_CONTACT = loader(
  "../../../graphql/schema/ui/contact-us/getContact.graphql"
);
const UPDATE_CONTACT = loader(
  "../../../graphql/schema/ui/contact-us/updateContact.graphql"
);
const ALLADMINS = loader("../../../graphql/schema/ui/GetAdmins.graphql");
const EXPORT_CONTACTS = loader(
  "../../../graphql/schema/ui/contact-us/exportContacts.graphql"
);

const ContactUs = (props) => {
  const { classes, setLoading } = props;

  const [filteredSubmissionsData, setFilteredSubmissionsData] = React.useState(
    []
  );
  const [submissionsFullData, setSubmissionsFullData] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [activePage, setActivePage] = React.useState(1);
  const [perPage] = React.useState(20);
  const [allAdminsData, setAllAdminsData] = React.useState([]);
  const [openNotesPopup, setOpenNotesPopup] = React.useState(false);
  const [openMessagePopup, setOpenMessagePopup] = React.useState(false);
  const [openEmailPopup, setOpenEmailPopup] = React.useState(false);
  const [notes, setNotes] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState("3");
  const [filterTopicsList, setFilterTopicsList] = React.useState([]);
  const [fetchSubmissionFlag, setFetchSubmissionFlag] = React.useState(true);
  const [email, setEmail] = React.useState({
    from: localStorage.getItem("Inqwiry::user_name"),
    subject: "",
    title: "",
    body: "Hi [First name]",
  });
  const [activePriority, setActivePriority] = React.useState({
    id: 0,
    status: false,
  });
  const [openNotesListPopup, setOpenNotesListPopup] = React.useState({
    id: 0,
    status: false,
  });

  const [filtered, setFiltered] = React.useState({
    topic: [],
    priority: ["High", "Medium", "Low", "None"],
    status: ["completed", "pending"],
  });
  const [openPopOver, setOpenPopOver] = React.useState({
    topic: false,
    priority: false,
    status: false,
  });
  const [activeFilter, setActiveFilter] = React.useState({
    topic: ["ALL"],
    priority: ["High", "Medium", "Low", "None"],
    status: ["completed", "pending"],
  });

  const [currentPageData, setCurrentPageData] = React.useState([]);

  const [getContactData, { data: result }] = useLazyQuery(GET_CONTACT, {
    variables: {},
    fetchPolicy: "network-only",
    onCompleted() {
      if (result.getContact) {
        for (let index = 0; index < result.getContact.length; index++) {
          const element = result.getContact[index];
          element.id = index + 1;
          if (activePage >= 2)
            element.id = index + activePage * perPage - (perPage - 1);
        }
        fetchSubmissionFlag && setSubmissionsFullData(result.getContact);
        setFetchSubmissionFlag(false);
        setFilteredSubmissionsData(result.getContact);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      console.log(error);
      setLoading(false);
    },
  });

  const [exportContactData, { data: exportData }] = useLazyQuery(
    EXPORT_CONTACTS,
    {
      variables: {},
      fetchPolicy: "network-only",
      onCompleted() {
        const data = exportData.exportContacts;
        if (data) {
          var link = document.createElement("a");
          link.download = "support";
          link.href = data.url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
        }
      },
      onError(error) {
        let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
        setErrorMessage(errorMsg);
        console.log(error);
        setLoading(false);
      },
    }
  );
  const { data: response } = useQuery(ALLADMINS, {
    variables: {},
    fetchPolicy: "network-only",
    onCompleted() {
      if (response.GetAdmins) {
        setAllAdminsData(response.GetAdmins);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  const [updateContact] = useMutation(UPDATE_CONTACT, {
    onCompleted() {
      getContactData();
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  React.useEffect(() => {
    setLoading(true);
    getContactData();
  }, []);

  React.useEffect(() => {
    if (result || response) {
      setLoading(false);
    }
  }, [result, response]);

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleOpenNotesPopup = (column_name, row_id) => {
    setOpenNotesPopup(true);
    setSelectedRow(row_id);
    setNotes({
      column_name,
    });
  };

  const handleNotesPopupClose = () => {
    setOpenNotesPopup(false);
  };

  const handleNotesChange = (e) => {
    setNotes({
      column_name: notes.column_name,
      value: e.target.value,
      id: selectedRow,
    });
  };

  const closeSavedNotes = () => {
    setOpenNotesPopup(false);
  };
  const handleSaveNotes = () => {
    if (notes.value !== undefined) {
      closeSavedNotes();
      updateContact({
        variables: {
          data: {
            column_name: notes.column_name,
            value: notes.value,
            id: notes.id,
          },
        },
      });
    }
  };

  const handleChanges = (event, column_name, row_id, statusValue) => {
    switch (column_name) {
      case "assign_to":
        const selected =
          allAdminsData[
            _.findIndex(allAdminsData, { name: event.target.value })
          ];
        updateContact({
          variables: {
            data: {
              column_name,
              value: selected.id,
              id: row_id,
            },
          },
        });
        break;
      case "priority":
        console.log(event, row_id, column_name);
        updateContact({
          variables: {
            data: {
              column_name,
              value: event,
              id: row_id,
            },
          },
        });
        break;
      case "status":
        if (statusValue === "pending") {
          updateContact({
            variables: {
              data: {
                column_name: "status",
                value: "completed",
                id: row_id,
              },
            },
          });
          const current = new Date();
          const value =
            current.getFullYear() +
            "-" +
            (current.getMonth() + 1) +
            "-" +
            current.getDate() +
            " " +
            current.getHours() +
            ":" +
            current.getMinutes() +
            ":" +
            current.getSeconds();
          updateContact({
            variables: {
              data: {
                column_name: "closed_at",
                value: value,
                id: row_id,
              },
            },
          });
        } else {
          updateContact({
            variables: {
              data: {
                column_name: "status",
                value: "pending",
                id: row_id,
              },
            },
          });
          updateContact({
            variables: {
              data: {
                column_name: "closed_at",
                value: "-",
                id: row_id,
              },
            },
          });
        }
        break;
      default:
        break;
    }
  };

  const handleOpenFilterPopOver = (event, type) => {
    if (!openPopOver[type]) {
      setOpenPopOver({ ...openPopOver, [type]: true });
    } else {
      setOpenPopOver({ priority: false, status: false, topic: false });
    }
  };
  React.useEffect(() => {
    let uniqueTopicsList = _.uniqBy(submissionsFullData, (value) => {
      return value.topic;
    });
    uniqueTopicsList = uniqueTopicsList.map((value) => value.topic);
    setFilterTopicsList(uniqueTopicsList);

    setFiltered({ ...filtered, topic: uniqueTopicsList });
    handlePerPageData(filteredSubmissionsData);
  }, [filteredSubmissionsData, activePage]);

  const handleFilterChange = (selectedfilterValues, type) => {
    if (activeFilter[type].includes(selectedfilterValues)) {
      if (selectedfilterValues === "All") {
        setActiveFilter({ ...activeFilter, [type]: [] });
      } else {
        const filterIndex = activeFilter[type].indexOf(selectedfilterValues);
        const newFilter = [...activeFilter[type]];
        newFilter.splice(filterIndex, 1);
        setActiveFilter({ ...activeFilter, [type]: newFilter });
      }
    } else {
      if (selectedfilterValues === "All") {
        if (type === "topic") {
          setActiveFilter({
            ...activeFilter,
            topic: ["All", ...filterTopicsList],
          });
        } else if (type === "priority") {
          setActiveFilter({
            ...activeFilter,
            priority: ["All", "High", "Low", "Medium", "None"],
          });
        } else if (type === "status") {
          setActiveFilter({
            ...activeFilter,
            status: ["All", "completed", "pending"],
          });
        }
      } else {
        setActiveFilter({
          ...activeFilter,
          [type]: [...activeFilter[type], selectedfilterValues],
        });
      }
    }
  };
  const loadFilterData = (type) => {
    setOpenPopOver({ priority: false, status: false, topic: false });
    setActivePage(1);
    console.log(activeFilter);
    getContactData({
      variables: {
        filter: activeFilter,
      },
    });
  };
  const handlePerPageData = () => {
    const indexOfLast = activePage * perPage;
    const indexOfFirst = indexOfLast - perPage;
    setCurrentPageData(
      filteredSubmissionsData.slice(indexOfFirst, indexOfLast)
    );
  };
  const openPriorityList = (id) => {
    setActivePriority({ id, status: true });
  };
  const handleEmailChange = (e) => {
    setEmail({ ...email, [e.target.name]: e.target.value });
  };
  const sendEmail = () => {
    console.log(email);
  };

  const exportContactTableData = () => {
    setLoading(true);
    exportContactData({
      variables: {
        filter: activeFilter,
      },
    });
  };

  return (
    <Typography className="main-bannerRight">
      <div className={classes.userTableSec}>
        <ReactTable
          columns={[
            {
              name: "User Details",
              row: ["first_name", "last_name", "email", "zip_code"],
              customRowComponent: (value) => {
                return (
                  <>
                    <div>
                      <span>
                        <strong>Name: </strong>
                        {`${value.first_name} ${value.last_name}` || "N/A"}
                      </span>
                    </div>
                    <div>
                      <span>
                        <strong>Email: </strong>
                        {value.email || "N/A"}
                      </span>
                    </div>
                    <div>
                      <span>
                        <strong>Zip Code: </strong>
                        {value.zip_code || "N/A"}
                      </span>
                    </div>
                  </>
                );
              },
            },
            {
              name: "Topic",
              row: "topic",
            },
            {
              name: "Question/Comment",
              row: "message",
            },
            {
              name: "Posted on",
              row: "created_at",
            },
            {
              name: "Assign To",
              row: "name",
            },
            {
              name: "Priority",
              row: "name",
            },
            {
              name: "Status",
              row: "name",
            },
            {
              name: "Close on",
              row: "name",
            },
            {
              name: "Notes",
              row: "name",
            },
          ]}
          rows={submissionsFullData}
        />
        <div className={classes.userTableDiv}>
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>User Details</th>
                <th>
                  Topic
                  <span className={classes.filterIcon}>
                    <img
                      onClick={(e) => handleOpenFilterPopOver(e, "topic")}
                      src="./img/filter-icon.png"
                      alt=""
                    />
                    {openPopOver.topic &&
                      !openPopOver.priority &&
                      !openPopOver.status && (
                        <Filter
                          loadFilterData={loadFilterData}
                          handleFilterChange={handleFilterChange}
                          activeFilter={activeFilter.topic}
                          list={filterTopicsList}
                          type="topic"
                        />
                      )}
                  </span>
                </th>
                <th>Question/Comment</th>
                <th>Posted on</th>
                <th>Assign To</th>
                <th>
                  Priority
                  <span
                    className={classes.filterIcon + " " + classes.filterIconNew}
                  >
                    <img
                      onClick={(e) => handleOpenFilterPopOver(e, "priority")}
                      src="./img/filter-icon.png"
                      alt=""
                    />
                    {openPopOver.priority &&
                      !openPopOver.topic &&
                      !openPopOver.status && (
                        <Filter
                          loadFilterData={loadFilterData}
                          handleFilterChange={handleFilterChange}
                          activeFilter={activeFilter.priority}
                          list={filtered.priority}
                          type="priority"
                        />
                      )}
                  </span>
                </th>
                <th>
                  Status
                  <span
                    className={classes.filterIcon + " " + classes.filterIconNew}
                  >
                    <img
                      onClick={(e) => handleOpenFilterPopOver(e, "status")}
                      src="./img/filter-icon.png"
                      alt=""
                    />
                    {openPopOver.status &&
                      !openPopOver.topic &&
                      !openPopOver.topic && (
                        <Filter
                          loadFilterData={loadFilterData}
                          handleFilterChange={handleFilterChange}
                          activeFilter={activeFilter.status}
                          list={filtered.status}
                          type="status"
                        />
                      )}
                  </span>
                </th>
                <th>Close on</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody
              onClick={(e) => {
                if (activePriority.status) setActivePriority({ status: false });
                if (openPopOver.topic) handleOpenFilterPopOver(e, "topic");
                if (openPopOver.priority)
                  handleOpenFilterPopOver(e, "priority");
                if (openPopOver.status) handleOpenFilterPopOver(e, "status");
              }}
            >
              {errorMessage && (
                <div className={classes.noteError}>{errorMessage}</div>
              )}
              {currentPageData.length !== 0 ? (
                currentPageData.map((contact_us_data, index) => (
                  <tr key={contact_us_data.id}>
                    <td>
                      <span>{contact_us_data.id}</span>
                    </td>
                    <td>
                      <div>
                        <span>
                          <strong>Name: </strong>
                          {`${contact_us_data.first_name} ${contact_us_data.last_name}` ||
                            "N/A"}
                        </span>
                      </div>
                      <div>
                        <span>
                          <strong>Email: </strong>
                          {contact_us_data.email || "N/A"}
                        </span>
                      </div>
                      <div>
                        <span>
                          <strong>Zip Code: </strong>
                          {contact_us_data.zip_code || "N/A"}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span>{contact_us_data.topic || "N/A"}</span>
                    </td>
                    <td>
                      <div>
                        <span>{contact_us_data.message || "N/A"}</span>
                      </div>
                    </td>
                    <td>
                      <span>
                        {contact_us_data.created_at
                          ? contact_us_data.created_at
                          : "N/A"}
                      </span>
                    </td>
                    <td>
                      <span>
                        <select
                          name={`admin${index + 1}`}
                          id={`admin${index + 1}`}
                          onChange={(e) =>
                            handleChanges(e, "assign_to", contact_us_data.id)
                          }
                        >
                          <option value="Default">Select admin</option>
                          {allAdminsData.length > 0 &&
                            allAdminsData.map((admin_data) => (
                              <option
                                key={admin_data.id}
                                selected={
                                  admin_data.id === contact_us_data.assign_to &&
                                  true
                                }
                                value={admin_data.name}
                              >
                                {admin_data.name}
                              </option>
                            ))}
                        </select>
                      </span>
                    </td>
                    <td>
                      <span className={classes.prioritySelect}>
                        <div
                          className={classes.prioritySelectDiv}
                          onClick={() => {
                            openPriorityList(contact_us_data.id);
                          }}
                        >
                          {contact_us_data.priority === "High" ? (
                            <span className={classes.High}>
                              <i
                                class="fa fa-exclamation-circle"
                                title="High"
                              ></i>
                            </span>
                          ) : (
                            ""
                          )}
                          {contact_us_data.priority === "Medium" ? (
                            <span className={classes.Medium}>
                              <i
                                class="fa fa-exclamation-circle"
                                title="Medium"
                              ></i>
                            </span>
                          ) : (
                            ""
                          )}
                          {contact_us_data.priority === "Low" ? (
                            <span className={classes.Low}>
                              <i
                                class="fa fa-exclamation-circle"
                                title="Low"
                              ></i>
                            </span>
                          ) : (
                            ""
                          )}
                          {contact_us_data.priority === "None" ||
                          contact_us_data.priority == "none" ? (
                            <span className={classes.None}>
                              <i
                                class="fa fa-exclamation-circle"
                                title="None"
                              ></i>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        {activePriority.status &&
                        activePriority.id === contact_us_data.id ? (
                          <div className={classes.priorityList}>
                            <span
                              onClick={() =>
                                handleChanges(
                                  "High",
                                  "priority",
                                  contact_us_data.id
                                )
                              }
                              className={classNames(
                                classes.High,
                                contact_us_data.priority === "High"
                                  ? classes.activePriority
                                  : ""
                              )}
                            >
                              <i
                                class="fa fa-exclamation-circle"
                                title="High"
                              ></i>
                              High
                            </span>
                            <span
                              onClick={() =>
                                handleChanges(
                                  "Medium",
                                  "priority",
                                  contact_us_data.id
                                )
                              }
                              className={classNames(
                                classes.Medium,
                                contact_us_data.priority === "Medium"
                                  ? classes.activePriority
                                  : ""
                              )}
                            >
                              <i
                                class="fa fa-exclamation-circle"
                                title="Medium"
                              ></i>
                              Medium
                            </span>
                            <span
                              onClick={() =>
                                handleChanges(
                                  "Low",
                                  "priority",
                                  contact_us_data.id
                                )
                              }
                              className={classNames(
                                classes.Low,
                                contact_us_data.priority === "Low"
                                  ? classes.activePriority
                                  : ""
                              )}
                            >
                              <i
                                class="fa fa-exclamation-circle"
                                title="Low"
                              ></i>
                              Low
                            </span>
                            <span
                              onClick={() =>
                                handleChanges(
                                  "None",
                                  "priority",
                                  contact_us_data.id
                                )
                              }
                              className={classNames(
                                classes.None,
                                contact_us_data.priority === "None"
                                  ? classes.activePriority
                                  : "",
                                contact_us_data.priority === "none"
                                  ? classes.activePriority
                                  : ""
                              )}
                            >
                              <i
                                class="fa fa-exclamation-circle"
                                title="None"
                              ></i>
                              None
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </span>
                    </td>
                    <td>
                      <span className={classes.statusBtnSpan}>
                        <button
                          className={classNames(
                            classes.closeBtn,
                            contact_us_data.status === "pending"
                              ? classes.statusClose
                              : classes.statusBtn
                          )}
                          onClick={(e) =>
                            handleChanges(
                              e,
                              "status",
                              contact_us_data.id,
                              contact_us_data.status
                            )
                          }
                        >
                          {contact_us_data.status === "pending" ? (
                            <img
                              src="./img/status_pending.png"
                              alt=""
                              title="pending"
                            />
                          ) : (
                            <img
                              src="./img/status_ok.png"
                              alt=""
                              title="completed"
                            />
                          )}
                        </button>
                      </span>
                    </td>
                    <td>
                      <span>
                        <span>
                          {contact_us_data.closed_at !== "-"
                            ? contact_us_data.closed_at
                            : " "}
                        </span>
                      </span>
                    </td>
                    <td>
                      <div className={classes.notesDiv}>
                        <span
                          onMouseOver={() =>
                            setOpenNotesListPopup({
                              id: contact_us_data.id,
                              status: true,
                            })
                          }
                          onMouseLeave={() => {
                            setOpenNotesListPopup({
                              id: contact_us_data.id,
                              status: false,
                            });
                          }}
                          className={classNames(classes.notes)}
                        >
                          <a
                            onClick={(e) =>
                              handleOpenNotesPopup("note", contact_us_data.id)
                            }
                            title="Add Note"
                          >
                            Notes({contact_us_data.note.length})
                          </a>
                          {contact_us_data.note.length !== 0 &&
                            openNotesListPopup.id === contact_us_data.id &&
                            openNotesListPopup.status && (
                              <div
                                className={classNames(classes.dialogNotesList)}
                                onMouseLeave={() => {
                                  setOpenNotesListPopup({
                                    id: contact_us_data.id,
                                    status: false,
                                  });
                                }}
                              >
                                <h3>Previous Notes</h3>
                                <ul className={classNames(classes.list)}>
                                  {contact_us_data.note.map((not) => (
                                    <li
                                      className={classNames(classes.listItem)}
                                    >
                                      <Typography variant="span">
                                        {not.note}
                                      </Typography>
                                      <Typography
                                        variant="span"
                                        className={classes.noteDate}
                                      >
                                        added by <b>{not.admin_name}</b> on{" "}
                                        {not.created_at}
                                      </Typography>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className={classes.noData}>
                  <td colspan="9">No Contact Submissions</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={classes.paginationBottom}>
          <div className={classes.paginationLeft}>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={perPage}
              totalItemsCount={filteredSubmissionsData.length}
              pageRangeDisplayed={5}
              onChange={handleGetFineshedMatchesPageClick}
            />
          </div>
          <div className={classes.schoolsBtn}>
            <div className={classes.msgBtn}>
              <button onClick={() => setOpenMessagePopup(true)}>Message</button>
            </div>
            <div className={classes.clearFilterBtn}>
              <button
                onClick={() => {
                  Promise.resolve()
                    .then(
                      setActiveFilter({
                        topic: filterTopicsList,
                        priority: ["High", "Medium", "Low", "None"],
                        status: ["completed", "pending"],
                      })
                    )
                    .then(loadFilterData());
                }}
              >
                CLEAR ALL FILTERS
              </button>
            </div>
            <div className={classes.exportBtn}>
              <button onClick={() => exportContactTableData()}>EXPORT</button>
            </div>
          </div>
        </div>
        <Dialog
          className="popup-model"
          open={openMessagePopup}
          onClose={() => setOpenMessagePopup(false)}
          aria-labelledby="message-dialog-title"
          aria-describedby="message-dialog-description"
        >
          <div className="model-content">
            <div className="modal-header">
              <button
                onClick={() => {
                  setOpenMessagePopup(false);
                }}
              >
                X
              </button>
              <h3>Send a message to all selected students</h3>
            </div>
            <div className="model-body">
              You can send a custom message to all selected students in the form
              of an Inkwiry notification, email or both. Use the buttons below
              to choose one of those options. Then, draft your message and send
              to your students.
            </div>
            <div className="model-footer">
              <button
                onClick={() => {
                  setOpenMessagePopup(false);
                  setOpenEmailPopup(true);
                }}
                className="delete-btn"
              >
                Email
              </button>
              <button
                onClick={() => setOpenMessagePopup(false)}
                className="close-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
        <Dialog
          className="popup-model popup-model-new"
          open={openEmailPopup}
          onClose={() => {
            setOpenEmailPopup(false);
          }}
          aria-labelledby="note-dialog-title"
          aria-describedby="note-dialog-description"
        >
          <div className="model-content">
            <div className="modal-header">
              <button
                onClick={() => {
                  setOpenEmailPopup(false);
                }}
              >
                <i className="fa fa-times"></i>
              </button>
              <h3 className="text-light">
                Send a message to all selected students
              </h3>
            </div>
            <div className="model-body">
              <div className="input-div">
                <label htmlFor="from">
                  From{" "}
                  <span className="tool-info" title="Test">
                    <img src="./img/info-icon.svg" alt="" />
                  </span>
                </label>
                <input
                  onChange={(e) => handleEmailChange(e)}
                  value={email.from}
                  type="text"
                  name="from"
                  id="from"
                  className={classNames(classes.input)}
                />
              </div>
              <div className="input-div">
                <label htmlFor="subject">
                  Subject{" "}
                  <span className="tool-info" title="Test">
                    <img src="./img/info-icon.svg" alt="" />
                  </span>
                </label>
                <input
                  onChange={(e) => handleEmailChange(e)}
                  placeholder="subject"
                  value={email.subject}
                  type="text"
                  name="subject"
                  id="subject"
                  className={classNames(classes.input)}
                />
              </div>
              <div className="input-div">
                <label htmlFor="title">
                  Title{" "}
                  <span className="tool-info" title="Test">
                    <img src="./img/info-icon.svg" alt="" />
                  </span>
                </label>
                <input
                  onChange={(e) => handleEmailChange(e)}
                  value={email.title}
                  placeholder="title"
                  type="text"
                  name="title"
                  id="title"
                  className={classNames(classes.input)}
                />
              </div>
              <div className="input-div">
                <label htmlFor="body">
                  Body{" "}
                  <span className="tool-info" title="Test">
                    <img src="./img/info-icon.svg" alt="" />
                  </span>
                </label>
                <textarea
                  onChange={(e) => handleEmailChange(e)}
                  rows="9"
                  value={email.body}
                  name="body"
                  className={classNames(classes.input)}
                  id=""
                  cols="60"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <div className="model-footer">
              <button onClick={sendEmail} className="delete-btn">
                Send
              </button>
              <button
                onClick={() => {
                  setOpenEmailPopup(false);
                  setEmail({
                    from: localStorage.getItem("Inqwiry::user_name"),
                    subject: "",
                    title: "",
                    body: "Hi [First name]",
                  });
                }}
                className="close-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
        <Dialog
          className="popup-model"
          open={openNotesPopup}
          onClose={handleNotesPopupClose}
          aria-labelledby="note-dialog-title"
          aria-describedby="note-dialog-description"
        >
          <div className="model-content">
            <div className="modal-header">
              <button onClick={handleNotesPopupClose}>X</button>
              <h3>Add Notes</h3>
            </div>
            <div className="model-body">
              <div className="input-div">
                <textarea
                  onChange={(e) => handleNotesChange(e)}
                  name="w3review"
                  className={classNames(classes.input)}
                  id=""
                  cols="60"
                  rows="6"
                ></textarea>
              </div>
            </div>
            <div className="model-footer">
              <button onClick={handleSaveNotes} className="delete-btn">
                Save
              </button>
              <button onClick={closeSavedNotes} className="close-btn">
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </Typography>
  );
};
ContactUs.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);
export default enhance(ContactUs);
