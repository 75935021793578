import * as commonFunctions from "../../../utilities/commonFunctions";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTheme, withStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NumberFormat from "react-number-format";
import Pageloader from "../../../components/ui/pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import scrollToComponent from "react-scroll-to-component";
import styles from "../styles";
import { useLazyQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

const GET_PF101_CHALLENGE_COMPARISION = loader(
  "../../../graphql/schema/pf101/challengeReports/getPF101ChallengeComparisionAnalyticsGraph.graphql"
);
const GET_PF101_CHALLENGE = loader(
  "../../../graphql/schema/pf101/challengeReports/getPF101ChallengeAnalyticsGraph.graphql"
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  if (index !== 3)
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const PF101ChallengeAnalytics = (props) => {
  const { classes } = props;

  const [value, setValue] = useState(0);
  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [challenge, setAllPF101Challenge] = React.useState({});
  const [challengeComparsion, setChallengeComparsion] = React.useState([1]);
  const [offset] = React.useState(0);
  const [groupId, setGroup] = React.useState([]);

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setLoading(true);

    newValue === 3
      ? getPf101ChallengeComparision({
          variables: {
            data: [],
          },
        })
      : getAllPf101ChallengeGraph({
          variables: {
            data: {
              challenge_type: `challenge_${newValue + 1}`,
              groups: groupId,
            },
          },
        });
  };
  const [getAllPf101ChallengeGraph, { data: PF101ChallengesData }] =
    useLazyQuery(GET_PF101_CHALLENGE, {
      fetchPolicy: "network-only",
      onCompleted() {
        PF101ChallengesData.getPF101ChallengeAnalyticsGraph &&
          setAllPF101Challenge(
            PF101ChallengesData.getPF101ChallengeAnalyticsGraph
          );
        setLoading(false);
      },
      onError(error) {
        setLoading(false);
      },
    });
  const [
    getPf101ChallengeComparision,
    { data: getPf101ChallengeComparisionData },
  ] = useLazyQuery(GET_PF101_CHALLENGE_COMPARISION, {
    fetchPolicy: "network-only",
    onCompleted() {
      getPf101ChallengeComparisionData.getPF101ChallengeComparisionAnalyticsGraph &&
        setChallengeComparsion(
          getPf101ChallengeComparisionData.getPF101ChallengeComparisionAnalyticsGraph
        );
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  useEffect(() => {
    getAllPf101ChallengeGraph({
      variables: {
        data: {
          challenge_type: `challenge_1`,
          groups: groupId,
        },
      },
    });
    setLoading(true);
  }, []);
  return (
    <div>
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <div className={classNames(classes.dashboardPage, "dashboard-page")}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.dashboardTabs}
        >
          <Tab label="Challenge 1" {...a11yProps(0)} />
          <Tab label="Challenge 2" {...a11yProps(1)} />
          <Tab label="Challenge 3" {...a11yProps(2)} />
          <Tab label="Challenge Comparison" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={value} index={0} dir={theme.direction}>
          {challenge.graph_data ? (
            <div className={classes.percentageRow}>
              <div
                className={classNames(
                  classes.percentageText,
                  "percentage_data ng-scope"
                )}
              >
                <span class="ng-binding">
                  # of Students = {challenge.users}
                </span>
                <span> | </span>
                <span class="ng-binding">
                  Average Attempts = {challenge.avg_attempts}
                </span>
                <span> | </span>
                <span class="ng-binding">Average XP = {challenge.avg_xp}</span>
              </div>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                  },

                  title: {
                    text: "Average Attempts and XP Earned",
                    align: "left",
                  },

                  xAxis: {
                    categories: challenge.graph_data.question,
                  },

                  yAxis: [
                    {
                      labels: {
                        formatter: function () {
                          return parseFloat(this.value).toFixed(1);
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      min: 0,
                      tickInterval: 1,
                      max: 4,
                      opposite: true,
                      title: {
                        text: "Average Attempts",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                    {
                      // Tertiary yAxis
                      min: 0,
                      tickInterval: 25,
                      max: 100,
                      title: {
                        text: "Average XP Earned",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      labels: {
                        formatter: function () {
                          return this.value;
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                  ],
                  tooltip: {
                    formatter: function () {
                      return (
                        "<b> " +
                        this.x +
                        "</b><br/>" +
                        this.series.name +
                        ": " +
                        parseFloat(this.y).toFixed(1)
                      );
                    },
                  },
                  plotOptions: {
                    series: {
                      cursor: "pointer",
                      point: {
                        events: {
                          click: function () {
                            var id = this.category.split(" ");
                            var level = parseInt(id[1]);
                            scrollToComponent(
                              document.getElementById(
                                `details_question_1_${level}`
                              ),
                              { offset: offset, align: "top", duration: 500 }
                            );
                          },
                        },
                      },
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  series: [
                    {
                      name: "Average XP Earned",
                      yAxis: 1,
                      data: challenge.graph_data.xp_earned,
                      color: "#6982ce",
                    },
                    {
                      name: "Total Average XP Earned",
                      yAxis: 1,
                      data: challenge.graph_data.total_xp_earned,
                      color: "#d4af37",
                      type: "line",
                      marker: {
                        enabled: false,
                      },
                    },
                    {
                      name: "Average Attempts",
                      yAxis: 0,
                      data: challenge.graph_data.attempts,
                      color: "orange",
                    },
                    {
                      type: "line",
                      name: "Total Average Attempts",
                      yAxis: 0,
                      data: challenge.graph_data.total_attempts,
                      color: "#333333",
                      marker: {
                        enabled: false,
                      },
                    },
                  ],
                }}
              />
              <div
                className={classNames(
                  classes.questionExpalin,
                  classes.mt50,
                  "ng-scope"
                )}
                ng-if="table_select=='challenge_1'"
              >
                <span id="details_question_1_1">Question 1: </span>
                <span>
                  What day is "Tax Day" in the United States? This is the day
                  that personal tax returns are due.
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>
                    April 10<sup>th</sup>
                  </li>
                  <li className={classNames(classes.correct)}>
                    April 15<sup>th</sup>
                  </li>
                  <li>
                    April 20<sup>th</sup>
                  </li>
                  <li>April Fools</li>
                </ul>
                <span id="details_question_1_2">Question 2: </span>
                <span>
                  Complete the following equation: Assets – Liabilities = ?
                </span>
                <ul className={classes.awarenessOptions}>
                  <li className={classNames(classes.correct)}>Net Worth</li>
                  <li>Net Income</li>
                  <li>Adjusted Gross Income</li>
                  <li>Net Change in Cash</li>
                </ul>
                <span id="details_question_1_3">Question 3: </span>
                <span>
                  Click to open the Net Worth Calculator and use the following
                  details to calculate Mr. B's net worth.
                </span>
                <ul className={classes.awarenessOptionsFull}>
                  <li>
                    Mr. B has $1,000 in cash, $3,000 in his checking account,
                    and $5,000 in his savings account
                  </li>
                  <li>
                    He just financed the purchase of a used car with 50% in cash
                    and 50% from a loan that is worth $20,000
                  </li>
                  <li>
                    He has $50,000 in his 401(k) retirement account and $20,000
                    in his investment account
                  </li>
                  <li>
                    Mr. B is almost done repaying his student loans and has
                    $5,000 outstanding
                  </li>
                </ul>
                <span
                  className={classNames(
                    classes.questionPaddingLeft,
                    "b_net_worth"
                  )}
                >
                  What’s Mr. B’s net worth?
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>$99,000</li>
                  <li>$94,000</li>
                  <li>$84,000</li>
                  <li className={classNames(classes.correct)}>$74,000</li>
                </ul>
                <span id="details_question_1_4">Question 4: </span>
                <span>
                  Put the 3 types of income in order from highest taxes to
                  lowest taxes.
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>Passive &gt; Earned &gt; Portfolio</li>
                  <li>Portfolio &gt; Earned &gt; Passive</li>
                  <li className={classNames(classes.correct)}>
                    Earned &gt; Portfolio &gt; Passive
                  </li>
                  <li>Earned &gt; Passive &gt; Portfolio</li>
                </ul>
                <span id="details_question_1_5">Question 5: </span>
                <span>
                  Social Security and Medicare taxes for self-employed
                  individuals are ____% and for not self-employed individuals
                  are ____%.
                </span>
                <ul className={classes.awarenessOptions}>
                  <li className={classNames(classes.correct)}>
                    15.30% / 7.65%
                  </li>
                  <li>12.40% / 6.20%</li>
                  <li>6.20% / 12.40%</li>
                  <li>7.65% / 15.30%</li>
                </ul>
                <span id="details_question_1_6">Question 6: </span>
                <span>
                  Click to open the Budgeting 101 Calculator and use the
                  following details to calculate the amount Mr. B is saving each
                  month.
                </span>
                <ul className={classes.awarenessOptionsFull}>
                  <li>
                    Mr. B earns $72,000 per year in salary and pays $14,400 a
                    year in taxes
                  </li>
                  <li>
                    His student loan monthly payment is $300, and his car loan
                    payment is $190
                  </li>
                  <li>
                    He buys $300 of clothing and personal care items, spends
                    $300 on entertainment and tech, and $300 on gas
                  </li>
                  <li>
                    He spends $800 on rent and utilities, plus another $800 on
                    food and drink per month
                  </li>
                  <li>
                    His monthly health insurance premium is $150, and he spend
                    about $100 more on other healthcare costs
                  </li>
                  <li>
                    Mr. B has a car insurance policy with a $250 monthly premium
                    and budgets another $100 in car maintenance per month
                  </li>
                </ul>
                <span class="b_net_worth">
                  What's Mr. B's Monthly Cash Left Over for Savings / Invest?
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>$2,090</li>
                  <li>$1,510</li>
                  <li className={classNames(classes.correct)}>$1,210</li>
                  <li>$950</li>
                </ul>
                <span id="details_question_1_7">Question 7: </span>
                <span>
                  Continuing with the Mr. B example, if Mr. B saves and invests
                  $1,210 per month and earns a 7% return on his investment, how
                  much will he have in 15 years? Use the Savings Growth
                  calculator to discover how much Mr. B’s savings can grow to.
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>$209,433</li>
                  <li className={classNames(classes.correct)}>$383,524</li>
                  <li>$285,906</li>
                  <li>$173,553</li>
                </ul>
                <span id="details_question_1_8">Question 8: </span>
                <span>
                  Click to open the Federal Income Taxes Calculator. If Mr. B is
                  single and earns $72,00 per year, how much federal income
                  taxes can he expect to pay?
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>$5,317</li>
                  <li>$6,700</li>
                  <li>$7,542</li>
                  <li className={classNames(classes.correct)}>$8,946</li>
                </ul>
              </div>
            </div>
          ) : (
            <div className={classes.noData}>No data display graph</div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {challenge.graph_data ? (
            <div className={classes.percentageRow}>
              <div
                className={classNames(
                  classes.percentageText,
                  "percentage_data ng-scope"
                )}
              >
                <span class="ng-binding">
                  # of Students = {challenge.users}
                </span>
                <span> | </span>
                <span class="ng-binding">
                  Average Attempts = {challenge.avg_attempts}
                </span>
                <span> | </span>
                <span class="ng-binding">Average XP = {challenge.avg_xp}</span>
              </div>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                  },

                  title: {
                    text: "Average Attempts and XP Earned",
                    align: "left",
                  },

                  xAxis: {
                    categories: challenge.graph_data.question,
                  },

                  yAxis: [
                    {
                      labels: {
                        formatter: function () {
                          return parseFloat(this.value).toFixed(1);
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      min: 0,
                      tickInterval: 1,
                      max: 4,
                      opposite: true,
                      title: {
                        text: "Average Attempts",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                    {
                      // Tertiary yAxis
                      min: 0,
                      tickInterval: 25,
                      max: 100,
                      title: {
                        text: "Average XP Earned",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      labels: {
                        formatter: function () {
                          return this.value;
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                  ],
                  tooltip: {
                    formatter: function () {
                      return (
                        "<b> " +
                        this.x +
                        "</b><br/>" +
                        this.series.name +
                        ": " +
                        parseFloat(this.y).toFixed(1)
                      );
                    },
                  },
                  plotOptions: {
                    series: {
                      cursor: "pointer",
                      point: {
                        events: {
                          click: function () {
                            var id = this.category.split(" ");
                            var level = parseInt(id[1]);
                            scrollToComponent(
                              document.getElementById(
                                `details_question_2_${level}`
                              ),
                              { offset: offset, align: "top", duration: 500 }
                            );
                          },
                        },
                      },
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  series: [
                    {
                      name: "Average XP Earned",
                      yAxis: 1,
                      data: challenge.graph_data.xp_earned,
                      color: "#6982ce",
                    },
                    {
                      name: "Total Average XP Earned",
                      yAxis: 1,
                      data: challenge.graph_data.total_xp_earned,
                      color: "#d4af37",
                      type: "line",
                      marker: {
                        enabled: false,
                      },
                    },
                    {
                      name: "Average Attempts",
                      yAxis: 0,
                      data: challenge.graph_data.attempts,
                      color: "orange",
                    },
                    {
                      type: "line",
                      name: "Total Average Attempts",
                      yAxis: 0,
                      data: challenge.graph_data.total_attempts,
                      color: "#333333",
                      marker: {
                        enabled: false,
                      },
                    },
                  ],
                }}
              />
              <div
                className={classNames(classes.questionExpalin, classes.mt50)}
              >
                <span id="details_question_2_1">Question 1: </span>
                <span>
                  Your parents gift you $5,000 for your 30th birthday. Which of
                  the following options below would be the best use of that
                  money?
                </span>
                <ul className={classes.awarenessOptions}>
                  <li className={classes.correct}>
                    Pay off $5,000 credit card balance
                  </li>
                  <li>Invest that money in your friend's new business</li>
                  <li>Repay $5,000 car loan balance</li>
                  <li>Repay $5,000 student loan balance</li>
                </ul>
                <span id="details_question_2_2">Question 2: </span>
                <span>
                  Which of the following is NOT a characteristic of a checking
                  account?
                </span>
                <ul className={classes.awarenessOptionsFull}>
                  <li>
                    A checking account is often used to pay bills and other
                    frequent financial transactions such as writing a check or
                    setting up direct deposit for your paychecks
                  </li>
                  <li>A checking account is a highly liquid bank account</li>
                  <li className={classes.correct}>
                    A bank usually pays you a high interest rate for money in a
                    checking account
                  </li>
                  <li>
                    You can use your debit card at an ATM to withdraw cash from
                    your checking account
                  </li>
                </ul>
                <span id="details_question_2_3">Question 3: </span>
                <span>
                  You use your credit card to buy a TV for $300. When your
                  credit card balance comes due and you don't make your payment,
                  what happens next?
                </span>
                <ul className={classes.awarenessOptionsFull}>
                  <li>
                    You can just pay back the $300 to the credit card company
                    next month
                  </li>
                  <li>
                    Since it's only $300, the credit card company will allow you
                    to pay it back over the next three months free of charge
                  </li>
                  <li className={classes.correct}>
                    Interest begins to accrue on your credit card balance the
                    day after your payment due date and does not stop accruing
                    until the $300 plus interest and late fees are paid off
                  </li>
                  <li>
                    You can cancel the credit card immediately to avoid interest
                    charges
                  </li>
                </ul>
                <span id="details_question_2_4">Question 4: </span>
                <span>
                  Personal Finance 101: Do you know how to write a check?
                </span>
                <ul className={classes.awarenessOptions}>
                  <li className={classes.correct}>
                    1) Check number
                    <br /> 2) Today's date
                    <br /> 3) Person or entity you are paying
                    <br /> 4) Amount written in numbers
                    <br /> 5) Amount written in words
                    <br /> 6) Reason for check (not required)
                    <br /> 7) Your signature
                    <br /> 8) Routing number
                    <br /> 9) Bank account number{" "}
                  </li>
                </ul>
                <span id="details_question_2_5">Question 5: </span>
                <span>
                  What is the term for a three-digit number ranging from 300 –
                  850 (higher the better) that relates to how likely you are to
                  repay debt.
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>Debt Repayment Score</li>
                  <li>Credit Repayment Score</li>
                  <li>Financial Score</li>
                  <li className={classes.correct}>Credit Score</li>
                </ul>
                <span id="details_question_2_6">Question 6: </span>
                <span>
                  Which of the following is NOT a way to improve your credit
                  score?
                </span>
                <ul className={classes.awarenessOptionsFull}>
                  <li>Pay your bills on time</li>
                  <li>
                    Keep your credit card balance low and repay your balance in
                    full every month
                  </li>
                  <li className={classes.correct}>
                    Repay and cancel all of your credit cards and only pay with
                    your debit card or cash
                  </li>
                  <li>Check your credit score and dispute any errors</li>
                </ul>
                <span id="details_question_2_7">Question 7: </span>
                <span>
                  Student loan monthly payments are made up of two parts:
                  ___________ and interest.
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>Balance</li>
                  <li>Capital</li>
                  <li className={classes.correct}>Principal Repayment</li>
                  <li>Amortization</li>
                </ul>
                <span id="details_question_2_8">Question 8: </span>
                <span>
                  You take a $10,000 private student loan your first year of
                  college. That loan has an annual interest rate of 8% and
                  interest accrues on a monthly basis. In your first month of
                  college, you don't make a payment. What happens next?
                </span>
                <ul className={classes.awarenessOptionsFull}>
                  <li>
                    While you are in school, you don't have to make any payments
                  </li>
                  <li className={classes.correct}>
                    Your student loan balance increases by $67 because interest
                    accrues every month you don't make a payment
                  </li>
                  <li>
                    Your student loan balance increases by $800 because interest
                    accrues every month you don't make a payment
                  </li>
                  <li>
                    You will default on your loans because you missed a payment
                  </li>
                </ul>
                <span id="details_question_2_9">Question 9: </span>
                <span>
                  Which of the following is a good reason to get a credit card?
                </span>
                <ul className={classes.awarenessOptionsFull}>
                  <li>
                    Using a credit card will automatically increase my credit
                    score
                  </li>
                  <li>I need a credit card in case of financial emergencies</li>
                  <li>
                    I need a credit card so that I can earn rewards on all of my
                    purchases
                  </li>
                  <li className={classes.correct}>
                    Credit cards offer more security than debit cards and cash
                  </li>
                </ul>
                <span id="details_question_2_10">Question 10: </span>
                <span>
                  Click to open the Extra Loan Payment Calculator and use the
                  following details to calculate Mr. B's total interest paid on
                  his mortgage.
                </span>
                <div class="el_calcs_img">
                  <table className={classes.extraLoanTb}>
                    <thead>
                      <tr>
                        <th>Terms</th>
                        <th>Mortgage</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Loan Amount</td>
                        <td>$500,000.00</td>
                      </tr>
                      <tr>
                        <td>Interest Rate</td>
                        <td>4.00%</td>
                      </tr>
                      <tr>
                        <td>Term of the loan</td>
                        <td>30 years</td>
                      </tr>
                      <tr>
                        <td>Monthly Payment</td>
                        <td>$2,387.08</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className={classes.bNetWorth}>
                  What's Mr. B's total interest savings on his 30-yr mortgage if
                  he makes an extra $300 payment?{" "}
                </p>
                <ul className={classes.awarenessOptions}>
                  <li>$281,828.45</li>
                  <li>$359,347.53</li>
                  <li className={classes.correct}>$77,519.08</li>
                  <li>$3,600.00</li>
                </ul>
              </div>
            </div>
          ) : (
            <div className={classes.noData}>No data display graph</div>
          )}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {challenge.graph_data ? (
            <div className={classes.percentageRow}>
              <div
                className={classNames(
                  classes.percentageText,
                  "percentage_data ng-scope"
                )}
              >
                <span class="ng-binding">
                  # of Students = {challenge.users}
                </span>
                <span> | </span>
                <span class="ng-binding">
                  Average Attempts = {challenge.avg_attempts}
                </span>
                <span> | </span>
                <span class="ng-binding">Average XP = {challenge.avg_xp}</span>
              </div>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                  },

                  title: {
                    text: "Average Attempts and XP Earned",
                    align: "left",
                  },

                  xAxis: {
                    categories: challenge.graph_data.question,
                  },

                  yAxis: [
                    {
                      labels: {
                        formatter: function () {
                          return parseFloat(this.value).toFixed(1);
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      min: 0,
                      tickInterval: 1,
                      max: 4,
                      opposite: true,
                      title: {
                        text: "Average Attempts",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                    {
                      // Tertiary yAxis
                      min: 0,
                      tickInterval: 25,
                      max: 100,
                      title: {
                        text: "Average XP Earned",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      labels: {
                        formatter: function () {
                          return this.value;
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                  ],
                  tooltip: {
                    formatter: function () {
                      return (
                        "<b> " +
                        this.x +
                        "</b><br/>" +
                        this.series.name +
                        ": " +
                        parseFloat(this.y).toFixed(1)
                      );
                    },
                  },
                  plotOptions: {
                    series: {
                      cursor: "pointer",
                      point: {
                        events: {
                          click: function () {
                            var id = this.category.split(" ");
                            var level = parseInt(id[1]);
                            scrollToComponent(
                              document.getElementById(
                                `details_question_3_${level}`
                              ),
                              { offset: offset, align: "top", duration: 500 }
                            );
                          },
                        },
                      },
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  series: [
                    {
                      name: "Average XP Earned",
                      yAxis: 1,
                      data: challenge.graph_data.xp_earned,
                      color: "#6982ce",
                    },
                    {
                      name: "Total Average XP Earned",
                      yAxis: 1,
                      data: challenge.graph_data.total_xp_earned,
                      color: "#d4af37",
                      type: "line",
                      marker: {
                        enabled: false,
                      },
                    },
                    {
                      name: "Average Attempts",
                      yAxis: 0,
                      data: challenge.graph_data.attempts,
                      color: "orange",
                    },
                    {
                      type: "line",
                      name: "Total Average Attempts",
                      yAxis: 0,
                      data: challenge.graph_data.total_attempts,
                      color: "#333333",
                      marker: {
                        enabled: false,
                      },
                    },
                  ],
                }}
              />
              <div
                className={classNames(classes.questionExpalin, classes.mt50)}
              >
                <span id="details_question_3_1">Question 1: </span>
                <span>
                  Successful businesses, organizations, entrepreneurs,
                  professional athletes, famous artists and military generals
                  all have one thing in common: a strategy and a plan that works
                  for them. Why do successful people develop strategies and make
                  plans?
                </span>
                <ul className={classes.awarenessOptionsFull}>
                  <li>
                    Developing a strategy and a plan provides a clear roadmap to
                    achieving their goals
                  </li>
                  <li>
                    Developing a strategy and a plan allows them to ask, "What
                    if I do this or that?" and see what might happen in the
                    future
                  </li>
                  <li>
                    Having a strategy and a plan better prepares them for the
                    future
                  </li>
                  <li className={classes.correct}>All of the above</li>
                </ul>
                <span id="details_question_3_3">Question 2: </span>
                <span>
                  The expression, "don't put all your eggs in one basket" has
                  great meaning. Which of the following best describes how that
                  expression relates to investing?
                </span>
                <ul className={classes.awarenessOptionsFull}>
                  <li>
                    If you invest all your money in one company, you are taking
                    a big risk because if that one company goes bankrupt, you
                    will lose all your money.
                  </li>
                  <li>
                    If you put all your eggs in one basket, that basket will
                    overflow and your eggs will fall on the ground and break.
                  </li>
                  <li>
                    If you diversify and invest in 100 companies and one company
                    goes bankrupt, you lose only a little bit of money.
                  </li>
                  <li className={classes.correct}>Both A and C</li>
                </ul>
                <span id="details_question_3_3">Question 3: </span>
                <span>
                  {" "}
                  You hit the lottery – congratulations! Now, you have a choice
                  to make. <br />
                  <br /> Option A: Receive $75,000,000 today in a lump sum
                  payment
                  <br /> Option B: Receive $500,000 per month for the next 200
                  months
                  <br /> <br /> Which option is a better money move? Both
                  options are after taxes.
                </span>
                <ul className={classes.awarenessOptions}>
                  <li className={classes.correct}>Option A</li>
                  <li>Option B</li>
                  <li>I don't care which option, just give me my money!</li>
                </ul>
                <span id="details_question_3_4">Question 4: </span>
                <span>
                  True or False: When you make contributions to your 401(k)
                  retirement account, you are contributing a portion of your
                  paycheck after taxes are taken out.
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>True</li>
                  <li className={classes.correct}>False</li>
                </ul>
                <span id="details_question_3_5">Question 5: </span>
                <span>
                  True or False: Since contributions to your 401(k) retirement
                  account are deducted from your salary before taxes, when you
                  go to withdraw that money in retirement, you won't have to pay
                  taxes.
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>True</li>
                  <li className={classes.correct}>False</li>
                </ul>
                <span id="details_question_3_6">Question 6: </span>
                <span>
                  A(n) _______ is a monetary asset that is purchased with the
                  hope that it will generate income or will appreciate in the
                  future.
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>Car</li>
                  <li>Liability</li>
                  <li>TV</li>
                  <li className={classes.correct}>Investment</li>
                </ul>
                <span id="details_question_3_7">Question 7: </span>
                <span>
                  Using the Rule of 72, how long would it take an investment
                  earning 4% annually to double in value?
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>30 years</li>
                  <li>27 years</li>
                  <li className={classes.correct}>18 years</li>
                  <li>9 years</li>
                </ul>
                <span id="details_question_3_8">Question 8: </span>
                <span>
                  Drag and drop the items on the right into the appropriate
                  place on the risk ladder. The top of the ladder being the
                  least risky and the bottom of the ladder being most risky.
                  There is only one right place for each!
                </span>
                <ul className={classes.awarenessOptions}>
                  <li className={classes.correct}>
                    High-Yield Savings Account
                    <br /> US 10-yr Treasury Bond
                    <br /> US Equities Mutual Fund
                    <br /> Under Armour Stock
                    <br /> Hedge Fund{" "}
                  </li>
                </ul>
                <span id="details_question_3_9">Question 9: </span>
                <span>
                  Use the Stock Profit Calculator to figure out Mr. B's profit
                  on buying Under Armour stock.
                </span>
                <ul className={classes.awarenessOptionsFull}>
                  <li>
                    Mr. B buys 100 shares of Under Armour stock for $13.15 per
                    share with $0 buying fees
                  </li>
                  <li>
                    He then sells his shares two years later for $20.75 per
                    share with $0 selling fees
                  </li>
                  <li>Under Armour paid no dividends during this period</li>
                </ul>
                <span
                  className={classNames(
                    classes.questionPaddingLeft,
                    classes.bNetWorth
                  )}
                >
                  Assuming a capital gains tax rate of 15%, what is Mr. B's
                  profit on this investment?{" "}
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>$760.00</li>
                  <li>$354.00</li>
                  <li>$1,315.00</li>
                  <li className={classes.correct}>$646.00</li>
                </ul>
                <span id="details_question_3_10">Question 10: </span>
                <span>
                  Use the following details to calculate Mr. B's medical bills
                  for his shoulder surgery:
                </span>
                <ul className={classes.awarenessOptions}>
                  <li>$1,900</li>
                  <li>$8,100</li>
                  <li className={classes.correct}>$1,000</li>
                  <li>$9,000</li>
                </ul>
              </div>
            </div>
          ) : (
            <div className={classes.noData}>No data display graph</div>
          )}
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          {challengeComparsion ? (
            <div>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                  },

                  title: {
                    text: "Average Attempts and XP Earned",
                    align: "left",
                  },

                  xAxis: {
                    categories: challengeComparsion.challenges,
                  },

                  yAxis: [
                    {
                      labels: {
                        formatter: function () {
                          return parseFloat(this.value).toFixed(1);
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      min: 0,
                      tickInterval: 1,
                      max: 4,
                      opposite: true,
                      title: {
                        text: "Average Attempts",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                    {
                      min: 0,
                      tickInterval: 25,
                      max: 100,
                      title: {
                        text: "Average XP Earned",
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      labels: {
                        formatter: function () {
                          return this.value;
                        },
                        style: {
                          color: "#000000",
                          fontSize: "14px",
                        },
                      },
                      gridLineWidth: 0,
                    },
                  ],
                  tooltip: {
                    formatter: function () {
                      return (
                        "<b> " +
                        this.x +
                        "</b><br/>" +
                        this.series.name +
                        ": " +
                        parseFloat(this.y).toFixed(1)
                      );
                    },
                  },
                  plotOptions: {
                    series: {
                      cursor: "pointer",
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  series: [
                    {
                      name: "Average XP Earned",
                      data: challengeComparsion.xp,
                      yAxis: 0,
                      color: "#D4AF37",
                    },
                    {
                      name: "Total Average Attempts",
                      data: challengeComparsion.attempts,
                      yAxis: 0,
                      type: "line",
                      color: "#83A947",
                      marker: {
                        enabled: false,
                      },
                    },
                  ],
                }}
              />
            </div>
          ) : (
            <div className={classes.noData}>No data display graph</div>
          )}
        </TabPanel>
      </div>
    </div>
  );
};

PF101ChallengeAnalytics.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(PF101ChallengeAnalytics);
