import * as commonFunctions from "../../utilities/commonFunctions";

import { Box, Tooltip } from "@mui/material";
import { Dialog, DialogTitle, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, withApollo } from "react-apollo";

import EmailPage from "../ui/users/emailPage";
import { Grid } from "@material-ui/core";
import NotificationPage from "../ui/users/notificationPage";
import Pageloader from "../../components/ui/pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTable from "../ui/Table/ReactTable";
import ReactTooltip from "react-tooltip";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import useDebounce from "../../hooks/useDebounce";
import { useUserDetailsContext } from "../../context/userDetails";
import { withStyles } from "@material-ui/core/styles";

const BUSINESS_ADMIN = loader(
  "../../graphql/schema/business/get-business-admin.graphql"
);
const DELETE_ADMIN = loader("../../graphql/schema/schools/deleteAdmin.graphql");
const INSTITUTIONS = loader(
  "../../graphql/schema/business/business-institutions.graphql"
);
const EXPORT_BUSINESS_ADMIN = loader(
  "../../graphql/schema/business/export-business-admin.graphql"
);

const BusinessAdminMain = (props) => {
  const { classes, history } = props;
  const {
    state: { search = "", admin_type },
    clearSearch,
  } = useUserDetailsContext();
  const debouncedSearch = useDebounce(search, 1000);
  const ref = React.useRef(null);
  const ref1 = React.useRef(null);
  const [loading, setLoading] = React.useState(true);
  const [filterType, setFilterType] = React.useState("");
  const [openFullNameFilters, setOpenFullNameFilters] = React.useState(false);
  const [openSchoolFilters, setOpenSchoolFilters] = React.useState(false);
  const [schoolAdminData, setSchoolAdminData] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState("");
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] =
    React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [institutionData, setInstitutionData] = React.useState([]);
  const [schoolName, setSchoolName] = React.useState([]);
  const [schoolFilter, setSchoolFilter] = React.useState([]);
  const [level, setLevel] = useState({ key: "name", val: null });
  const [resetFilter, setResetFilter] = useState(false);

  const [openMessagePopup, setOpenMessagePopup] = React.useState("");
  const [openNotification, setOpenNotification] = React.useState("");
  const [openEmail, setOpenEmail] = React.useState("");
  const [messageType, setMessageType] = React.useState("");
  const [selectedNoficationEmails, setSelectedNoficationEmails] =
    React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [activePage, setActivePage] = React.useState(1);
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);

  const [schooladmins, { fetchMore }] = useLazyQuery(BUSINESS_ADMIN, {
    variables: {
      data: {
        page: 1,
        perPage: 20,
        filter: {
          orderBy: "",
          sortBy: "",
          search_name: "",
          schools: [],
        },
      },
    },
    fetchPolicy: "network-only",
    onCompleted({ getBusinessAdmins }) {
      setSchoolAdminData(getBusinessAdmins?.admin);
      setLoading(false);
      setResetFilter(false);
      setActivePage(getBusinessAdmins?.page);
      setPageFineshedCount(getBusinessAdmins.totalCount);
      setSelectedNoficationEmails(
        getBusinessAdmins?.admin.map(function (item) {
          return item["email"];
        })
      );
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  useEffect(
    () => {
      setLoading(true);
      setActivePage(1);
      // setLevel({ key: "name", val: null });
      schooladmins({
        variables: {
          data: {
            page: 1,
            perPage: 20,
            filter: {
              orderBy: "",
              sortBy: "",
              search_name: debouncedSearch,
              schools: filterType,
            },
          },
        },
      });
    },
    [debouncedSearch] // Only call effect if debounced search term changes
  );

  const [exportbussinessadmins, { data: exportData }] = useLazyQuery(
    EXPORT_BUSINESS_ADMIN,
    {
      fetchPolicy: "network-only",
      onCompleted() {
        const data = exportData.exportBusinessAdmins;
        if (data) {
          var link = document.createElement("a");
          link.download = "support";
          link.href = data.url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
        }
        setLoading(false);
      },
      onError(errors) {
        let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
        setErrorMessage(errorMsg);
        setLoading(false);
      },
    }
  );

  const [deleteUserData, { data: deleteResult }] = useMutation(DELETE_ADMIN, {
    onCompleted() {
      setOpenConfirmDeletePopup(false);
      setDeleteId("");
      setLoading(false);
      schooladmins();
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  React.useEffect(() => {
    if (deleteResult) {
      setSuccessMessage(deleteResult.deleteAdmin.message);
    }
  }, [deleteResult]);

  const handleDeleteUser = () => {
    setLoading(true);
    deleteUserData({
      variables: {
        id: deleteId,
      },
    });
  };

  const handleUserEdit = (id) => {
    history.push("/edit-business-admin?school_admin_id=" + id);
  };

  const [institutions] = useLazyQuery(INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(response.getBusinessInstitutions);
      setLoading(false);
    },
  });

  useEffect(() => {
    schooladmins();
    institutions();
  }, []);

  const handleSorting = (row, val) => {
    setLoading(true);
    if (row === "institutions") {
      setFilterType(val);
      schooladmins({
        variables: {
          data: {
            page: activePage,
            perPage: 20,
            filter: {
              sortBy: level?.key,
              orderBy: level?.val,
              schools: val,
              search_name: debouncedSearch,
            },
          },
        },
      });
    } else {
      setLevel({ key: row, val });
      schooladmins({
        variables: {
          data: {
            page: activePage,
            perPage: 20,
            filter: {
              schools: filterType,
              sortBy: row,
              orderBy: val,
              search_name: debouncedSearch,
            },
          },
        },
      });
    }
  };

  const handleOpenConfirmDeletePopup = (id) => {
    setDeleteId(id);
    setOpenConfirmDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenConfirmDeletePopup(false);
    setDeleteId("");
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenFullNameFilters(false);
      setOpenSchoolFilters(false);
    }
    if (ref1.current && !ref1.current.contains(event.target)) {
      setOpenFullNameFilters(false);
      setOpenSchoolFilters(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const handleCheckBox = (e) => {
    if (schoolName.indexOf(e.target.value) === -1) {
      setSchoolName([...schoolName, e.target.value]);
    } else {
      const index = schoolName.indexOf(e.target.value);
      const schoolIds = [...schoolName];
      schoolIds.splice(index, 1);
      setSchoolName(schoolIds);
    }
  };

  const handleGo = () => {
    setLoading(true);
    setSchoolFilter(schoolName);
    setOpenSchoolFilters(false);
  };

  const handleClearFilter = () => {
    setLoading(true);
    setResetFilter(true);
    setLevel({ key: "name", val: null });
    setActivePage(1);
    clearSearch();
    setFilterType([]);
    schooladmins({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          filter: {
            schools: [],
            sortBy: "",
            orderBy: "",
            search_name: "",
          },
        },
      },
    });
  };

  const handleSchoolFilter = () => {
    if (schoolName.length > 0) {
      setLoading(true);
    }
    setSchoolFilter([]);
    setSchoolName([]);
    setOpenSchoolFilters(false);
  };

  const handleCloseMessagePopup = () => {
    setOpenMessagePopup(false);
  };

  const handleOpenNotification = () => {
    setOpenNotification(true);
    setOpenMessagePopup(false);
  };
  const handleNotificationClose = () => {
    setOpenNotification(false);
  };

  const handleOpenEmail = (val) => {
    setOpenEmail(true);
    setOpenMessagePopup(false);
    setMessageType(val);
  };
  const handleEmailClose = () => {
    setOpenEmail(false);
  };
  const handleOpenMessagePopup = () => {
    setOpenMessagePopup(true);
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    fetchMore({
      variables: {
        data: {
          page: pageNumber,
          perPage: 20,
          filter: {
            sortBy: level?.key,
            orderBy: level?.val,
            search_name: debouncedSearch,
            schools: filterType,
          },
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setSchoolAdminData(fetchMoreResult?.getBusinessAdmins?.admin);
      },
    });
    setActivePage(pageNumber);
  };

  const convertPassword = (password) => {
    // let passwordStr = password.toString();
    let encryPass = "*";
    for (let i = 0; i < 10; i++) {
      encryPass = encryPass + "*";
    }
    return encryPass;
  };

  return (
    <div>
      <Typography className="main-bannerRight">
        {loading === true ? <Pageloader loading={loading} /> : ""}
        {/* <h3>School Admins</h3> */}
        <Grid container className={classes.searchRow} spacing={2}>
          <Grid item md={6} sm={6} xs={12}></Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div>
              {pageFineshedCount > 0 && (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={20}
                  totalItemsCount={pageFineshedCount}
                  pageRangeDisplayed={5}
                  onChange={handleGetFineshedMatchesPageClick}
                />
              )}
            </div>
          </Grid>
        </Grid>
        <ReactTable
          resetFilter={resetFilter}
          activePage={activePage}
          count={pageFineshedCount}
          filterFunction={(row, value) => {
            handleSorting(row, value);
          }}
          columns={
            admin_type === "business"
              ? [
                  {
                    name: "First Name",
                    row: "name",
                    filterList: [
                      { key: "", value: "None" },
                      { key: "ASC", value: "Sort A to Z" },
                      { key: "DESC", value: "Sort Z to A" },
                    ],
                  },
                  {
                    name: "Last Name",
                    row: "user_lname",
                  },
                  {
                    name: "Username",
                    row: "user_name",
                  },
                  {
                    name: "Password",
                    row: "password",
                    customRowComponent: (value) => convertPassword(value),
                  },
                  {
                    name: "Email",
                    row: "email",
                  },
                  {
                    name: "Options",
                    row: "id",
                    customRowComponent: (value) => (
                      <span className={classes.moreInfo}>
                        <ul>
                          <li
                            className={classes.editAdmin}
                            onClick={() => handleUserEdit(value)}
                          >
                            <i className="fa fa-pencil" title="Edit"></i>
                          </li>
                          <li
                            className={classes.deleteAdmin}
                            onClick={() => handleOpenConfirmDeletePopup(value)}
                          >
                            <i className="fa fa-trash" title="Delete"></i>
                          </li>
                        </ul>
                      </span>
                    ),
                  },
                ]
              : [
                  {
                    name: "First Name",
                    row: "name",
                    filterList: [
                      { key: "", value: "None" },
                      { key: "ASC", value: "Sort A to Z" },
                      { key: "DESC", value: "Sort Z to A" },
                    ],
                  },
                  {
                    name: "Last Name",
                    row: "user_lname",
                  },
                  {
                    name: "Username",
                    row: "user_name",
                  },
                  {
                    name: "Password",
                    row: "password",
                    customRowComponent: (value) => convertPassword(value),
                  },
                  {
                    name: "Email",
                    row: "email",
                  },
                  {
                    name: "Business",
                    row: "institutions",
                    customRowComponent: (value) => {
                      return (
                        <Box display={"flex"} flexWrap="wrap" width={"20rem"}>
                          {value.map((l, i) => {
                            return (
                              <Box paddingRight={1}>{`${l.institution_name}${
                                i !== value.length - 1 ? "," : ""
                              }`}</Box>
                            );
                          })}
                        </Box>
                      );
                    },
                    showCheckboxwithFilter: true,
                    filterList: institutionData?.map((l) => ({
                      key: l.id,
                      value: l.institution_name,
                    })),
                  },
                  {
                    name: "Options",
                    row: "id",
                    customRowComponent: (value) => (
                      <span className={classes.moreInfo}>
                        <ul>
                          <li
                            className={classes.editAdmin}
                            onClick={() => handleUserEdit(value)}
                          >
                            <i className="fa fa-pencil" title="Edit"></i>
                          </li>
                          <li
                            className={classes.deleteAdmin}
                            onClick={() => handleOpenConfirmDeletePopup(value)}
                          >
                            <i className="fa fa-trash" title="Delete"></i>
                          </li>
                        </ul>
                      </span>
                    ),
                  },
                ]
          }
          rows={schoolAdminData}
        />
        {/* <div className={classes.userTableSec}>
          <div className={classes.userTableDiv}>
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>
                    First Name
                    <span className={classes.filterIcon}>
                      <img
                        onClick={() =>
                          setOpenFullNameFilters(!openFullNameFilters)
                        }
                        src="./img/filter-icon.png"
                        alt=""
                      />
                      {openFullNameFilters && (
                        <ul className={classes.filterDrop} ref={ref}>
                          <li
                            onClick={() => handleSorting("")}
                            className={classes.active}
                          >
                            None
                          </li>
                          <li onClick={() => handleSorting("ASC")}>
                            Sort A to Z
                          </li>
                          <li onClick={() => handleSorting("DESC")}>
                            Sort Z to A
                          </li>
                        </ul>
                      )}
                    </span>
                  </th>
                  <th>
                    Last Name
                    <span className={classes.filterIcon}>
                      <img
                        onClick={() =>
                          setOpenFullNameFilters(!openFullNameFilters)
                        }
                        src="./img/filter-icon.png"
                        alt=""
                      />
                      {openFullNameFilters && (
                        <ul className={classes.filterDrop} ref={ref}>
                          <li
                            onClick={() => handleSorting("")}
                            className={classes.active}
                          >
                            None
                          </li>
                          <li onClick={() => handleSorting("ASC")}>
                            Sort A to Z
                          </li>
                          <li onClick={() => handleSorting("DESC")}>
                            Sort Z to A
                          </li>
                        </ul>
                      )}
                    </span>
                  </th>
                  <th>Username </th>
                  <th>Password </th>
                  <th>Email </th>
                  <th>
                    School
                    <span className={classes.filterIcon}>
                      <img
                        onClick={() => setOpenSchoolFilters(!openSchoolFilters)}
                        src="./img/filter-icon.png"
                        alt=""
                      />
                      {openSchoolFilters && (
                        <ul
                          className={
                            classes.filterTwoDrop + " " + classes.filterDrop
                          }
                          ref={ref1}
                        >
                          <li onClick={handleSchoolFilter}>None</li>
                          <li>
                            <ul>
                              {institutionData.length !== 0 &&
                                institutionData.map((l, index) => (
                                  <li>
                                    <label key={index}>
                                      <input
                                        type="checkbox"
                                        id="school"
                                        value={l.id}
                                        onClick={(e) => {
                                          handleCheckBox(e);
                                        }}
                                        checked={
                                          schoolName.length > 0 &&
                                          schoolName.indexOf(l.id) > -1
                                            ? true
                                            : false
                                        }
                                      />
                                      {l.institution_name}
                                      <br />
                                    </label>
                                  </li>
                                ))}
                            </ul>
                          </li>
                          <li>
                            <button
                              onClick={handleGo}
                              className={classes.goBtn}
                            >
                              Go
                            </button>
                          </li>
                        </ul>
                      )}
                    </span>
                  </th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {schoolAdminData.length !== 0 ? (
                  schoolAdminData.map((l, index) => (
                    <tr>
                      <td>
                        <span>{index + 1}</span>
                      </td>
                      <td>
                        <span>{l.name}</span>
                      </td>
                      <td>
                        <span>{l.user_lname}</span>
                      </td>
                      <td>
                        <span>{l.user_name}</span>
                      </td>
                      <td>
                        <span>{convertPassword(l.password) || "N/A"}</span>
                      </td>
                      <td>
                        <span>{l.email}</span>
                      </td>
                      <td>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: l.institution_name,
                          }}
                        ></span>
                      </td>
                      <td>
                        <span className={classes.moreInfo}>
                          <ul>
                            <li
                              className={classes.editAdmin}
                              onClick={() => handleUserEdit(l.id)}
                            >
                              <i className="fa fa-pencil" title="Edit"></i>
                            </li>
                            <li
                              onClick={() => handleOpenConfirmDeletePopup(l.id)}
                            >
                              <i className="fa fa-trash" title="Delete"></i>
                            </li>
                          </ul>
                        </span>
                      </td>
                    </tr>
                  ))
                ) : errorMessage ? (
                  <tr>
                    <td colspan="12" className={classes.noteError}>
                      {errorMessage}
                    </td>
                  </tr>
                ) : (
                  <tr className={classes.noData}>
                    <td colspan="12">No Admin Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div> */}
        <div className={classes.userTableSec}>
          <div className={classes.schoolsBtn}>
            {/* <div className={classes.msgBtn}>
                            <a onClick={handleOpenMessagePopup}>Message</a>
                        </div> */}
            <div className={classes.addAdminBtn}>
              <Link to="/add-business-admin">ADD NEW ADMIN</Link>
            </div>
            <div className={classes.clearFilterBtn}>
              <button onClick={handleClearFilter}>CLEAR ALL FILTERS</button>
            </div>
            <Tooltip
              title=" This will export only the data that is currently shown above to
                excel. If you want to export all the data in this tab, make sure
                you clear all filters before using the export button."
            >
              <div className={classes.exportBtn}>
                <button
                  data-tip
                  data-for="main"
                  onClick={() => {
                    exportbussinessadmins({
                      variables: {
                        data: {
                          filter: {
                            search_name: debouncedSearch,
                            sortBy: level?.key,
                            orderBy: level?.val,
                            schools: filterType,
                          },
                        },
                      },
                    });
                    setLoading(true);
                  }}
                >
                  EXPORT
                </button>
              </div>
            </Tooltip>
          </div>
        </div>

        <Dialog
          className="popup-model"
          open={openConfirmDeletePopup}
          onClose={handleCloseDeletePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="model-content">
            <div className="modal-header">
              {/* <button onClick={handleCloseDeletePopup} >X</button> */}
              <h3>Delete User</h3>
            </div>

            <div
              className="model-body"
              id="customized-dialog-title"
              onClose={handleCloseDeletePopup}
            >
              Are you sure you want to delete this admin?
            </div>
            <div className="model-footer">
              <button
                onClick={() => handleDeleteUser()}
                className="close-btn delete-btn"
              >
                Ok
              </button>
              <button onClick={handleCloseDeletePopup} className="close-btn">
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
        <Dialog
          className="popup-model"
          open={openMessagePopup}
          onClose={handleCloseMessagePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="model-content">
            <div className="modal-header">
              <button onClick={handleCloseMessagePopup}>X</button>
              <h3 className="text-light">
                Send a message to all selected students
              </h3>
            </div>

            <div
              className="model-body"
              id="customized-dialog-title"
              onClose={handleCloseMessagePopup}
            >
              You can send a custom message to all selected students in the form
              of an Inkwiry notification, email or both. Use the buttons below
              to choose one of those options. Then, draft your message and send
              to your students.
            </div>
            <div className="model-footer">
              <button
                onClick={() => handleOpenNotification()}
                className="close-btn delete-btn-light"
              >
                Notification
              </button>
              <button
                onClick={() => handleOpenEmail("Email")}
                className="close-btn delete-btn-light"
              >
                Email
              </button>
              <button
                onClick={() => handleOpenEmail("Both")}
                className="close-btn delete-btn-light"
              >
                Both
              </button>
              <button onClick={handleCloseMessagePopup} className="close-btn">
                Cancel
              </button>
            </div>
          </div>
        </Dialog>
        <NotificationPage
          handleClose={handleNotificationClose}
          open={openNotification}
          selectedNoficationEmails={selectedNoficationEmails}
        />
        <EmailPage
          handleClose={handleEmailClose}
          open={openEmail}
          selectedNoficationEmails={selectedNoficationEmails}
          messageType={messageType}
        />
      </Typography>
    </div>
  );
};

BusinessAdminMain.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(BusinessAdminMain);
