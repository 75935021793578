const styles = () => ({
  userTableSec: {
    // display: 'flex',
    // overflow: 'hidden',
    // position: 'relative',
    // overflowY: 'auto',
    marginBottom: "70px",
  },
  headingDiv: {
    display: "flex",
    alignItems: "center",
    float: "right",
    width: "100%",
    paddingBottom: "15px",
  },
  searchRow: {
    marginBottom: "15px",
  },
  searchBar: {
    position: "relative",
    "& input": {
      borderRadius: "15px",
      border: "1px solid #ccc",
      padding: "7px 15px",
      outline: "0",
      width: "calc(100% - 30px)",
      color: "#555",
      font: "inherit",
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      fontSize: "14px !important",
      fontWeight: "100 !important",
      maxWidth: "380px",
      "&::placeholder": {
        color: "#7c7c7c",
      },
    },
  },
  searchInput: {
    width: "360px",
    "& input": {
      borderRadius: "15px",
      border: "1px solid #ccc",
      outline: 0,
      width: "calc(100% - 30px)",
      padding: "8px 15px",
      fontSize: "14px",
      fontFamily: "MuseoSans-300",
      fontWeight: "normal",
      "&::placeholder": {
        color: "#625c5c",
      },
    },
  },
  userTableDiv: {
    width: "calc(100% - 4px)",
    background: "#fff",
    boxShadow: "0 0px 2px rgb(25 25 25 / 20%)",
    transition: "all .3s ease-in-out",
    marginLeft: "2px",
    marginBottom: "20px",
    overflow: "auto",
    borderRadius: "15px 11px 0px 0px",
    "& table": {
      width: "100%",
      background: "#036",
      tableLayout: "fixed",
      border: "1px solid #ccc",
      borderSpacing: "0",
      borderCollapse: "collapse",
      "& thead": {
        width: "100%",
        borderRadius: "15px 11px 0 0",
        color: "#fff",
        "& th": {
          padding: "25px 10px 10px",
          fontSize: "14px",
          textAlign: "left",
          width: "150px",
          background: " #036",
          color: "white",
          "&:nth-child(1)": {
            width: "42px",
            textAlign: "center",
          },
          "&:nth-child(2)": {
            width: "155px",
          },
          "&:nth-child(3)": {
            width: "150px",
          },
          "&:nth-child(4)": {
            width: "100px",
          },
          "&:nth-child(5)": {
            width: "175px",
          },
          "&:nth-child(6)": {
            width: "88px",
          },
          "&:nth-child(7)": {
            width: "150px",
          },
          "&:nth-child(8)": {
            width: "100px",
          },
          "& i": {
            marginLeft: "5px",
            cursor: "pointer",
          },
        },
      },
      "& tbody": {
        background: "#fff",
        "& tr": {
          background: "#fff",
          "&:nth-child(even)": {
            background: "#f2f2f2",
          },
          "& td": {
            padding: "8px 10px",
            fontSize: "14px",
            borderBottom: "1px solid #ededed",
            verticalAlign: "middle",
            color: "#333",
            wordBreak: "break-all",
            "&:nth-child(1)": {
              width: "42px",
              textAlign: "center",
            },
            "&:nth-child(2)": {
              width: "155px",
            },
            "&:nth-child(3)": {
              width: "150px",
            },
            "&:nth-child(4)": {
              width: "100px",
            },
            "&:nth-child(5)": {
              width: "175px",
            },
            "&:nth-child(6)": {
              width: "88px",
            },
            "&:nth-child(7)": {
              width: "150px",
            },
            "&:nth-child(8)": {
              width: "100px",
            },
          },
        },
      },
    },
  },
  emailText: {
    width: "200px",
  },
  noteError: {
    color: "#ff0606",
    padding: "10px",
    fontSize: "14px",
    background: "#ffefef",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "1.66",
    marginBottom: "10px",
  },
  moreInfo: {
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        fontSize: "15px",
        cursor: "pointer",
        display: "inline-block",
      },
    },
  },
  editAdmin: {
    color: "#23527c",
    marginRight: "8px",
  },
  deleteAdmin: {
    color: "#333",
  },
  confirmDeleteBtn: {
    display: "inline-block",
    width: "auto",
    flex: "initial",
    padding: "0px !important",
    margin: "0px 10px",
    "& button": {
      background: "white",
    },
  },
  openApply: {
    padding: "15px",
    textAlign: "right",
    borderTop: "1px solid #e5e5e5",
  },
  closeBtn: {
    display: "inline-block",
    padding: "6px 12px",
    marginBottom: "0",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    userSelect: "none",
    border: "1px solid transparent",
    borderRadius: "4px",
    color: "#333",
    backgroundColor: "#fff",
    borderColor: "#ccc",
  },
  activeBtn: {
    backgroundColor: "#036",
    borderColor: "#036",
    padding: "5px 15px",
    boxShadow: "none",
    color: "#fff",
    marginRight: "10px",
  },
  colseButton: {
    padding: "15px",
    borderBottom: "1px solid #e5e5e5",
    "& button": {
      float: "right",
      fontSize: "21px",
      lineHeight: "1",
      color: "#000",
      textShadow: "0 1px 0 #fff",
      filter: "alpha(opacity=20)",
      opacity: ".2",
      padding: "0",
      cursor: "pointer",
      background: "0 0",
      border: "0",
    },
    "& h3": {
      height: "0px",
    },
  },
  uploadImageDiv: {
    maxWidth: "45%",
    borderLeft: "1px solid #ddd",
    padding: "0 30px",
  },
  srcImageDiv: {
    display: "inline-block",
    width: "100%",
    border: "1px solid #eaeaea",
    marginTop: "5px",
    borderRadius: "5px",
    position: "relative",
  },
  inputImageDiv: {
    position: "relative",
    textAlign: "center",
    height: "30px",
    lineHeight: "30px",
    background: "#f4f4f4",
    "& input": {
      position: "absolute",
      width: "100%",
      height: "30px",
      lineHeight: "30px",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
      zIndex: 9,
      opacity: 0,
    },
  },
  mainDiv: {
    padding: "20px",
    display: "inline-block",
    width: "100%",
  },
  inputDiv: {
    marginBottom: "15px",
    "& label": {
      width: "154px",
      display: "-webkit-inline-box",
    },
    "& input": {
      position: "relative",
      width: "57%",
      outline: 0,
      height: "17px",
      padding: "6px 12px",
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "4px",
      webkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
    },
  },
  stateInput: {
    marginBottom: "15px",
    "& label": {
      width: "154px",
      display: "-webkit-inline-box",
    },
    "& >div": {
      width: "60%",
      "& div > div": {
        position: "relative",
        outline: 0,
        height: "17px",
        padding: "6px 12px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: "4px",
        webkitBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
        boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      },
    },
  },
  updateBtn: {
    marginLeft: "auto",
    // marginRight: 'auto',
    width: "60%",
    "& span": {
      color: "white",
      transform: "none",
    },
  },
  updateText: {
    marginBottom: "15px",
    color: "#3070bf",
  },
  sucessPopup: {
    textAlign: "center",
    padding: "50px 30px",
    "& p": {
      fontWeight: "500",
      fontSize: "14px",
    },
    "& img": {
      maxWidth: "150px",
    },
  },
  statusSuccess: {
    display: "inline",
    padding: ".2em .6em .3em",
    fontSize: "75%",
    color: "#fff",
    backgroundColor: "#5cb85c",
    borderRadius: ".25em",
  },
  statusInActive: {
    display: "inline",
    padding: ".2em .6em .3em",
    fontSize: "75%",
    color: "#fff",
    backgroundColor: "#e23d63",
    borderRadius: ".25em",
  },
  noData: {
    "& td": {
      fontWeight: "600",
      padding: "30px 0px !important",
    },
  },
  filterIcon: {
    "& img": {
      maxWidth: "15px",
      marginLeft: "5px",
      cursor: "pointer",
      position: "relative",
      top: "2px",
    },
  },
  /* button css */
  schoolsBtn: {
    display: "flex",
    float: "right",
    margin: "10px 0px",
    "& > div": {
      border: "1px solid transparent",
    },
    "& a": {
      textAlign: "center",
      display: "inline-block",
      fontWeight: "400",
      padding: "5px 12px",
      marginBottom: "0",
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      WebkitUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textDecoration: "none",
      WebkitTransition: "all .5s ease 0s",
      MozTransition: "all .5s ease 0s",
      color: "#fff",
    },
    "& button": {
      textAlign: "center",
      display: "inline-block",
      fontWeight: "400",
      padding: "5px 12px",
      marginBottom: "0",
      fontSize: "13px",
      lineHeight: "1.42857143",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      WebkitUserSelect: "none",
      MsUserSelect: "none",
      userSelect: "none",
      backgroundImage: "none",
      border: "1px solid transparent",
      borderRadius: "4px",
      textDecoration: "none",
      WebkitTransition: "all .5s ease 0s",
      MozTransition: "all .5s ease 0s",
      color: "#fff",
      cursor: "pointer",
    },
  },
  msgBtn: {
    "& a": {
      backgroundColor: "#036",
    },
    "& button": {
      backgroundColor: "#036",
    },
  },
  addAdminBtn: {
    "& a": {
      backgroundColor: "green",
    },
    "& button": {
      backgroundColor: "green",
    },
  },
  clearFilterBtn: {
    "& a": {
      backgroundColor: "#caa336",
    },
    "& button": {
      backgroundColor: "#caa336",
    },
  },
  exportBtn: {
    "& a": {
      backgroundColor: "#006AAA",
    },
    "& button": {
      backgroundColor: "#006AAA",
    },
  },
});

export default styles;
