export const question = {
  level1: {
    modalHeaderName: "Level 1 Tracker",
    questions: [
      {
        id: 1,
        name: "Career Path + Higher Education questions",
      },
      {
        id: 2,
        name: "SMART Goal setting",
      },
    ],
  },

  level2: {
    modalHeaderName: "Level 2 Tracker",
    questions: [
      {
        id: 1,
        name: "Take the Awareness Challenge",
      },
      {
        id: 2,
        name: "Take the Discovery Challenge",
      },
    ],
  },
  level3: {
    modalHeaderName: "Level 3 Tracker",
    questions: [
      {
        id: 1,
        name: "Jumpstart planning with Inkwiry Career Sketches",
      },
      {
        id: 2,
        name: "Explore careers and select one that interests you",
      },
      {
        id: 3,
        name: "Find a job application for that career that interests you",
      },
    ],
  },
  level4: {
    modalHeaderName: "Level 4 Tracker",
    questions: [
      {
        id: 1,
        name: "Financial stats that might shock you",
      },
      {
        id: 2,
        name: "Power of Career Sketching",
      },
    ],
  },
  level5: {
    modalHeaderName: "Level 5 Tracker",
    questions: [
      {
        id: 1,
        name: "Explore stepping stones in Inkwiry's Career Sketches",
      },
      {
        id: 2,
        name: "My Stepping Stones",
      },
    ],
  },
  level6: {
    modalHeaderName: "Level 6 Tracker",
    questions: [
      {
        id: 1,
        name: "Go to the Timeline Sketcher and view your timeline",
      },
      {
        id: 2,
        name: "Complete the Financial Independence module",
      },
      {
        id: 3,
        name: "Build a scenario for Stepping Stone #1",
      },
    ],
  },
  level7: {
    modalHeaderName: "Level 7 Tracker",
    questions: [
      {
        id: 1,
        name: "Go to the Timeline Sketcher",
      },
      {
        id: 2,
        name: "Build a scenario for Stepping Stone #2",
      },
      {
        id: 3,
        name: "Build a scenario for Stepping Stone #3",
      },
      {
        id: 4,
        name: "Build a scenario for Stepping Stone #4",
      },
    ],
  },
  level8: {
    modalHeaderName: "Level 8 Tracker",
    questions: [
      {
        id: 1,
        name: "Open the Timeline Sketcher in a separate tab",
      },
      {
        id: 2,
        name: "Complete the 7-point checklist above",
      },
    ],
  },
};
