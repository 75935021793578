import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';

const IMPORT_USER = loader('../../graphql/schema/schools/import-school.graphql');

const ImportUser = (props) => {
    const { setImportOpen, institutionData } = props;
    const [school, setSchool] = useState('');
    const [userType, setUserType] = useState('');
    const [file, setFile] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const [importUser] = useMutation(IMPORT_USER);

    const handleChange = (e, field) => {
        if (field === 'school') {
            setSchool(e.target.value);
        }
        if (field === 'user_type') {
            setUserType(e.target.value);
        }
    }

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setFile(reader.result);
               
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const handleSubmit = () => {
        if (!file || !userType || !school) {
            if (!file) {
                setErrorMsg('please select the file')
            }
            if (!userType) {
                setErrorMsg('please select user type');
            }
            if (!school) {
                setErrorMsg('please select school');
            }
        } else {
            importUser({
                variables: {
                    file: {
                        institution_id: school,
                        account_type: userType,
                        file: file
                    },
                },
            })
        }
    }
    return (
        <div>
            <h4>Select School</h4>
            <div>
                <select onChange={(e) => handleChange(e, 'school')}>
                    <option value=''>Select school</option>
                    {institutionData.length !== 0 && institutionData.map((l, index) =>
                        <option value={l.id}>{l.institution_name}</option>
                    )}
                </select>
            </div>
            <h4>Select User Type</h4>
            <div>
                <select onChange={(e) => handleChange(e, 'user_type')}>
                    <option value=''>Select User Type</option>
                    <option value='inkwiry_personnel'>Inkwiry Personnel</option>
                    <option value='test'>Test User</option>
                    <option value='pf101'>PF101 Only</option>
                    <option value='pwi'>PWI Only</option>
                    <option value='pf101_pwi'>PWI + PF101</option>
                    <option value='school_test'>School Prospect</option>
                    <option value='corporate_prospect'>Corporate Prospect</option>
                    <option value='friends_and_family'>Friends and Family</option>
                </select>
            </div>
            <h4>User Data</h4>
            <div>
                <input type="file" onChange={handleFileChange} accept="application/vnd.ms-excel,.csv,.xlsx" />
            </div>
            <div>
                <button onClick={() => { setImportOpen(false) }}>Cancel</button>
                <button onClick={handleSubmit}>Upload</button>
            </div>
            {errorMsg && <p>{errorMsg}</p>}
        </div>
    )
}

ImportUser.propTypes = {
    setImportOpen: PropTypes.func.isRequired,
    institutionData: PropTypes.array.isRequired,
};

export default ImportUser