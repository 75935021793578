import "./style/style.css";

import { ThemeProvider, createTheme } from "@mui/material";

import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { ApolloProvider } from "react-apollo";
import React from "react";
import Router from "./components/router";
import { SnackbarProvider } from "notistack";
import { UserDetailsProvider } from "./context/userDetails";
import client from "./apollo/apollo-client";

const theme = createTheme({
  typography: {
    fontFamily: ["museo-sans", "sans-serif"].join(","),
    fontSize: 15,
    fontWeightRegular: 300,
  },
  palette: {
    neutral: {
      main: "#fff",
      contrastText: "#fff",
    },
    dark: {
      main: "#000",
      contrastText: "#000",
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#454456",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        containedSizeSmall: {
          padding: "2px 8px",
          minWidth: "auto",
        },
        outlinedSizeSmall: {
          padding: "2px 8px",
          minWidth: "auto",
        },
      },
    },
    // MuiDialog: {
    //   styleOverrides: {
    //     paper: {
    //       position: "absolute",
    //       top: 0,
    //     },
    //   },
    // },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: 4,
        },
      },
    },
    // MuiListItemText: {
    //   styleOverrides: {
    //     secondary: ({ theme }) => ({
    //       // color: "#fff",
    //       // fontSize: theme.typography.subtitle2.fontSize,
    //     }),
    //   },
    // },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "1px solid #ffffff",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#036",
        },
      },
    },
  },
});

const App = () => (
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          <UserDetailsProvider>
            <Router />
          </UserDetailsProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </ApolloHooksProvider>
  </ApolloProvider>
);

export default App;
