import * as commonFunctions from "../../utilities/commonFunctions";

import { Dialog, DialogTitle, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React, { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

// import NotificationPage from './notificationPage';
// import EmailPage from './emailPage';
import { Grid } from "@material-ui/core";
import Pageloader from "../../components/ui/pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
// // import history from "../../../utilities/history";
// import moment from "moment";
import UserUtils from "../../utilities/userUtils";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
// import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const ALL_SCHOOL_USERS = loader(
  "../../graphql/schema/schools/get-school-userManagement.graphql"
);
const EXPORT_SCHOOL_USER = loader(
  "../../graphql/schema/schools/export-school-user.graphql"
);
const DELETEUSER = loader("../../graphql/schema/ui/deleteMember.graphql");
const STATES = loader("../../graphql/schema/ui/states.graphql");
const SCHOOLS = loader(
  "../../graphql/schema/business/business-institutions.graphql"
);
// const DEACTIVATEUSER = loader('../../../graphql/schema/ui/changeUserStatus.graphql');

const BusinessUsersManagement = (props) => {
  const { classes, history } = props;

  const [loading, setLoading] = React.useState(true);
  const [usersData, setUsersData] = React.useState([]);
  const [statesData, setStatesData] = React.useState([]);
  const [schoolsData, setSchoolsData] = React.useState([]);
  const [selectedStateData, setSelectedStateData] = React.useState([]);
  const [selectedSchoolsData, setSelectedSchoolsData] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] =
    React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [openSuccesPopup, setOpenSuccesPopup] = React.useState(false);
  const [openFirstNameFilters, setOpenFirstNameFilters] = React.useState(false);
  const [openLastNameFilters, setOpenLastNameFilters] = React.useState(false);
  const [openEmailFilters, setOpenEmailFilters] = React.useState(false);
  const [openUserNameFilters, setOpenUserNameFilters] = React.useState(false);
  const [openZipcodeFilters, setOpenZipcodeFilters] = React.useState(false);
  const [openCityFilters, setOpenCityFilters] = React.useState(false);
  const [openStateFilters, setOpenStateFilters] = React.useState(false);
  const [openSchoolsFilters, setOpenSchoolsFilters] = React.useState(false);
  const [newsOpen, setNewsOpen] = React.useState(false);
  const [feOpen, setFeOpen] = React.useState(false);
  const [promotionOpen, setPromotionOpen] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState("");
  const [activePage, setActivePage] = React.useState(1);
  const [perPage, setPerPage] = useState([]);
  const [count] = useState(20);
  const [institutionData, setInstitutionData] = React.useState([]);
  const [schoolName, setSchoolName] = React.useState([]);
  const [schoolFilter, setSchoolFilter] = React.useState([]);
  const [openSchoolFilters, setOpenSchoolFilters] = React.useState(false);
  const [news, setNews] = React.useState("");
  const [state, SetState] = useState([]);
  const [stateFilter, setStateFilter] = useState([]);
  // const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  // const [deActivateId, setDeActivateId] = React.useState("");
  // const [openConfirmDeActivatePopup, setOpenConfirmDeActivatePopup] = React.useState(false);
  // const [deActivatStatus, setDeActivatStatus] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [selectedCol, setSelectedCol] = React.useState("");
  // const [selectedtype, setSelectedtype] = React.useState('');
  // const [openMessagePopup, setOpenMessagePopup] = React.useState('');
  // const [openNotification, setOpenNotification] = React.useState('');
  // const [openEmail, setOpenEmail] = React.useState('');
  // const [selectedNoficationEmails, setSelectedNoficationEmails] = React.useState([]);
  // const [messageType, setMessageType] = React.useState('');
  // const [count, setCount] = useState(1);
  // const [perPage] = React.useState(20)

  const ref = React.useRef(null);
  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);
  const ref3 = React.useRef(null);
  const ref4 = React.useRef(null);
  const ref5 = React.useRef(null);
  const ref6 = React.useRef(null);
  const ref7 = React.useRef(null);
  const ref8 = React.useRef(null);
  const ref9 = React.useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenFirstNameFilters(false);
    } else if (ref1.current && !ref1.current.contains(event.target)) {
      setOpenLastNameFilters(false);
    } else if (ref2.current && !ref2.current.contains(event.target)) {
      setOpenEmailFilters(false);
    } else if (ref3.current && !ref3.current.contains(event.target)) {
      setOpenZipcodeFilters(false);
    } else if (ref4.current && !ref4.current.contains(event.target)) {
      setOpenCityFilters(false);
    } else if (ref5.current && !ref5.current.contains(event.target)) {
      setOpenStateFilters(false);
    } else if (ref6.current && !ref6.current.contains(event.target)) {
      setOpenSchoolsFilters(false);
    } else if (ref7.current && !ref7.current.contains(event.target)) {
      setNewsOpen(false);
    } else if (ref8.current && !ref8.current.contains(event.target)) {
      setPromotionOpen(false);
    } else if (ref9.current && !ref9.current.contains(event.target)) {
      setFeOpen(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const [schoolUsers] = useLazyQuery(ALL_SCHOOL_USERS, {
    fetchPolicy: "network-only",
    variables: {
      data: {
        name: searchText,
        account_type: "business",
        filter: {
          finance_explained: news,
          news: news,
          promotions: news,
          schools: schoolFilter,
          selectedCol: "name",
          selectedtype: selectedType,
          state: stateFilter,
        },
      },
    },
    onCompleted(response) {
      setUsersData(response.getSchoolUsers);
      setLoading(false);
      setPerPage(response.getSchoolUsers.slice(0, count));
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  React.useEffect(() => {
    schoolUsers();
  }, []);

  const [exportschooladmins] = useLazyQuery(EXPORT_SCHOOL_USER, {
    variables: {
      data: {
        name: searchText,
        account_type: "school",
        filter: {
          finance_explained: news,
          news: news,
          promotions: news,
          schools: schoolFilter,
          selectedCol: selectedCol,
          selectedtype: selectedType,
          state: stateFilter,
        },
      },
    },
    fetchPolicy: "network-only",
    onCompleted(response) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = response.exportSchoolUsers.url;
      hiddenElement.download = "school_user.csv";
      hiddenElement.click();
      setLoading(false);
    },
  });

  const { data: stateResult } = useQuery(STATES, {
    variables: {},
    fetchPolicy: "network-only",
    onCompleted() {
      if (stateResult.States) {
        setStatesData(stateResult.States);
      }
      setLoading(false);
    },
  });

  const { data: schoolsResult } = useQuery(SCHOOLS, {
    variables: {},
    fetchPolicy: "network-only",
    onCompleted() {
      if (schoolsResult.getBusinessInstitutions) {
        setInstitutionData(schoolsResult.getBusinessInstitutions);
      }
      setLoading(false);
    },
  });

  const [deleteUserData, { data: deleteResult }] = useMutation(DELETEUSER, {
    onCompleted() {
      setOpenConfirmDeletePopup(false);
      setDeleteId("");
      setLoading(false);
      setOpenSuccesPopup(true);
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });
  React.useEffect(() => {
    if (deleteResult) {
      schoolUsers();
      setSuccessMessage(deleteResult.deleteMember.message);
    }
  }, [deleteResult]);

  const handleOpenConfirmDeletePopup = (id) => {
    setDeleteId(id);
    setOpenConfirmDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenConfirmDeletePopup(false);
    setDeleteId("");
  };

  const handleDeleteUser = () => {
    setLoading(true);
    deleteUserData({
      variables: {
        id: deleteId,
      },
    });
  };

  const handleUserEdit = (value) => {
    history.push("/edit-business-user?user_id=" + value.id);
  };
  const handleClose = () => {
    setOpenSuccesPopup(false);
    setSuccessMessage("");
  };

  const handleFilters = (val) => {
    if (val === "user_fname") {
      setNewsOpen(false);
      setOpenFirstNameFilters(!openFirstNameFilters);
      setOpenLastNameFilters(false);
      setOpenEmailFilters(false);
      setOpenUserNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setPromotionOpen(false);
      setOpenSchoolFilters(false);
      setFeOpen(false);
      setSelectedCol(val);
    } else if (val === "user_lname") {
      setNewsOpen(false);
      setOpenLastNameFilters(!openLastNameFilters);
      setOpenFirstNameFilters(false);
      setOpenEmailFilters(false);
      setOpenUserNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setOpenSchoolFilters(false);
      setPromotionOpen(false);
      setFeOpen(false);
      setSelectedCol(val);
    } else if (val === "news") {
      setNewsOpen(true);
      setOpenLastNameFilters(false);
      setOpenFirstNameFilters(false);
      setOpenEmailFilters(false);
      setOpenUserNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setPromotionOpen(false);
      setFeOpen(false);
      setOpenSchoolFilters(false);
      setSelectedCol(val);
    } else if (val === "email") {
      setNewsOpen(false);
      setOpenEmailFilters(!openEmailFilters);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenUserNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setPromotionOpen(false);
      setFeOpen(false);
      setOpenSchoolFilters(false);
      setSelectedCol(val);
    } else if (val === "zip_code") {
      setOpenUserNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenCityFilters(false);
      setOpenZipcodeFilters(!openZipcodeFilters);
      setNewsOpen(false);
      setOpenSchoolFilters(false);
      setPromotionOpen(false);
      setFeOpen(false);
    } else if (val === "city") {
      setOpenUserNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(!openCityFilters);
      setNewsOpen(false);
      setPromotionOpen(false);
      setOpenSchoolFilters(false);
      setFeOpen(false);
    } else if (val === "state") {
      setOpenUserNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setOpenStateFilters(true);
      setNewsOpen(false);
      setPromotionOpen(false);
      setFeOpen(false);
      setOpenSchoolFilters(false);
    } else if (val === "schools") {
      setOpenUserNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setOpenStateFilters(false);
      setOpenSchoolFilters(!openSchoolFilters);
      setNewsOpen(false);
      setPromotionOpen(false);
      setFeOpen(false);
    } else if (val === "promotions") {
      setOpenUserNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setOpenStateFilters(false);
      setOpenSchoolFilters(false);
      setNewsOpen(false);
      setPromotionOpen(!promotionOpen);
      setFeOpen(false);
    } else if (val === "Finance_Explained") {
      setOpenUserNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setOpenStateFilters(false);
      setOpenSchoolFilters(false);
      setNewsOpen(false);
      setPromotionOpen(false);
      setFeOpen(!feOpen);
    }
  };
  const handleFullName = (val) => {
    if (selectedType === "") {
      setLoading(false);
    } else if (val === "ASC") {
      setLoading(true);
    } else if (val === "DESC") {
      setLoading(true);
    } else {
      setLoading(true);
    }
    setSelectedType(val);
  };

  const handleNews = (val) => {
    if (news === "") {
      setLoading(false);
    } else if (val === 1) {
      setLoading(true);
    } else if (val === 0) {
      setLoading(true);
    } else {
      setLoading(true);
    }
    setNews(val);

    setNewsOpen(false);
  };

  const handleClearFilter = () => {
    if (schoolName.length > 0) {
      setLoading(true);
    }
    if (selectedType === "") {
      setLoading(false);
    }
    if (!news) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    setSchoolFilter([]);
    setStateFilter([]);
    setSchoolName([]);
    setOpenSchoolFilters(false);
    setSelectedType("");
    setSearchText("");
    setActivePage(1);
    SetState([]);
    setNews("");
  };

  let pageNumber = [];
  for (let i = 1; i < Math.ceil(usersData.length / count) + 1; i++) {
    pageNumber.push(i);
  }

  const pageHandler = (pageNumber) => {
    setPerPage(usersData.slice(pageNumber * count - count, pageNumber * count));
    setActivePage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const handleCheckBox = (e) => {
    if (schoolName.indexOf(e.target.value) === -1) {
      setSchoolName([...schoolName, e.target.value]);
    } else {
      const index = schoolName.indexOf(e.target.value);
      const schoolIds = [...schoolName];
      schoolIds.splice(index, 1);
      setSchoolName(schoolIds);
    }
  };

  const handleStateCheckBox = (e) => {
    if (state.indexOf(e.target.value) === -1) {
      SetState([...state, e.target.value]);
    } else {
      const index = state.indexOf(e.target.value);
      const schoolIds = [...state];
      schoolIds.splice(index, 1);
      SetState(schoolIds);
    }
  };

  const handleGo = () => {
    setLoading(true);
    setSchoolFilter(schoolName);
    setOpenSchoolFilters(false);
  };

  const handleStateGo = () => {
    setLoading(true);
    setStateFilter(state);
    setOpenStateFilters(false);
  };

  const handleSchoolFilter = () => {
    if (schoolName.length > 0) {
      setLoading(true);
    }
    setSchoolFilter([]);
    setSchoolName([]);
    setOpenSchoolFilters(false);
  };

  const handleStateFilter = () => {
    if (schoolName.length > 0) {
      setLoading(true);
    }
    setStateFilter([]);
    SetState([]);
    setOpenStateFilters(false);
  };

  return (
    <Typography className="main-bannerRight">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <Grid container className={classes.searchRow} spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <div className={classes.searchBar}>
            <input
              type="text"
              placeholder="Search all users by full name"
              onChange={handleSearchChange}
            />
          </div>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <div>
            {perPage.length > 0 && (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={count}
                totalItemsCount={usersData.length}
                pageRangeDisplayed={5}
                onChange={pageHandler}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <div className={classes.userTableSec}>
        <div className={classes.userTableDiv}>
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>
                  Full Name
                  <span
                    className={classes.filterIcon}
                    onClick={() => handleFilters("user_fname")}
                  >
                    <img src="./img/filter-icon.png" alt="" />
                    {openFirstNameFilters && (
                      <ul className={classes.filterDrop} ref={ref}>
                        <li onClick={() => handleFullName("")}>None</li>
                        <li onClick={() => handleFullName("ASC")}>
                          Sort A to Z
                        </li>
                        <li onClick={() => handleFullName("DESC")}>
                          Sort Z to A
                        </li>
                      </ul>
                    )}
                  </span>
                </th>
                <th>
                  Username
                  <span
                    className={classes.filterIcon}
                    onClick={() => handleFilters("user_lname")}
                  >
                    <img src="./img/filter-icon.png" alt="" />
                    {openLastNameFilters && (
                      <ul
                        className={
                          classes.filterDrop + " " + classes.filterTwoDrop
                        }
                        ref={ref1}
                      >
                        <li onClick={() => handleFullName("")}>None</li>
                        <li onClick={() => handleFullName("ASC")}>
                          Sort A to Z
                        </li>
                        <li onClick={() => handleFullName("DESC")}>
                          Sort Z to A
                        </li>
                      </ul>
                    )}
                  </span>
                </th>
                <th>
                  Email
                  <span
                    className={classes.filterIcon}
                    onClick={() => handleFilters("email")}
                  >
                    <img src="./img/filter-icon.png" alt="" />
                    {openEmailFilters && (
                      <ul
                        className={
                          classes.filterThreeDrop + " " + classes.filterDrop
                        }
                        ref={ref2}
                      >
                        <li onClick={() => handleFullName("")}>None</li>
                        <li onClick={() => handleFullName("ASC")}>
                          Sort A to Z
                        </li>
                        <li onClick={() => handleFullName("DESC")}>
                          Sort Z to A
                        </li>
                      </ul>
                    )}
                  </span>
                </th>
                <th>
                  Business Name
                  <span className={classes.filterIcon}>
                    <img
                      onClick={() => handleFilters("schools")}
                      src="./img/filter-icon.png"
                      alt=""
                    />
                    {openSchoolFilters && (
                      <ul
                        className={
                          classes.filterTwoDrop + " " + classes.filterDrop
                        }
                        ref={ref1}
                      >
                        <li onClick={handleSchoolFilter}>None</li>
                        <li>
                          <ul>
                            {institutionData.length !== 0 &&
                              institutionData.map((l, index) => (
                                <li>
                                  <label key={index}>
                                    <input
                                      type="checkbox"
                                      id="school"
                                      value={l.id}
                                      onClick={(e) => {
                                        handleCheckBox(e);
                                      }}
                                      checked={
                                        schoolName.length > 0 &&
                                        schoolName.indexOf(l.id) > -1
                                          ? true
                                          : false
                                      }
                                    />
                                    {l.institution_name}
                                    <br />
                                  </label>
                                </li>
                              ))}
                          </ul>
                        </li>
                        <li>
                          <button onClick={handleGo} className={classes.goBtn}>
                            Go
                          </button>
                        </li>
                      </ul>
                    )}
                  </span>
                </th>
                <th>
                  News
                  <span className={classes.filterIcon}>
                    <img
                      onClick={() => handleFilters("news")}
                      src="./img/filter-icon.png"
                      alt=""
                    />
                    {newsOpen && (
                      <ul
                        className={
                          classes.filterFourDrop + " " + classes.filterDrop
                        }
                        ref={ref7}
                      >
                        <li onClick={() => handleNews("")}>None</li>
                        <li onClick={() => handleNews("1")}>Yes</li>
                        <li onClick={() => handleNews("0")}>No</li>
                      </ul>
                    )}
                  </span>
                </th>
                <th>
                  Promotions
                  <span
                    className={classes.filterIcon}
                    onClick={() => handleFilters("promotions")}
                  >
                    <img src="./img/filter-icon.png" alt="" />
                    {promotionOpen && (
                      <ul
                        className={
                          classes.filterFiveDrop + " " + classes.filterDrop
                        }
                        ref={ref8}
                      >
                        <li onClick={() => handleNews("")}>None</li>
                        <li onClick={() => handleNews("1")}>Yes</li>
                        <li onClick={() => handleNews("0")}>No</li>
                      </ul>
                    )}
                  </span>
                </th>
                <th>
                  Finance Explained
                  <span
                    className={classes.filterIcon}
                    onClick={() => handleFilters("Finance_Explained")}
                  >
                    <img src="./img/filter-icon.png" alt="" />
                    {feOpen && (
                      <ul
                        className={
                          classes.filterFiveDrop + " " + classes.filterDrop
                        }
                        ref={ref9}
                      >
                        <li onClick={() => handleNews("")}>None</li>
                        <li onClick={() => handleNews("1")}>Yes</li>
                        <li onClick={() => handleNews("0")}>No</li>
                      </ul>
                    )}
                  </span>
                </th>
                <th>
                  City
                  <span
                    className={classes.filterIcon}
                    onClick={() => handleFilters("city")}
                  >
                    <img src="./img/filter-icon.png" alt="" />
                    {openCityFilters && (
                      <ul
                        className={
                          classes.filterFiveDrop + " " + classes.filterDrop
                        }
                        ref={ref4}
                      >
                        <li onClick={() => handleFullName("")}>None</li>
                        <li onClick={() => handleFullName("ASC")}>
                          Sort A to Z
                        </li>
                        <li onClick={() => handleFullName("DESC")}>
                          Sort Z to A
                        </li>
                      </ul>
                    )}
                  </span>
                </th>
                <th>
                  State
                  <span className={classes.filterIcon}>
                    <img
                      onClick={() => handleFilters("state")}
                      src="./img/filter-icon.png"
                      alt=""
                    />
                    {/* {openStateFilters && <ul className={classes.filterDrop + " " + classes.filterTwoDrop} ref={ref5}>
                                        {statesData.length !== 0
                                            ? statesData.map((l, index) => (
                                                <li>
                                                    <input type="checkbox" onChange={() => handleStateChange(l.id)}></input>
                                                    {l.state_name}</li>
                                            )) : (<span className="noData">No Data</span>)}
                                    </ul>} */}
                    {openStateFilters && (
                      <ul
                        className={
                          classes.filterTwoDrop + " " + classes.filterDrop
                        }
                        ref={ref5}
                      >
                        <li onClick={handleStateFilter}>None</li>
                        <li>
                          <ul>
                            {statesData.length !== 0 &&
                              statesData.map((l, index) => (
                                <li>
                                  <label key={index}>
                                    <input
                                      type="checkbox"
                                      id="school"
                                      value={l.id}
                                      onClick={(e) => {
                                        handleStateCheckBox(e);
                                      }}
                                      checked={
                                        state.length > 0 &&
                                        state.indexOf(l.id) > -1
                                          ? true
                                          : false
                                      }
                                    />
                                    {l.state_name}
                                    <br />
                                  </label>
                                </li>
                              ))}
                          </ul>
                        </li>
                        <li>
                          <button
                            onClick={handleStateGo}
                            className={classes.goBtn}
                          >
                            Go
                          </button>
                        </li>
                      </ul>
                    )}
                  </span>
                </th>
                <th>
                  ZIP Code
                  <span
                    className={classes.filterIcon}
                    onClick={() => handleFilters("zip_code")}
                  >
                    <img src="./img/filter-icon.png" alt="" />
                    {openZipcodeFilters && (
                      <ul
                        className={
                          classes.filterZipcodeDrop + " " + classes.filterDrop
                        }
                        ref={ref3}
                      >
                        <li onClick={() => handleFullName("")}>None</li>
                        <li onClick={() => handleFullName("ASC")}>
                          Sort Smallest to Largest{" "}
                        </li>
                        <li onClick={() => handleFullName("DESC")}>
                          Sort Largest to Smallest{" "}
                        </li>
                      </ul>
                    )}
                  </span>
                </th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              {perPage.length > 0 ? (
                perPage.map((value, index) => (
                  <tr>
                    <td>
                      <span>{index + 1}</span>
                    </td>
                    <td>
                      <span>{value.user_fname + value.user_lname}</span>
                    </td>
                    <td>
                      <span>{value.user_name}</span>
                    </td>
                    <td>
                      <span className={classes.emailText}>{value.email}</span>
                    </td>
                    <td>
                      <span>
                        {value.institution_name
                          ? value.institution_name
                          : "N/A"}
                      </span>
                    </td>
                    <td>
                      <span>
                        {value.inkwiry_news !== null &&
                        value.inkwiry_news === "1"
                          ? "YES"
                          : "No"}
                      </span>
                    </td>
                    <td>
                      <span>
                        {value.inkwiry_promotions !== null &&
                        value.inkwiry_promotions === "1"
                          ? "YES"
                          : "No"}
                      </span>
                    </td>
                    <td>
                      <span>
                        {value.finance_explained !== null &&
                        value.finance_explained === "1"
                          ? "YES"
                          : "No"}
                      </span>
                    </td>
                    <td>
                      <span>{value.city ? value.city : "N/A"}</span>
                    </td>
                    <td>
                      <span>{value.state ? value.state : "N/A"}</span>
                    </td>
                    <td>
                      <span>{value.zip_code ? value.zip_code : "N/A"}</span>
                    </td>
                    <td>
                      <span className={classes.moreInfo}>
                        <ul>
                          <li
                            className={classes.editAdmin}
                            onClick={() => handleUserEdit(value)}
                          >
                            <i className="fa fa-pencil" title="Edit"></i>
                          </li>
                          <li>
                            <i
                              onClick={() =>
                                handleOpenConfirmDeletePopup(value.id)
                              }
                              className="fa fa-trash"
                              title="Delete"
                            ></i>
                          </li>
                          {/* <li onClick={() => handleOpenConfirmDeActivePopup(l.id, l.alias_status)}><i className="fa fa-ban" title="DeActivate"></i></li> */}
                        </ul>
                      </span>
                    </td>
                  </tr>
                ))
              ) : errorMessage ? (
                <tr>
                  <td colspan="12" className={classes.noteError}>
                    {errorMessage}
                  </td>
                </tr>
              ) : (
                <tr className={classes.noData}>
                  <td colspan="12">No Admin Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={classes.schoolsBtn}>
          {/* <div className={classes.createNew + " " + classes.messageColor}>
                        <Link to='#' >Message</Link>
                    </div> */}
          <div className={classes.createNew}>
            <Link to="/add-business-user">Add User</Link>
          </div>
          <div className={classes.clearFilters}>
            <a onClick={handleClearFilter}>CLEAR ALL FILTERS</a>
          </div>
          <div className={classes.export}>
            <a>Import</a>
          </div>
          <div className={classes.export}>
            <a
              data-tip
              data-for="main"
              onClick={() => {
                exportschooladmins();
                setLoading(true);
              }}
            >
              EXPORT
            </a>
          </div>
          <ReactTooltip id="main" type="info" place="bottom">
            <span>
              This will export only the data that is currently shown above to
              excel. If you want to export all the data in this tab, make sure
              you clear all filters before using the export button.
            </span>
          </ReactTooltip>
        </div>
      </div>
      <Dialog
        className="popupDiv popupDiv-Loan"
        open={openConfirmDeletePopup}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.colseButton}>
          <button onClick={handleCloseDeletePopup}>
            <i className="fa fa-times"></i>
          </button>
          <h3>Delete User</h3>
        </div>

        <DialogTitle
          className="modal-header"
          id="customized-dialog-title"
          onClose={handleCloseDeletePopup}
        >
          Are you sure you want to delete this user? Deleting this user will
          immediately remove their account from the site. Plus, it will delete
          all their information and data from the database after 90 days.
        </DialogTitle>
        <div className={classes.openApply}>
          <button
            onClick={() => handleDeleteUser()}
            className={classes.closeBtn + " " + classes.activeBtn}
          >
            Ok
          </button>
          <button onClick={handleCloseDeletePopup} className={classes.closeBtn}>
            Cancel
          </button>
        </div>
      </Dialog>
      <Dialog
        className="popupBankDetails"
        open={openSuccesPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.colseButton}>
          <button onClick={handleClose}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className={classes.sucessPopup}>
          <p>{successMessage || ""}</p>
          <figure>
            <img src="./img/success-img.png" alt="" />
          </figure>
        </div>
      </Dialog>
      {/*<Dialog
                className="popupDiv popupDiv-Loan"
                open={openConfirmDeActivatePopup}
                onClose={handleCloseDeActivatePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.colseButton}>
                    <button onClick={handleCloseDeActivatePopup} ><i className="fa fa-times"></i></button>
                    <h3>Update User Status</h3>
                </div>

                <DialogTitle className="modal-header" id="customized-dialog-title" onClose={handleCloseDeActivatePopup}>
                    Are You Sure You Want to {deActivatStatus === 0 ? "Activate" : "DeActivate"} this User?
                </DialogTitle>
                <div className={classes.openApply}>
                    <button onClick={() => handleDeActivateUser()} className={classes.closeBtn + " " + classes.activeBtn}>Ok</button>
                    <button onClick={handleCloseDeActivatePopup} className={classes.closeBtn}>Cancel</button>
                </div>
            </Dialog>
            <Dialog
                className="popupBankDetails"
                open={openSuccesPopup}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.colseButton}><button onClick={handleClose}><i className="fa fa-times"></i></button></div>
                <div className={classes.sucessPopup}>
                    <p>{successMessage || ''}</p>
                    <figure>
                        <img src="./img/success-img.png" alt="" />
                    </figure>
                </div>
            </Dialog>
            <Dialog
                className="popupDiv popupDiv-Loan"
                open={openMessagePopup}
                onClose={handleCloseMessagePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.colseButton}>
                    <button onClick={handleCloseMessagePopup} ><i className="fa fa-times"></i></button>
                    <h3>Send a message to all selected students</h3>
                </div>

                <DialogTitle className="modal-header" id="customized-dialog-title" onClose={handleCloseMessagePopup}>
                    You can send a custom message to all selected students in the form of an Inkwiry notification, email or both. Use the buttons below to choose one of those options. Then, draft your message and send to your students.
                </DialogTitle>
                <div className={classes.openApply}>
                    <button onClick={() => handleOpenNotification()} className={classes.closeBtn + " " + classes.activeBtn}>Notification</button>
                    <button onClick={() => handleOpenEmail('Email')} className={classes.closeBtn + " " + classes.activeBtn}>Email</button>
                    <button onClick={() => handleOpenEmail('Both')} className={classes.closeBtn + " " + classes.activeBtn}>Both</button>
                    <button onClick={handleCloseMessagePopup} className={classes.closeBtn}>Cancel</button>
                </div>
            </Dialog>
            <NotificationPage handleClose={handleNotificationClose} open={openNotification} selectedNoficationEmails={selectedNoficationEmails} />
            <EmailPage handleClose={handleEmailClose} open={openEmail} selectedNoficationEmails={selectedNoficationEmails} messageType={messageType} /> */}
    </Typography>
  );
};
BusinessUsersManagement.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);
export default enhance(BusinessUsersManagement);
