import * as commonFunctions from "../../utilities/commonFunctions";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import _, { isEqual } from "lodash";
import { useTheme, withStyles } from "@material-ui/core/styles";

import { FilterAlt } from "@mui/icons-material";
import { Grid } from "@material-ui/core";
import GroupFilter from "../../components/ui/GroupFilter/GroupFilter";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import IsLoadingHOC from "../../Hoc/IsLoadingHOC";
import NumberFormat from "react-number-format";
import Pageloader from "../../components/ui/pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTable from "../../components/ui/Table/ReactTable";
import ReactTooltip from "react-tooltip";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import { useLazyQuery } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";

const DASH_BOARD_DETAILS = loader(
  "../../graphql/schema/pf101/pf101-dashboard-details.graphql"
);
const LEADERBOARD_BOARD_DETAILS = loader(
  "../../graphql/schema/pf101/dashboard-leaderboard.graphql"
);
const EXPORT_LEADER_BOARD = loader(
  "../../graphql/schema/pf101/exportLeaderBoard.graphql"
);
const GET_SCHOOLS_GROUP_NAMES = loader(
  "../../graphql/schema/pf101/get-schools-groups-names.graphql"
);

const CustomTab = styled(Tab)(() => ({
  textTransform: "none",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const DashBoard = (props) => {
  const { classes, setLoading } = props;

  const [value, setValue] = useState(0);
  const [graphData, setGraphData] = useState({});
  const [leaderboardData, setLeaderboardData] = useState("");
  const [activePage, setActivePage] = React.useState(1);
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [groupId, setGroup] = React.useState([]);
  const [groupNames, setGroupNames] = React.useState([]);
  const [resetFilter, setResetFilter] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [pf101DashboardDetails] = useLazyQuery(DASH_BOARD_DETAILS, {
    fetchPolicy: "no-cache",
    variables: {
      group: groupId,
    },
    onCompleted(response) {
      setGraphData(response.getPf101DashboardDetails);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });
  const [getSchoolGroupNames] = useLazyQuery(GET_SCHOOLS_GROUP_NAMES, {
    fetchPolicy: "network-only",
    onCompleted({ getSchoolsGroupNames }) {
      setLoading(false);
      setGroupNames(getSchoolsGroupNames);
    },
    onError() {
      setLoading(false);
    },
  });

  const [leaderboardDetails, { fetchMore }] = useLazyQuery(
    LEADERBOARD_BOARD_DETAILS,
    {
      fetchPolicy: "network-only",
      variables: {
        data: {
          page: 1,
          perPage: 20,
          groups: groupId,
        },
      },
      onCompleted({ getLeaderBoard }) {
        setLeaderboardData(getLeaderBoard?.result);
        setActivePage(getLeaderBoard?.page);
        setPageFineshedCount(getLeaderBoard.totalCount);
        setLoading(false);
        setResetFilter(false);
      },
      onError() {
        setLoading(false);
      },
    }
  );

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    fetchMore({
      variables: {
        data: {
          page: pageNumber,
          perPage: 20,
          groups: groupId,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setLeaderboardData(fetchMoreResult?.getLeaderBoard?.result);
        // return Object.assign({}, prev, {
        //   feed: [...prev.feed, ...fetchMoreResult.feed]
        // });
      },
    });
    setActivePage(pageNumber);
  };

  const handleClearAllFilters = () => {
    setLoading(true);
    setResetFilter(true);
    setActivePage(1);
    setGroup([]);
    leaderboardDetails({
      variables: {
        data: {
          page: 1,
          perPage: 20,
          groups: [],
        },
      },
    });
  };

  const [exportLeaderBoardData, { data: exportData }] = useLazyQuery(
    EXPORT_LEADER_BOARD,
    {
      fetchPolicy: "network-only",
      onCompleted() {
        const data = exportData.exportLeaderBoard;
        if (data) {
          var link = document.createElement("a");
          link.download = "support";
          link.href = data.url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
        }
      },
      onError(error) {
        let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
        setErrorMessage(errorMsg);
        setLoading(false);
      },
    }
  );
  const exportLeaderBoardTableData = () => {
    setLoading(true);
    exportLeaderBoardData({
      variables: {
        data: {
          groups: groupId,
        },
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    if (value === 0) {
      pf101DashboardDetails();
    } else if (value === 1) {
      leaderboardDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderboardDetails, pf101DashboardDetails, value]);

  useEffect(() => {
    getSchoolGroupNames();
  }, []);

  return (
    <div>
      {groupNames && groupNames.length > 0 && (
        <div>
          <GroupFilter
            data={groupNames}
            resetFilter={resetFilter}
            getFilterValue={(value) => {
              if (!isEqual(value, groupId)) {
                setLoading(true);
                pf101DashboardDetails({
                  variables: {
                    group: value,
                  },
                });
                setGroup(value);
              }
            }}
          />
        </div>
      )}
      <div className="dashboard-page">
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.dashboardTabs}
        >
          <CustomTab label="Progress Report" {...a11yProps(0)} />
          <CustomTab label="Leaderboard" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          {graphData ? (
            <div>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  exporting: {
                    enabled: false,
                  },
                  chart: {
                    type: "column",
                    height: 600,
                  },
                  plotOptions: {
                    column: {
                      stacking: "normal",
                    },
                  },
                  title: {
                    text: "",
                  },
                  credits: {
                    enabled: false,
                  },
                  yAxis: {
                    min: 0,
                    tickInterval: 20,
                    max: 100,
                    gridLineWidth: 0,
                    labels: {
                      format: "{value}%",
                      style: {
                        fontSize: "14px",
                      },
                    },
                    title: {
                      text: " ",
                    },
                  },
                  xAxis: {
                    categories: graphData.lessons,
                    labels: {
                      style: {
                        fontSize: "14px",
                      },
                    },
                    title: {
                      text: " ",
                    },
                  },
                  tooltip: {
                    formatter: function () {
                      return (
                        "<b>" +
                        this.x +
                        "</b><br/>" +
                        this.series.name +
                        ": " +
                        this.y +
                        "%<br/>"
                      );
                    },
                    fontSize: "14px",
                  },
                  legend: {
                    itemStyle: {
                      fontSize: "14px",
                    },
                    align: "center",
                  },
                  series: [
                    {
                      dashStyle: "LongDash",
                      name: "Not Completed",
                      data: graphData.not_completed,
                      color: "#FFFFFF",
                      stack: "male",
                      legendIndex: 1,
                      borderColor: "#A6A6A6",
                    },
                    {
                      name: "Completed",
                      data: graphData.completed,
                      color: "#4472C4",
                      stack: "male",
                      legendIndex: 0,
                      borderColor: "#4472C4",
                      dashStyle: "",
                    },
                  ],
                }}
              />
            </div>
          ) : (
            <div>No data display graph</div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            container
            className={classes.searchRow}
            style={{ marginBottom: "10px" }}
          >
            <Grid item md={6} sm={6} xs={12}></Grid>
            <Grid item md={6} sm={6} xs={12}>
              <div>
                {pageFineshedCount > 0 && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={20}
                    totalItemsCount={pageFineshedCount}
                    pageRangeDisplayed={5}
                    onChange={handleGetFineshedMatchesPageClick}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <ReactTable
            activePage={activePage}
            count={pageFineshedCount}
            resetFilter={resetFilter}
            columns={[
              {
                name: "Alias",
                row: "name",
              },
              {
                name: "PowerUp XP",
                row: "total_points",
              },
              {
                name: "Full Name",
                row: "full_name",
              },
              {
                name: "Group",
                row: "grade",
              },
            ]}
            rows={leaderboardData}
          />
          <Grid
            container
            className={classes.searchRow}
            style={{ marginBottom: "10px" }}
          >
            <Grid item md={6} sm={6} xs={12}></Grid>
            <Grid item md={6} sm={6} xs={12}>
              <div className={classes.schoolsBtn}>
                <div className={classes.clearFilterBtn}>
                  <button onClick={handleClearAllFilters}>
                    CLEAR ALL FILTERS
                  </button>
                </div>
                <div className={classes.exportBtn}>
                  <Tooltip
                    title="This will export only the data that is currently shown above
                  to excel. If you want to export all the data in this tab, make
                  sure you clear all filters before using the export button."
                  >
                    <button
                      data-tip
                      data-for="main"
                      onClick={exportLeaderBoardTableData}
                    >
                      EXPORT
                    </button>
                  </Tooltip>
                </div>
              </div>
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    </div>
  );
};

DashBoard.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
  withApollo,
  IsLoadingHOC
);

export default enhance(DashBoard);
