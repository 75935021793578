const style = {
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      darkGray: "#a1a1a1",
      darkBlue: "#153180",
      gainsboro: "#e4e4e4",
      dimGray: "#666666",
      whiteSmoke: "#f8f8f8",
      tropicalBlue: "#b4d1ee",
      sushi: "#70a438",
      cinnabar: "#dd4247",
    },
  },
  typography: {
    useNextVariants: true,
    // fontFamily: "'Open Sans', sans-serif",
    fontFamily: "'museo-sans', sans-serif",
    fontSize: 14,
    lineHeight: "20px",
    color: "#666666",
    button: {
      background: "#153180",
      border: "2px solid #153180",
      color: "#fff",
      padding: "10px 15px",
      cursor: "pointer",
      textAlign: "center",
      textTransform: "inherit",
      fontSize: 16,
      "&:hover": {
        background: "#fff",
        border: "2px solid #153180",
        color: "#153180",
      },
    },
    body1: {
      fontSize: 14,
      // color: '#666',
      color: "#333",
      width: "100%",
    },
    h1: {
      fontSize: 40,
    },
    h2: {
      fontSize: 36,
      fontWeight: "800",
      lineHeight: "40px",
    },
    h3: {
      fontSize: 25,
    },
    h4: {
      fontSize: 20,
      fontWeight: "600",
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 16,
    },
  },
  container: {
    maxWidth: "1170px",
    padding: "0 15px",
    margin: "0px auto",
    boxSizing: "border-box",
    // "& img": {
    //   maxWidth: "100%",
    // },
    "@media (max-width: 767px)": {
      "& h3": {
        fontSize: "25px !important",
        lineHeight: "30px !important",
      },
      "& h2": {
        fontSize: "25px !important",
        lineHeight: "30px !important",
      },
      "& h4": {
        fontSize: "25px !important",
        lineHeight: "30px !important",
      },
      "& p": {
        fontSize: "17px !important",
        lineHeight: "28px !important",
      },
    },
  },
  containerAdmin: {
    width: "100%",
    margin: "0",
    maxWidth: "100%",
  },
  root: {
    display: "block",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#fff",
    minHeight: "70vh",
  },
  mainDiv: {
    display: "flex",
    "& > div": {
      margin: "0px",
    },
  },
  tableGroup: {
    background: "#fff",
    borderTop: "1px solid #ccc",
    paddingTop: "30px",
  },
  gridSpacing: {
    width: "100%",
  },
  sidbarDivCol: {
    width: "275px",
    paddingLeft: "0",
    flexBasis: "initial",
    padding: "0px !important",
    "& > div": {
      maxWidth: "260px",
    },
  },
  tableDivCol: {
    width: "calc(100% - 275px)",
    paddingRight: "0 !important",
    maxWidth: "initial",
    flexBasis: "initial",
    padding: "0px 15px !important",
  },
  tableDivColNew: {
    width: "100%",
    paddingRight: "0 !important",
    maxWidth: "initial",
    flexBasis: "initial",
    padding: "0px 15px !important",
  },
  filterIcon: {
    "& img": {
      maxWidth: "15px",
      marginLeft: "5px",
      cursor: "pointer",
      position: "relative",
      top: "2px",
    },
  },
  menuButton: {
    display: "flex",
    alignItems: "center",
    background: "none",
    color: "#003366",
    border: "none",
  },
};

export default style;
