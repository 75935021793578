import * as commonFunctions from "../../utilities/commonFunctions";

import { Button, Grid } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import { useMutation, withApollo } from "react-apollo";

import { Link } from "react-router-dom";
import Pageloader from "../ui/pageloader";
import PropTypes from "prop-types";
import { TextField } from "final-form-material-ui";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import { useLazyQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const CHECK_USER_NAME = loader("../../graphql/schema/ui/CheckUserName.graphql");
const CHECK_EMAIL = loader("../../graphql/schema/ui/checkEmail.graphql");
const INSTITUTIONS = loader(
  "../../graphql/schema/business/business-institutions.graphql"
);
const ADD_SCHOOL_ADMIN = loader(
  "../../graphql/schema/schools/saveSchoolAdmin.graphql"
);

const AddBusinessAdmin = (props) => {
  const { classes, history } = props;

  const [loading, setLoading] = React.useState(true);
  const [emailValue, setEmailValue] = useState("");
  const [passCheck, setPassCheck] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [alphabetsExist, setAlphabetsExist] = useState(false);
  const [numericExist, setNumericExist] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [userName, setUserName] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [institutionData, setInstitutionData] = useState([]);
  const [schoolError, setSchoolError] = useState(false);

  const [addschooladmin] = useMutation(ADD_SCHOOL_ADMIN, {
    onCompleted() {
      setLoading(false);
      history.push("/businesses-admins");
    },
    onError() {
      setLoading(false);
    },
  });

  const [checkUserName] = useLazyQuery(CHECK_USER_NAME, {
    variables: {
      data: userName,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckUserName: { status } }) {
      if (status) {
        setUsernameError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (userName !== "") {
        setUsernameError(formatedErrors);
      }
    },
  });

  const [institutions] = useLazyQuery(INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(response.getBusinessInstitutions);
      setLoading(false);
    },
  });

  useEffect(() => {
    institutions();
  }, []);

  const [checkEmailExist] = useLazyQuery(CHECK_EMAIL, {
    variables: {
      email: emailValue,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckEmail: { status } }) {
      if (status) {
        setEmailError("");
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (emailValue !== "") {
        setEmailError(formatedErrors);
      }
    },
  });

  const handleSubmitClick = () => {
    setSubmitClicked(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required.";
    }

    if (values.email) {
      if (!commonFunctions.validateEmail(values.email)) {
        errors.email = "Enter valid email.";
      }
      setEmailValue(values.email);
    }
    if (!values.email) {
      errors.email = "email is required.";
      setEmailValue("");
    }

    if (!values.userName) {
      errors.userName = "username is required.";
    } else {
      setUserName(values.userName);
    }

    if (!values.password) {
      errors.password = "password is required.";
    }

    if (values.password) {
      if (values.password.length >= 8) {
        setPasswordLength(true);
      } else {
        errors.password = "";
        setPasswordLength(false);
      }
      if (!commonFunctions.validateAlpha(values.password)) {
        setAlphabetsExist(true);
      } else {
        errors.password = "";
        setAlphabetsExist(false);
      }
      if (!commonFunctions.validateNumeric(values.password)) {
        setNumericExist(true);
      } else {
        errors.password = "";
        setNumericExist(false);
      }
    } else {
      setPasswordLength(false);
      setAlphabetsExist(false);
      setNumericExist(false);
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = "confirm password is required.";
    } else {
      if (values.password_confirmation) {
        if (values.password === values.password_confirmation) {
          setPasswordMatch(true);
        } else {
          setPasswordMatch(false);
          errors.password_confirmation = "";
        }
      }
    }
    if (!values.School) {
      if (submitClicked) {
        setSchoolError(true);
      }
      errors.School = "please select School";
    } else {
      setSchoolError(false);
    }
    return errors;
  };

  const onSubmit = (values) => {
    setSubmitClicked(false);
    setLoading(true);
    addschooladmin({
      variables: {
        data: {
          name: values.first_name,
          user_lname: values.last_name,
          user_name: values.userName,
          email: emailValue,
          password: values.password,
          confirm_password: values.password_confirmation,
          institution_id: values.School,
        },
      },
    });
  };

  return (
    <div>
      <Typography className="edit-page-new">
        {loading === true ? <Pageloader loading={loading} /> : ""}
        <div className={classes.backToAdmin}>
          <Link to="/businesses-admins">
            <i className="fa fa-long-arrow-left" aria-hidden="true"></i> Back to
            Business Admin
          </Link>
        </div>
        <div className={classes.formBlock}>
          <h3>Add Business Admin</h3>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Form
                validate={validate}
                onSubmit={onSubmit}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.fields}>
                      <div className={classes.formGroup}>
                        <label>First Name </label>
                        <Field
                          placeholder="ex. Mike"
                          id="outlined-basic"
                          variant="outlined"
                          type="text"
                          autoComplete="current-password"
                          name="first_name"
                          fullWidth
                          required={!values.first_name && true}
                          component={TextField}
                          className={classes.inputLeft}
                        />
                      </div>
                      <div className={classes.formGroup}>
                        <label>Last Name</label>
                        <Field
                          placeholder="ex. Inkwiry"
                          id="outlined-basic"
                          variant="outlined"
                          type="text"
                          autoComplete="current-password"
                          name="last_name"
                          fullWidth
                          required
                          component={TextField}
                          className={classes.inputLeft}
                        />
                      </div>
                      <div className={classes.formGroup}>
                        <label>Email</label>
                        <Field
                          placeholder="ex. mike@inkwiry.com"
                          id="outlined-basic"
                          variant="outlined"
                          type="text"
                          autoComplete="current-password"
                          name="email"
                          fullWidth
                          required
                          onBlur={(e) => {
                            checkEmailExist(e);
                          }}
                          component={TextField}
                          className={classes.inputLeft}
                        />
                      </div>
                      <div>{emailError}</div>
                      <div className={classes.formGroup}>
                        <label>School</label>
                        <Field
                          name="School"
                          component="select"
                          variant="outlined"
                          multiple
                          className={classNames(
                            classes.inputLeft,
                            schoolError ? classes.typeError : ""
                          )}
                        >
                          {institutionData.length !== 0 &&
                            institutionData.map((l, index) => (
                              <option key={index} value={l.id}>
                                {l.institution_name}
                              </option>
                            ))}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.fields}>
                      <h3>Username and Password</h3>
                      <div className={classes.formGroup}>
                        <label>Username</label>
                        <div className={classes.inputLeft}>
                          <Field
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            autoComplete="current-password"
                            name="userName"
                            placeholder="Username"
                            fullWidth
                            required
                            onBlur={(e) => {
                              checkUserName(e);
                            }}
                            component={TextField}
                          />
                          {usernameError ? (
                            <div className={classes.nameError}>
                              {usernameError}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className={classes.formGroup}>
                        <label>New Password</label>
                        <Field
                          id="outlined-basic"
                          variant="outlined"
                          type="password"
                          autoComplete="current-password"
                          placeholder="Password"
                          name="password"
                          fullWidth
                          required
                          component={TextField}
                          onFocus={() => {
                            setPassCheck(true);
                          }}
                          onBlur={() => {
                            setPassCheck(false);
                          }}
                          className={classes.inputLeft}
                        />
                        <div
                          className={
                            passCheck
                              ? classes.resetPassCondition +
                                " " +
                                classes.resetPass
                              : classes.resetPass
                          }
                        >
                          <div className={classes.conditions}>
                            <p>
                              <span>
                                {passwordLength ? (
                                  <img src="./img/pass.png" alt="" />
                                ) : (
                                  <img src="./img/fail.png" alt="" />
                                )}
                              </span>
                              <label>Include at least 8 characters</label>
                            </p>
                            <p>
                              <span>
                                {alphabetsExist ? (
                                  <img src="./img/pass.png" alt="" />
                                ) : (
                                  <img src="./img/fail.png" alt="" />
                                )}
                              </span>
                              <label>
                                Include upper and lower case characters
                              </label>
                            </p>
                            <p>
                              <span>
                                {numericExist ? (
                                  <img src="./img/pass.png" alt="" />
                                ) : (
                                  <img src="./img/fail.png" alt="" />
                                )}
                              </span>
                              <label>Include at least 1 number</label>{" "}
                            </p>
                            <p>
                              <span>
                                {passwordMatch ? (
                                  <img src="./img/pass.png" alt="" />
                                ) : (
                                  <img src="./img/fail.png" alt="" />
                                )}
                              </span>
                              <label>Passwords match</label>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={classes.formGroup}>
                        <label>Confirm Password</label>
                        <Field
                          id="outlined-basic"
                          variant="outlined"
                          type="password"
                          autoComplete="current-password"
                          name="password_confirmation"
                          placeholder="Confirm New Password"
                          fullWidth
                          required
                          component={TextField}
                          onFocus={() => {
                            setPassCheck(true);
                          }}
                          onBlur={() => {
                            setPassCheck(false);
                          }}
                          className={classes.inputLeft}
                        />
                      </div>
                      <div className={classes.updateBtn}>
                        <button type="submit" onClick={handleSubmitClick}>
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              />
            </Grid>
          </Grid>
        </div>
      </Typography>
    </div>
  );
};

AddBusinessAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object.isRequired,
};

const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(AddBusinessAdmin);
