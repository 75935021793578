import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import compose from "recompose/compose";
import styles from "./styles";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

function Filter(props) {
  const {
    filterQuestion,
    activeFilter,
    setActiveFilter,
    type,
    classes,
    loadFilterData,
    setOpenFilters,
  } = props;

  const ref = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];
  const handleClickOutside = (event) => {
    if (ref[0].current && !ref[0].current.contains(event.target)) {
      setOpenFilters({ question_1: false });
    }
  };
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  const update = (index, type, to) => {
    const newArr = activeFilter[type];
    newArr[index] = to;
    setActiveFilter({ ...activeFilter, [type]: newArr });
  };
  return (
    <ul className={classes.filterDrop} ref={ref[0]}>
      <li
        onClick={() => {
          setActiveFilter({ ...activeFilter, [type]: [] });
        }}
      >
        None
      </li>
      {filterQuestion[type].map((value, index) => (
        <li
          key={index}
          onClick={() => {
            typeof value !== "string"
              ? !activeFilter[type].includes(value)
                ? update(index, type, index + 1)
                : update(index, type, 0)
              : activeFilter[type].includes(value)
              ? update(index, type, "")
              : update(index, type, filterQuestion[type][index]);
          }}
        >
          <input
            type="checkbox"
            id={`${type}${index}`}
            name={`${type}${index}`}
            value={value}
            checked={
              activeFilter[type][index] === filterQuestion[type][index]
                ? true
                : false
            }
          />
          <span>{value}</span>
        </li>
      ))}
      <button
        tton
        onClick={() => loadFilterData(type)}
        className={classes.goBtn}
      >
        Go
      </button>
    </ul>
  );
}

Filter.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter);
export default enhance(Filter);
