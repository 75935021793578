import * as commonFunctions from "../../../utilities/commonFunctions";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import { ACCESS_TOKEN } from "../../../utilities/userUtils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ChangeClientModal from "../Modal/ChangeClientModal";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styled from "@emotion/styled";
import styles from "./styles";
import { useUserDetailsContext } from "../../../context/userDetails";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const INSTITUTIONS = loader("../../../graphql/schema/ui/Institutions.graphql");
const UPDATE_INSTITUTION_ID = loader(
  "../../../graphql/schema/ui/updateInstitutions.graphql"
);

const ListItemButtonCustom = styled(ListItemButton)(() => ({
  // padding: "5px 10px",
}));

const ListItemTextCustom = styled(ListItemText)(({ theme }) => ({
  color: "#fff",
  fontSize: theme.typography.h3,
  ".MuiListItemText-secondary": {
    color: "#fff",
  },
  // p: {
  //   color: "#fff",
  // },
}));

const IconSize = styled("i")(() => ({
  width: "8px",
  height: "4px",
}));

const getActiveTab = (pathname) => {
  switch (pathname) {
    case "/schools":
    case "/business":
      return "b2b_clients";

    case "/businesses-admins":
    case "/schools-admins":
    case "/inkwiry-admins":
      return "accounts";

    case "/pf101-dashboard":
    case "/pf101-member-tracker":
    case "/pf101-member-survey":
    case "/pf101-baseline-assessment":
    case "/pf101-final-assessment":
      return "personal_finance";

    case "/mcs-dashboard":
    case "/mcs-student-tacker":
      return "carrer_sketch";

    default:
      break;
  }
};

const Sidbar = (props) => {
  const location = useLocation();
  const { classes, history, setSideBar } = props;
  const { state, selectSchoolhandler } = useUserDetailsContext();
  const [selectSchool, setSelectSchool] = React.useState("");
  const [schoolOpen, setSchoolOpen] = React.useState(false);
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] =
    React.useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = React.useState(true);
  const [openSelectPopup, setOpenSelectPopup] = React.useState(false);
  const [pf101, setPf101] = React.useState(false);
  const [institutionData, setInstitutionData] = React.useState([]);
  const [businessOpen, setBusinessOpen] = React.useState(false);
  const [mcs, setMcs] = React.useState(false);
  const [AdministratorAccounts, setAdministratorAccounts] =
    React.useState(false);
  const [MemberData, setMemberData] = React.useState(false);
  const [challenges, setChallenges] = React.useState(false);
  const [memberDetails, setMemberDetails] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => {
    setExpanded(panel === expanded ? false : panel);
  };
  useEffect(() => {
    setExpanded(() => getActiveTab(location.pathname));
  }, []);

  const handleChangeSchool = () => {
    setSchoolOpen(!schoolOpen);
    // setPf101(false);
    // setBusinessOpen(false);
    // setMcs(false);
  };

  const handleSchoolChange = (e) => {
    setSelectSchool(e.target.value);
    if (e.target.value !== "Proceed as Main Admin") {
      selectSchoolhandler(e.target.value);
      localStorage.setItem("institution", JSON.stringify(e.target.value));
    } else {
      localStorage.removeItem("institution");
    }
  };

  const handleLogout = () => {
    setOpenSelectPopup(true);
    localStorage.removeItem("app-token");
    localStorage.removeItem("institution");
    history.push("/");
  };

  const handleCloseDeletePopup = () => {
    setOpenConfirmDeletePopup(false);
  };

  // const handleChangeLogoutType = () => {
  //   setOpenConfirmDeletePopup(true);
  // };
  const [updateInstitutionId] = useMutation(UPDATE_INSTITUTION_ID, {
    onCompleted({ updateInstitutionId }) {
      setLoading(false);
      setOpenSelectPopup(false);
      const { admin } = updateInstitutionId;
      localStorage.setItem(
        "institution_details",
        JSON.stringify(admin?.selected_institution)
      );
      if (admin?.institution_id === null) {
        history.push("/inkwiry-admins");
      } else {
        history.push("/client-dashboard");
      }
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const handleGOClick = () => {
    if (selectSchool === "Proceed as Main Admin") {
      setSideBar("A");
      setLoading(true);
      updateInstitutionId({
        variables: {
          institution_id: null,
        },
      });
      localStorage.removeItem("institution");
    } else {
      setLoading(true);
      updateInstitutionId({
        variables: {
          institution_id: selectSchool?.id,
        },
      });
      // history.push("/client-dashboard");
      setSideBar("B");
    }
  };

  const handleChangeSchoolType = () => {
    setOpenSelectPopup(true);
  };

  // const handleClose = () => {
  //   setOpenSelectPopup(false);
  // };
  const handleClose = () => {
    setOpenSelectPopup(false);
  };

  const handleOpenPf101 = () => {
    setPf101(!pf101);
    setAdministratorAccounts(false);
    setMemberData(false);
    setMcs(false);
    setChallenges(false);
    setMemberDetails(false);
  };
  const handleAdministratorAccounts = () => {
    setAdministratorAccounts(!AdministratorAccounts);
    setMemberData(false);
    setPf101(false);
    setMcs(false);
    setChallenges(false);
    setMemberDetails(false);
  };
  const handleMemberData = () => {
    setMemberData(!MemberData);
    setAdministratorAccounts(false);
    setPf101(false);
    setMcs(false);
    setChallenges(false);
    setMemberDetails(false);
  };
  const handleMcs = () => {
    setMcs(!mcs);
    setAdministratorAccounts(false);
    setMemberData(false);
    setPf101(false);
    setChallenges(false);
    setMemberDetails(false);
  };
  const handleChallenges = () => {
    setMcs(false);
    setAdministratorAccounts(false);
    setMemberData(false);
    setPf101(false);
    setChallenges(!challenges);
    setMemberDetails(false);
  };
  const handleMemberDetails = () => {
    setMcs(false);
    setAdministratorAccounts(false);
    setMemberData(false);
    setPf101(false);
    setChallenges(false);
    setMemberDetails(!memberDetails);
  };

  const handleOpenBusiness = () => {
    setSchoolOpen(false);
    setMcs(false);
    setPf101(false);
    setBusinessOpen(!businessOpen);
  };

  const [institutions] = useLazyQuery(INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(response.Institutions);
    },
  });

  React.useEffect(() => {
    institutions();
  }, []);

  return (
    <>
      <List
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: 360,
          backgroundColor: "#036",
          color: "#fff",
          height: "100%",
          paddingTop: "20px",
        }}
        dense={true}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButtonCustom onClick={() => handleChange("b2b_clients")}>
          <ListItemTextCustom secondary="B2B Clients" />
          {expanded === "b2b_clients" ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </ListItemButtonCustom>
        <Collapse in={expanded === "b2b_clients"} timeout="auto" unmountOnExit>
          <List dense={true} component="div" disablePadding>
            <ListItemButton
              selected={location.pathname === "/schools"}
              component={NavLink}
              to="/schools"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Schools" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/business"}
              component={NavLink}
              to="/business"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Business" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButtonCustom onClick={() => handleChange("accounts")}>
          <ListItemTextCustom secondary="Administrator Accounts" />
          {expanded === "accounts" ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </ListItemButtonCustom>
        <Collapse in={expanded === "accounts"} timeout="auto" unmountOnExit>
          <List dense={true} component="div" disablePadding>
            <ListItemButton
              selected={location.pathname === "/inkwiry-admins"}
              component={NavLink}
              to="/inkwiry-admins"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Inkwiry Admins" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/schools-admins"}
              component={NavLink}
              to="/schools-admins"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="School Admins" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/businesses-admins"}
              component={NavLink}
              to="/businesses-admins"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Business Admins" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButtonCustom
          selected={location.pathname === "/user-management"}
          component={NavLink}
          to="/user-management"
        >
          <ListItemTextCustom secondary="Member Details" />
        </ListItemButtonCustom>
        <ListItemButtonCustom onClick={() => handleChange("personal_finance")}>
          <ListItemTextCustom secondary="Personal Finance 101" />
          {expanded === "personal_finance" ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </ListItemButtonCustom>
        <Collapse
          in={expanded === "personal_finance"}
          timeout="auto"
          unmountOnExit
        >
          <List dense={true} component="div" disablePadding>
            <ListItemButton
              selected={location.pathname === "/pf101-dashboard"}
              component={NavLink}
              to="/pf101-dashboard"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Dashboard" />
            </ListItemButton>
            {/* <ListItemButton
              selected={location.pathname === "/pf101-level-reports"}

              component={NavLink}
              to="/pf101-level-reports"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Level Reports" />
            </ListItemButton> */}
            <ListItemButton
              selected={location.pathname === "/pf101-member-tracker"}
              component={NavLink}
              to="/pf101-member-tracker"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Member Tracker" />
            </ListItemButton>
            {/* <ListItemButton
              selected={location.pathname === "/pf101-challenge-analytics"}

              component={NavLink}
              to="/pf101-challenge-analytics"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Challenge Reports" />
            </ListItemButton> */}
            <ListItemButton
              selected={location.pathname === "/pf101-member-survey"}
              component={NavLink}
              to="/pf101-member-survey"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Survey Results" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/pf101-baseline-assessment"}
              component={NavLink}
              to="/pf101-baseline-assessment"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Baseline Assessment" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/pf101-final-assessment"}
              component={NavLink}
              to="/pf101-final-assessment"
              sx={{ pl: 4 }}
            >
              <ListItemTextCustom secondary="Final Assessment" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButtonCustom onClick={() => handleChange("carrer_sketch")}>
          <ListItemTextCustom secondary="My Career Sketch" />
          {expanded === "carrer_sketch" ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </ListItemButtonCustom>
        <Collapse
          in={expanded === "carrer_sketch"}
          timeout="auto"
          unmountOnExit
        >
          <List dense={true} component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname === "/mcs-dashboard"}
              component={NavLink}
              to="/mcs-dashboard"
            >
              <ListItemTextCustom secondary="Dashboard" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component={NavLink}
              selected={location.pathname === "/mcs-student-tacker"}
              to="/mcs-student-tacker"
            >
              <ListItemTextCustom secondary="Member Tracker" />
            </ListItemButton>
          </List>
        </Collapse>
        {/* <ListItemButtonCustom component={NavLink} to="/contact-us">
          <ListItemTextCustom secondary="Contact Submissions" />
        </ListItemButtonCustom> */}
        <ListItemButtonCustom
          selected={location.pathname === "/document-center"}
          component={NavLink}
          to="/document-center"
        >
          <ListItemTextCustom secondary="Document Center" />
        </ListItemButtonCustom>
        <ListItemButtonCustom onClick={handleChangeSchoolType}>
          <ListItemTextCustom secondary="CHANGE ADMIN TOOL" />
        </ListItemButtonCustom>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <Divider sx={{ backgroundColor: "#fff" }} />
          <ListItemButtonCustom>
            <ListItemTextCustom secondary={<i>Plan Better; Live Better</i>} />
          </ListItemButtonCustom>
        </Box>
      </List>
      {openSelectPopup && (
        <ChangeClientModal
          header="Select Admin Tool"
          setSideBar={setSideBar}
          open={openSelectPopup}
          handleClose={() => setOpenSelectPopup(!openSelectPopup)}
          {...props}
        />
      )}
      {/* {openSelectPopup && (
        <Dialog
          open={openSelectPopup}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="school-popup change-school-popup"
        >
          <div className="modal-header">
            <button onClick={handleClose}>X</button>
            <DialogTitle id="alert-dialog-title">{"Select School"}</DialogTitle>
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <label>
                Select the school from the drop down to go to that school's
                admin tool.
              </label>
              <div>
                <FormControl variant="filled" className="formControl">
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={selectSchool}
                    onChange={handleSchoolChange}
                    placeholder="Select School"
                    label="Select School"
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Select School</em>
                    </MenuItem>
                    {JSON.parse(localStorage.getItem(ACCESS_TOKEN))
                      ?.admin_type === "inkwiry" && (
                      <MenuItem value="Proceed as Main Admin">
                        {" "}
                        Proceed as Main Admin{" "}
                      </MenuItem>
                    )}
                    {institutionData.length > 0 &&
                      institutionData.map((l, index) => {
                        return (
                          // <MenuItem value={l.id}> {l.institution_name} </MenuItem>
                          <MenuItem value={l}> {l.institution_name} </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="model-footer">
            <Button onClick={handleGOClick} color="primary" autoFocus>
              GO
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
    </>

    // <div className={classNames(classes.sidbarDiv, "sidebar-div")}>
    //   <ul>
    //     <li>
    //       <NavLink exact to="/dashboard" activeClassName={classes.listActive}>
    //         {" "}
    //         Main Dashboard{" "}
    //       </NavLink>
    //     </li>
    //     <li>
    //       <a
    //         onClick={handleAdministratorAccounts}
    //         className={classes.villanovaFlex}
    //       >
    //         <span className={classes.villanovaTxt}>
    //           Administrator Accounts{" "}
    //         </span>
    //         <span className={classes.villanovaIcon}>
    //           <i className="fa fa-caret-down" aria-hidden="true"></i>
    //         </span>
    //       </a>
    //       {AdministratorAccounts ? (
    //         <ul className={classes.dropDownMenu}>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/inkwiry-admins"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Inkwiry Admins
    //             </NavLink>
    //           </li>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/schools-admins"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               School Admins
    //             </NavLink>
    //           </li>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/businesses-admins"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Business Admins
    //             </NavLink>
    //           </li>
    //         </ul>
    //       ) : (
    //         ""
    //       )}
    //     </li>
    //     {/* <li>
    //       Billing
    //     </li>
    //     <li>Boost My Awareness</li>
    //     <li>Business Contact Submissions</li>
    //     <li>School Contact Submissions</li>
    //     <li>Dashboard</li>
    //     <li>Email Lists</li> */}
    //     <li>
    //       <a onClick={handleMemberDetails} className={classes.villanovaFlex}>
    //         <span className={classes.villanovaTxt}>Member Details</span>
    //         <span className={classes.villanovaIcon}>
    //           <i className="fa fa-caret-down" aria-hidden="true"></i>
    //         </span>
    //       </a>
    //       {memberDetails ? (
    //         <ul className={classes.dropDownMenu}>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/user-management"
    //               className={classes.submenuList}
    //               activeClassName={classes.listActive}
    //             >
    //               Table view
    //             </NavLink>
    //           </li>
    //           {/* <li>
    //             <NavLink exact to="/member-dashboard" className={classes.submenuList} activeClassName={classes.listActive}>Dashboard view</NavLink>
    //           </li> */}
    //         </ul>
    //       ) : (
    //         ""
    //       )}
    //     </li>
    //     <li>
    //       <a onClick={handleMemberData} className={classes.villanovaFlex}>
    //         <span className={classes.villanovaTxt}>
    //           Financial Configurator Member Data{" "}
    //         </span>
    //         <span className={classes.villanovaIcon}>
    //           <i className="fa fa-caret-down" aria-hidden="true"></i>
    //         </span>
    //       </a>
    //       {MemberData ? (
    //         <ul className={classes.dropDownMenu}>
    //           <li>
    //             <a className={classes.submenuList}>Test Drive Details</a>
    //           </li>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/user-management"
    //               className={classes.submenuList}
    //               activeClassName={classes.listActive}
    //             >
    //               {" "}
    //               Member Details
    //             </NavLink>
    //           </li>
    //         </ul>
    //       ) : (
    //         ""
    //       )}
    //     </li>
    //     {/* <li>Personal Finance Challenge Report</li> */}
    //     <li>
    //       <a onClick={handleOpenPf101} className={classes.villanovaFlex}>
    //         <span className={classes.villanovaTxt}>Personal Finance 101 </span>
    //         <span className={classes.villanovaIcon}>
    //           <i className="fa fa-caret-down" aria-hidden="true"></i>
    //         </span>
    //       </a>
    //       {pf101 ? (
    //         <ul className={classes.dropDownMenu}>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/pf101-dashboard"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Dashboard
    //             </NavLink>
    //           </li>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/pf101-member-tracker"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               {" "}
    //               Member Tracker
    //             </NavLink>
    //           </li>
    //           <li>
    //             <Link
    //               to="/pf101-baseline-assessment"
    //               className={classes.submenuList}
    //             >
    //               Baseline Assessment
    //             </Link>
    //           </li>
    //           <li>
    //             <Link
    //               to="/pf101-final-assessment"
    //               className={classes.submenuList}
    //             >
    //               Final Assessment
    //             </Link>
    //           </li>
    //           <li>
    //             <Link to="/pf101-level-reports" className={classes.submenuList}>
    //               Level Reports
    //             </Link>
    //           </li>
    //           <li>
    //             <Link
    //               to="/pf101-challenge-analytics"
    //               className={classes.submenuList}
    //             >
    //               Challenge Reports
    //             </Link>
    //           </li>
    //           {/* <li>
    //             <Link to="/pf101-baseline-assessment">PF101 Baseline Assessment</Link>
    //           </li> */}
    //           {/* <li>
    //             <Link to="/pf101-final-assessment">PF101 Final Assessment</Link>
    //           </li> */}
    //           {/* <li>
    //             <Link to="/pf101-survey-devon">Devon Prep Survey</Link>
    //           </li> */}
    //           <li>
    //             <Link
    //               to="/pf101-student-survey"
    //               className={classes.submenuList}
    //             >
    //               Survey Result
    //             </Link>
    //           </li>
    //           {/* <li>
    //             <Link to="/pf101-member-survey" className={classes.submenuList}>Member Survey</Link>
    //           </li> */}
    //         </ul>
    //       ) : (
    //         ""
    //       )}
    //     </li>
    //     <li>
    //       <a onClick={handleMcs} className={classes.villanovaFlex}>
    //         <span className={classes.villanovaTxt}>My Carrer Sketch </span>
    //         <span className={classes.villanovaIcon}>
    //           <i className="fa fa-caret-down" aria-hidden="true"></i>
    //         </span>
    //       </a>
    //       {mcs && (
    //         <ul className={classes.dropDownMenu}>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/mcs-dashboard"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Dashboard
    //             </NavLink>
    //           </li>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/mcs-student-tacker"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Member Tracker
    //             </NavLink>
    //           </li>
    //           <li>
    //             <Link
    //               to="/pf101-student-survey"
    //               className={classes.submenuList}
    //             >
    //               Student Survey
    //             </Link>
    //           </li>
    //           <li>
    //             <Link to="/pf101-member-survey" className={classes.submenuList}>
    //               Member Survey
    //             </Link>
    //           </li>
    //         </ul>
    //       )}
    //     </li>

    //     <li>
    //       <NavLink exact to="/schools" activeClassName={classes.listActive}>
    //         {" "}
    //         Schools{" "}
    //       </NavLink>
    //     </li>
    //     <li>
    //       <NavLink exact to="/business" activeClassName={classes.listActive}>
    //         {" "}
    //         Business{" "}
    //       </NavLink>
    //     </li>

    //     {/* <li>
    //       <a onClick={handleChangeSchool} className={classes.villanovaFlex}>
    //         <span className={classes.villanovaTxt}>Schools</span>
    //         <span className={classes.villanovaIcon}>
    //           <i className="fa fa-caret-down" aria-hidden="true"></i>
    //         </span>
    //       </a>
    //       {schoolOpen && (
    //         <ul className={classes.dropDownMenu}>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/schools"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Schools Management
    //             </NavLink>
    //           </li>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/school-user-management"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Schools User Management
    //             </NavLink>
    //           </li>
    //         </ul>
    //       )}
    //     </li>

    //     <li>
    //       <a onClick={handleOpenBusiness} className={classes.villanovaFlex}>
    //         <span className={classes.villanovaTxt}>Business</span>
    //         <span className={classes.villanovaIcon}>
    //           <i className="fa fa-caret-down" aria-hidden="true"></i>
    //         </span>
    //       </a>
    //       {businessOpen && (
    //         <ul className={classes.dropDownMenu}>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/business"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Business Management
    //             </NavLink>
    //           </li>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/business-user-management"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Business User Management
    //             </NavLink>
    //           </li>
    //         </ul>
    //       )}
    //     </li> */}

    //     <li>
    //       <a>Career Sketches</a>
    //     </li>
    //     <li>
    //       <a onClick={handleChallenges}>
    //         {" "}
    //         Challenges{" "}
    //         <span>
    //           <i className="fa fa-caret-down" aria-hidden="true"></i>
    //         </span>
    //       </a>
    //       {challenges && (
    //         <ul className={classes.dropDownMenu}>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/mcs-dashboard"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Home Page Challenge
    //             </NavLink>
    //           </li>
    //           <li>
    //             <NavLink
    //               exact
    //               to="/mcs-student-tacker"
    //               className={classes.submenuList}
    //               activeClassName={classes.subMenActive}
    //             >
    //               Awareness
    //             </NavLink>
    //           </li>
    //           <li>
    //             <Link
    //               to="/pf101-student-survey"
    //               className={classes.submenuList}
    //             >
    //               Discovery
    //             </Link>
    //           </li>
    //         </ul>
    //       )}
    //     </li>
    //     <li>
    //       <Link to="/downloads">Downloads</Link>
    //     </li>
    //     <li>
    //       <NavLink exact to="/contact-us" activeClassName={classes.listActive}>
    //         Contact Submissions
    //       </NavLink>
    //     </li>
    //     <li>Finance Explained</li>
    //     <li>
    //       <NavLink
    //         exact
    //         to="/general-settings"
    //         activeClassName={classes.listActive}
    //       >
    //         General Settings
    //       </NavLink>
    //     </li>
    //     {/* <li>
    //       <Link to="/document-center">Document Center</Link>
    //     </li> */}

    //     {/* <li>Subscribers</li>
    //     <li>Support Tickets</li>
    //     <li>Unsubscribe List</li>
    //     <li>User Analytics</li> */}
    //     {/* <li>
    //       <NavLink exact to="/user-management" activeClassName={classes.listActive}> User Management</NavLink>
    //     </li>
    //   <li>Villanova</li>*/}
    //     <li onClick={handleChangeSchoolType}>
    //       <a>Change School</a>
    //     </li>
    //     {/* <li onClick={handleChangeLogoutType}>
    //       {" "}
    //       <NavLink to="#">Logout</NavLink>
    //     </li> */}

    //     <Dialog
    //       className="popupDiv popupDiv-Loan"
    //       open={openConfirmDeletePopup}
    //       onClose={handleCloseDeletePopup}
    //       aria-labelledby="alert-dialog-title"
    //       aria-describedby="alert-dialog-description"
    //     >
    //       <DialogTitle
    //         className="modal-header"
    //         id="customized-dialog-title"
    //         onClose={handleCloseDeletePopup}
    //       >
    //         Are you sure you want to Logout
    //       </DialogTitle>
    //       <div className={classes.openApply}>
    //         <button
    //           onClick={() => handleLogout()}
    //           className={classes.closeBtn + " " + classes.activeBtn}
    //         >
    //           Yes
    //         </button>
    //         <button
    //           onClick={handleCloseDeletePopup}
    //           className={classes.closeBtn}
    //         >
    //           No
    //         </button>
    //       </div>
    //     </Dialog>
    //   </ul>
    // </div>
  );
};

// export default withStyles(styles)(Sidbar);
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(Sidbar);
