import Filter from "../filter";
import Pagination from "react-js-pagination";
import React, { useEffect, useState } from "react";
import { Typography, Tabs, Tab, Box } from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/core/styles";
import * as commonFunctions from "../../../utilities/commonFunctions";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import scrollToComponent from "react-scroll-to-component";
import { withApollo } from "react-apollo";
import compose from "recompose/compose";
import classNames from "classnames";
import { withRouter, Link } from "react-router-dom";
import styles from "../styles";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import Pageloader from "../../../components/ui/pageloader";
import filter from "../../../components/ui/contact-us/filter";
import PF101Survey from "../SurveryContainer/index";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  if (index !== 3)
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
}

function PF101SurveyDevon(props) {
  const { classes } = props;

  const [value, setValue] = useState(0);
  const [loading, setLoading] = React.useState(true);
  const [offset] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [activePage, setActivePage] = React.useState(1);
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);

  const [filters, setOpenFilters] = React.useState({
    question_1: false,
    question_2: false,
    question_3: false,
    question_4: false,
    question_5: false,
    question_6: false,
    question_7: false,
    dateAndTime: false,
  });
  const [activeFilter, setActiveFilter] = React.useState({
    question_1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_3: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_4: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_5: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_7: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    dateAndTime: [
      "Today",
      "This week",
      "This month",
      "Last 30 days",
      "Last 3 Months",
      "Last 6 Months",
      "Year to Date",
      "Last 12 months",
    ],
  });

  const [filterQuestion] = React.useState({
    question_1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_3: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_4: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_5: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    question_7: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    dateAndTime: [
      "Today",
      "This week",
      "This month",
      "Last 30 days",
      "Last 3 Months",
      "Last 6 Months",
      "Year to Date",
      "Last 12 months",
    ],
  });
  const [groupId, setGroup] = React.useState([]);

  const institutionId = JSON.parse(localStorage.getItem("institution"));
  useEffect(() => {
    if (
      institutionId !== undefined &&
      institutionId !== null &&
      institutionId !== ""
    ) {
      const inId = [];
      inId.push(institutionId);
      setGroup(inId);
    } else {
      setGroup([]);
    }
  }, [institutionId]);

  const theme = useTheme();
  const ref = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];

  const handleClickOutside = (event) => {
    if (ref[0].current && !ref[0].current.contains(event.target)) {
      setOpenFilters({ question_1: false });
    }
  };
  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setActivePage(pageNumber);
    // setLoading(true);
  };
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  React.useEffect(() => {
    setLoading(false);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      {loading === true ? (
        <Pageloader loading={loading} />
      ) : (
        <div className="dashboard-page">
          <PF101Survey />
          <Tabs
            value={value}
            onChange={handleChange}
            className={classes.dashboardTabs}
          >
            <Tab label="Table View" {...a11yProps(0)} />
            <Tab label="Graph View" {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={value} index={0} dir={theme.direction}>
            <div>
              {errorMessage && (
                <div className={classes.noteError}>{errorMessage}</div>
              )}
              <div className={classes.TableSec}>
                <div className={classes.TableDiv2}>
                  <table>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>
                          Question 01
                          <span className={classes.filterIcon}>
                            <img
                              onClick={() =>
                                setOpenFilters({
                                  question_1: !filters.question_1,
                                })
                              }
                              src="./img/filter-icon.png"
                              alt=""
                            />
                            {filters.question_1 && (
                              <Filter
                                filterQuestion={filterQuestion}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                                type="question_1"
                                setOpenFilters={setOpenFilters}
                              />
                            )}
                          </span>
                        </th>
                        <th>
                          Question 02
                          <span className={classes.filterIcon}>
                            <img
                              onClick={() =>
                                setOpenFilters({
                                  question_2: !filters.question_2,
                                })
                              }
                              src="./img/filter-icon.png"
                              alt=""
                            />
                            {filters.question_2 && (
                              <Filter
                                filterQuestion={filterQuestion}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                                type="question_2"
                                setOpenFilters={setOpenFilters}
                              />
                            )}
                          </span>
                        </th>
                        <th>
                          Question 03
                          <span className={classes.filterIcon}>
                            <img
                              onClick={() =>
                                setOpenFilters({
                                  question_3: !filters.question_3,
                                })
                              }
                              src="./img/filter-icon.png"
                              alt=""
                            />
                            {filters.question_3 && (
                              <Filter
                                filterQuestion={filterQuestion}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                                type="question_3"
                                setOpenFilters={setOpenFilters}
                              />
                            )}
                          </span>
                        </th>
                        <th>
                          Question 04
                          <span className={classes.filterIcon}>
                            <img
                              onClick={() =>
                                setOpenFilters({
                                  question_4: !filters.question_4,
                                })
                              }
                              src="./img/filter-icon.png"
                              alt=""
                            />
                            {filters.question_4 && (
                              <Filter
                                filterQuestion={filterQuestion}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                                type="question_4"
                                setOpenFilters={setOpenFilters}
                              />
                            )}
                          </span>
                        </th>
                        <th>
                          Question 05
                          <span className={classes.filterIcon}>
                            <img
                              onClick={() =>
                                setOpenFilters({
                                  question_5: !filters.question_5,
                                })
                              }
                              src="./img/filter-icon.png"
                              alt=""
                            />
                            {filters.question_5 && (
                              <Filter
                                filterQuestion={filterQuestion}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                                type="question_5"
                                setOpenFilters={setOpenFilters}
                              />
                            )}
                          </span>
                        </th>
                        <th>
                          Question 06
                          <span className={classes.filterIcon}>
                            <img
                              onClick={() =>
                                setOpenFilters({
                                  question_6: !filters.question_6,
                                })
                              }
                              src="./img/filter-icon.png"
                              alt=""
                            />
                            {filters.question_6 && (
                              <Filter
                                filterQuestion={filterQuestion}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                                type="question_6"
                                setOpenFilters={setOpenFilters}
                              />
                            )}
                          </span>
                        </th>
                        <th>
                          Question 07
                          <span className={classes.filterIcon}>
                            <img
                              onClick={() =>
                                setOpenFilters({
                                  question_7: !filters.question_7,
                                })
                              }
                              src="./img/filter-icon.png"
                              alt=""
                            />
                            {filters.question_7 && (
                              <Filter
                                filterQuestion={filterQuestion}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                                type="question_7"
                                setOpenFilters={setOpenFilters}
                              />
                            )}
                          </span>
                        </th>
                        <th>Question 08</th>
                        <th>Question 09</th>
                        <th>Question 10</th>
                        <th>
                          Completed Date
                          <span className={classes.filterIcon}>
                            <img
                              onClick={() =>
                                setOpenFilters({
                                  dateAndTime: !filters.dateAndTime,
                                })
                              }
                              src="./img/filter-icon.png"
                              alt=""
                            />
                            {filters.dateAndTime && (
                              <Filter
                                filterQuestion={filterQuestion}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                                type="dateAndTime"
                                setOpenFilters={setOpenFilters}
                              />
                            )}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={classes.paginationBottom}>
                <div className={classes.paginationLeft}>
                  {pageFineshedCount > 0 && (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={20}
                      totalItemsCount={pageFineshedCount}
                      pageRangeDisplayed={5}
                      onChange={handleGetFineshedMatchesPageClick}
                    />
                  )}
                </div>

                <div className={classes.schoolsBtn}>
                  <div className={classes.clearFilters}>
                    <button
                      onClick={() => {
                        setActiveFilter({
                          question_1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                          question_2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                          question_3: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                          question_4: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                          question_5: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                          question_6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                          question_7: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                          dateAndTime: [
                            "Today",
                            "This week",
                            "This month",
                            "Last 30 days",
                            "Last 3 Months",
                            "Last 6 Months",
                            "Year to Date",
                            "Last 12 months",
                          ],
                        });
                      }}
                    >
                      CLEAR ALL FILTERS
                    </button>
                  </div>
                  <div className={classes.export}>
                    <button>EXPORT</button>
                  </div>
                </div>
              </div>
              <div class="question_expalin">
                <div
                  className={classNames(
                    classes.questionExpalin,
                    "full_length_quesions"
                  )}
                >
                  <span class="question_list" id="details_question_1">
                    Question 1:
                  </span>
                  <span>
                    Personal Finance 101 improved my money skills and knowledge.
                  </span>
                </div>
                <div
                  className={classNames(
                    classes.questionExpalin,
                    "full_length_quesions"
                  )}
                >
                  <span class="question_list" id="details_question_2">
                    Question 2:
                  </span>
                  <span>I wish I could learn more about personal finance.</span>
                </div>
                <div
                  className={classNames(
                    classes.questionExpalin,
                    "full_length_quesions"
                  )}
                >
                  <span class="question_list" id="details_question_3">
                    Question 3:
                  </span>
                  <span>
                    I believe other students would benefit from taking Personal
                    Finance 101.
                  </span>
                </div>
                <div
                  className={classNames(
                    classes.questionExpalin,
                    "full_length_quesions"
                  )}
                >
                  <span class="question_list" id="details_question_4">
                    Question 4:
                  </span>
                  <span>
                    Personal Finance 101 was engaging and easy-to-use.
                  </span>
                </div>
                <div
                  className={classNames(
                    classes.questionExpalin,
                    "full_length_quesions"
                  )}
                >
                  <span class="question_list" id="details_question_5">
                    Question 5:
                  </span>
                  <span>
                    Would you recommend Personal Finance 101 to a friend?
                  </span>
                </div>
                <div
                  className={classNames(
                    classes.questionExpalin,
                    "full_length_quesions"
                  )}
                >
                  <span class="question_list" id="details_question_6">
                    Question 6:
                  </span>
                  <span>
                    I want to plan for higher education and for my career.
                  </span>
                </div>
                <div
                  className={classNames(
                    classes.questionExpalin,
                    "full_length_quesions"
                  )}
                >
                  <span class="question_list" id="details_question_7">
                    Question 7:
                  </span>
                  <span>
                    I want to know what my net worth will be in 10 years.
                  </span>
                </div>
                <div
                  className={classNames(
                    classes.questionExpalin,
                    "full_length_quesions"
                  )}
                >
                  <span class="question_list" id="details_question_8">
                    Question 8:
                  </span>
                  <span>What was your favorite thing about PF101?</span>
                </div>
                <div
                  className={classNames(
                    classes.questionExpalin,
                    "full_length_quesions"
                  )}
                >
                  <span class="question_list" id="details_question_9">
                    Question 9:
                  </span>
                  <span>What was one thing you would change about PF101?</span>
                </div>
                <div
                  className={classNames(
                    classes.questionExpalin,
                    "full_length_quesions"
                  )}
                >
                  <span class="question_list" id="details_question_10">
                    Question 10:
                  </span>
                  <span>
                    Do you have any other comments about how we can improve?
                    (Optional)
                  </span>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {/* {allPF101Levels ? */}
            <div>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                  },

                  title: {
                    text: "PF101 Student Survey",
                    align: "center",
                  },

                  xAxis: {
                    categories: [
                      "Question 1",
                      "Question 2",
                      "Question 3",
                      "Question 4",
                      "Question 5",
                      "Question 6",
                      "Question 7",
                      "Question 8",
                      "Question 9",
                      "Question 10",
                    ],
                  },
                  yAxis: {
                    // Tertiary yAxis

                    min: 0,
                    tickInterval: 1,
                    max: 10,
                    labels: {
                      formatter: function () {
                        return parseFloat(this.value);
                      },
                      style: {
                        color: "#000000",
                        fontSize: "14px",
                      },
                    },
                    gridLineWidth: 0,
                  },

                  plotOptions: {
                    series: {
                      cursor: "pointer",
                      point: {
                        events: {
                          click: function () {
                            var id = this.category.split(" ");
                            var level = parseInt(id[1]);
                            scrollToComponent(
                              document.getElementById(
                                `details_question_${level}`
                              ),
                              { offset: offset, align: "top", duration: 500 }
                            );
                          },
                        },
                      },
                    },
                  },
                  tooltip: {
                    formatter: function () {
                      return (
                        "<b> " +
                        this.x +
                        "</b><br/>" +
                        "PF101 Student Survey: " +
                        parseFloat(this.y).toFixed(1)
                      );
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  series: [
                    {
                      name: "PF101 Student Survey",
                      data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                      yAxis: 0,
                      color: "#6982ce",
                    },
                  ],
                }}
              />
              <div class="question_expalin">
                <span class="question_list" id="details_question_1">
                  Question 1:
                </span>
                <span>
                  Personal Finance 101 improved my money skills and knowledge.
                </span>
                <span class="question_list" id="details_question_2">
                  Question 2:
                </span>
                <span>I wish I could learn more about personal finance.</span>
                <span class="question_list" id="details_question_3">
                  Question 3:
                </span>
                <span>
                  I believe other students would benefit from taking Personal
                  Finance 101.
                </span>
                <span class="question_list" id="details_question_4">
                  Question 4:
                </span>
                <span>Personal Finance 101 was engaging and easy-to-use.</span>
                <span class="question_list" id="details_question_5">
                  Question 5:
                </span>
                <span>
                  Would you recommend Personal Finance 101 to a friend?
                </span>
                <span class="question_list" id="details_question_6">
                  Question 6:
                </span>
                <span>
                  I want to plan for higher education and for my career.
                </span>
                <span class="question_list" id="details_question_7">
                  Question 7:
                </span>
                <span>
                  I want to know what my net worth will be in 10 years.
                </span>
                <span class="question_list" id="details_question_8">
                  Question 8:
                </span>
                <span>What was your favorite thing about PF101?</span>
                <span class="question_list" id="details_question_9">
                  Question 9:
                </span>
                <span>What was one thing you would change about PF101?</span>
                <span class="question_list" id="details_question_10">
                  Question 10:
                </span>
                <span>
                  Do you have any other comments about how we can improve?
                  (Optional)
                </span>
              </div>
            </div>
            {/* : <div>No data display graph</div>         
                    } */}
          </TabPanel>
        </div>
      )}
    </div>
  );
}
PF101SurveyDevon.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(PF101SurveyDevon);
