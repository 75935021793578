import * as commonFunctions from "../../../utilities/commonFunctions";

import {
  Avatar,
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { isEmpty, startCase } from "lodash";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import { useHistory, useLocation } from "react-router-dom";

import AccountCircle from "@mui/icons-material/AccountCircle";
import { Grid } from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import Utils from "../../../utilities/userUtils";
// import styles from './styles.css';
import combineStyles from "../../../utilities/combineStyles";
import compose from "recompose/compose";
import globalStyles from "../../layout/globalStyles";
import { loader } from "graphql.macro";
import { padding } from "@mui/system";
import styles from "./styles";
import { useUserDetailsContext } from "../../../context/userDetails";
import { withStyles } from "@material-ui/core/styles";

const ALLADMINS = loader("../../../graphql/schema/ui/GetAdmins.graphql");
const DELETEADMIN = loader("../../../graphql/schema/ui/deleteAdmin.graphql");

const drawerWidth = 275;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.neutral,
  // "&:hover": {
  //   backgroundColor: alpha(theme.palette.common.black, 0.25),
  // },
  borderColor: theme.palette.dark,
  border: "1px solid",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));
const ToolbarCustom = styled(Toolbar)(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
}));

const HeaderTitle = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const Header = (props) => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const location = useLocation();
  const {
    state = {},
    searchByNameHandler,
    clearSearch,
  } = useUserDetailsContext();
  const { classes, open, handleDrawerOpen, name, searchBar } = props;
  const [searchText, setSearchText] = React.useState("");
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const [activePage, setActivePage] = React.useState(1);
  const [allAdminsData, setAllAdminsData] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] =
    React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [openSuccesPopup, setOpenSuccesPopup] = React.useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  useEffect(() => {
    clearSearch();
  }, [location.pathname]);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    localStorage.removeItem("Inqwiry::access_token");
    localStorage.removeItem("Inqwiry::user_name");
    localStorage.removeItem("institution");
    localStorage.clear();
    apolloClient.resetStore();
    history.push("/");
  };

  //   const { data: result } = useQuery(ALLADMINS, {
  //     variables: {
  //     },
  //     fetchPolicy: 'network-only',
  //     onCompleted() {
  //         if (result.GetAdmins) {
  //             setAllAdminsData(result.GetAdmins);
  //         }
  //     },
  //     onError(error) {
  //         let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
  //         setErrorMessage(errorMsg);
  //         setLoading(false);
  //     },
  // });

  const [deleteAdminData, { data: deleteResult }] = useMutation(DELETEADMIN, {
    onCompleted() {
      setOpenConfirmDeletePopup(false);
      setDeleteId("");
      setLoading(false);
      setOpenSuccesPopup(true);
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  // React.useEffect(() => {
  //   if (result) {
  //     setLoading(false);
  //   }
  // }, [result]);
  React.useEffect(() => {
    if (deleteResult) {
      setLoading(false);
    }
  }, [deleteResult]);
  const handleOpenConfirmDeletePopup = (id) => {
    setDeleteId(id);
    setOpenConfirmDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenConfirmDeletePopup(false);
    setDeleteId("");
  };

  const handleDeleteAdmin = () => {
    setLoading(true);
    deleteAdminData({
      variables: {
        id: deleteId,
      },
    });
  };

  const handleUserEdit = (id) => {
    history.push("/edit-admin?admin_id=" + id);
  };
  const handleClose = () => {
    setOpenSuccesPopup(false);
    window.location.reload(true);
  };

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setActivePage(pageNumber);
    setLoading(true);
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const convertPassword = (password) => {
    // let passwordStr = password.toString();
    let encryPass = "*";
    for (let i = 0; i < 10; i++) {
      encryPass = encryPass + "*";
    }
    return encryPass;
  };
  var getLocation = function (href) {
    var l = document.createElement("a");
    l.href = href;
    return l;
  };
  var l = getLocation(window.location.href);
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  return (
    // <React.Fragment>
    //   <div className={classes.headerVillanova}>
    //     <div className={`${classes.container} ${classes.containerAdmin}`}>
    //       <Grid
    //         container
    //         className={`${classes.root} ${classes.flexCenter}`}
    //         spacing={0}
    //       >
    //         <Grid
    //           item
    //           md={3}
    //           sm={3}
    //           xs={12}
    //           className={classes.villanovaLogoLeft}
    //         >
    //           <div className={classes.villanovaLogo}>
    //             <img src="/logo.png" alt="" />
    //           </div>
    //         </Grid>
    //         <Grid
    //           item
    //           md={6}
    //           sm={6}
    //           xs={12}
    //           className={classes.villanovaLogoMiddle}
    //         >
    //           <div className={classes.villanovaMainHead}>
    //             <Typography variant="h2" component="h2">
    //               {state?.school_type &&
    //               state?.school_type.toLowerCase() === "business"
    //                 ? startCase(l.pathname.replace("students", "business"))
    //                 : startCase(l.pathname)}
    //             </Typography>
    //             {/* <div className={classes.searchBar}>
    //               <input type="text" placeholder="Search all users by full name" onChange={handleSearchChange} />
    //             </div> */}
    //           </div>
    //         </Grid>
    //         <Grid item md={3} sm={3} xs={12}>
    //           <div className="villanova_pager">
    //             <div className="pagination">
    //               {pageFineshedCount > 1 && (
    //                 <Pagination
    //                   activePage={activePage}
    //                   itemsCountPerPage={20}
    //                   totalItemsCount={pageFineshedCount}
    //                   pageRangeDisplayed={5}
    //                   onChange={handleGetFineshedMatchesPageClick}
    //                 />
    //               )}
    //             </div>
    //           </div>

    //         </Grid>
    //       </Grid>
    //     </div>
    //   </div>
    // </React.Fragment>

    <AppBar position="fixed" variant="outlined" color="inherit" open={open}>
      <ToolbarCustom>
        <HeaderTitle>
          <Tooltip title="Show menu" arrow>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            {!isEmpty(name)
              ? name
              : state?.school_type &&
                state?.school_type.toLowerCase() === "business"
              ? startCase(l.pathname.replace("students", "business"))
              : startCase(l.pathname)}
          </Typography>
        </HeaderTitle>
        <HeaderTitle>
          {searchBar && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={state?.search}
                placeholder="Search by name"
                inputProps={{ "aria-label": "search" }}
                onChange={searchByNameHandler}
              />
            </Search>
          )}
          <Box sx={{ flexGrow: 0, paddingLeft: "10px" }}>
            <Tooltip title="Click to logout">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={startCase(Utils.getUserName() || "U")}
                  src="/static/images/avatar/2.jpg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </HeaderTitle>
      </ToolbarCustom>
    </AppBar>
  );
};

const combinedStyles = combineStyles(globalStyles, styles);

const enhance = compose(withStyles(combinedStyles));

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};
// const enhance = compose(
//   withStyles(styles),
//   withRouter,
//   withApollo
// );

export default enhance(Header);
