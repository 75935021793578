import * as React from "react";

import {
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { isArray, isEmpty, size } from "lodash";

import { FilterAlt } from "@mui/icons-material";
import GroupFilter from "../GroupFilter/GroupFilter";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const UlCustom = styled("ul")(() => ({
  display: "block",
  position: "absolute",
  background: "#e9e6e6",
  color: "#000",
  padding: 0,
  left: "50%",
  transform: "translatex(-50%)",
  minWidth: "220px",
  fontFamily: "museosans-100",
  listStyle: "none",
  zIndex: 9999999,
  top: "29px",
  margin: "0px 0px 10px",
  "&::before": {
    borderLeft: "9px solid transparent",
    borderRight: "9px solid transparent",
    content: '""',
    position: "absolute",
    width: 0,
    background: "transparent none repeat scroll 0 0",
    borderBottom: "9px solid #e9e6e6",
    borderTop: "10px solid transparent",
    height: "auto",
    left: "50%",
    top: "-19px",
    transform: "translateX(-50%)",
  },
  "& li": {
    padding: "4px 20px",
    fontSize: "14px",
    lineHeight: "1.4",
    cursor: "pointer",
    color: "#333",
    "&:hover": {
      color: "#0069aa",
    },
  },
}));
const LiCustom = styled("li")(() => ({}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      // horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      // horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const GroupListComponent = ({
  data,
  anchorEl,
  open,
  handleClose,
  filterFunction,
  row,
  setActiveFilter,
  resetFilter = false,
}) => {
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    if (value === "") {
      setChecked([]);
    } else {
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    }
  };

  const handleFilterAPI = () => {
    if (size(checked) === 0) {
      setActiveFilter("");
    } else {
      setActiveFilter(row);
    }
    filterFunction(row, checked);
    handleClose();
  };

  React.useEffect(() => {
    if (resetFilter) {
      setChecked([]);
    }
  }, [resetFilter]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 100 * 4.5,
          width: "auto",
        },
      }}
    >
      <MenuItem
        dense
        sx={{ padding: "1px 10px", minWidth: "200px" }}
        key={"none"}
        onClick={handleToggle("")}
      >
        <ListItemText sx={{ fontSize: 13 }}>None</ListItemText>
      </MenuItem>
      {data.map(({ key, value }) => {
        const labelId = `checkbox-list-label-${key}`;
        return (
          <>
            <MenuItem
              dense
              sx={{ padding: "1px 10px" }}
              key={key}
              onClick={handleToggle(key)}
            >
              <ListItemIcon color="primary">
                <Checkbox
                  color="primary"
                  size="small"
                  edge="start"
                  checked={checked.indexOf(key) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText sx={{ fontSize: 13 }}>{value}</ListItemText>
            </MenuItem>
          </>
        );
      })}
      <Box display={"flex"} justifyContent="flex-end">
        <Button
          onClick={handleFilterAPI}
          sx={{ marginRight: 1 }}
          size="small"
          variant="contained"
        >
          Go
        </Button>
      </Box>
    </Menu>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#CFD5E9",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#E8EBF4",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function ReactTable({
  columns = [],
  rows = [],
  activePage = null,
  count = null,
  showNo = true,
  resetFilter = false,
  filterFunction = () => {},
}) {
  const ref = React.useRef(null);
  const [activeFilter, setActiveFilter] = React.useState("");

  const makeObject = (arr, row) => {
    let obj = {};
    for (let x = 0; x < arr.length; x++) {
      obj[arr[x]] = row[arr[x]];
    }
    return obj;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const handleClick = (e, val) => {
    setAnchorEl(e.currentTarget);
    setOpen(val);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  React.useEffect(() => {
    if (resetFilter) {
      setActiveFilter("");
    }
  }, [resetFilter]);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(null);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const filterHandler = (row, value) => {
    if (value === "None" || value === "") {
      setActiveFilter("");
    } else {
      setActiveFilter(row);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 525 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: "#4472C4" }}>
            {showNo && (
              <TableCell
                sx={{ color: "#fff", whiteSpace: "nowrap", fontWeight: "bold" }}
                size="small"
              >
                No
              </TableCell>
            )}
            {columns.map(
              (
                { name, filterList, row, showCheckboxwithFilter = false },
                i
              ) => (
                <TableCell
                  sx={{
                    color: "#fff",
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                  size="small"
                  key={`${name}+${i}`}
                >
                  <Box display={"flex"} alignItems="center">
                    {name}
                    {!isEmpty(filterList) && (
                      <>
                        <IconButton
                          size="small"
                          sx={{ padding: 0 }}
                          aria-label="more"
                          id={row}
                          aria-controls={anchorEl ? row : undefined}
                          aria-expanded={anchorEl ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => handleClick(e, row)}
                        >
                          <FilterAlt
                            sx={{
                              transform:
                                row === activeFilter
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                            }}
                            fontSize="small"
                            color={"neutral"}
                          />
                        </IconButton>
                        {showCheckboxwithFilter ? (
                          <GroupListComponent
                            data={filterList}
                            anchorEl={anchorEl}
                            open={open === row}
                            handleClose={handleClose}
                            filterFunction={filterFunction}
                            row={row}
                            setActiveFilter={setActiveFilter}
                            resetFilter={resetFilter}
                          />
                        ) : (
                          <Menu
                            id={row}
                            MenuListProps={{
                              "aria-labelledby": row,
                            }}
                            anchorEl={anchorEl}
                            open={open === row}
                            onClose={handleClose}
                          >
                            {(filterList || []).map(({ key, value }) => (
                              <MenuItem
                                dense
                                key={key}
                                selected={key === "Pyxis"}
                                onClick={() => {
                                  filterHandler(row, value);
                                  filterFunction(row, key);
                                  handleClose();
                                }}
                              >
                                {value}
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      </>
                    )}
                  </Box>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty(rows) ? (
            <TableRow key={"no_data"}>
              <TableCell align="center" colSpan={columns.length}>
                <Typography variant="h6" component="h2">
                  No Data
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row, i) => (
              <StyledTableRow key={row.id}>
                {showNo && (
                  <StyledTableCell
                    sx={{ whiteSpace: "nowrap" }}
                    size="small"
                    // key={col?.row + i}
                    component="td"
                    scope="row"
                  >
                    {i + 1 + (activePage - 1) * 20}
                  </StyledTableCell>
                )}
                {columns.map((col, i) => (
                  <StyledTableCell
                    sx={{
                      whiteSpace: col?.minWidth ? "normal" : "nowrap",
                      maxWidth: "50rem",
                      width: "50rem",
                      minWidth: col?.minWidth || "auto",
                    }}
                    size="small"
                    key={col?.row + i}
                    component="td"
                    scope="row"
                  >
                    {col?.customRowComponent
                      ? col?.customRowComponent(
                          isArray(col?.row)
                            ? makeObject(col?.row, row)
                            : row[col?.row],
                          row
                        )
                      : row[col?.row] === null
                      ? "N/A"
                      : row[col?.row] || "N/A"}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
