import * as commonFunctions from "../../utilities/commonFunctions";

import { Button, Grid } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { isEmpty, startCase } from "lodash";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import Pageloader from "../../components/ui/pageloader";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import { useUserDetailsContext } from "../../context/userDetails";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const CHECK_USER_NAME = loader(
  "../../graphql/schema/schools/check-admin-username.graphql"
);
const CHECK_EMAIL = loader(
  "../../graphql/schema/schools/check-admin-email.graphql"
);
const UPDATE_ADMIN = loader(
  "../../graphql/schema/schools/update-school-admin.graphql"
);
const UPDATE_ADMIN_PASSWORD = loader(
  "../../graphql/schema/schools/update-school-admin-password.graphql"
);
const GETADMINBY_ID = loader(
  "../../graphql/schema/schools/get-school-admin.graphql"
);
const SCHOOL_INSTITUTIONS = loader(
  "../../graphql/schema/schools/get-schools.graphql"
);
const BUSINESS_INSTITUTIONS = loader(
  "../../graphql/schema/business/business-institutions.graphql"
);

const EditSchoolAdmin = (props) => {
  const { classes, history } = props;
  const {
    state: { admin_type },
  } = useUserDetailsContext();
  const location = useLocation();
  const [adminText, setAdminText] = useState();
  const [loading, setLoading] = React.useState(true);
  const [defaultData, setdefaultData] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  // const [userName, setUserName] = React.useState("");
  // const [password, setPassword] = React.useState("");
  // const [confirmPassword, setConfirmPassword] = React.useState("");
  const [userId, setUserId] = React.useState("");
  // const [paswordValidation, setPaswordValidation] = React.useState(false);
  // const [alpFild, setAlpFild] = React.useState(false);
  // const [uplet, setUplet] = React.useState(false);
  // const [numFild, setNumFild] = React.useState(false);
  // const [passLen, setPassLen] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");

  const [lastName, setLastName] = React.useState("");
  const [emailValue, setEmailValue] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [passCheck, setPassCheck] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [alphabetsExist, setAlphabetsExist] = useState(false);
  const [numericExist, setNumericExist] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [userName, setUserName] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [institutionData, setInstitutionData] = useState([]);
  const [schoolError, setSchoolError] = useState(false);
  const [successmsg, setSuccessMsg] = useState("");
  const [userSuccessMsg, setUserSuccessMsg] = useState("");

  useEffect(() => {
    setAdminText(
      location.pathname.includes("school")
        ? "schools"
        : location.pathname.includes("business") && "businesses"
    );

    if (location.pathname === "/edit-school-admin") {
      schoolInstitutions();
    } else if (location.pathname === "/edit-business-admin") {
      businessInstitutions();
    }
  }, []);

  const [schoolInstitutions] = useLazyQuery(SCHOOL_INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(response.getSchoolInstitutions);
      setLoading(false);
    },
  });

  const [businessInstitutions] = useLazyQuery(BUSINESS_INSTITUTIONS, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setInstitutionData(response.getBusinessInstitutions);
      setLoading(false);
    },
  });

  useEffect(() => {
    userData();
  }, [userId]);

  const [userData, { data: userResult }] = useLazyQuery(GETADMINBY_ID, {
    variables: {
      id: userId,
    },
    fetchPolicy: "network-only",
    onCompleted() {
      if (userResult.getSchoolAdminById) {
        setdefaultData(userResult.getSchoolAdminById);
        setFirstName(userResult.getSchoolAdminById.name);
        setLastName(userResult.getSchoolAdminById.user_lname);
        setEmailValue(userResult.getSchoolAdminById.email);
        setLoading(false);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [updateSchoolAdminData] = useMutation(UPDATE_ADMIN, {
    onCompleted() {
      setLoading(false);
      setSchoolError("");
      setSuccessMsg("Account information has been updated successfully.");
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setSchoolError(errorMsg);
      setSuccessMsg("");
      setLoading(false);
    },
  });

  const [updateSchoolAdminPassword] = useMutation(UPDATE_ADMIN_PASSWORD, {
    onCompleted() {
      setLoading(false);
      setErrorMessage("");
      setUserSuccessMsg(
        "Username and password have been updated successfully."
      );
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setUserSuccessMsg("");
      setLoading(false);
    },
  });

  React.useEffect(() => {
    setUserId(window.location.search.split("=")[1]);
  }, []);

  const handleSubmitClick = () => {
    setSubmitClicked(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name is required";
    } else {
      setFirstName(values.first_name);
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required.";
    } else {
      setLastName(values.last_name);
    }

    if (values.email) {
      if (!commonFunctions.validateEmail(values.email)) {
        errors.email = "Enter valid email.";
      }
      setEmailValue(values.email);
    }
    if (!values.email) {
      errors.email = "email is required.";
      setEmailValue("");
    }
    if (!["school", "business"].includes(admin_type)) {
      if (!values.School) {
        errors.School = "please select School";
      } else {
        setSchoolId(values.School);
      }
    }
    return errors;
  };

  const validateOne = (values) => {
    const errors = {};

    if (!values.userName) {
      errors.userName = "username is required.";
    } else {
      setUserName(values.userName);
    }

    if (values.userName) {
      if (values.userName.length <= 3) {
        setUsernameError("User must be more that 4 charecters");
      } else {
        setUsernameError("");
      }
    }

    if (values.password) {
      if (values.password.length >= 8) {
        setPasswordLength(true);
      } else {
        errors.password = "";
        setPasswordLength(false);
      }
      if (!commonFunctions.validateAlpha(values.password)) {
        setAlphabetsExist(true);
      } else {
        errors.password = "";
        setAlphabetsExist(false);
      }
      if (!commonFunctions.validateNumeric(values.password)) {
        setNumericExist(true);
      } else {
        errors.password = "";
        setNumericExist(false);
      }
    } else {
      setPasswordLength(false);
      setAlphabetsExist(false);
      setNumericExist(false);
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = "confirm password is required.";
    } else {
      if (values.password_confirmation) {
        if (values.password === values.password_confirmation) {
          setPasswordMatch(true);
        } else {
          setPasswordMatch(false);
          errors.password_confirmation = "";
        }
      }
    }
    return errors;
  };

  const onSubmit = (values) => {
    setSubmitClicked(false);
    setLoading(true);
    updateSchoolAdminData({
      variables: {
        data: {
          id: userId,
          name: values.first_name,
          user_lname: values.last_name,
          user_name: values.userName,
          email: values.email,
          password: values.password,
          confirm_password: values.password_confirmation,
          institution_id: ["school", "business"].includes(admin_type)
            ? [JSON.parse(localStorage.getItem("institution"))?.id]
            : values.School,
        },
      },
    });
  };

  const onSubmitOne = (values) => {
    setLoading(true);
    updateSchoolAdminPassword({
      variables: {
        data: {
          id: userId,
          user_name: userName,
          password: values.password,
        },
      },
    });
  };

  return (
    <Typography className="edit-page-new formError">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <div className={classes.backToAdmin}>
        <Link to={`/${adminText}-admins`}>
          <i className="fa fa-long-arrow-left" aria-hidden="true"></i>{" "}
          {`Back to
          ${startCase(adminText)} Admins`}
        </Link>
      </div>
      <div className={classes.formBlock}>
        <h3>{`Edit ${startCase(adminText)} Admin`}</h3>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Form
              validate={validate}
              onSubmit={onSubmit}
              initialValues={{
                first_name: defaultData.name,
                last_name: defaultData.user_lname,
                email: defaultData.email,
                School: defaultData.institution_ids,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <div className={classes.formGroup}>
                      <label>First Name</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="first_name">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Mike"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Last Name</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="last_name">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Inkwiry"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Email</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="email">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. mike@inkwiry.com"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    {/* <div>{emailError}</div> */}
                    {!["business", "school"].includes(admin_type) && (
                      <div className={classes.formGroup}>
                        <label>{startCase(adminText)}</label>
                        <div className={classNames(classes.inputLeft)}>
                          <Field name="School">
                            {({ input, meta }) => (
                              <div>
                                <select
                                  onChange={input?.onChange}
                                  multiple
                                  name={input?.name}
                                  value={input?.value}
                                >
                                  {institutionData.length !== 0 &&
                                    institutionData.map((l, index) => (
                                      <option key={l.id} value={l.id}>
                                        {l.institution_name}
                                      </option>
                                    ))}
                                </select>
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span className={classes.noteError}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    )}
                    {successmsg && (
                      <div className={classes.sccessMsgDiv}>
                        <div className={classes.sccessMsg}>
                          <span>{successmsg}</span>
                        </div>
                      </div>
                    )}
                    {!isEmpty(schoolError) && (
                      <div className={classes.sccessMsg}>
                        <div className={classes.nameError}>{schoolError}</div>
                      </div>
                    )}
                    <div className={classes.centerBtn}>
                      <div className={classes.updateBtn}>
                        <button type="submit" onClick={handleSubmitClick}>
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
            <Form
              validate={validateOne}
              onSubmit={onSubmitOne}
              initialValues={{
                userName: defaultData.user_name,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <h3>Username and Password</h3>
                    {/* <div className={classes.updateText}>
																					<span>Username and Password</span>
																			</div> */}
                    <div className={classes.formGroup}>
                      <label>Username</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="userName">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="Username"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                maxLength={5}
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className={classes.formGroup}>
                      <label>New Password</label>
                      <div className={classes.inputLeftSelect}>
                        <div className={classes.passwordPopup}>
                          <Field name="password">
                            {({ input, meta }) => (
                              <div>
                                <TextField
                                  name={input?.name}
                                  placeholder="Password"
                                  type="password"
                                  value={input?.value}
                                  onChange={input?.onChange}
                                  required
                                  maxLength={5}
                                  fullWidth
                                  onFocus={() => {
                                    setPassCheck(true);
                                  }}
                                  onBlur={() => {
                                    setPassCheck(false);
                                  }}
                                  className={classes.inputLeft}
                                />
                                {(meta.error || meta.submitError) &&
                                  meta.touched && (
                                    <span className={classes.noteError}>
                                      {meta.error || meta.submitError}
                                    </span>
                                  )}
                              </div>
                            )}
                          </Field>
                          <div
                            className={
                              passCheck
                                ? classes.resetPassCondition +
                                  " " +
                                  classes.resetPass
                                : classes.resetPass
                            }
                          >
                            <div className={classes.conditions}>
                              <p>
                                <span>
                                  {passwordLength ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>Include at least 8 characters</label>
                              </p>
                              <p>
                                <span>
                                  {alphabetsExist ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>
                                  Include upper and lower case characters
                                </label>
                              </p>
                              <p>
                                <span>
                                  {numericExist ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>Include at least 1 number</label>{" "}
                              </p>
                              <p>
                                <span>
                                  {passwordMatch ? (
                                    <img src="./img/pass.png" alt="" />
                                  ) : (
                                    <img src="./img/fail.png" alt="" />
                                  )}
                                </span>
                                <label>Passwords match</label>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Confirm Password</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="password_confirmation">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="Confirm New Password"
                                type="password"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                maxLength={5}
                                fullWidth
                                className={classes.inputLeft}
                                onFocus={() => {
                                  setPassCheck(true);
                                }}
                                onBlur={() => {
                                  setPassCheck(false);
                                }}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    {userSuccessMsg && (
                      <div className={classes.sccessMsgDiv}>
                        <div className={classes.sccessMsg}>
                          <span>{userSuccessMsg}</span>
                        </div>
                      </div>
                    )}
                    {!isEmpty(errorMessage) && (
                      <div className={classes.sccessMsg}>
                        <div className={classes.nameError}>{errorMessage}</div>
                      </div>
                    )}
                    <div className={classes.centerBtn}>
                      <div className={classes.updateBtn}>
                        <button type="submit">UPDATE</button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Typography>
  );
};
EditSchoolAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(EditSchoolAdmin);
