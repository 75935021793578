import React, { useState,useRef,useEffect } from "react";
import PropTypes from 'prop-types';
import Pagination from "react-js-pagination";
import { loader } from 'graphql.macro';
// import { useQuery } from '@apollo/react-hooks';
import { useQuery } from '@apollo/react-hooks';
import Pageloader from '../pageloader';
import { Dialog, DialogTitle, Typography ,Tabs,Tab,Box, } from "@material-ui/core";
import { Button } from '@mui/material';
import { withStyles,useTheme } from '@material-ui/core/styles';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import styles from './styles';
import { withApollo } from "react-apollo";
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { Grid } from "@material-ui/core";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Divider from '@mui/material/Divider';

const CAREERSKETCH_DETAIL_OUTPUT = loader('../../../graphql/schema/ui/careers/careerSkecthUserDetails.graphql');
const  Career_Skethcing_Details  = loader('../../../graphql/schema/ui/careers/careerSkethcingDetails.graphql');


function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div  
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.object.isRequired,
    index: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}



const Careers = (props)=>{
    const {classes} = props;
    const [openNameFilter,setOpenNameFilter] = React.useState(false);
    const [loading,setLoading] = React.useState(true);
    const [careerDetails,setCareerDetails] = React.useState([]);
  
    const [selectedInst,setSelectedInst] = React.useState(JSON.parse(localStorage.getItem("institution")));
    const [sorting,setSorting] = React.useState('');
    const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
    const [activePage, setActivePage] = React.useState(1);
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const [perPage,setPerPage] = useState([]);
    const [graphData,setGraphData] = React.useState();
    
    const refToUl = React.useRef(null);

   

   
    const {data} = useQuery(CAREERSKETCH_DETAIL_OUTPUT,{
      variables: {
        filter:{
          school:[
            {
              school_id:selectedInst.id,
              school:selectedInst.institution_name
            }
          ],
          sort:[
            {by:sorting,
            column:"name"
            }
            ]
        },
         fetchPolicy: 'network-only',
      },
    
      onCompleted(response) {
        setLoading(false);
         
        setCareerDetails(response.careerSkecthUserDetails);
        setPageFineshedCount(response.careerSkecthUserDetails.length);
        setPerPage(response.careerSkecthUserDetails.slice(0, 15));
        // console.log(response.careerSkecthUserDetails,'tocheck')
      },
      onError(error){
       
        setLoading(false);
      },
    });

    const {data:result,loading:career_load} = useQuery(Career_Skethcing_Details,{
      variables: {
         "id":selectedInst.id,
      },
         fetchPolicy: 'network-only',
    
      onCompleted(response) {
        // console.log(response,'tocheck',selectedInst);
         setGraphData(response.CareerSkethcingDetails);
         setLoading(false);
      },
      onError(error){
      //  console.log(error,'tocheck',selectedInst);
        setLoading(false);
      },
    });

    

  
  

   

    // const handleTabs = (val)=>{
    
    // }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

     
    const handleFilter = (val)=>{
      setOpenNameFilter(!openNameFilter)
    }

    const handleSorting =(val)=>{
        setLoading(true);
        setSorting(val);
       
        
    }

    const handleClickOutside = (event)=>{
      if (refToUl.current && !refToUl.current.contains(event.target)) {
            setOpenNameFilter(false);
        }
    }

    
   
     const pageHandler = (pageNumber) => {
         setPerPage(careerDetails.slice((pageNumber * 15) - 15, pageNumber * 15));
        setActivePage(pageNumber); 
    }

     useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <Typography className="main-bannerRight dashboard-page">
              {loading === true ? <Pageloader loading={loading} /> : ""}
          
               

                   
                     <Tabs
                         value={value}
                    onChange={handleChange}
                    className={classes.dashboardTabs}
                     >
                    
                         <Tab label=" Sketch ViewerShip" {...a11yProps(0)} />
                         <Tab label="Student ViewerShip" {...a11yProps(1)} />
                        
                    </Tabs>
                       <TabPanel value={value} index={0} dir={theme.direction}>
                         {career_load? <p>loading...</p>: <p>
                           {
                            graphData?
                        <HighchartsReact 
                           highcharts={Highcharts}
                           options={
                             {
                               chart:{
                                 type: 'column'
                               },
                               xAxis: {
                                 categories:graphData.cards
                               },
                                title: {
                                    text: 'Sketch Viewership',
                                    align: 'left',
                                },
                                subtitle:{
                                     text: `<div>
                                                <span>
                                                    # of students That Read a Sketch = ${graphData.students} |
                                                    Total # of Sketches Read = ${graphData.story_total} |
                                                    Total # of Career Cards Read = ${graphData.cards_total}
                                                 </span>
                                              </div>`,
                                      align:'right',
                                      y:20,
                                      style:{
                                        fontSize:'14px',
                                        color:'#0000ff',
                                        fontWeight:'normal',
                                      },
                                    },
                                credits: {
                                  enabled: false
                                },
                               yAxis: {
                                 min: 0,
                                 tickInterval:7,
                                 labels: {
                                            formatter: function () {
                                                return parseFloat(this.value).toFixed(1);
                                            },
                                            style: {
                                                color: '#000000',
                                                fontSize: '14px'
                                            }
                                        },
                                  gridLineWidth: 0,
                                  title:{
                                    text: 'Viewership',
                                    style:{
                                      color: '#000000',
                                    }
                                  },
                                },
                                tooltip: {
                                      formatter: function () {
                                          return '<b> ' + this.x + '</b><br/>' +
                                          this.series.name + ': ' + parseFloat(this.y).toFixed(2)      
                                      }
                                  },
                               series: [
                                 {
                                    name:'Cs Card Views',
                                   type:'column',
                                   color: '#6982ce',
                                   data:graphData.card_values,
                                 },
                                 {
                                    name:'Average Cs Card Views',
                                    type: 'line',
                                    color: '#ffdd2d',
                                   data:graphData.card_avg,
                                   marker: {
                                        enabled: false
                                    },
                                 },
                                 {
                                    name:'Cs Story Views',
                                   type:'column',
                                   color: '#ffa500',
                                   data:graphData.stories_values,
                                 },
                                 {
                                    name:'Average Cs Story Views',
                                  type: 'line',
                                  marker: {
                                      enabled: false
                                  },
                                  color: '#454545',
                                   data:graphData.stories_avg,
                                 }
                               ],
                               legend:{
                                 symbolHeight:10,
                                 symbolWidth:15,
                                 borderWidht:0,
                                symbolRadius: 0
                               },
                             }
                           }
                        />
                        : ''
                            }
                         </p>}
                       </TabPanel>
                       <TabPanel value={value} index={1} dir={theme.direction}>
                         <div className={classes.careerTableSec}>
                           <div className={classes.careerTableDiv}>
                               <div className={ classes.careerTableDivSkecth}>
                               
                                   <span>
                                     {/* <ClearIcon  color="error" fontSize="15px" /> */}
                                     <i className="fa fa-times text-danger"></i>
                                     { ' ' } 
                                     Not Viewed</span> <em>|</em>
                                   <span>
                                     {/* <DoneIcon  color="success" fontSize="15px"/> */}
                                     <i className="fa fa-check text-success"></i>
                                     { ' ' } 
                                     Viewed CS Card </span> <em>|</em>
                                   <span>
                                     {/* <DoneAllIcon color="success" fontSize="15px"/> */}
                                     <i className="fa fa-check text-success"></i>
                                     { ' ' } 
                                     <i className="fa fa-check text-success"></i>
                                     { ' ' } 
                                     Viewed CS Card + Story</span> 
                                
                               </div>
                              <table>
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>
                                        Full Name
                                        <span className={classes.filterIcon} onClick={() => handleFilter("name")}>
                                              <img src="./img/filter-icon.png" alt="" />
                                          {openNameFilter && <ul className={classes.filterDrop} ref={refToUl}>
                                              <li onClick={() =>{ handleSorting('')}}>None</li>
                                              <li onClick={() =>{ handleSorting('ASC')}}>Sort A to Z</li>
                                              <li onClick={() =>{ handleSorting('DESC') }}>Sort Z to A</li>
                                          </ul>}
                                      </span>  
                                      </th>
                                      <th>Registered Architect</th>
                                      <th>Police Officer</th>
                                      <th>Executive Chef</th>
                                      <th>Wall Street Banker</th>
                                      <th>Real Estate Broker</th>
                                      <th>Master Plumber</th>
                                      <th>Software Architect</th>
                                      <th>Fashion Designer</th>
                                      <th>Math Teacher</th>
                                      <th>Corporate Attorney</th>
                                      <th>Army Engineer</th>
                                      <th>Master Electrician</th>
                                      <th>Car Salesman</th>
                                      <th>Dairy Farmer</th>
                                      <th>Financial Advisor</th>
                                      <th>Navy Physician</th>
                                      <th>Paramedic</th>
                                      <th>Neuroradiologist</th>
                                      <th>Graphic Designer</th>
                                      <th>Construction Manager</th>
                                      <th>Cosmetologist</th>
                                      <th>Mechanical Engineer</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    {

                                      perPage.length !== 0
                                        ? perPage.map((val, index) => (
                                          <tr>
                                              <td><span> {index+1}</span></td>
                                              <td key={index}><span>{val.name|| 'N/A'} </span></td>
                                              <td key={index}><span>{ (val.mike_card === 0 && val.mike_story === 0) ?
                                              // <ClearIcon  color="error" fontSize="15px"/> 
                                              <i className="fa fa-times text-danger"></i>
                                              :( (val.mike_card > 0 && val.mike_story > 0) ? 
                                              <i className="fa fa-check text-success"></i>
                                              // <DoneAllIcon color="success" fontSize="15px"/>
                                              :
                                              // <DoneIcon  color="success" fontSize="15px"/>
                                              <i className="fa fa-check text-success"></i>
                                              
                                               ) }</span></td>
                                              <td key={index}><span> { (val.frank_card === 0 && val.frank_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.frank_card > 0 && val.frank_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }  </span></td>
                                              <td key={index}><span>{ (val.monica_card === 0 && val.monica_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.monica_card > 0 && val.monica_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.santiago_card === 0 && val.santiago_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.santiago_card > 0 && val.santiago_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.jeremy_card === 0 && val.jeremy_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.jeremy_card > 0 && val.jeremy_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.jennifer_card === 0 && val.jennifer_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.jennifer_card > 0 && val.jennifer_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.sam_card === 0 && val.sam_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.sam_card > 0 && val.sam_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.katelyn_card === 0 && val.katelyn_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.katelyn_card > 0 && val.katelyn_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.aaron_card === 0 && val.aaron_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.aaron_card > 0 && val.aaron_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.anita_card === 0 && val.anita_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.anita_card > 0 && val.anita_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.brooke_card === 0 && val.brooke_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.brooke_card > 0 && val.brooke_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.calvin_card === 0 && val.calvin_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.calvin_card > 0 && val.calvin_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.robert_card === 0 && val.robert_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.robert_card > 0 && val.robert_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.taylor_card === 0 && val.taylor_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.taylor_card > 0 && val.taylor_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.michelle_card === 0 && val.michelle_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.michelle_card > 0 && val.michelle_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.david_card === 0 && val.david_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.david_card > 0 && val.david_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.laura_card === 0 && val.laura_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.laura_card > 0 && val.laura_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.erica_card === 0 && val.erica_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.erica_card > 0 && val.erica_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.katrina_card === 0 && val.katrina_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.katrina_card > 0 && val.katrina_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.jay_card === 0 && val.jay_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.jay_card > 0 && val.jay_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.amber_card === 0 && val.amber_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.amber_card > 0 && val.amber_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                              <td key={index}><span> { (val.tim_card === 0 && val.tim_story === 0) ?<i className="fa fa-times text-danger"></i>:( (val.tim_card > 0 && val.tim_story > 0) ? <><i className="fa fa-check text-success"></i> <i className="fa fa-check text-success"></i> </>:<i className="fa fa-check text-success"></i> ) }   </span></td>
                                          </tr>
                                      )) :  ""
                                    
                                    }

                                  </tbody>
                                </table>
                          </div>
                          <div className={ classes.paginationBottom }>
                            <div className={ classes.paginationLeft}>
                              {(perPage.length > 0) &&
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={15}
                                    totalItemsCount={pageFineshedCount}
                                    pageRangeDisplayed={5}
                                    onChange={pageHandler}
                                  />
                              }
                            </div>
                            <div className={classes.schoolsBtn}>
                              <div className={classes.clearFilterBtn}>
                                <button onClick={()=>{ setSorting(' ') }} variant="contained" color="warning" >clear all filters</button>
                              </div>
                              <div className={classes.exportBtn}>
                                <button  color="primary" variant="contained" >export</button>
                              </div>
                            
                            </div>
                            </div>
                            
                          </div>
                           
                            
                       </TabPanel>

                     
                     
             

      </Typography>
    );
}
Careers.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles),
    withRouter,
    withApollo
);
export default enhance(Careers);