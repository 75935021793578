import {
  Box,
  Button,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  alpha,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { capitalize, isEqual, startCase } from "lodash";

import { FilterAlt } from "@mui/icons-material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      // horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      // horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const GroupFilter = ({ data = [], getFilterValue, resetFilter = false }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState([]);
  const [activeFilter, setActiveFilter] = useState(false);
  useEffect(() => {
    const temp = (data || []).map((l) => l.grade);
    setChecked(temp);
  }, [data]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    if (value === "Select All") {
      if (checked.length === data.length) {
        setChecked([]);
      } else {
        setChecked(data.map((l) => l?.grade));
      }
    } else {
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    }
  };

  const handleFilterAPI = () => {
    setActiveFilter(true);
    getFilterValue(checked);
    handleClose();
  };
  const handleClearFilter = () => {
    getFilterValue([]);
    setChecked([]);
    setActiveFilter(false);
    handleClose();
  };

  React.useEffect(() => {
    if (resetFilter) {
      setActiveFilter(false);
      getFilterValue([]);
      setChecked([]);
    }
  }, [resetFilter]);

  return (
    <div>
      <Button
        sx={{ marginBottom: 1, fontSize: "15px" }}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        startIcon={
          <FilterAlt
            sx={{
              transform: activeFilter ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        }
        color="inherit"
      >
        Filter
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 70 * 4.5,
            width: "auto",
          },
        }}
      >
        <Box display={"flex"} justifyContent="space-between">
          <Button
            onClick={handleClearFilter}
            sx={{ marginLeft: 1 }}
            size="small"
            variant="outlined"
          >
            Clear
          </Button>
          <Button
            onClick={handleFilterAPI}
            sx={{ marginRight: 1 }}
            size="small"
            variant="contained"
          >
            Done
          </Button>
        </Box>
        <MenuItem
          dense
          sx={{ padding: "1px 10px" }}
          key={"Select All"}
          onClick={handleToggle("Select All")}
        >
          <ListItemIcon color="primary">
            <Checkbox
              color="primary"
              size="small"
              edge="start"
              checked={checked.length === data.length}
              tabIndex={-1}
              disableRipple
              inputProps={{
                "aria-labelledby": `checkbox-list-label-Select All`,
              }}
            />
          </ListItemIcon>
          <ListItemText sx={{ fontSize: 13 }}>Select All</ListItemText>
        </MenuItem>
        {data.map((value) => {
          const labelId = `checkbox-list-label-${value?.grade}`;
          return (
            <>
              <MenuItem
                dense
                sx={{ padding: "1px 10px" }}
                key={value?.grade}
                onClick={handleToggle(value?.grade)}
              >
                <ListItemIcon color="primary">
                  <Checkbox
                    color="primary"
                    size="small"
                    edge="start"
                    checked={checked.indexOf(value?.grade) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ fontSize: 13 }}>
                  {capitalize(value?.grade)}
                </ListItemText>
              </MenuItem>
            </>
          );
        })}
      </StyledMenu>
    </div>
  );
};

export default GroupFilter;
