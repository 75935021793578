import * as commonFunctions from "../../../utilities/commonFunctions";

import { Dialog, DialogTitle, Typography } from "@material-ui/core";
import { Link, useLocation, withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { isEmpty, startCase, upperCase } from "lodash";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

// import NotificationPage from './notificationPage';
// import EmailPage from './emailPage';
import { Grid } from "@material-ui/core";
import GroupFilter from "../GroupFilter/GroupFilter";
import Pageloader from "../pageloader";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import ReactTable from "../Table/ReactTable";
import { Tooltip } from "@mui/material";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "./styles";
import useDebounce from "../../../hooks/useDebounce";
import { useUserDetailsContext } from "../../../context/userDetails";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const ALLUSERS = loader(
  "../../../graphql/schema/member-manegement/get-all-school-user.graphql"
);
const DELETEUSER = loader("../../../graphql/schema/ui/deleteMember.graphql");
const STATES = loader("../../../graphql/schema/ui/states.graphql");
const SCHOOLS = loader(
  "../../../graphql/schema/ui/getCompareSchoolList.graphql"
);
const DEACTIVATEUSER = loader(
  "../../../graphql/schema/ui/changeUserStatus.graphql"
);
const EXPORT_USERS = loader(
  "../../../graphql/schema/member-manegement/ExportAllUsersForAdmin.graphql"
);
const GET_SCHOOLS_GROUP_NAMES = loader(
  "../../../graphql/schema/pf101/get-schools-groups-names.graphql"
);
const IMPORT_STUDENT_DATA = loader(
  "../../../graphql/schema/schools/import-school.graphql"
);
const MemberManagement = (props) => {
  const {
    state: { search = "", type, institution },
    clearSearch,
  } = useUserDetailsContext();
  const debouncedSearch = useDebounce(search, 1000);
  const inputRef = useRef(null);
  const [level, setLevel] = useState({ key: "user_fname", val: null });
  const { classes, history } = props;
  const [loading, setLoading] = React.useState(true);
  const [usersData, setUsersData] = React.useState([]);
  const [statesData, setStatesData] = React.useState([]);
  const [schoolsData, setSchoolsData] = React.useState([]);
  const [column, setCloumn] = React.useState("");
  const [selectedStateData, setSelectedStateData] = React.useState([]);
  const [selectedSchoolsData, setSelectedSchoolsData] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] =
    React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [openSuccesPopup, setOpenSuccesPopup] = React.useState(false);
  const [openFirstNameFilters, setOpenFirstNameFilters] = React.useState(false);
  const [openLastNameFilters, setOpenLastNameFilters] = React.useState(false);
  const [openEmailFilters, setOpenEmailFilters] = React.useState(false);
  const [openAliasNameFilters, setopenAliasNameFilters] = React.useState(false);
  const [openZipcodeFilters, setOpenZipcodeFilters] = React.useState(false);
  const [openCityFilters, setOpenCityFilters] = React.useState(false);
  const [openStateFilters, setOpenStateFilters] = React.useState(false);
  const [openSchoolsFilters, setOpenSchoolsFilters] = React.useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const [pageFineshedCount, setPageFineshedCount] = React.useState(0);
  const [successText, setSuccessText] = useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [deActivateId, setDeActivateId] = React.useState("");
  const [openConfirmDeActivatePopup, setOpenConfirmDeActivatePopup] =
    React.useState(false);
  const [deActivatStatus, setDeActivatStatus] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [selectedCol, setSelectedCol] = React.useState("");
  const [selectedtype, setSelectedtype] = React.useState("");
  const [openMessagePopup, setOpenMessagePopup] = React.useState("");
  const [importPopup, setImportPopup] = React.useState("");

  const [exportCol, setExportCol] = React.useState("");
  const [openNotification, setOpenNotification] = React.useState("");
  const [openEmail, setOpenEmail] = React.useState("");
  const [selectedNoficationEmails, setSelectedNoficationEmails] =
    React.useState([]);
  const [resetFilter, setResetFilter] = useState(false);

  const [messageType, setMessageType] = React.useState("");
  const [count, setCount] = useState(1);
  const [perPage] = React.useState(20);
  const [groupNames, setGroupNames] = React.useState([]);
  const [groupId, setGroup] = React.useState([]);
  const [status, setStatus] = useState(null);
  const location = useLocation();
  const [admin_type, setAdmin_type] = useState("");
  const [file, setFile] = React.useState("");

  useEffect(() => {
    setAdmin_type(
      location.pathname === "/students_management"
        ? "student"
        : location.pathname === "/member_management" && "member"
    );
  }, []);

  const [getSchoolGroupNames] = useLazyQuery(GET_SCHOOLS_GROUP_NAMES, {
    fetchPolicy: "network-only",
    onCompleted({ getSchoolsGroupNames }) {
      setLoading(false);
      setGroupNames(getSchoolsGroupNames);
    },
    onError() {
      setLoading(false);
    },
  });

  const [getStudentList, { fetchMore }] = useLazyQuery(ALLUSERS, {
    variables: {
      filter: {
        page: 1,
        perPage: 20,
        column_name: "",
        order_by: "",
        search_name: "",
        groups: groupId,
      },
    },
    fetchPolicy: "network-only",
    onCompleted({ GetAllUsersForAdmin }) {
      setUsersData(GetAllUsersForAdmin.user);
      setActivePage(GetAllUsersForAdmin?.page);
      setPageFineshedCount(GetAllUsersForAdmin.totalCount);
      setResetFilter(false);

      setLoading(false);
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  useEffect(
    () => {
      setLoading(true);
      setActivePage(1);
      setLevel({ key: "name", val: null });
      setStatus(null);
      setGroup([]);
      getStudentList({
        variables: {
          filter: {
            page: 1,
            perPage: 20,
            column_name: "",
            order_by: "",
            search_name: debouncedSearch,
            groups: groupId,
            status: "",
          },
        },
      });
    },
    [debouncedSearch] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    getSchoolGroupNames();
    getStudentList();
  }, []);

  const [deleteUserData, { data: deleteResult }] = useMutation(DELETEUSER, {
    onCompleted() {
      setOpenConfirmDeletePopup(false);
      setDeleteId("");
      setLoading(false);
      setOpenSuccesPopup(true);
      getStudentList({
        variables: {
          filter: {
            page: activePage,
            perPage: 20,
            column_name: level?.key,
            search_name: debouncedSearch,
            order_by: level?.val,
            groups: groupId,
            status: status,
          },
        },
      });
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [exportUsersData, { data: exportData }] = useLazyQuery(EXPORT_USERS, {
    fetchPolicy: "network-only",
    onCompleted() {
      const data = exportData.ExportAllUsersForAdmin;
      if (data) {
        var link = document.createElement("a");
        link.download = "support";
        link.href = data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    onError(error) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(error);
      setErrorMessage(errorMsg);
      setLoading(false);
    },
  });

  const [importSchoolUsers] = useMutation(IMPORT_STUDENT_DATA, {
    onCompleted(data) {
      setSuccessText(true);
      setLoading(false);
      setFile("");
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setErrorMessage(errorMsg);
      setLoading(false);
      setFile("");
      setSuccessText(false);
    },
  });

  const exportUsersTableData = () => {
    const activeFilter = {
      selectedCol: selectedCol,
      selectedtype: selectedtype,
      state: selectedStateData,
      schools: selectedSchoolsData,
      name: searchText,
    };
    exportUsersData({
      variables: {
        filter: activeFilter,
      },
    });
  };

  React.useEffect(() => {
    if (deleteResult) {
      setSuccessMessage(deleteResult.deleteMember.message);
    }
  }, [deleteResult]);

  // React.useEffect(() => {
  //     if (deActivateResult) {
  //         setLoading(false);
  //         setSuccessMessage(deActivateResult.changeUserStatus.message);
  //     }
  // }, [deActivateResult]);
  const handleOpenConfirmDeletePopup = (id) => {
    setDeleteId(id);
    setOpenConfirmDeletePopup(true);
  };
  const handleOpenConfirmDeActivePopup = (id, status) => {
    setDeActivateId(id);
    setDeActivatStatus(status === 0 ? 1 : 0);
    setOpenConfirmDeActivatePopup(true);
  };
  const handleCloseDeletePopup = () => {
    setOpenConfirmDeletePopup(false);
    setDeleteId("");
  };
  const handleCloseDeActivatePopup = () => {
    setOpenConfirmDeActivatePopup(false);
    setDeActivateId("");
  };
  const handleOpenMessagePopup = () => {
    setOpenMessagePopup(true);
  };
  const handleImport = () => {
    setImportPopup(true);
  };
  const handleCloseMessagePopup = () => {
    setOpenMessagePopup(false);
  };
  const handleCloseImportPopup = () => {
    setImportPopup(false);
    setFile("");
    setSearchText(false);
  };

  const handleUploadData = () => {
    if (file) {
      setLoading(true);
      importSchoolUsers({
        variables: {
          file: {
            institution_id: institution?.id,
            account_type: admin_type === "student" ? "School" : "Business",
            file: file,
          },
        },
      });
    }
  };

  const handleDeleteUser = () => {
    setLoading(true);
    deleteUserData({
      variables: {
        id: deleteId,
      },
    });
  };

  const handleUserEdit = (id) => {
    history.push(`/edit-${admin_type}-user?userId=` + id);
  };

  const handleClose = () => {
    setOpenSuccesPopup(false);
    setSuccessMessage("");
  };
  const handleFilters = (val) => {
    if (val === "user_fname") {
      setOpenFirstNameFilters(!openFirstNameFilters);
      setOpenLastNameFilters(false);
      setOpenEmailFilters(false);
      setopenAliasNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setSelectedCol(val);
    } else if (val === "user_lname") {
      setOpenLastNameFilters(!openLastNameFilters);
      setOpenFirstNameFilters(false);
      setOpenEmailFilters(false);
      setopenAliasNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setSelectedCol(val);
    } else if (val === "alias_name") {
      setopenAliasNameFilters(!openAliasNameFilters);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setSelectedCol(val);
    } else if (val === "grade") {
      setopenAliasNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(!openCityFilters);
      setSelectedCol(val);
    } else if (val === "schools") {
      setopenAliasNameFilters(false);
      setOpenEmailFilters(false);
      setOpenFirstNameFilters(false);
      setOpenLastNameFilters(false);
      setOpenZipcodeFilters(false);
      setOpenCityFilters(false);
      setOpenStateFilters(false);
      setOpenSchoolsFilters(true);
    }
  };
  const handleSorting = (row, val) => {
    setLoading(true);
    if (row === "status") {
      const [statusActive] = val;
      setStatus(statusActive);
      getStudentList({
        variables: {
          filter: {
            search_name: debouncedSearch,
            column_name: level?.key,
            order_by: level?.val,
            page: activePage,
            perPage: 20,
            groups: groupId,
            status: statusActive,
          },
        },
      });
    } else {
      setLevel({ key: row, val });
      getStudentList({
        variables: {
          filter: {
            search_name: debouncedSearch,
            column_name: row,
            order_by: val,
            page: activePage,
            perPage: 20,
            groups: groupId,
            status: status,
          },
        },
      });
    }
  };

  const handleGetFineshedMatchesPageClick = (pageNumber) => {
    setLoading(true);
    fetchMore({
      variables: {
        filter: {
          page: pageNumber,
          perPage: 20,
          column_name: level?.key,
          search_name: debouncedSearch,
          order_by: level?.val,
          groups: groupId,
          status: status,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoading(false);
        if (!fetchMoreResult) return prev;
        setUsersData(fetchMoreResult?.GetAllUsersForAdmin.user);
      },
    });
    setActivePage(pageNumber);
  };
  const handleFileChange = (e) => {
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      return;
    }
    setFile(e.target.files[0]);
    setErrorMessage("");
  };
  const convertPassword = (password) => {
    // let passwordStr = password.toString();
    let encryPass = "*";
    for (let i = 0; i < 10; i++) {
      encryPass = encryPass + "*";
    }
    return encryPass;
  };
  const handleOpenNotification = () => {
    setOpenNotification(true);
    setOpenMessagePopup(false);
  };
  const handleNotificationClose = () => {
    setOpenNotification(false);
  };
  const handleClearAllFilters = () => {
    setLoading(true);
    setResetFilter(true);
    setLevel({ key: "name", val: null });
    setActivePage(1);
    clearSearch();
    setGroup([]);
    setStatus(null);
    getStudentList({
      variables: {
        filter: {
          page: 1,
          perPage: 20,
          column_name: "",
          search_name: "",
          order_by: "",
          groups: [],
          status: "",
        },
      },
    });
  };
  const handleOpenEmail = (val) => {
    setOpenEmail(true);
    setOpenMessagePopup(false);
    setMessageType(val);
  };
  const handleEmailClose = () => {
    setOpenEmail(false);
  };
  return (
    <Typography className="main-bannerRight">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <Grid container className={classes.searchRow} spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          {groupNames && groupNames.length > 0 && (
            <div>
              <GroupFilter
                resetFilter={resetFilter}
                data={groupNames}
                getFilterValue={(value) => {
                  setGroup(value);
                  setActivePage(1);
                  setStatus(null);
                  setLevel({ key: "user_fname", val: null });
                  getStudentList({
                    variables: {
                      filter: {
                        page: 1,
                        perPage: 20,
                        column_name: "",
                        order_by: "",
                        search_name: debouncedSearch,
                        groups: value,
                        status: "",
                      },
                    },
                  });
                }}
              />
            </div>
          )}
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <div>
            {pageFineshedCount > 0 && (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={20}
                totalItemsCount={pageFineshedCount}
                pageRangeDisplayed={5}
                onChange={handleGetFineshedMatchesPageClick}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <ReactTable
        filterFunction={(row, value) => {
          handleSorting(row, value);
        }}
        resetFilter={resetFilter}
        activePage={activePage}
        count={pageFineshedCount}
        rows={usersData}
        columns={[
          {
            name: "First Name",
            row: "user_fname",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "Last Name",
            row: "user_lname",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "Alias Name",
            row: "alias_name",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "Username",
            row: "user_name",
          },
          {
            name: "Password",
            row: "password",
            customRowComponent: (value) => convertPassword(value),
          },
          {
            name: "Email",
            row: "email",
          },
          {
            name: startCase(
              location.pathname === "/member_management" ? "Business" : "School"
            ),
            row: "institution_name",
          },
          // {
          //   name: "Account Type",
          //   row: "account_type",
          // },
          {
            name: "Group",
            row: "grade",
            filterList: [
              { key: "", value: "None" },
              { key: "ASC", value: "Sort A to Z" },
              { key: "DESC", value: "Sort Z to A" },
            ],
          },
          {
            name: "Expiration Date",
            row: "end_date",
          },
          {
            name: "Status",
            row: "status",
            customRowComponent: (value) => (
              <>
                {value === "A" ? (
                  <span className={classes.statusSuccess}>active</span>
                ) : (
                  <span className={classes.statusInActive}>inactive</span>
                )}
              </>
            ),
            showCheckboxwithFilter: true,
            filterList: [
              {
                key: "active",
                value: "Active",
              },
              {
                key: "in_active",
                value: "Inactive",
              },
            ],
          },
          {
            name: "Options",
            row: "id",
            customRowComponent: (value) => (
              <span className={classes.moreInfo}>
                <ul>
                  <li
                    className={classes.editAdmin}
                    onClick={() => handleUserEdit(value)}
                  >
                    <i className="fa fa-pencil" title="Edit"></i>
                  </li>
                  <li
                    className={classes.deleteAdmin}
                    onClick={() => handleOpenConfirmDeletePopup(value)}
                  >
                    <i className="fa fa-trash" title="Delete"></i>
                  </li>
                </ul>
              </span>
            ),
          },
        ]}
      />
      <div className={classes.userTableSec}>
        <div className={classes.schoolsBtn}>
          <div className={classes.msgBtn}>
            <button onClick={handleOpenMessagePopup}>MESSAGE</button>
          </div>
          <div className={classes.addAdminBtn}>
            <Link to={`/add_${admin_type}`}>{`ADD NEW ${upperCase(
              admin_type
            )}`}</Link>
          </div>
          <div className={classes.clearFilterBtn}>
            <button onClick={handleClearAllFilters}>CLEAR ALL FILTERS</button>
          </div>
          <div className={classes.importFilterBtn}>
            <button onClick={handleImport}>IMPORT</button>
          </div>
          <Tooltip
            title=" This will export only the data that is currently shown above to
                excel. If you want to export all the data in this tab, make sure
                you clear all filters before using the export button."
          >
            <div className={classes.exportBtn}>
              <button
                data-tip
                data-for="main"
                onClick={() => {
                  exportUsersData({
                    variables: {
                      filter: {
                        column_name: level?.key,
                        search_name: debouncedSearch,
                        order_by: level?.val,
                        groups: groupId,
                        status: status,
                      },
                    },
                  });
                }}
              >
                EXPORT
              </button>
            </div>
          </Tooltip>
        </div>
      </div>

      <Dialog
        className="popupDiv popupDiv-Loan"
        open={openConfirmDeletePopup}
        onClose={handleCloseDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.colseButton}>
          <button onClick={handleCloseDeletePopup}>
            <i className="fa fa-times"></i>
          </button>
          <h3>Delete User</h3>
        </div>

        {/* <DialogTitle
          className="modal-header"
          id="customized-dialog-title"
          onClose={handleCloseDeletePopup}
        > */}
        <div className={classes.modalHeader} onClose={handleCloseDeletePopup}>
          Are you sure you want to delete this user? Deleting this user will
          immediately remove their account from the site. Plus, it will delete
          all their information and data from the database after 90 days.
        </div>
        {/* </DialogTitle> */}
        <div className="model-footer">
          <button
            onClick={() => handleDeleteUser()}
            className="close-btn delete-btn-light"
          >
            OK
          </button>
          <button onClick={handleCloseDeletePopup} className="close-btn">
            CANCEL
          </button>
        </div>
      </Dialog>
      <Dialog
        className="popupBankDetails"
        open={openSuccesPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.colseButton}>
          <button onClick={handleClose}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className={classes.sucessPopup}>
          <p>{successMessage || ""}</p>
          <figure>
            <img src="./img/success-img.png" alt="" />
          </figure>
        </div>
      </Dialog>
      <Dialog
        className="popup-model"
        open={openMessagePopup}
        onClose={handleCloseMessagePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="model-content">
          <div className="modal-header">
            <button onClick={handleCloseMessagePopup}>X</button>
            <h3>Send a message to all selected students</h3>
          </div>

          <div
            className="model-body"
            id="customized-dialog-title"
            onClose={handleCloseMessagePopup}
          >
            You can send a custom message to all selected students in the form
            of an Inkwiry notification, email or both. Use the buttons below to
            choose one of those options. Then, draft your message and send to
            your students.
          </div>
          <div className="model-footer">
            <button
              onClick={() => handleOpenNotification()}
              className="close-btn delete-btn-light"
            >
              Notification
            </button>
            <button
              onClick={() => handleOpenEmail("Email")}
              className="close-btn delete-btn-light"
            >
              Email
            </button>
            <button
              onClick={() => handleOpenEmail("Both")}
              className="close-btn delete-btn-light"
            >
              Both
            </button>
            <button onClick={handleCloseMessagePopup} className="close-btn">
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        className="popup-model"
        open={importPopup}
        onClose={handleCloseImportPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="model-content">
          <div className="modal-header">
            <button onClick={handleCloseImportPopup}>x</button>
            <h3 className={classes.importHeader}>
              {successText
                ? `${startCase(admin_type)} accounts imported successfully`
                : `Upload ${admin_type} data`}
            </h3>
          </div>
          <div
            className="model-body"
            id="customized-dialog-title"
            onClose={handleCloseImportPopup}
          >
            {!successText
              ? "Once you have all student details entered into the Excel file, save that file on your computer. Click on choose field, select that file, and click on upload. Your students will be sent an activation email in five minutes or so to complete the registration process."
              : "Users have been imported successfully. Check your email for confirmation. As your students activate their accounts, they will change to active status in the Student Management tab."}

            {!successText && (
              <>
                <div className={classes.uploadDiv}>
                  <span>
                    <input
                      type="file"
                      ref={inputRef}
                      onChange={handleFileChange}
                      accept=".xlsx"
                    />
                  </span>
                </div>
                <p className={errorMessage && classes.noteError}>
                  {errorMessage && errorMessage}
                </p>
              </>
            )}
          </div>
          <div className="model-footer">
            <button onClick={handleCloseImportPopup} className="close-btn">
              CANCEL
            </button>
            {!successText && (
              <button onClick={handleUploadData} className="delete-btn-light">
                UPLOAD
              </button>
            )}
          </div>
        </div>
      </Dialog>
      {/* <NotificationPage handleClose={handleNotificationClose} open={openNotification} selectedNoficationEmails={selectedNoficationEmails} /> */}
      {/* <EmailPage handleClose={handleEmailClose} open={openEmail} selectedNoficationEmails={selectedNoficationEmails} messageType={messageType} /> */}
    </Typography>
  );
};
MemberManagement.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);
export default enhance(MemberManagement);
