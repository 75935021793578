const styles = () => ({
		container: {
			maxWidth: '1170px',
			padding: '0 15px',
			margin: '0px auto',
			boxSizing: 'border-box',
			'& img': {
				maxWidth: '100%',
			},
			'@media (max-width: 767px)': {
				'& h3': {
					fontSize: '25px !important',
					lineHeight: '30px !important',
				},
				'& h2': {
					fontSize: '25px !important',
					lineHeight: '30px !important',
				},
				'& h4': {
					fontSize: '25px !important',
					lineHeight: '30px !important',
				},
				'& p': {
					fontSize: '17px !important',
					lineHeight: '28px !important',
				},
			},
		},
		containerAdmin:{
			width: '100%',
			margin: '0',
			maxWidth: '100%',
		},
		footerVillanova:{
			display: 'block',
			background: '#fff',
			borderTop: '1px solid #ccc',
			padding: '10px 0',
			height: 'auto',
		},
		villanovaLeft:{
			position: 'relative',
			'& a':{
				display: 'flex',
				fontSize: '20px',
				lineHeight: '20px',
				color: '#0069aa',
				textDecoration: 'none',
    		alignItems: 'center',
				'& img':{
					border: '1px solid #ccc',
					boxShadow: '0 0 0 1px #b9b9b9',
					marginRight: '8px',
					borderRadius: '50%',
					width: '30px',
					height: '30px',
				}
			}
		},
		logutText:{
			padding: '0',
			background: '#036',
			transform: 'scale(1)',
			MozTransform: 'scale(1)',
			WebkitTransform: 'scale(1)',
			top: 'auto',
			bottom: '100%',
			zIndex: '99999',
			position: 'absolute',
			left: '0',
			float: 'left',
			minWidth: '160px',
			margin: '2px 0 0',
			fontSize: '14px',
			listStyle: 'none',
			border: '1px solid rgba(0,0,0,.15)',
			borderRadius: '4px',
			WebkitBoxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
			boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
			marginBottom: '8px',
			'&:before':{
				borderTop: '9px solid #001489',
				borderBottom: 'none',
				borderLeft: '9px solid transparent',
				borderRight: '9px solid transparent',
				left: '6px',
				top: 'auto',
				bottom: '-10px',
				content: '""',
				height: '0',
				position: 'absolute',
				width: '0',
			},
			'& a':{
				color: '#fff',
				background: '#036',
				display: 'block',
				padding: '8px 11px',
				fontSize: '16px',
				cursor: 'pointer',
				alignItems: 'center',
				lineHeight: '20px',
				textDecoration: 'none',
				position: 'relative',
			}
		},
		inkwiryLogo:{
			textAlign: 'right',
			'& a':{
				display: 'inline-block',
				fontSize: '20px',
				lineHeight: '20px',
				color: '#0069aa',
				'& img':{
					maxWidth: '132px',
				}
			}
		}
});

export default styles;
