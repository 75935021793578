// import Loading from '../../ui/loading/loading';
import Gate from "./gate";
import PropTypes from "prop-types";
import React from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";

/**
 * Render the gate component
 * @param {*} data logged In User
 */
const renderGateComponent = (data, props) => {
  const { component, layout, allowedRoles } = props;

  return (
    <Gate
      component={component}
      layout={layout}
      allowedRoles={allowedRoles}
      loggedInUser={data.me}
    />
  );
};

/**
 * Renders authenticated component
 *
 * @returns
 * @memberof Authenticated
 */
const renderAuthenticated = (props) => renderGateComponent(props);

/**
 * HOC to check if there is a user session.
 */
const Authenticated = (props) => renderAuthenticated(props);

renderGateComponent.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Authenticated.propTypes = {
  component: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const enhance = compose(withRouter);

export default enhance(Authenticated);
