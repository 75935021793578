// import * as UserUtils from '../../utilities/userUtils';
import * as commonFunctions from "../../../utilities/commonFunctions";

import { Field, Form } from "react-final-form";
import { Link, withRouter } from "react-router-dom";
import React, { useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import Fail from "../../../assets/img/passwordCheck/fail.png";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Pageloader from "../../../components/ui/pageloader";
import Pass from "../../../assets/img/passwordCheck/pass.png";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Utilities from "../../../utilities/userUtils";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import styles from "../styles.css";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const FORGOT_PASSWORD = loader(
  "../../../graphql/schema/auth/forgot-password.graphql"
);
// import ErrorWithDelay from './ErrorWithDelay'

const ForgotPassword = (props) => {
  const { history, classes, location } = props;
  const { search } = location;
  const [loading, setLoading] = React.useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const [forgotPasswordEmail] = useMutation(FORGOT_PASSWORD, {
    onCompleted(data) {
      setSuccessMsg("Login details sent to your registered email address");
      setLoading(false);
    },
    onError(errors) {
      let errorMsg = commonFunctions.parseGraphQLErrorMessage(errors);
      setEmailError(errorMsg);
      setLoading(false);
    },
  });

  const handleSubmit = () => {
    if (email === "") {
      setEmailError("please enter email");
    } else if (!commonFunctions.validateEmail(email)) {
      setEmailError("Invalid email");
    } else {
      forgotPasswordEmail({
        variables: {
          data: {
            email,
          },
        },
      });
      setEmailError("");
    }
  };
  return (
    <Typography className="main-banner-login">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <Grid container className="container">
        <Grid item xl={7} lg={5} md={5} sm={12} xs={12} className="col-login">
          <div className="login">
            <figure>
              <img src="./img/logo2.png" alt="inkwiryLogo" />
            </figure>
            <div className="login-box">
              <Typography variant="h3">Forgot Password</Typography>
              <form className="form">
                <div className="form-group">
                  <input
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="email"
                    placeholder="Email Address"
                    className={emailError ? "has-error" : ""}
                  />
                </div>
                {emailError && <div className="noteError">{emailError}</div>}
                {successMsg && (
                  <div className="noteSuccess">
                    Login details sent to your registered email address
                  </div>
                )}

                <div>
                  <div className="text-center">
                    <button
                      className="otp-button"
                      name="data"
                      type="button"
                      onClick={handleSubmit}
                      // onBlur={() => setDisplayError(false)}
                    >
                      Reset Password
                    </button>
                  </div>
                  <div className="text-center forgot">
                    Already have a username?
                    <Link to="/"> LogIn here</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
      <footer className="desktop_footer">
        <div className="container_footer">
          <Grid container>
            {/* <Grid item xl lg={1} md={1} sm={1} xs={1} /> */}
            <Grid item lg={6} md={6} sm={6} className="copyright copyright_txt">
              <p>Copyright © 2022 // All rights reserved</p>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="copyright powered-text">
              <p className="logoDesc">
                Informed and Powered by{" "}
                <Link to="/" className="logoLink">
                  <img src="./img/logo2.png" alt="" />
                </Link>
              </p>
            </Grid>
          </Grid>
        </div>
        <div className="container-fluid new_gray_footer">
          <p className="terms_links">
            <Link
              to={{ pathname: "https://inkwiry.com/termsofservice" }}
              target="_blank"
            >
              Terms of Service
            </Link>
            &nbsp;//&nbsp;
            <Link
              to={{ pathname: "https://inkwiry.com/privacy" }}
              target="_blank"
            >
              Privacy Policy
            </Link>
            &nbsp;//&nbsp;
            <Link
              to={{ pathname: "https://inkwiry.com/security" }}
              target="_blank"
            >
              Security
            </Link>
          </p>
        </div>
      </footer>
    </Typography>
  );
};
// export default withStyles(styles)(Home);
ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(ForgotPassword);
