import React from 'react';
import  SchoolAdminMain from '../../../components/schools/SchoolAdmin'

const SchoolAdmin = () =>{
    return(
        <div>
           <SchoolAdminMain />
        </div>
    )
}

export default SchoolAdmin