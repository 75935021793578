const styles = () => ({
	userTableDiv: {
		width: 'calc(100% - 4px)',
		background: '#fff',
		boxShadow: '0 0px 2px rgb(25 25 25 / 20%)',
		transition: 'all .3s ease-in-out',
		marginLeft: '2px',
		marginBottom: '20px',
		overflow: 'auto',
		borderRadius: '15px 11px 0px 0px',
		'& table': {
			width: '100%',
			background: '#036',
			tableLayout: 'fixed',
			border: '1px solid #ccc',
			borderSpacing: '0',
			borderCollapse: 'collapse',
			'& thead': {
				width: '100%',
				borderRadius: '15px 11px 0 0',
				color: '#fff',
					'& th': {
						padding: '25px 10px 10px',
						fontSize: '14px',
						textAlign: 'left',
						width: '125px',
						background: ' #036',
						color: 'white',
						width: '125px',
						fontFamily: 'museosans-700',
						fontWeight: 'normal',
						'&:nth-child(1)':{
							width: '42px',
							textAlign: 'center',
							padding: '25px 10px 10px',			
						},
						'&:nth-child(2)':{
							width: '155px',
						},
						'& i': {
							marginLeft: '5px',
							cursor: 'pointer',
						}
					},
				},
				'& tbody': {
					background: '#fff',
					'& tr':{
						background: '#fff',
						'&:nth-child(even)':{
							background: '#f2f2f2',
						},
						'& td': {
							padding: '8px 10px',
							fontSize: '14px',
							borderBottom: '1px solid #ededed',
							verticalAlign: 'middle',
							color: '#333',
							wordBreak: 'break-all',
							width: '125px',
							'&:nth-child(1)':{
								width: '42px',
								textAlign: 'center',
								padding: '8px 13px',
							},
							'&:nth-child(2)':{
								width: '155px',
							},
							'& a':{
								color: '#337ab7',
								'&:hover':{
									color: '#23527c',
								}
							}
						}
					}	
				},
			},
			'& h3': {
				color: '#0069AA',
				fontFamily: 'museosans-500',
				fontWeight: 'normal',
			},
		},	
	schoolsBtn: {
		display: 'flex',
		float: 'right',
	},
	dashboardTabs:{
		position: 'relative',
	},
	colGrid:{
		margin: '17px 0px 10px',
		width: '100%',
		'& > div':{
			padding: '0px !important',
		}
	},
	colLeft:{
		'& ul':{
			float: 'none',
		}
	},
	colRight:{
		textAlign: 'right',
	},
	
  infoIcon: {
    margin: '9px 1px',
    textAlign: 'center',
    display: 'inline-block',
    width: '25px !important',
    position: 'relative',
    '& img': {
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
      border: '0',
      verticalAlign: 'middle',
    },
  },
	input: {
		width: '150px',
		borderRadius: "5px",
		border: "1px solid #aaa",
		boxShadow: 'inset 0px 0px 3px rgba(0,0,0,0.1)',
		padding: '10px',
		boxSizing: "border-box",
		margin: "0px",
		color: "#777",
		outline: "none",
	},
	"& label":{
		padding:"0px 10px",
	},
	saveBtn: {
		border: "none",
		backgroundColor: '#036',
		borderColor: '#036',
		padding: '8px 15px',
		boxShadow: 'none',
		color: '#fff',
		marginRight: '10px',
		borderRadius: '5px',
		letterSpacing: '1px',
		fontFamily: 'MuseoSans-300',
    fontWeight: 'normal',
	},
	
	dialog: {
		position: 'fixed',
		transform: 'translateX(-50%)',
		top: "5%",
		left: "50%",
		maxWidth: '550px',
		backgroundColor: "#fff",
		borderRadius: "5px",
		padding: "10px",
		boxSizing: 'border-box',
		zIndex: '2',
		boxShadow: "0px 0px 5px rgba(0,0,0,0.4)",
	},
	title: {
		display: 'flex',
		justifyContent: "space-between",
		borderBottom: "1px solid #ddd",
		'& h2': {
			margin: '10px 0px 0px 10px',
		}
	},
	content: {
		padding: '10px',
		borderBottom: "1px solid #ddd",
		width: '100%',
		marginBottom: '10px',
		'& label': {
				
		},
		'& input': {
			width: '90%',
			borderRadius: "5px",
			border: "1px solid #ddd",
			padding: '10px',
			margin: "0px",
			color: "#777"
		}
	},
	billingRow:{
		'& h3':{
			fontSize: '16px',
			lineHeight: '23px',
			marginBottom: '20px',
			color: '#0069aa',
			fontFamily: 'museosans-500',
			fontWeight: 'normal',
		},
		'& p':{
			color: '#333',
		}
	},
	reminders_checkbox:{
		'& div':{
				display: 'inline-block',
    		paddingRight: '20px',
			'& input[type=checkbox]':{
				width: '16px',
				height: '16px',
				cursor: 'pointer',
			},
			'& label':{
				position: 'relative',
				paddingLeft: '5px',
				margin: '12px 0 0',
				fontSize: '14px',
				fontFamily: '"MuseoSans-100"',
				fontWeight: 'normal',
				top: '-3px',
			}
		}
	}
});
export default styles;