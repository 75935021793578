import { loader } from 'graphql.macro';
import React from 'react';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import Pageloader from '../pageloader';
import styles from './styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Grid, Button } from "@material-ui/core";
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { withApollo } from "react-apollo";

const DASHBOARD = loader('../../../graphql/schema/dashboard/dashboard.graphql');

const Dashboard = (props) => {
    const { classes } = props;
    const [loading, setLoading] = React.useState(true);
    const [dashboardData, setDashboardData] = useState('');
    const [totalUsers, setTotalUsers] = useState([]);

    const [dashboard] = useLazyQuery(DASHBOARD, {
        fetchPolicy: 'network-only',
        onCompleted(res) {
            setDashboardData(res.GetDashboardDetails)
            let clicksCount = res.GetDashboardDetails.total_users + '';
            clicksCount = clicksCount.split('');
            for (let i = clicksCount.length; i < 6; i++) {
                clicksCount.unshift('0');
            }
            setTotalUsers(clicksCount);
            setLoading(false)
        },
        onError() {
            setLoading(false)
        }
    });

    useEffect(() => {
        dashboard();
    }, []);

    const formatDollar = (num) => {
        var p = num.toFixed(2).split(".");
        return p[0].split("").reverse().reduce(function (acc, num, i, orig) {
            return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
        }, "");
    }

    return (
        <div>
            {loading === true ? <Pageloader loading={loading} /> : ""}
            <Grid container spacing={3}>
                <Grid item md={4} sm={4} xs={12} className={classes.bdRight}>
                    <div className={classes.clicksCount}>
                        <h3>Total # of Users</h3>
                        <div className={classes.comingSoonHours}>
                            <span>{totalUsers && totalUsers[0]}</span>
                            <span>{totalUsers && totalUsers[1]}</span>
                            <span>{totalUsers && totalUsers[2]}</span>
                            <span>{totalUsers && totalUsers[3]}</span>
                            <span>{totalUsers && totalUsers[4]}</span>
                            <span>{totalUsers && totalUsers[5]}</span>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} sm={4} xs={12} className={classes.bdRight}>
                    <div className={classes.clicksCount}>
                        <h3>Active Monthly Members</h3>
                        <div className={classes.MemberGrapgh}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={
                                    {
                                        chart: {
                                            type: 'column',
                                            height: 300
                                        },
                                        title: {
                                            text: 'Active Monthly Members'
                                        },
                                        xAxis: {
                                            categories: dashboardData && dashboardData.active_monthly['days'],
                                        },
                                        yAxis: {
                                            min: 0,
                                            title: {
                                                text: 'Active Members'
                                            },
                                        },
                                        tooltip: {
                                            headerFormat: "",
                                            pointFormatter: function () {
                                                return '<table><tr><td align="left">' + (dashboardData && dashboardData.active_monthly['days'][this.x]) + ': <b>' + formatDollar(this.y) + '</b></td></tr></table>';
                                            },
                                            shared: true,
                                            useHTML: true
                                        },
                                        plotOptions: {
                                            column: {
                                                pointPadding: 0.2,
                                                borderWidth: 0
                                            }
                                        },
                                        credits: {
                                            enabled: false
                                        },
                                        series: [{
                                            data: dashboardData && dashboardData.active_monthly['count'],
                                            color: '#0069aa',
                                            showInLegend: false,
                                        }],
                                    }}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                    <div className={classes.clicksCount}>
                        <h3>Unique Monthly Visitors</h3>
                        <div className={classes.MemberGrapgh}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={
                                    {
                                        chart: {
                                            type: 'column',
                                            height: 300
                                        },
                                        title: {
                                            text: 'Unique Monthly Visitors'
                                        },
                                        xAxis: {
                                            categories: dashboardData && dashboardData.active_monthly['days'],
                                        },
                                        yAxis: {
                                            min: 0,
                                            title: {
                                                text: 'Unique Monthly Visitors'
                                            },
                                        },
                                        tooltip: {
                                            headerFormat: "",
                                            pointFormatter: function () {
                                                return '<table><tr><td align="left">' + (dashboardData && dashboardData.active_monthly['days'][this.x]) + ': <b>' + formatDollar(this.y) + '</b></td></tr></table>';
                                            },
                                            shared: true,
                                            useHTML: true
                                        },
                                        plotOptions: {
                                            column: {
                                                pointPadding: 0.2,
                                                borderWidth: 0
                                            }
                                        },
                                        credits: {
                                            enabled: false
                                        },
                                        series: [{
                                            data: dashboardData && dashboardData.active_monthly['unique_count'],
                                            color: '#84a84d',
                                            showInLegend: false,
                                        }],
                                    }}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.colRowMiddle}>
                <Grid item md={4} sm={4} xs={12} className={classes.bdRight}>
                    <div className={classes.clicksCount}>
                        <h3>Total Monthly Site Visits</h3>
                        <div className={classes.MemberGrapgh}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={
                                    {
                                        chart: {
                                            type: 'column',
                                            height: 300
                                        },
                                        title: {
                                            text: 'Unique Monthly Visitors'
                                        },
                                        xAxis: {
                                            categories: dashboardData && dashboardData.total_monthly_visitors['months'],
                                        },
                                        yAxis: {
                                            min: 0,
                                            title: {
                                                text: 'Unique Monthly Visitors'
                                            },
                                        },
                                        tooltip: {
                                            headerFormat: "",
                                            pointFormatter: function () {
                                                return '<table><tr><td align="left">' + (dashboardData && dashboardData.total_monthly_visitors['months'][this.x]) + ': <b>' + formatDollar(this.y) + '</b></td></tr></table>';
                                            },
                                            shared: true,
                                            useHTML: true
                                        },
                                        plotOptions: {
                                            column: {
                                                pointPadding: 0.2,
                                                borderWidth: 0
                                            }
                                        },
                                        credits: {
                                            enabled: false
                                        },
                                        series: [{
                                            data: dashboardData && dashboardData.total_monthly_visitors['count'],
                                            color: '#84a84d',
                                            showInLegend: false,
                                        }],
                                    }}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} sm={4} xs={12} className={classes.bdRight}>
                    <div className={classes.clicksCount}>
                        <h3>Source</h3>
                        <div className={classes.MemberGrapgh}>
                            <img src="./img/user-graph.png" alt="" />
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                    <div className={classes.clicksCount}>
                        <h3>Bounce Rate</h3>
                        <div className={classes.MemberGrapgh}>
                            <img src="./img/monthly-visitors.png" alt="" />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.colRowMiddle}>
                <Grid item md={4} sm={4} xs={12} className={classes.bdRight}>
                    <div className={classes.clicksCount}>
                        <h3>Top 10 visited pages</h3>
                        {dashboardData && dashboardData.top10_pages_visits > 0 ?
                            <ul>
                                {dashboardData && dashboardData.top10_pages_visits.map((d) => {
                                    <li>{d}</li>
                                })
                                }
                            </ul>
                            :
                            <p className={classes.noDataFound}>No data found</p>
                        }
                    </div>
                </Grid>
                <Grid item md={4} sm={4} xs={12} className={classes.bdRight}>
                    <div className={classes.clicksCount}>
                        <h3>Top 10 time spended pages</h3>
                        {dashboardData && dashboardData.top10_pages_total_time > 0 ?
                            <ul>
                                {dashboardData && dashboardData.top10_pages_total_time.map((d) => {
                                    <li>{d}</li>
                                })
                                }
                            </ul>
                            :
                            <p className={classes.noDataFound}>No data found</p>
                        }
                    </div>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                    <div className={classes.clicksCount}>
                        <h3>Top 10 worst pages</h3>
                        {dashboardData && dashboardData.worst10_pages_visits > 0 ?
                            <ul>
                                {dashboardData && dashboardData.worst10_pages_visits.map((d) => {
                                    <li>{d}</li>
                                })
                                }
                            </ul>
                            :
                            <p className={classes.noDataFound}>No data found</p>
                        }
                    </div>
                </Grid>
            </Grid>
            {/* <div>
                <h3> Total User</h3>
                <div>
                    <p><NumberFormat value={dashboardData.total_users} displayType={'text'} thousandSeparator /></p>
                </div>
            </div>
            <div>
                <h3>Active Monthly Members</h3>
                <div>
                    <p>Users logged in the past 30 days </p>
                    <p><NumberFormat value={dashboardData.users_logged_in_past_30days} displayType={'text'} thousandSeparator /></p>
                </div>
                <div>
                    <p>Last 6 months monthly Active Users</p>
                    <table border='1'>
                        <thead>
                            <tr>
                                {dashboardData !== '' ? dashboardData.active_monthly.days.map((l, index) => (
                                    <th key={index}>{l}</th>
                                ))
                                    :
                                    'No data found'
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {dashboardData !== '' ? dashboardData.active_monthly.count.map((l, index) => (
                                    <td key={index}>{l}</td>
                                ))
                                    :
                                    'No data found'
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h3>Unique Monthly Visitors</h3>
                <div>
                    <p>Unique individuals that are visited the website</p>
                    <p><NumberFormat value={dashboardData.unique_site_visits_last_month} displayType={'text'} thousandSeparator /></p>
                </div>
                <div>
                    <p>Last 6 months unique monthly visitors</p>
                    <table border='1'>
                        <thead>
                            <tr>
                                {dashboardData !== '' ? dashboardData.unique_visitors_montly.months.map((l, index) => (
                                    <th key={index}>{l}</th>
                                ))
                                    :
                                    'No data found'
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {dashboardData !== '' ? dashboardData.unique_visitors_montly.unique_count.map((l, index) => (
                                    <td key={index}>{l}</td>
                                ))
                                    :
                                    'No data found'
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h3>Total Monthly Site Visits</h3>
                <div>
                    <p>Total last month visited users</p>
                    <p><NumberFormat value={dashboardData.total_site_visits_last_month} displayType={'text'} thousandSeparator /></p>
                </div>
                <div>
                    <p>Last 6 months monthly site visitors</p>
                    <table border='1'>
                        <thead>
                            <tr>
                                {dashboardData !== '' ? dashboardData.total_monthly_visitors.months.map((l, index) => (
                                    <th key={index}>{l}</th>
                                ))
                                    :
                                    'No data found'
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {dashboardData !== '' ? dashboardData.total_monthly_visitors.count.map((l, index) => (
                                    <td key={index}>{l}</td>
                                ))
                                    :
                                    'No data found'
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div></div>
            <div></div>
            <div>
                <h3>Top 10 visited pages</h3>
                <div>
                    <ul>
                        {dashboardData !== '' && dashboard.top10_pages_visits !== undefined && dashboard.top10_pages_visits.length > 0 ? dashboard.top10_pages_visits.map((l, index) => (
                            <li key={index}>{l}</li>
                        ))
                            :
                            'No data found'
                        }
                    </ul>
                </div>
            </div>
            <div>
                <h3>Top 10 time spended pages </h3>
                <div>
                    <ul>
                        {dashboardData !== '' && dashboard.top10_pages_total_time !== undefined ? dashboard.top10_pages_total_time.map((l, index) => (
                            <li key={index}>{l}</li>
                        ))
                            :
                            'No data found'
                        }
                    </ul>
                </div>
            </div>
            <div>
                <h3>Top 10 worst pages </h3>
                <div>
                    <ul>
                        {dashboardData !== '' && dashboard.worst10_pages_visits !== undefined ? dashboard.worst10_pages_visits.map((l, index) => (
                            <li key={index}>{l}</li>
                        ))
                            :
                            'No data found'
                        }
                    </ul>
                </div>
            </div> */}
        </div>

    )
}
// AddBusinessUsers.propTypes = {
// 	classes: PropTypes.object.isRequired,
// 	classNames: PropTypes.object.isRequired,
// };
const enhance = compose(
    withStyles(styles),
    withRouter,
    withApollo
);

export default enhance(Dashboard);