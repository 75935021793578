import HCExporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/react-hooks";

const MEMBER_LEVEL_BREAKDOWN = loader(
  "../../../graphql/schema/ui/member-details/member-level-breakdown.graphql"
);
const MEMBER_SOURCE_BREAKDOWN = loader(
  "../../../graphql/schema/ui/member-details/member-source-breakdown.graphql"
);
const MEMBER_IDENTITY_BREAKDOWN = loader(
  "../../../graphql/schema/ui/member-details/member-identity-breakdown.graphql"
);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  colors: ["#389BD2", "#FF7322", "#A7A39E"],
});
HCExporting(Highcharts);

const MemberDashboard = () => {
  const [memberLevelBreakDown, setMemberLevelBreakDown] = React.useState([]);
  const [memberSourceBreakDown, setMemberSourceBreakDown] = React.useState([]);
  const [memberIdentityBreakDown, setMemberIdentityBreakDown] = React.useState(
    []
  );
  const [memberLevelMonthlyBreakDown, setMemberLevelMonthlyBreakDown] =
    React.useState([]);
  const [memberSourceMonthlyBreakDown, setMemberSourceMonthlyBreakDown] =
    React.useState([]);
  const [memberIdentityMonthlyBreakDown, setMemberIdentityMonthlyBreakDown] =
    React.useState([]);

  const [memberLevelBreakdown] = useLazyQuery(MEMBER_LEVEL_BREAKDOWN, {
    fetchPolicy: "network-only",
    onCompleted(res) {
      setMemberLevelBreakDown(res.memberLevelBreakdown);
    },
  });

  const [memberLevelMonthlyBreakdown] = useLazyQuery(MEMBER_LEVEL_BREAKDOWN, {
    fetchPolicy: "network-only",
    variables: {
      by_time: true,
    },
    onCompleted(res) {
      setMemberLevelMonthlyBreakDown(res.memberLevelBreakdown);
    },
  });

  const [memberSourceBreakdown] = useLazyQuery(MEMBER_SOURCE_BREAKDOWN, {
    fetchPolicy: "network-only",
    onCompleted(res) {
      setMemberSourceBreakDown(res.memberSourceBreakdown);
    },
  });

  const [memberSourceMonthlyBreakdown] = useLazyQuery(MEMBER_SOURCE_BREAKDOWN, {
    fetchPolicy: "network-only",
    variables: {
      by_time: true,
    },
    onCompleted(res) {
      setMemberSourceMonthlyBreakDown(res.memberSourceBreakdown);
    },
  });

  const [memberIdentityBreakdown] = useLazyQuery(MEMBER_IDENTITY_BREAKDOWN, {
    fetchPolicy: "network-only",
    onCompleted(res) {
      setMemberIdentityBreakDown(res.memberIdentityBreakdown);
    },
  });

  const [memberIdentityMonthlyBreakdown] = useLazyQuery(
    MEMBER_IDENTITY_BREAKDOWN,
    {
      fetchPolicy: "network-only",
      variables: {
        by_time: true,
      },
      onCompleted(res) {
        setMemberIdentityMonthlyBreakDown(res.memberIdentityBreakdown);
      },
    }
  );

  React.useEffect(() => {
    memberSourceBreakdown();
    memberLevelBreakdown();
    memberIdentityBreakdown();
    memberLevelMonthlyBreakdown();
    memberSourceMonthlyBreakdown();
    memberIdentityMonthlyBreakdown();
  }, []);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: "#ffffff",
            plotBorderColor: "#cccccc",
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: 450,
            // width: graphMainWidth
          },
          navigation: {
            menuItemStyle: {
              fontSize: "14px",
              textAlign: "left",
            },
            menuItemHoverStyle: {
              background: "#f5f5f5",
              color: "#4c4c4c",
              fontSize: "14px",
            },
            buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              _titleKey: "y",
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  {
                    textKey: "downloadPNG",
                    onclick() {
                      const chart = this;
                      chart.exportChart();
                    },
                  },
                  {
                    textKey: "downloadJPEG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/jpeg",
                      });
                    },
                  },
                  {
                    textKey: "downloadPDF",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "application/pdf",
                      });
                    },
                  },
                  {
                    textKey: "downloadSVG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/svg+xml",
                      });
                    },
                  },
                ],
              },
            },
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          tooltip: {
            // eslint-disable-next-line
            pointFormat: "${point.y:,.0f} ({point.percentage:.0f}%)",
          },
          plotOptions: {
            pie: {
              cursor: "pointer",
              dataLabels: {
                distance: -30,
                enabled: true,
                format: "{point.percentage:.0f}%",
                style: {
                  color:
                    (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                    "black",
                  fontSize: "14px",
                  textOutline: false,
                },
              },
              showInLegend: true,
            },
          },
          legend: {
            backgroundColor: "#F5F5F5",
            align: "center",
          },
          series: [
            {
              name: "Expenses",
              type: "pie",
              data: [
                [
                  "monthly",
                  memberLevelBreakDown.length > 0 &&
                    memberLevelBreakDown[1].total_subscriptions,
                ],
                [
                  "semiYear",
                  memberLevelBreakDown.length > 0 &&
                    memberLevelBreakDown[2].total_subscriptions,
                ],
                [
                  "yearly",
                  memberLevelBreakDown.length > 0 &&
                    memberLevelBreakDown[3].total_subscriptions,
                ],
              ],
            },
          ],
        }}
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: "#ffffff",
            plotBorderColor: "#cccccc",
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: 450,
            // width: graphMainWidth
          },
          navigation: {
            menuItemStyle: {
              fontSize: "14px",
              textAlign: "left",
            },
            menuItemHoverStyle: {
              background: "#f5f5f5",
              color: "#4c4c4c",
              fontSize: "14px",
            },
            buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              _titleKey: "y",
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  {
                    textKey: "downloadPNG",
                    onclick() {
                      const chart = this;
                      chart.exportChart();
                    },
                  },
                  {
                    textKey: "downloadJPEG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/jpeg",
                      });
                    },
                  },
                  {
                    textKey: "downloadPDF",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "application/pdf",
                      });
                    },
                  },
                  {
                    textKey: "downloadSVG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/svg+xml",
                      });
                    },
                  },
                ],
              },
            },
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          tooltip: {
            // eslint-disable-next-line
            pointFormat: "${point.y:,.0f} ({point.percentage:.0f}%)",
          },
          plotOptions: {
            pie: {
              cursor: "pointer",
              dataLabels: {
                distance: -30,
                enabled: true,
                format: "{point.percentage:.0f}%",
                style: {
                  color:
                    (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                    "black",
                  fontSize: "14px",
                  textOutline: false,
                },
              },
              showInLegend: true,
            },
          },
          legend: {
            backgroundColor: "#F5F5F5",
            align: "center",
          },
          series: [
            {
              name: "Expenses",
              type: "pie",
              data: [
                [
                  "user",
                  memberSourceBreakDown.length > 0 &&
                    memberSourceBreakDown[0].total_users,
                ],
                [
                  "student",
                  memberSourceBreakDown.length > 0 &&
                    memberSourceBreakDown[1].total_users,
                ],
                [
                  "young Professional",
                  memberSourceBreakDown.length > 0 &&
                    memberSourceBreakDown[2].total_users,
                ],
              ],
            },
          ],
        }}
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: "#ffffff",
            plotBorderColor: "#cccccc",
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: 450,
            // width: graphMainWidth
          },
          navigation: {
            menuItemStyle: {
              fontSize: "14px",
              textAlign: "left",
            },
            menuItemHoverStyle: {
              background: "#f5f5f5",
              color: "#4c4c4c",
              fontSize: "14px",
            },
            buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              _titleKey: "y",
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  {
                    textKey: "downloadPNG",
                    onclick() {
                      const chart = this;
                      chart.exportChart();
                    },
                  },
                  {
                    textKey: "downloadJPEG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/jpeg",
                      });
                    },
                  },
                  {
                    textKey: "downloadPDF",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "application/pdf",
                      });
                    },
                  },
                  {
                    textKey: "downloadSVG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/svg+xml",
                      });
                    },
                  },
                ],
              },
            },
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          tooltip: {
            // eslint-disable-next-line
            pointFormat: "${point.y:,.0f} ({point.percentage:.0f}%)",
          },
          plotOptions: {
            pie: {
              cursor: "pointer",
              dataLabels: {
                distance: -30,
                enabled: true,
                format: "{point.percentage:.0f}%",
                style: {
                  color:
                    (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                    "black",
                  fontSize: "14px",
                  textOutline: false,
                },
              },
              showInLegend: true,
            },
          },
          legend: {
            backgroundColor: "#F5F5F5",
            align: "center",
          },
          series: [
            {
              name: "Expenses",
              type: "pie",
              data: [
                [
                  "parent",
                  memberIdentityBreakDown.length > 0 &&
                    memberIdentityBreakDown[0].total_users,
                ],
                [
                  "student",
                  memberIdentityBreakDown.length > 0 &&
                    memberIdentityBreakDown[2].total_users,
                ],
                [
                  "employee",
                  memberIdentityBreakDown.length > 0 &&
                    memberIdentityBreakDown[3].total_users,
                ],
                [
                  "other",
                  memberIdentityBreakDown.length > 0 &&
                    memberIdentityBreakDown[4].total_users,
                ],
                [
                  "Young Professional",
                  memberIdentityBreakDown.length > 0 &&
                    memberIdentityBreakDown[5].total_users,
                ],
                [
                  "A",
                  memberIdentityBreakDown.length > 0 &&
                    memberIdentityBreakDown[6].total_users,
                ],
              ],
            },
          ],
        }}
      />
      <div>Need api data for graph</div>
      <div>Need api data for graph</div>
      <div>Need api data for graph</div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: "#ffffff",
            plotBorderColor: "#cccccc",
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: 450,
            // width: graphMainWidth
          },
          navigation: {
            menuItemStyle: {
              fontSize: "14px",
              textAlign: "left",
            },
            menuItemHoverStyle: {
              background: "#f5f5f5",
              color: "#4c4c4c",
              fontSize: "14px",
            },
            buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              _titleKey: "y",
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  {
                    textKey: "downloadPNG",
                    onclick() {
                      const chart = this;
                      chart.exportChart();
                    },
                  },
                  {
                    textKey: "downloadJPEG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/jpeg",
                      });
                    },
                  },
                  {
                    textKey: "downloadPDF",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "application/pdf",
                      });
                    },
                  },
                  {
                    textKey: "downloadSVG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/svg+xml",
                      });
                    },
                  },
                ],
              },
            },
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          tooltip: {
            // eslint-disable-next-line
            pointFormat: "${point.y:,.0f} ({point.percentage:.0f}%)",
          },
          plotOptions: {
            pie: {
              cursor: "pointer",
              dataLabels: {
                distance: -30,
                enabled: true,
                format: "{point.percentage:.0f}%",
                style: {
                  color:
                    (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                    "black",
                  fontSize: "14px",
                  textOutline: false,
                },
              },
              showInLegend: true,
            },
          },
          legend: {
            backgroundColor: "#F5F5F5",
            align: "center",
          },
          series: [
            {
              name: "Expenses",
              type: "pie",
              data: [
                [
                  "monthly",
                  memberLevelMonthlyBreakDown.length > 0 &&
                    memberLevelMonthlyBreakDown[1].total_subscriptions,
                ],
                [
                  "semiYear",
                  memberLevelMonthlyBreakDown.length > 0 &&
                    memberLevelMonthlyBreakDown[2].total_subscriptions,
                ],
                // ['yearly', memberLevelMonthlyBreakDown.length > 0 && memberLevelMonthlyBreakDown[3].total_subscriptions]
              ],
            },
          ],
        }}
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: "#ffffff",
            plotBorderColor: "#cccccc",
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: 450,
            // width: graphMainWidth
          },
          navigation: {
            menuItemStyle: {
              fontSize: "14px",
              textAlign: "left",
            },
            menuItemHoverStyle: {
              background: "#f5f5f5",
              color: "#4c4c4c",
              fontSize: "14px",
            },
            buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              _titleKey: "y",
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  {
                    textKey: "downloadPNG",
                    onclick() {
                      const chart = this;
                      chart.exportChart();
                    },
                  },
                  {
                    textKey: "downloadJPEG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/jpeg",
                      });
                    },
                  },
                  {
                    textKey: "downloadPDF",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "application/pdf",
                      });
                    },
                  },
                  {
                    textKey: "downloadSVG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/svg+xml",
                      });
                    },
                  },
                ],
              },
            },
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          tooltip: {
            // eslint-disable-next-line
            pointFormat: "${point.y:,.0f} ({point.percentage:.0f}%)",
          },
          plotOptions: {
            pie: {
              cursor: "pointer",
              dataLabels: {
                distance: -30,
                enabled: true,
                format: "{point.percentage:.0f}%",
                style: {
                  color:
                    (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                    "black",
                  fontSize: "14px",
                  textOutline: false,
                },
              },
              showInLegend: true,
            },
          },
          legend: {
            backgroundColor: "#F5F5F5",
            align: "center",
          },
          series: [
            {
              name: "Expenses",
              type: "pie",
              data: [
                [
                  "user",
                  memberSourceMonthlyBreakDown.length > 0 &&
                    memberSourceMonthlyBreakDown[0].total_users,
                ],
                // ['student', memberSourceMonthlyBreakDown.length > 0 && memberSourceMonthlyBreakDown[1].total_users],
                // ['young Professional', memberSourceMonthlyBreakDown.length > 0 && memberSourceMonthlyBreakDown[2].total_users]
              ],
            },
          ],
        }}
      />

      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: "#ffffff",
            plotBorderColor: "#cccccc",
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: 450,
            // width: graphMainWidth
          },
          navigation: {
            menuItemStyle: {
              fontSize: "14px",
              textAlign: "left",
            },
            menuItemHoverStyle: {
              background: "#f5f5f5",
              color: "#4c4c4c",
              fontSize: "14px",
            },
            buttonOptions: {
              height: 40,
              width: 48,
              symbolSize: 24,
              symbolX: 24,
              symbolY: 21,
              symbolStrokeWidth: 2,
              _titleKey: "y",
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  {
                    textKey: "downloadPNG",
                    onclick() {
                      const chart = this;
                      chart.exportChart();
                    },
                  },
                  {
                    textKey: "downloadJPEG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/jpeg",
                      });
                    },
                  },
                  {
                    textKey: "downloadPDF",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "application/pdf",
                      });
                    },
                  },
                  {
                    textKey: "downloadSVG",
                    onclick() {
                      const chart = this;
                      chart.exportChart({
                        type: "image/svg+xml",
                      });
                    },
                  },
                ],
              },
            },
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          tooltip: {
            // eslint-disable-next-line
            pointFormat: "${point.y:,.0f} ({point.percentage:.0f}%)",
          },
          plotOptions: {
            pie: {
              cursor: "pointer",
              dataLabels: {
                distance: -30,
                enabled: true,
                format: "{point.percentage:.0f}%",
                style: {
                  color:
                    (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                    "black",
                  fontSize: "14px",
                  textOutline: false,
                },
              },
              showInLegend: true,
            },
          },
          legend: {
            backgroundColor: "#F5F5F5",
            align: "center",
          },
          series: [
            {
              name: "Expenses",
              type: "pie",
              data: [
                [
                  "parent",
                  memberIdentityMonthlyBreakDown.length > 0 &&
                    memberIdentityMonthlyBreakDown[0].total_users,
                ],
                [
                  "student",
                  memberIdentityMonthlyBreakDown.length > 0 &&
                    memberIdentityMonthlyBreakDown[1].total_users,
                ],
                [
                  "employee",
                  memberIdentityMonthlyBreakDown.length > 0 &&
                    memberIdentityMonthlyBreakDown[2].total_users,
                ],
                // ['other', memberIdentityMonthlyBreakDown.length > 0 && memberIdentityMonthlyBreakDown[4].total_users],
                // ['Young Professional', memberIdentityMonthlyBreakDown.length > 0 && memberIdentityMonthlyBreakDown[5].total_users],
                // ['A', memberIdentityMonthlyBreakDown.length > 0 && memberIdentityMonthlyBreakDown[6].total_users]
              ],
            },
          ],
        }}
      />
      {/* <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            plotShadow: false,
                            type: 'pie',
                            height: 310,
                            width: 260,
                            align: 'top'
                        },
                        credits: {
                            enabled: false
                        },
                        title: {
                            text: ''
                        },
                        tooltip: {
                            pointFormat: '{point.y:,.0f} ({point.percentage:.0f}%)'
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer'
                            },
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                        return Math.round((this.percentage * 100) / 100) + '%';
                                    },
                                    distance: -50,
                                    color: '#000',
                                    style: {
                                        fontSize: '14px'
                                    }
                                }
                            }
                        },
                        legend: {
                            align: 'bottom',
                            itemStyle: {
                                // color: '#A5A5A5',
                                fontWeight: 'normal',
                                fontSize: '13px',
                            },
                            // y:50
                        },
                        series: [{
                            name: 'Expenses',
                            type: 'pie',
                            data: [{
                                name: 'Students',
                                y: 450,
                                color: '#4472C4'
                            }, {
                                name: 'Monthly',
                                y: 50,
                                color: '#A5A5A5'
                            }, {
                                name: 'Semi Annually',
                                y: 50,
                                color: '#FFC000'
                            }, {
                                name: 'Annually',
                                y: 75,
                                color: '#70AD47'
                            }, {
                                name: 'Others',
                                y: 51,
                                color: '#ED7D31'
                            }],
                            showInLegend: false,
                        }]
                    }
                }
            />
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'spline'
                        },

                        legend: {
                            symbolWidth: 40
                        },

                        title: {
                            text: 'Most common desktop screen readers'
                        },

                        subtitle: {
                            text: 'Source: WebAIM. Click on points to visit official screen reader website'
                        },

                        yAxis: {
                            title: {
                                text: 'Percentage usage'
                            },
                            accessibility: {
                                description: 'Percentage usage'
                            }
                        },

                        xAxis: {
                            title: {
                                text: 'Time'
                            },
                            accessibility: {
                                description: 'Time from December 2010 to September 2019'
                            },
                            categories: ['December 2010', 'May 2012', 'January 2014', 'July 2015', 'October 2017', 'September 2019']
                        },

                        tooltip: {
                            valueSuffix: '%'
                        },

                        plotOptions: {
                            series: {
                                point: {
                                    events: {
                                        click: function () {
                                            window.location.href = this.series.options.website;
                                        }
                                    }
                                },
                                cursor: 'pointer'
                            }
                        },
                        series: [
                            {
                                name: 'NVDA',
                                data: [34.8, 43.0, 51.2, 41.4, 64.9, 72.4],
                                website: 'https://www.nvaccess.org',
                                color: 'red',
                                accessibility: {
                                    description: 'This is the most used screen reader in 2019'
                                }
                            }, {
                                name: 'JAWS',
                                data: [69.6, 63.7, 63.9, 43.7, 66.0, 61.7],
                                website: 'https://www.freedomscientific.com/Products/Blindness/JAWS',
                                dashStyle: 'ShortDashDot',
                                color: 'blue'
                            }, {
                                name: 'VoiceOver',
                                data: [20.2, 30.7, 36.8, 30.9, 39.6, 47.1],
                                website: 'http://www.apple.com/accessibility/osx/voiceover',
                                dashStyle: 'ShortDot',
                                color: 'green'
                            }, {
                                name: 'Narrator',
                                data: [null, null, null, null, 21.4, 30.3],
                                website: 'https://support.microsoft.com/en-us/help/22798/windows-10-complete-guide-to-narrator',
                                dashStyle: 'Dash',
                                color: 'yellow'
                            }, {
                                name: 'ZoomText/Fusion',
                                data: [6.1, 6.8, 5.3, 27.5, 6.0, 5.5],
                                website: 'http://www.zoomtext.com/products/zoomtext-magnifierreader',
                                dashStyle: 'ShortDot',
                                color: 'gray'
                            }, {
                                name: 'Other',
                                data: [42.6, 51.5, 54.2, 45.8, 20.2, 15.4],
                                website: 'http://www.disabled-world.com/assistivedevices/computer/screen-readers.php',
                                dashStyle: 'ShortDash',
                                color: 'orange'
                            }
                        ],

                    }
                }
            />
            <HighchartsReact
                highcharts={Highcharts}
                options={
                    {
                        chart: {
                            type: 'line',
                            inverted: true,
                            height: '80%'
                        },

                        title: {
                            text: 'U.S. states by population in 2016'
                        },

                        xAxis: {
                            visible: false
                        },

                        yAxis: {
                            visible: false
                        },

                        colorAxis: {
                            dataClasses: [{
                                from: 0,
                                to: 1000000,
                                color: '#F9EDB3',
                                name: '< 1M'
                            }, {
                                from: 1000000,
                                to: 5000000,
                                color: '#FFC428',
                                name: '1M - 5M'
                            }, {
                                from: 5000000,
                                to: 20000000,
                                color: '#FF7987',
                                name: '5M - 20M'
                            }, {
                                from: 20000000,
                                color: '#FF2371',
                                name: '> 20M'
                            }]
                        },

                        tooltip: {
                            headerFormat: '',
                            pointFormat: 'The population of <b> {point.name}</b> is <b>{point.value}</b>'
                        },

                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.hc-a2}',
                                    color: '#000000',
                                    style: {
                                        textOutline: false
                                    }
                                }
                            }
                        },
                        series: [{
                            name: '',
                            data: [{
                                'hc-a2': 'AL',
                                name: 'Alabama',
                                region: 'South',
                                x: 6,
                                y: 7,
                                value: 4849377
                            }, {
                                'hc-a2': 'AK',
                                name: 'Alaska',
                                region: 'West',
                                x: 0,
                                y: 0,
                                value: 737732
                            }, {
                                'hc-a2': 'AZ',
                                name: 'Arizona',
                                region: 'West',
                                x: 5,
                                y: 3,
                                value: 6745408
                            }, {
                                'hc-a2': 'AR',
                                name: 'Arkansas',
                                region: 'South',
                                x: 5,
                                y: 6,
                                value: 2994079
                            }, {
                                'hc-a2': 'CA',
                                name: 'California',
                                region: 'West',
                                x: 5,
                                y: 2,
                                value: 39250017
                            }, {
                                'hc-a2': 'CO',
                                name: 'Colorado',
                                region: 'West',
                                x: 4,
                                y: 3,
                                value: 5540545
                            }, {
                                'hc-a2': 'CT',
                                name: 'Connecticut',
                                region: 'Northeast',
                                x: 3,
                                y: 11,
                                value: 3596677
                            }, {
                                'hc-a2': 'DE',
                                name: 'Delaware',
                                region: 'South',
                                x: 4,
                                y: 9,
                                value: 935614
                            }, {
                                'hc-a2': 'DC',
                                name: 'District of Columbia',
                                region: 'South',
                                x: 4,
                                y: 10,
                                value: 7288000
                            }]
                        }]
                    }
                }
            /> */}
    </div>
  );
};

export default MemberDashboard;
