import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import UserUtils from "../utilities/userUtils";
import { createHttpLink } from "apollo-link-http";
import { createUploadLink } from "apollo-upload-client";
import errorHandler from "./error-handler";
import { onError } from "apollo-link-error";
import { persistCache } from "apollo-cache-persist";
import resolvers from "../graphql/resolvers";
import { setContext } from "apollo-link-context";
import { withClientState } from "apollo-link-state";
const cache = new InMemoryCache({
  addTypename: false,
});

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL}/graphql` || "",
});

const authLink = setContext((_, { headers }) => {
  const token = UserUtils.getAccessToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const defaults = {
  isLoggedIn: false,
  isAdmin: false,
  dialogs: [
    {
      __typename: "Dialog",
      id: "login",
      isOpen: false,
    },
  ],
};

persistCache({
  cache,
  storage: window.localStorage,
  maxSize: false,
});

const stateLink = withClientState({
  defaults,
  resolvers,
  cache,
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(errorHandler),
    stateLink,
    authLink.concat(httpLink),
  ]),
  cache,
  resolvers,
  typeDefs: {},
  connectToDevTools: true,
});

client.onResetStore(stateLink.writeDefaults);

export default client;
