export const question = {
  level1: {
    modalHeaderName: "Level 1 Tracker: What is Personal Finance?",
    questions: [
      {
        id: 1,
        name: "What is Personal Finance?",
      },
    ],
  },

  level2: {
    modalHeaderName: "Level 2 Tracker: Income + Taxes",
    questions: [
      {
        id: 1,
        name: "3 Types of Income",
      },
      {
        id: 2,
        name: "Personal Income Taxes 101",
      },
      {
        id: 3,
        name: "Federal Income Taxes",
      },
      {
        id: 4,
        name: "Filling Your Taxes",
      },
    ],
  },
  level3: {
    modalHeaderName: "Level 3 Tracker: Budgeting + Saving",
    questions: [
      {
        id: 1,
        name: "Budgeting 101",
      },
      {
        id: 2,
        name: "Budgeting Guidelines",
      },
    ],
  },
  challenge1: {
    modalHeaderName: "Challenge 01 Tracker",
  },
  level4: {
    modalHeaderName: "Level 4 Tracker: Bank Accounts + Bank Cards",
    questions: [
      {
        id: 1,
        name: "Checking v Savings Accounts",
      },
      {
        id: 2,
        name: "Debit Card v Credit Card",
      },
      {
        id: 3,
        name: "7 Tips to Avoid Fees and Bank Responsibly",
      },
    ],
  },
  level5: {
    modalHeaderName: "Level 5 Tracker: Online + Mobile Banking",
    questions: [
      {
        id: 1,
        name: "Online Banking",
      },
      {
        id: 2,
        name: "Mobile Banking",
      },
      {
        id: 3,
        name: "Safe Online Banking Tips",
      },
    ],
  },
  level6: {
    modalHeaderName: "Level 6 Tracker: Credit Score + Debt",
    questions: [
      {
        id: 1,
        name: "Credit Score 101",
      },
      {
        id: 2,
        name: "Debunking Credit Score Myths",
      },
      {
        id: 3,
        name: "Loans 101",
      },
      {
        id: 4,
        name: "Student Loans",
      },
      {
        id: 5,
        name: "Loan Repayment",
      },
      {
        id: 6,
        name: "How to Lower the Cost of a Loan",
      },
    ],
  },
  challenge2: {
    modalHeaderName: "Challenge 02 Tracker",
  },
  level7: {
    modalHeaderName: "Level 7 Tracker: Basics of Investing",
    questions: [
      {
        id: 1,
        name: "Investing 101",
      },
      {
        id: 2,
        name: "Stocks and Bonds",
      },
      {
        id: 3,
        name: "Funds and Alternative Investments",
      },
      {
        id: 4,
        name: "Retirement Accounts",
      },
      {
        id: 5,
        name: "How to Start Investing",
      },
    ],
  },
  level8: {
    modalHeaderName: "Level 8 Tracker: Basics of Insurance",
    questions: [
      {
        id: 1,
        name: "What is Health Insurance?",
      },
      {
        id: 2,
        name: "Life Insurance 101",
      },
      {
        id: 3,
        name: "Asset Insurance",
      },
    ],
  },
  level9: {
    modalHeaderName: "Level 9 Tracker: Goals + Purpose",
    questions: [
      {
        id: 1,
        name: "SMART Goals",
      },
      {
        id: 2,
        name: "Personal Finance With a Purpose",
      },
    ],
  },
  challenge3: {
    modalHeaderName: "Challenge 03 Tracker",
  },
  level10: {
    modalHeaderName: "Level 10 Tracker: Wrapup + Review",
  },
};
