import * as React from "react";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { isEmpty } from "lodash";
import styled from "@emotion/styled";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#CFD5E9",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#E8EBF4",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function BasicTable({ tableHead = [], tableRow = [] }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: "#4472C4" }}>
          <TableRow>
            {tableHead.map(({ name }) => (
              <TableCell
                sx={{
                  color: "#fff",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
                size="small"
              >
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRow.map((row, i) => (
            <>
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  size="small"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "50rem",
                    width: "50rem",
                  }}
                  component="th"
                  scope="row"
                >
                  {row[tableHead[i]["key"]]}
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "50rem",
                    width: "50rem",
                  }}
                  component="th"
                  scope="row"
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "50rem",
                    width: "50rem",
                  }}
                  component="th"
                  scope="row"
                >
                  {(!isEmpty(row.activity_date_time || []) &&
                    (row.activity_date_time || []).map((item) => (
                      <p style={{ padding: 0, margin: 0 }}>
                        {item?.time || "N/A"}
                      </p>
                    ))) ||
                    "N/A"}
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "50rem",
                    width: "50rem",
                  }}
                  component="th"
                  scope="row"
                >
                  {row.exercise_time || "N/A"}
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "50rem",
                    width: "50rem",
                  }}
                  component="th"
                  scope="row"
                >
                  {row.exercise_date || "Pending"}
                </StyledTableCell>
                <StyledTableCell
                  size="small"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "50rem",
                    width: "50rem",
                  }}
                  component="th"
                  scope="row"
                >
                  {row.points}
                </StyledTableCell>
              </StyledTableRow>
              {!isEmpty(row?.avg_time_data) && (
                <StyledTableRow key={row.name}>
                  <StyledTableCell
                    size="small"
                    sx={{
                      whiteSpace: "nowrap",
                      maxWidth: "50rem",
                      width: "50rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    Average
                  </StyledTableCell>
                  <StyledTableCell
                    size="small"
                    sx={{
                      whiteSpace: "nowrap",
                      maxWidth: "50rem",
                      width: "50rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    --
                  </StyledTableCell>
                  <StyledTableCell
                    size="small"
                    sx={{
                      whiteSpace: "nowrap",
                      maxWidth: "50rem",
                      width: "50rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    {row?.avg_time_data?.[i]?.["avg_activity_time"] || "N/A"}
                  </StyledTableCell>
                  <StyledTableCell
                    size="small"
                    sx={{
                      whiteSpace: "nowrap",
                      maxWidth: "50rem",
                      width: "50rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    {row?.avg_time_data?.[i]?.["avg_exercise_time"] || "N/A"}
                  </StyledTableCell>
                  <StyledTableCell
                    size="small"
                    sx={{
                      whiteSpace: "nowrap",
                      maxWidth: "50rem",
                      width: "50rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    --
                  </StyledTableCell>
                  <StyledTableCell
                    size="small"
                    sx={{
                      whiteSpace: "nowrap",
                      maxWidth: "50rem",
                      width: "50rem",
                    }}
                    component="th"
                    scope="row"
                  >
                    --
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
