import * as commonFunctions from "../../../utilities/commonFunctions";

import { Field, Form } from "react-final-form";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import { Grid } from "@material-ui/core";
import Pageloader from "../pageloader";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import compose from "recompose/compose";
import { loader } from "graphql.macro";
import { startCase } from "lodash";
import styles from "../add-users/styles";
import { useUserDetailsContext } from "../../../context/userDetails";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const CHECK_EMAIL = loader("../../../graphql/schema/ui/checkEmail.graphql");
const SCHOOL_USER_REGISTRATION = loader(
  "../../../graphql/schema/student-registration/school-user-registration.graphql"
);

const AddMember = (props) => {
  const { classes, history } = props;
  const { state } = useUserDetailsContext();
  const [admin_type, setAdmin_type] = useState("");
  const location = useLocation();

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [business, setBusiness] = useState("");

  useEffect(() => {
    setAdmin_type(
      location.pathname === "/add_student"
        ? "school"
        : location.pathname === "/add_member" && "business"
    );
  }, []);

  React.useEffect(() => {
    const id = JSON.parse(localStorage.getItem("institution"));
    setBusiness(
      id !== null && id !== undefined && id !== "" ? id.institution_name : ""
    );
  }, []);

  const [checkEmailExist] = useLazyQuery(CHECK_EMAIL, {
    variables: {
      email: emailValue,
    },
    fetchPolicy: "network-only",
    onCompleted({ CheckEmail: { status } }) {
      if (status) {
        setEmailError("");
        setEmailSuccess(true);
      }
    },
    onError(errors) {
      setEmailSuccess(false);
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (emailValue !== "") {
        setEmailError(formatedErrors);
      }
    },
  });

  const [schoolUserRegistration] = useMutation(SCHOOL_USER_REGISTRATION, {
    onCompleted(res) {
      setLoading(false);
      history.push(
        admin_type === "school" ? "/students_management" : "member_management"
      );
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`forgot-password-error-${Math.random()}`}>{message}</span>
      ));
      if (emailValue !== "") {
        setErrorMessage(formatedErrors);
      }
      setLoading(false);
    },
  });

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required.";
    }

    if (values.email) {
      if (!commonFunctions.validateEmail(values.email)) {
        errors.email = "Enter valid email.";
      }
      setEmailValue(values.email);
    }
    if (!values.email) {
      errors.email = "email is required.";
      setEmailValue("");
    }

    if (!values.confirm_email) {
      errors.confirm_email = "confirm email is required.";
    } else {
      if (values.email !== values.confirm_email) {
        errors.confirm_email = "confirm email should be match.";
        setConfirmEmail("wrong");
      } else {
        setConfirmEmail("right");
      }
    }
    if (!values.groupName) {
      errors.groupName = "group is required.";
    }
    return errors;
  };

  const onSubmit = (values) => {
    setLoading(true);
    schoolUserRegistration({
      variables: {
        data: {
          school_id: state?.id,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          alias_name: values.alias_name,
          grade: values.groupName,
          school: business,
        },
      },
    });
  };

  return (
    <Typography className="edit-page-new formError">
      {loading === true ? <Pageloader loading={loading} /> : ""}
      <div className={classes.backToAdmin}>
        <Link
          to={
            admin_type === "school"
              ? "/students_management"
              : "member_management"
          }
        >
          <i className="fa fa-long-arrow-left" aria-hidden="true"></i> Back to
          User Management
        </Link>
      </div>
      <div className={classes.formBlock}>
        <h3>Add User</h3>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Form
              validate={validate}
              onSubmit={onSubmit}
              initialValues={{
                business: business,
              }}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.fields}>
                    <div className={classes.formGroup}>
                      <label>First Name </label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="first_name">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Mike"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Last Name</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="last_name">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Inkwiry"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Alias Name</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="alias_name">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Alias"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Email</label>
                      <div
                        className={classNames(
                          classes.inputLeft,
                          classes.iconError
                        )}
                      >
                        <Field name="email">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. mike@inkwiry.com"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                                onBlur={(e) => {
                                  checkEmailExist(e);
                                }}
                              />
                              {emailError && (
                                <span className={classes.iconWrong}>
                                  <img src="./img/fail.png" alt="" />
                                </span>
                              )}
                              {emailSuccess && (
                                <span className={classes.iconWright}>
                                  <img src="./img/pass.png" alt="" />
                                </span>
                              )}
                              <div className={classes.emailExist}>
                                {emailError}
                              </div>
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className={classes.formGroup}>
                      <label>Confirm Email</label>
                      <div
                        className={classNames(
                          classes.inputLeft,
                          classes.iconError
                        )}
                      >
                        <Field name="confirm_email">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. mike@inkwiry.com"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {confirmEmail === "wrong" && (
                                <span className={classes.iconWrong}>
                                  <img src="./img/fail.png" alt="" />
                                </span>
                              )}
                              {confirmEmail === "right" && (
                                <span className={classes.iconWright}>
                                  <img src="./img/pass.png" alt="" />
                                </span>
                              )}
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>{startCase(admin_type)}</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="business">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. Sophomore"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                disabled
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>Group</label>
                      <div className={classes.inputLeftSelect}>
                        <Field name="groupName">
                          {({ input, meta }) => (
                            <div>
                              <TextField
                                name={input?.name}
                                placeholder="ex. community"
                                type="text"
                                value={input?.value}
                                onChange={input?.onChange}
                                required
                                fullWidth
                                className={classes.inputLeft}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span className={classes.noteError}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.updateBtn}>
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </form>
              )}
            />
            {errorMessage && (
              <div className={classes.noteError}>{errorMessage}</div>
            )}
          </Grid>
        </Grid>
      </div>
    </Typography>
  );
};
AddMember.propTypes = {
  classes: PropTypes.object.isRequired,
  classNames: PropTypes.object.isRequired,
};
const enhance = compose(withStyles(styles), withRouter, withApollo);

export default enhance(AddMember);
